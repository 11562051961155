import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {formatDate} from '@angular/common';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-hour-edit-dialog',
    templateUrl: './hour-edit-dialog.component.html',
    styleUrls: ['./hour-edit-dialog.component.scss']
})
export class HourEditDialogComponent implements OnInit {

    hours: { hour: string, day: string, time: string }[];
    minutes: string[];

    selectedminute: string;
    selectedhour: string;

    moving = '';

    hourDateMap = new Map<string, Date>();

    timespan = 23;

    showNowButton = false;

    constructor(public dialogRef: MatDialogRef<HourEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    date: Date,
                    minDate: Date,
                    maxDate: Date,
                    addToCurrentTime: number,
                    hideNowButton: boolean,
                    extendToEarlier: boolean
                }) {
    }

    genMinutes(min = 0, max = 60) {
        this.minutes = [];
        while (min % 3 !== 0) {
            min++;
        }
        for (let i = min; i <= Math.min(57, max); i = i + 3) {
            let s = `${i}`;
            s = s.length < 2 ? '0' + s : s;
            this.minutes.push(s);
        }
    }

    ngOnInit(): void {
        this.data.minDate = new Date(this.data.minDate);
        this.hours = [];

        const loopDate = new Date(this.data.minDate);
        let maxDate = new Date(this.data.minDate);
        maxDate.setHours(maxDate.getHours() + this.timespan);
        if (this.data.maxDate) {
            maxDate = new Date(this.data.maxDate);
        }
        const currentTime = new Date();
        this.showNowButton = (currentTime.getTime() >= loopDate.getTime() && currentTime.getTime() <= maxDate.getTime());

        while (loopDate < maxDate) {
            let s = `${loopDate.getHours()}`;
            s = s.length < 2 ? '0' + s : s;
            this.hours.push({
                hour: s,
                day: formatDate(loopDate, 'E', 'nl'),
                time: `${loopDate.getTime()}`
            });
            loopDate.setHours(loopDate.getHours() + 1);
            this.hourDateMap.set(s, new Date(loopDate));
        }

        this.genMinutes();

        let time = new Date(this.data.date);
        if (time.getTime() < this.data.minDate.getTime()) {
            time = new Date(this.data.minDate);
        }

        this.setSelectedTime(new Date(time), 'auto');

        setTimeout(() => {
            fromEvent(document.querySelector(`.selectors .minutes`), 'scroll').pipe(debounceTime(50)).subscribe(() => {
                this.scrollHandeling('minute');
            });
            fromEvent(document.querySelector(`.selectors .hours`), 'scroll').pipe(debounceTime(50)).subscribe(() => {
                this.scrollHandeling('hour');
            });
        }, 100);
    }

    private setSelectedTime(time: Date, behavior: ScrollBehavior = 'smooth') {
        const inpHour = Utils.setTime(new Date(time), time.getHours(), this.data.minDate.getMinutes()).getTime();
        while ((time.getMinutes() % 3) !== 0) {
            time.setMinutes(time.getMinutes() + 1);
        }
        const inpMinute = `${time.getMinutes()}`;
        this.selectedhour = `${inpHour}`;
        this.selectedminute = inpMinute.length < 2 ? '0' + inpMinute : inpMinute;
        setTimeout(() => {
            this.setVisibleTime(behavior);
        });
    }

    public setVisibleTime(behavior: ScrollBehavior) {
        if (this.data.minDate && this.data.minDate.getHours() === new Date(+this.selectedhour).getHours()) {
            this.genMinutes(this.data.minDate.getMinutes());
        } else if (this.data.maxDate && this.data.maxDate.getHours() === new Date(+this.selectedhour).getHours()) {
            this.genMinutes(0, this.data.maxDate.getMinutes());
        } else {
            this.genMinutes();
        }
        setTimeout(() => {
            if (!this.minutes.includes(this.selectedminute)) {
                this.selectedminute = this.minutes[0];
            }

            this.data.date = new Date(this.hourDateMap.get(this.selectedhour));
            this.data.date.setTime(+this.selectedhour);
            this.data.date.setMinutes(+this.selectedminute);
            const element = document.querySelector('.selectors .hours') as HTMLElement;
            const scrollToMinute = (document.querySelector(`#minute${this.selectedminute}`) as HTMLElement).offsetTop - ((element.clientHeight / 7) * 3);
            document.querySelector(`.selectors .minutes`).scrollTo({top: scrollToMinute, behavior});
            const scrollToHour = (document.querySelector(`#hour${this.selectedhour}`) as HTMLElement)?.offsetTop - ((element.clientHeight / 7) * 3);
            document.querySelector(`.selectors .hours`).scrollTo({top: scrollToHour, behavior});
        });
    }

    currentTime() {
        const currentTime = new Date();
        currentTime.setMinutes(currentTime.getMinutes() + this.data.addToCurrentTime);
        this.setSelectedTime(currentTime);
    }

    scrollHandeling(type: 'hour' | 'minute') {
        const element = document.querySelector(`.selectors .${type}s`) as HTMLElement;
        const selectorElem = document.querySelector('.selectors') as HTMLElement;
        const y = selectorElem.offsetTop + (element.clientHeight / 2);
        const x = selectorElem.offsetLeft + (element.clientWidth / 2) + element.offsetLeft;
        const timeDiv = document.elementFromPoint(x, y);
        const selected = timeDiv.id.replace(type, '').trim();
        if (selected) {
            this[`selected${type}`] = selected;
        }
        this.setVisibleTime('smooth');
    }

    selectAndClose() {
        this.dialogRef.close(this.data.date);
    }

    touchmove(event: MouseEvent, type: 'hour' | 'minute') {
        if (event.buttons && (!this.moving || this.moving === type)) {
            this.moving = type;
            document.querySelector(`.selectors .${type}s`).scrollTo({top: document.querySelector(`.selectors .${type}s`).scrollTop - event.movementY});
        } else if (!event.buttons) {
            this.moving = '';
        }
    }

    clickHour(hour) {
        if (!this.moving) {
            this.selectedhour = hour;
            this.setVisibleTime('smooth');
        }
    }

    clickMinute(minute) {
        if (!this.moving) {
            this.selectedminute = minute;
            this.setVisibleTime('smooth');
        }
    }
}
