<div [class.dragover]="dragOver" [class.uploading]="uploading" class="dialog-container">
    <h1 class="condensed dialog-title">
        Asfaltlijst importeren
    </h1>
    <div class="dialog-content mt-3">
        <form [formGroup]="form">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Naam van de Molen</mat-label>
                <input [matAutocomplete]="auto" aria-label="Number" formControlName="name" matInput placeholder="Naam" type="text">
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let mill of asphaltMills" [value]="mill.name">
                        {{mill.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="file">
                <b>Geüpload bestand:</b> {{file.name}}
                <br>
            </div>
        </form>
        <div>
            <ng-container *ngIf="!file">Geen bestand toegevoegd</ng-container>
            <button color="primary" mat-button class="p-0 px-1">
                <label class="p-1 mt-0 mr-0 mb-0">
                    Bestand {{file ? 'wijzigen' : 'uploaden' }} <input (change)="selectFile($event)" hidden multiple type="file">
                </label>
            </button>
        </div>
    </div>
    <div class="d-flex flex-wrap">
        <button [mat-dialog-close]="false" mat-button>Annuleren</button>
        <div class="spacer"></div>
        <button (click)="import()" [disabled]="saving || !form.valid || !file" cdkFocusInitial color="primary" mat-raised-button>
            Importeren <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
    <div class="overlay">
        <div class="overlay-wrapper drag">
            <div class="upload-icon">
                <i class="fas fa-upload"></i>
            </div>
        </div>
        <div class="overlay-wrapper uploading">
            <div class="upload-icon">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
            <div>
                Bezig met importeren...
            </div>
        </div>
    </div>
</div>
