import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';

@Pipe({
    name: 'isOtherDate'
})
export class IsOtherDatePipe implements PipeTransform {

    transform(dateOne: Date, dateTwo: Date): boolean {
        return Utils.dateString(dateOne) !== Utils.dateString(dateTwo);
    }

}
