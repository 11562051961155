import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Planning} from '../../classes/planning.class';
import {combineLatest, Observable} from 'rxjs';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {PlanningAsfaltteam} from '../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {WebsocketService} from '../websocket/websocket.service';
import {Utils} from '../../utils.class';
import {RealtimeService} from '../realtime/realtime.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {map, startWith} from 'rxjs/operators';
import {PlanningStatus} from '../../planning-status.enum';
import {EntityTypeCode} from '../entities/entity-type.class';
import {formatDate} from '@angular/common';
import {PlanningProjectteam} from '../../classes/planningprojectteam.class';
import {PlanningPreparationteam} from '../../classes/planningpreparationteam.class';


export class TonsForDate {
    public maxForView: number;

    constructor(public date: Date, public tons: TonsPerPeriod[]) {
    }
}

export class TonsPerPeriod {
    constructor(public delivery: number, public returning: number) {
    }
}

export class TrucksForDate {
    public available: number;

    constructor(public date: Date, public trucks: number[]) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class PlanningService extends RealtimeService<Planning> {
    public mouseEnterPlanning = new EventEmitter<Planning>();
    public mouseLeavePlanning = new EventEmitter<Planning>();
    public planningEmitters = new Map<Planning, EventEmitter<Planning>>();

    constructor(protected apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.planning);
    }

    public deletePlanning(planningId: number) {
        return this.apiService.deleteCall<boolean>(`planning/${planningId}`);
    }

    public newAsfaltTeamPlanning() {
        const planning = new Planning();
        planning.planning_asfaltteam = new PlanningAsfaltteam();
        planning.planning_asfaltteam.entitytype_id = EntityTypeCode.AsfaltTeam;
        planning.asphalt_list = [];
        planning.planning_cutters = [];
        planning.planning_wipetrucks = [];
        planning.planning_sets = [];
        return planning;
    }

    public newProjectteamPlanning() {
        const planning = new Planning();
        planning.planning_projectteam = new PlanningProjectteam();
        planning.planning_projectteam.entitytype_id = EntityTypeCode.ProjectTeam;
        planning.asphalt_list = [];
        planning.planning_cutters = [];
        planning.planning_wipetrucks = [];
        planning.planning_sets = [];
        return planning;
    }

    public newPreparationteamPlanning() {
        const planning = new Planning();
        planning.planning_preparationteam = new PlanningPreparationteam();
        planning.planning_preparationteam.entitytype_id = EntityTypeCode.PreparationTeam;
        planning.planning_cutters = [];
        planning.planning_wipetrucks = [];
        return planning;
    }

    public newCutterPlanning() {
        const planning = new Planning();
        planning.planning_cutters = [new PlanningCutter()];
        planning.planning_cutters[0].entitytype_id = EntityTypeCode.Cutter;
        planning.planning_wipetrucks = [];
        return planning;
    }

    public newWipetruckPlanning() {
        const planning = new Planning();
        planning.planning_wipetrucks = [new PlanningHasEntity()];
        planning.planning_wipetrucks[0].entitytype_id = EntityTypeCode.Wipetruck;
        return planning;
    }

    public itemInDaterange(planning: Planning, fromDate: Date, toDate: Date) {
        return Utils.planningInDaterange(planning, fromDate, toDate);
    }

    public newPlanningByType(type: EntityTypeCode): Planning {
        if (type === EntityTypeCode.AsfaltTeam) {
            return this.newAsfaltTeamPlanning();
        }
        if (type === EntityTypeCode.ProjectTeam) {
            return this.newProjectteamPlanning();
        }
        if (type === EntityTypeCode.PreparationTeam) {
            return this.newPreparationteamPlanning();
        }
        if (type === EntityTypeCode.Cutter) {
            return this.newCutterPlanning();
        }
        if (type === EntityTypeCode.Wipetruck) {
            return this.newWipetruckPlanning();
        }
    }

    public getMainPlanning(planning: Planning): PlanningHasEntity {
        return Utils.mainPlanning(planning);
    }

    public generatePdf(date: Date) {
        const dateString = formatDate(date, 'yyyy-MM-dd', 'nl');
        return this.apiService.getBlobCall(`planning/pdf/${dateString}`);
    }

    public generateTransportPdf(date: Date) {
        const dateString = formatDate(date, 'yyyy-MM-dd', 'nl');
        return this.apiService.getBlobCall(`planning/transport-pdf/${dateString}`);
    }

    public save(planning: Planning): Promise<Planning> {
        return this.apiService.postCall(this.type, planning);
    }

    saveOrDeleteMultiple(planningHas: PlanningHasEntity[]) {
        return this.apiService.postCall$('planning/multisave', {
            planningHas
        });
    }

    public saveMultiple(plannings: any, planningId) {
        return this.apiService.postCall$<boolean>(this.type + '/multi', {planningHas: plannings, planningId});
    }

    public saveSingle(planning: any, skipWebsocket = false): Promise<PlanningHasEntity> {
        return this.apiService.postCall(this.type + '/single', {planning, skipWebsocket});
    }

    savePlanningComment(planningId, comment) {
        return this.apiService.postCall$<Planning>(this.type + '/comment', {planningId, comment});
    }

    public deleteSingle(planning: any): Promise<Planning> {
        return this.apiService.deleteCall(this.type + '/single', planning);
    }

    public getProjectmangerReport() {
        return this.apiService.getCall(this.type + '/projectmanager');
    }

    public checkEntityAvailability(entityId: number, planningId: number, beginDate: Date, endDate: Date, skipUnavailable = false): Observable<boolean> {
        return this.apiService.postCallObserver(`${this.type}/check`,
            {entityId, planningId, beginDate, endDate, skipUnavailable}
        );
    }

    applyAllEntitiesToPlanningHas(list: Planning[]) {
        list.forEach(entity => {
            const allEntities = Utils.planningAllEntities(entity) as PlanningHasEntity[];
            entity.planning_has = entity.planning_has ?? [];
            allEntities.forEach(allEntity => {
                if (!entity.planning_has.find(eph => eph === allEntity)) {
                    entity.planning_has = entity.planning_has.filter(eph => eph.id !== allEntity.id);
                    entity.planning_has.push(allEntity);
                }
            });
        });
    }

    public getListByStatus(status: PlanningStatus) {
        return this.getList().pipe(map(list => {
            this.applyAllEntitiesToPlanningHas(list);
            return list.filter(p => p.status_id === status);
        }));
    }

    public getFilteredList(fromDate: Date, toDate: Date) {
        return super.getFilteredList(fromDate, toDate).pipe(map(list => {
            this.applyAllEntitiesToPlanningHas(list);
            return list;
        }));
    }

    public getTons(fromDate: Date, toDate: Date, specificDate: Date): Observable<TonsForDate> {
        return new Observable<TonsForDate>((observer) => {
            this.getFilteredList(fromDate, toDate).subscribe((planning) => {
                const countDate = new Date(fromDate);
                let tonDates: TonsForDate[] = [];
                while (countDate.getTime() < toDate.getTime()) {
                    tonDates.push(new TonsForDate(new Date(countDate), [
                        new TonsPerPeriod(
                            Utils.getDeliveryTons(planning, countDate, 0, 6),
                            Utils.getReturnTonsForTime(planning, countDate, 0, 6)
                        ),
                        new TonsPerPeriod(
                            Utils.getDeliveryTons(planning, countDate, 6, 12),
                            Utils.getReturnTonsForTime(planning, countDate, 6, 12)
                        ),
                        new TonsPerPeriod(
                            Utils.getDeliveryTons(planning, countDate, 12, 18),
                            Utils.getReturnTonsForTime(planning, countDate, 12, 18)
                        ),
                        new TonsPerPeriod(
                            Utils.getDeliveryTons(planning, countDate, 18, 24),
                            Utils.getReturnTonsForTime(planning, countDate, 18, 24)
                        )
                    ]));
                    countDate.setDate(countDate.getDate() + 1);
                }
                const maxTons = Math.max(...tonDates.map(p => Math.max(...p.tons.map(q => q.delivery + q.returning))));
                tonDates.forEach(tonDate => tonDate.maxForView = maxTons);
                observer.next(tonDates.find(p => p.date.getDate() === specificDate.getDate()));
            });
        });
    }

    public getCountOfUsedTrucks(fromDate: Date, toDate: Date, specificDate: Date): Observable<TrucksForDate> {
        return this.getFilteredList(fromDate, toDate).pipe(map((planning) => {
            return new TrucksForDate(new Date(specificDate), [
                Utils.getCountOfUsedTrucksForTime(planning, specificDate, 0, 6),
                Utils.getCountOfUsedTrucksForTime(planning, specificDate, 6, 12),
                Utils.getCountOfUsedTrucksForTime(planning, specificDate, 12, 18),
                Utils.getCountOfUsedTrucksForTime(planning, specificDate, 18, 24)
            ]);
        }));
    }

    public getMaxUsedTrucksForRange(fromDate: Date, toDate: Date, skipPlanningId?: number, appendPlanning?: Planning): Observable<number> {
        const objectEmitter = this.updatePlanning(appendPlanning).pipe(startWith(appendPlanning));

        return combineLatest(this.getFilteredList(fromDate, toDate), objectEmitter)
            .pipe(map(([planning, emittedPlanning]) => {
                return Utils.getCountOfUsedTrucks(planning, fromDate, toDate, skipPlanningId, appendPlanning);
            }));
    }

    public updatePlanning(planning: Planning) {
        if (!this.planningEmitters.has(planning)) {
            this.planningEmitters.set(planning, new EventEmitter<Planning>());
        }

        this.planningEmitters.get(planning).emit(planning);

        return this.planningEmitters.get(planning);
    }

}
