import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {PlanningService} from "../services/planning/planning.service";
import {PlanningStatus} from "../planning-status.enum";
import {Planning} from "../classes/planning.class";
import {Subscription} from "rxjs";
import {UserService} from "../services/user/user.service";
import {User} from "../classes/user.class";
import {PlanningDetailDialogService} from "../services/dialog/planning-detail-dialog.service";
import {EntityTypeCode} from "../services/entities/entity-type.class";

@Component({
    selector: 'app-planning-list',
    templateUrl: './planning-list.component.html',
    styleUrls: ['./planning-list.component.scss']
})
export class PlanningListComponent implements OnInit, OnDestroy {

    planning: Planning[];
    subscriptions = new Subscription();
    users: Map<number, User>;
    tons = new Map<number, number>();

    constructor(public dialogRef: MatDialogRef<PlanningListComponent>,
                public planningService: PlanningService,
                public planningDetailDialogService: PlanningDetailDialogService,
                public userService: UserService) {
    }

    ngOnInit() {
        this.planningService.getListByStatus(PlanningStatus.bakvanwessel).subscribe(list => {
            list.forEach(pln => {
                this.tons.set(pln.id, pln.asphalt_list.map(asphalt => asphalt.tons).reduce((sum, current) => sum + current, 0));
            });
            this.planning = list;
        });

        this.subscriptions.add(this.userService.getMap().subscribe(users => {
            this.users = users;
        }));

    }

    openPlanning(planning?) {
        if (!planning) {
            planning = this.planningService.newPlanningByType(EntityTypeCode.AsfaltTeam);
            const mainPlanning = this.planningService.getMainPlanning(planning);
            mainPlanning.begindate = null;
            mainPlanning.enddate = null;
            planning.status_id = PlanningStatus.bakvanwessel;
        }
        this.planningDetailDialogService.openPlanning(planning);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
