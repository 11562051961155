import {PlanningStatus} from '../../planning-status.enum';
import {PlanningDetailDialogComponent} from './planning-detail-dialog.component';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {Utils} from '../../utils.class';
import {EntitiesListAbstractComponent} from './entities-list-abstract.component';
import {UserService, UserType} from '../../services/user/user.service';

export class PlanningDetailDialogRequiredRules {
    static worknumber = (component: PlanningDetailDialogComponent) => {
        return [PlanningStatus.gepland, PlanningStatus.definitief].indexOf(component.statusControl.value) !== -1;
    };

    static location = (component: PlanningDetailDialogComponent) => {
        return ![PlanningStatus.reservering, PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].includes(component.statusControl.value);
    };

    static asphalt_location = (component: PlanningDetailDialogComponent) => {
        return ![PlanningStatus.reservering, PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].includes(component.statusControl.value) && component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam;
    };

    static sets = (component: PlanningDetailDialogComponent) => {
        return !UserService.userHasRights(UserType.WORKPLANNER) && component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam &&
            [PlanningStatus.reservering, PlanningStatus.bakvanwessel, PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].indexOf(component.statusControl.value) === -1;
    };

    static dumpers = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam &&
            PlanningStatus.definitief === component.statusControl.value;
    };

    static cranes = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam &&
            PlanningStatus.definitief === component.statusControl.value;
    };

    static rollers = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam &&
            PlanningStatus.definitief === component.statusControl.value;
    };

    static date = (component: PlanningDetailDialogComponent) => {
        return component.statusControl.value !== PlanningStatus.bakvanwessel;
    };

    static begintime = PlanningDetailDialogRequiredRules.date;
    static endtime = PlanningDetailDialogRequiredRules.date;

    static definitief = (component: PlanningDetailDialogComponent) => {
        return component.statusControl.value === PlanningStatus.definitief;
    };

    static definitiefTeam = (component: PlanningDetailDialogComponent) => {
        return component.statusControl.value === PlanningStatus.definitief
            && component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam;
    };

    static traffic_controllers = PlanningDetailDialogRequiredRules.definitiefTeam;
    static traffic = PlanningDetailDialogRequiredRules.definitiefTeam;
    static thickness = PlanningDetailDialogRequiredRules.definitief;
    static trucks = PlanningDetailDialogRequiredRules.definitief;
    static tons = PlanningDetailDialogRequiredRules.definitief;


    static pouring_off = PlanningDetailDialogRequiredRules.definitiefTeam;
    static hotbox = PlanningDetailDialogRequiredRules.definitiefTeam;
    static scatter = PlanningDetailDialogRequiredRules.definitiefTeam;
    static sticky_wagon = PlanningDetailDialogRequiredRules.definitiefTeam;

    static gepland = (component: PlanningDetailDialogComponent) => {
        return [PlanningStatus.definitief, PlanningStatus.gepland].indexOf(component.statusControl.value) !== -1;
    };

    static performer = PlanningDetailDialogRequiredRules.gepland;

    static asphalt_performer = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam &&
            [PlanningStatus.definitief, PlanningStatus.gepland].indexOf(component.statusControl.value) !== -1;
    };

    static asfaltteam = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam && component.statusControl.value !== PlanningStatus.bakvanwessel;
    };

    static projectteam = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.ProjectTeam
            && ![PlanningStatus.bakvanwessel, PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].includes(component.statusControl.value);
    };

    static wipetruck = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.Wipetruck;
    };

    static driver = (component: PlanningDetailDialogComponent) => {
        return component.mainPlanning.entitytype_id === EntityTypeCode.Wipetruck
            && PlanningDetailDialogRequiredRules.definitief(component);
    };

    static projectmanager = (component: PlanningDetailDialogComponent) => {
        return ![PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].includes(component.statusControl.value);
    };

    static contractor = (component: PlanningDetailDialogComponent) => {
        return ![PlanningStatus.geblokkeerd].includes(component.statusControl.value);
    };

}

export function disabledWhenFinal(component: PlanningDetailDialogComponent | EntitiesListAbstractComponent<any>): boolean {
    return !!component.planning.id && component.initialStatus === PlanningStatus.definitief && Utils.getTimeOrNull(component.fixedDate) >= Utils.getTimeOrNull(component.mainPlanning.begindate);
}

export function RequiredRule(component: PlanningDetailDialogComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const getControlName = (c: AbstractControl): string | null => {
            if (c && c.parent) {
                const formGroup = c.parent.controls;
                return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
            }
        };

        // controlName is empty on initializing because parent has not been set yet
        const controlName = getControlName(control);
        if (controlName) {
            const rule = PlanningDetailDialogRequiredRules[controlName];
            if (!rule) {
                throw('No rule defined for ' + controlName + ', please define rule in planning-detail-dialog.required-rules.ts');
            }

            if ((control.value == null || (Array.isArray(control.value) && control.value.length < 1)) && rule.call(null, component)) {
                return {required: true};
            }
            return null;
        }
        return {required: true};
    };


}
