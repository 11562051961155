import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {LocalStorage} from './storage.class';
import {Settings} from './settings.class';
import {Utils} from './utils.class';
import {UserType} from './services/user/user.service';
import {environment} from '../environments/environment';

@Component({template: ''})
export class CodaltComponent implements OnDestroy {

    UserType = UserType;
    Utils = Utils;
    Settings = Settings;
    LocalStorage = LocalStorage;
    environment = environment;

    protected subscriptions = new Subscription();

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
