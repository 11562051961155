import {PlanningDayItem} from './planning-day-item.class';
import {Entity} from './entity.class';
import {EntityType} from '../services/entities/entity-type.class';

export class Entities {
    public entity: Entity;
    public entitytype: EntityType;
    public items: PlanningDayItem[] = [];
    public unavailableDays: number;

}
