import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AsphaltUsedListItemComponent} from './asphalt-list-item/asphalt-used-list-item.component';
import {AsphaltService} from '../../services/asphalt/asphalt.service';
import {PlanningAsfaltteam} from '../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {Planning} from '../../classes/planning.class';
import {Asphaltmill} from '../../classes/asphaltmill.class';
import {ProjectUsedAsphalt} from '../../classes/project-used-asphalt.class';
import {Project} from '../../classes/project.class';

@Component({
    selector: 'app-asphalt-used-list',
    templateUrl: './asphalt-used-list.component.html',
    styleUrls: ['./asphalt-used-list.component.scss']
})
export class AsphaltUsedListComponent implements OnInit {

    @Input() formsDisabled: boolean;
    @Input() mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity;
    @Input() planning: Planning;
    @Input() projectUsedAsphaltList: ProjectUsedAsphalt[];
    @Input() project: Project;
    @Input() date: Date;
    @Input() isAdhesive = false;
    @Input() print = false;

    @ViewChildren(AsphaltUsedListItemComponent) asphaltListComponents: AsphaltUsedListItemComponent[];
    asphaltMills: Asphaltmill[];
    @ViewChildren('millSelect') private millSelects: QueryList<any>;

    constructor(private asphaltService: AsphaltService) {
    }


    ngOnInit() {
        if (typeof this.projectUsedAsphaltList === 'undefined') {
            this.projectUsedAsphaltList = [];
        }

        this.asphaltService.getAsphaltMills().then(asphaltMill => {
            this.asphaltMills = asphaltMill;
        });
    }


    public disableForms() {
        this.asphaltListComponents.forEach(asphaltListItem => {
            asphaltListItem.form.disable();
        });
    }

    public enableForms() {
        this.asphaltListComponents.forEach(asphaltListItem => {
            asphaltListItem.form.enable();
        });
    }

    public reValidateFields(daysDiff = 0) {
        this.asphaltListComponents.forEach(asphaltListItem => {
            asphaltListItem.validateForms(daysDiff).then(() => {
            }, () => {
            });
        });
    }

    public addAsphaltPlanning() {
        if (!this.projectUsedAsphaltList) {
            this.projectUsedAsphaltList = [];
        }
        const planningAsphalt = new ProjectUsedAsphalt();
        planningAsphalt.afas_project_id = this.project?.afas_project_id;
        this.projectUsedAsphaltList.push(planningAsphalt);
    }

    public validateForms(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Promise.all([
                ...this.asphaltListComponents.map(pef => pef.validateForms())
            ]).then(() => {
                resolve(true);
            }, () => {
                this.asphaltListComponents.forEach(asphaltListItem => {
                    if (!asphaltListItem.form.valid) {
                        for (const name of Object.keys(asphaltListItem.form.controls)) {
                            asphaltListItem.form.controls[name].markAsTouched();
                        }
                    }
                });
                reject();
            });
        });
    }

    public removeAsphaltPlanning(planning: ProjectUsedAsphalt) {
        this.projectUsedAsphaltList.splice(this.projectUsedAsphaltList.indexOf(planning), 1);
    }

    public checkDirty(): boolean {
        return !!this.asphaltListComponents.find(asphaltListItem => asphaltListItem.form.dirty);
    }

}
