import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserGroups, UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Subscription} from 'rxjs';
import {MatDialogRef} from "@angular/material/dialog";
import {DialogService} from "../../services/dialog/dialog.service";
import {UserEditComponent} from "../user-edit/user-edit.component";

@Component({
    selector: 'app-users-overview',
    templateUrl: './users-overview.component.html',
    styleUrls: ['./users-overview.component.scss']
})
export class UsersOverviewComponent implements OnInit, OnDestroy {

    public users: User[];
    UserGroups = UserGroups;
    private subscriptions = new Subscription();

    constructor(private userService: UserService,
                private confirmDialog: ConfirmDialogService,
                public dialogRef: MatDialogRef<UsersOverviewComponent>,
                public dialogService: DialogService) {
    }

    close() {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.subscriptions.add(this.userService.getList().subscribe(users => this.users = users));
    }

    deleteUser(event, user) {
        event.preventDefault();
        event.stopPropagation();
        this.confirmDialog.confirm('Verwijderen',
            `Weet u zeker dat u de gebruiker ${user.name} wilt verwijderen?`, 'Ja').then(() => {
            this.userService.deleteUser(user.id);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    edit(user?: User) {
        if (!user) {
            user = new User();
        }
        this.dialogService.open(UserEditComponent, user);
    }

}
