import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Location} from '@angular/common';
import {LocalStorage} from '../../storage.class';
import {CodaltComponent} from '../../codalt.component';
import {LoginFormState} from '../login-form-state.enum';
import {AuthService} from '../../services/auth/auth.service';
import {UserService, UserType} from '../../services/user/user.service';
import {debounceTime, first, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-link-login',
    templateUrl: './link-login.component.html',
    styleUrls: ['./link-login.component.scss']
})
export class LinkLoginComponent extends CodaltComponent implements OnInit, OnDestroy {

    LoginFormState = LoginFormState;

    loginFormState: LoginFormState = LoginFormState.FillIn;
    errorMessage = null;
    fcCode: FormControl = new FormControl(null, [Validators.pattern(/[0-9]+/)]);

    loginForm = new FormGroup<{
        email: AbstractControl,
        code: AbstractControl,
        password?: AbstractControl
    }>({
        email: new FormControl(LocalStorage.getMail(), [Validators.required, Validators.email]),
        code: this.fcCode
    });

    checkComplete = false;

    constructor(private confirmDialog: ConfirmDialogService,
                private location: Location,
                private authService: AuthService,
                private confirmDialogService: ConfirmDialogService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        super();
        this.subscriptions.add(this.loginForm.valueChanges.subscribe(() => {
            LocalStorage.setMail(this.loginForm.value.email);
        }));
        this.subscriptions.add(this.loginForm.get('email').valueChanges.subscribe((val: string) => {
            if (val.indexOf(' ') !== -1) {
                this.loginForm.get('email').setValue(val.replace(' ', ''));
            }
        }));

    }

    pasteCode(event: ClipboardEvent) {
        // @ts-ignore
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text').replace(/\D/g, '');
        this.fcCode.setValue(pastedText);
        return false;
    }

    ngOnInit() {
        this.subscriptions.add(this.activatedRoute.params.pipe(first()).subscribe((params) => {
            if (params['token'] && params['token'].length > 20) {
                LocalStorage.setUserToken(params['token']);
                if (params['millId']) {
                    this.router.navigate(['/asphalt-order-list', params['millId'], params['date']]);
                } else {
                    this.router.navigate(['/print', params['date']]);
                }
            }
        }));
        this.loginForm.get('email').valueChanges.pipe(debounceTime(200), startWith(this.loginForm.get('email').value)).subscribe(email => {
            this.checkLoginType();
        });
    }

    checkLoginType(event?: any) {
        event?.stopPropagation();
        this.checkComplete = false;
        if (this.loginForm.get('email').valid) {
            this.authService.loginType(this.loginForm.get('email').value).subscribe(type => {
                this.checkComplete = true;
                if (type?.data?.needspassword) {
                    this.loginForm.addControl('password', new FormControl('', Validators.required));
                    this.loginFormState = LoginFormState.FillInPassword;
                } else {
                    this.loginForm.removeControl('password');
                    this.loginFormState = LoginFormState.FillIn;
                }
                if (event) {
                    this.login();
                }
            }, () => {
                this.checkComplete = true;
            });
        }
    }

    login() {
        if (this.checkComplete && this.loginFormState !== LoginFormState.Login) {
            this.errorMessage = null;

            if (this.loginFormState === LoginFormState.FillInPassword && this.loginForm.get('password')?.value) {
                this.loginFormState = LoginFormState.Login;
                this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
                    authResponse => {
                        LocalStorage.setUserToken(authResponse.access_token);
                        LocalStorage.setUser(authResponse.user);
                        if (UserService.userHasRights(UserType.USER)) {
                            this.router.navigate(['']);
                        } else {
                            this.router.navigate(['/weekschedule']);
                        }

                    }, failReason => {
                        this.errorMessage = failReason.message;
                        if (!this.errorMessage) {
                            this.errorMessage = 'Kon de server niet bereiken';
                        }
                        this.loginFormState = LoginFormState.FillInPassword;
                    });
            } else if (this.loginFormState === LoginFormState.FillIn) {
                this.loginFormState = LoginFormState.Login;
                this.authService.loginEmail(this.loginForm.value.email).subscribe(
                    () => {
                        this.loginFormState = LoginFormState.Send;
                    }, failReason => {
                        if (failReason?.status === 404) {
                            this.confirmDialogService.confirm(
                                'Je hebt nog geen account',
                                `Er bestaat geen account voor het e-mailadres <b>${this.loginForm.value.email}</b>.<br> Probeer het opnieuw of vraag een account aan.`,
                                'Opnieuw proberen',
                                'Account aanmaken').then(() => {
                                this.loginFormState = LoginFormState.FillIn;
                            }, () => {

                            });
                        } else {
                            this.errorMessage = failReason.message;
                            if (!this.errorMessage) {
                                this.errorMessage = 'Kon de server niet bereiken';
                            }
                            this.loginFormState = LoginFormState.Error;
                        }
                    });
            }
        }
    }

    validateCode() {
        if (this.loginFormState !== LoginFormState.Validating) {
            this.errorMessage = null;
            this.loginFormState = LoginFormState.Validating;
            this.authService.loginPin(this.loginForm.value.email, this.loginForm.value.code).subscribe(
                authResponse => {
                    if (UserService.userHasRights(UserType.USER)) {
                        this.router.navigate(['']);
                    } else {
                        this.router.navigate(['/weekschedule']);
                    }
                }, failReason => {
                    this.errorMessage = failReason.message;
                    if (!this.errorMessage) {
                        this.errorMessage = 'Kon de server niet bereiken';
                    }
                    this.loginFormState = LoginFormState.Send;
                });
        }
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if ((event.target as HTMLElement).nodeName.toLowerCase() === 'input') {
            return;
        }

        if (this.loginFormState === LoginFormState.Send) {
            if (!Number.isNaN(+event.key)) {
                this.fcCode.setValue(
                    (this.fcCode.value || '') + '' + event.key
                );
            }
        }
    }

    forgotPassword() {
        this.router.navigate(['wachtwoord-vergeten']);
        return false;
    }

}
