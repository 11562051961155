import {FormControl} from '@angular/forms';

export function requiredConditionalControl(dependantOn: FormControl, reverse = false) {
    return (control: FormControl) => {
        if ((!control.value || (Array.isArray(control.value) && control.value.length < 1)) && (reverse ? !dependantOn.value : !!dependantOn.value)) {
            return {required: true};
        }
        return null;
    };
}
