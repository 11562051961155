import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {LocalStorage} from '../../storage.class';
import {User} from '../../classes/user.class';
import {AuthResponse} from '../../classes/auth-response';
import {Router} from '@angular/router';
import {ConfirmDialogService} from "../confirm-dialog-service/confirm-dialog.service";
import {map} from 'rxjs/operators';

@Injectable()
export class AuthService {

    constructor(public apiService: ApiService, private router: Router, private confirmDialogService: ConfirmDialogService) {
    }

    loginPin(email, pin) {
        return this.apiService.postCall$<AuthResponse>('auth/login-code', {pin, email})
            .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }

    login(email, password) {
        return this.apiService.postCall$<AuthResponse>('auth/login', {email, password})
            .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }

    loginEmail(email) {
        return this.apiService.postCall$<boolean>('auth/login-email', {email});
    }

    loginType(email) {
        return this.apiService.postCall$<{
            needspassword: boolean
        }>('auth/login-type', {email});
    }

    public logout() {

        this.confirmDialogService.confirm(
            'Uitloggen',
            `U wordt uitgelogd... <i class="fas fa-spinner fa-spin"></i>`,
            null,
            null
        ).then(() => {
            console.log("LOGOUT A");
            window.location.reload();
        });
        this.apiService.postCall('auth/logout').then(() => {
            LocalStorage.logoutUser();
            setTimeout(() => {
                console.log("LOGOUT B");
                window.location.reload();
            });
        }, () => {
            LocalStorage.logoutUser();
            setTimeout(() => {
                console.log("LOGOUT C");
                window.location.reload();
            });
        });
    }

    public getUserDetails() {
        return this.apiService.getCall$<User>('auth/me').pipe(map(response => {
            LocalStorage.setUser(response.data);
            return response;
        }));
    }

    public requestRestorePassword(email): Promise<any> {
        return this.apiService.postCall('auth/request-restore', {email: email});
    }

    public verifyResetToken(token: string): Promise<Object> {
        return this.apiService.getCall('auth/verify-reset-token', {token});
    }

    public restorePassword(token: string, password: string) {
        return this.apiService.postCall$<AuthResponse>('auth/restore', {password, token})
            .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }


    public refresh(token?: string) {
        if (token) {
            return this.apiService.postCall$<AuthResponse>('auth/refresh', {token})
                .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
        }
        return this.apiService.getCall$<AuthResponse>('auth/refresh')
            .pipe(map(response => this.handleResponse(response.data)));
    }

    private handleResponse(authResponse: AuthResponse) {
        if (!!authResponse.access_token) {
            LocalStorage.setUserToken(authResponse.access_token);
            LocalStorage.setUser(authResponse.user);
            LocalStorage.setExpireTimeToken(authResponse.expires_in);
            return authResponse;
        } else {
            this.router.navigate(['logout']);
        }
    }
}
