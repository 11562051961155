import {FormControl} from '@angular/forms';
import {PlanningDetailDialogComponent} from "../planning-overview/planning-detail/planning-detail-dialog.component";

export function userIsNotDeleted(component: PlanningDetailDialogComponent) {
    return (control: FormControl) => {
        if (
            component.usersMap &&
            component.usersMap.has(control.value) &&
            component.usersMap.get(control.value).deleted_at
        ) {
            return {userDeleted: true};
        }
        return null;
    };
}
