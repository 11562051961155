import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Planning} from '../../../classes/planning.class';
import {Entity} from '../../../classes/entity.class';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {EntitiesService} from '../../../services/entities/entities.service';
import {MatSelectionListChange} from '@angular/material/list';

@Component({
    selector: 'app-hire-people-selector-dialog',
    templateUrl: './hire-people-selector-dialog.component.html',
    styleUrls: ['./hire-people-selector-dialog.component.scss']
})
export class HirePeopleSelectorDialogComponent implements OnInit {

    formArray: FormArrayEntities;
    entitiesMap: Map<number, Entity>;

    constructor(
        private entitiesService: EntitiesService,
        public dialogRef: MatDialogRef<HirePeopleSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            planning: Planning,
            entities: Entity[],
            disabled: boolean
        }) {
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.onNoClick();
    }

    ngOnInit() {
        this.entitiesService.getMap().subscribe(entitiesMap => {
            this.entitiesMap = entitiesMap;
        });
        this.formArray = new FormArray(this.data.entities.map(entity => {
            return new FormGroup({
                entity_id: new FormControl(entity.id),
                entitytype: new FormControl(entity.entitytype_id),
                planning_id: new FormControl(this.data.planning.id),
                count: new FormControl(this.data.planning.planning_hire_people?.find(h => h.entity_id === entity.id)?.count)
            }) as FormGroupPlanningEntity;
        })) as FormArrayEntities;
    }

    focus(event) {
        event.stopPropagation();
    }

    listChange(event: MatSelectionListChange) {
        this.formArray.controls.forEach((planningEntity: FormGroupPlanningEntity) => {
            if (event.options.filter(o => o.selected).map(o => o.value).indexOf(planningEntity.value.entity_id) !== -1) {
                const count = planningEntity.controls.count;
                if (!count.value) {
                    count.setValue(1);
                }
            }
            if (event.options.filter(o => !o.selected).map(o => o.value).indexOf(planningEntity.value.entity_id) !== -1) {
                planningEntity.controls.count.setValue(null);
            }
        });
    }

    select(): void {
        this.dialogRef.close(this.formArray.value.filter(v => v.count > 0));
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

export type FormArrayEntities = FormArray & { controls: FormGroupPlanningEntity[] };

export type FormGroupPlanningEntity = FormGroup & {
    controls: {
        entity_id: FormControl & { value: number },
        entitytype: FormControl & { value: string },
        planning_id: FormControl & { value: string },
        count: FormControl & { value: string }
    }
};
