import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input()
    public mainTitle: string;
    @Input()
    public subTitle: string;

    @Input() print: boolean;

    @Output()
    public clicked = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    logoclick() {
        this.clicked.emit();
    }

}
