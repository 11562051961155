<div class="dialog-container">
    <h1 class="condensed dialog-title">{{data.title}}</h1>
    <div class="dialog-content">
        <p [innerHTML]="data.text" *ngIf="data.text"></p>

        <mat-action-list *ngIf="data?.options?.length > 0">
            <button mat-list-item *ngFor="let option of data.options" [style.background-color]="option.bgColor" (click)="optionClicked(option)" [disabled]="option.disabled">
                <ng-container *ngIf="option.icon">
                    <i [class]="option.icon" class="mr-2"></i>
                </ng-container>
                <div>
                    {{option.name}}
                    <ng-container *ngIf="option.subtext">
                        <br>
                        <small>{{option.subtext}}</small>
                    </ng-container>
                </div>
            </button>
        </mat-action-list>

    </div>
    <div class="d-flex flex-wrap pt-3">
        <button *ngIf="data.cancel" mat-button (click)="onNoClick()">{{data.cancel}}</button>
        <div class="spacer"></div>
        <button *ngIf="data.confirm" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary">{{data.confirm}}</button>
    </div>
</div>
