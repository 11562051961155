import {Pipe, PipeTransform} from '@angular/core';
import {EntitiesService} from './services/entities/entities.service';
import {PlanningHirePeople} from './classes/planninghirepeople.class';
import {Entity} from './classes/entity.class';

@Pipe({
    name: 'PlanningEntitiesToString'
})
export class PlanningEntitiesToStringPipe implements PipeTransform {

    constructor(private entitiesService: EntitiesService) {
    }

    transform(value: PlanningHirePeople[], eMap: Map<number, Entity>): string {
        if (value) {
            return value.map(v => {
                const nr = v.count ? `(${v.count} st)` : '';
                return `${eMap.get(v.entity_id).name} ${nr}`;
            }).join(', ');
        }
        return null;
    }

}
