import {FormControl, FormGroup} from '@angular/forms';
import {PlanningService} from '../services/planning/planning.service';
import {Utils} from '../utils.class';
import {first, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {Entity} from '../classes/entity.class';

export const localEntityAvailableEquipmentValidator = (planningService?: PlanningService,
                                                       entitiesMap?: Map<number, Entity>) => {
    return (input: FormGroup | FormControl) => {
        const beginDate = Utils.newDate(input.get('begindate').value);
        const endDate = Utils.newDate(input.get('enddate').value);
        const entity = entitiesMap.get(input.get('entity_id').value);
        if (entity && !entity.use_once) {
            return of(null);
        }

        let res = false;
        return planningService.getFilteredList(beginDate, endDate).pipe(
            first(),
            map(planningList => {
                planningList?.forEach(pln => {
                    pln.planning_has.forEach(planningHas => {
                        if (planningHas.id !== input.get('id').value) {
                            if ([planningHas.entity_id, planningHas.truck_entity_id, planningHas.lowloader_entity_id].indexOf(input.get('entity_id').value) !== -1) {
                                if (Utils.getTimeOrNull(planningHas.begindate) < endDate.getTime()
                                    && Utils.getTimeOrNull(planningHas.enddate) > beginDate.getTime()) {
                                    res = true;
                                }
                            }
                        }
                    });
                });
                return res ? {notAvailable: res} : null;
            })
        );
    };
};
