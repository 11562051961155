import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {UserService, UserType} from '../services/user/user.service';
import {LocalStorage} from '../storage.class';
import {Settings} from '../settings.class';

@Pipe({
    name: 'OwnHourEditAllowed'
})
export class OwnHourEditAllowedPipe implements PipeTransform {

    transform(realisation: Realisation, ...args: unknown[]): unknown {
        if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) && !realisation.paid) {
            return true;
        }
        const now = new Date();
        const enddate = new Date(realisation.enddate);

        const diff = (now.getTime() - enddate.getTime()) / 1000 / 60 / 60;

        return diff < Settings.HOUR_EDIT_ALLOWED_HOURS && !realisation.approved && realisation.user_id === LocalStorage.getUser().id;
    }

}
