import {Injectable} from '@angular/core';
import {Planning} from '../../classes/planning.class';
import {PlanningService} from '../planning/planning.service';
import {MatDialog} from '@angular/material/dialog';
import {EntityTypeCode} from '../entities/entity-type.class';
import {PlanningDetailContainerComponent} from '../../planning-overview/planning-detail-container/planning-detail-container.component';
import {ConfirmDialogService} from '../confirm-dialog-service/confirm-dialog.service';

@Injectable({
    providedIn: 'root'
})
export class PlanningDetailDialogService {

    constructor(private planningService: PlanningService,
                private confirmDialogService: ConfirmDialogService,
                public dialog: MatDialog) {
    }

    openPlanning(planning: Planning) {
        const mainPlanning = this.planningService.getMainPlanning(planning as Planning);
        const isWipetruck = mainPlanning.entitytype_id === EntityTypeCode.Wipetruck;
        const isCutter = mainPlanning.entitytype_id === EntityTypeCode.Cutter;
        const duplicatedPlanning = JSON.parse(JSON.stringify(planning));
        if (planning.is_transport) {
            this.confirmDialogService.confirm(
                'Betreft transportplanning',
                'Deze planning kan alleen aangepast worden via de transportplanning.',
                'Sluiten',
                null).then(() => {
            });
        } else {

            // This is needed to create object references between planninghas and cutters etc, needed for validation
            this.planningService.applyAllEntitiesToPlanningHas([duplicatedPlanning]);
            return this.dialog.open(PlanningDetailContainerComponent, {
                maxWidth: isWipetruck ? '990px' : '99vw',
                maxHeight: '100%',
                height: (isWipetruck || isCutter) ? '' : window.matchMedia('(max-width: 1024px)').matches ? '99vh' : '',
                width: window.matchMedia('(max-width: 1520px)').matches ? '99vw' : '1500px',
                disableClose: true,
                panelClass: 'planning-detail-dialog',
                data: duplicatedPlanning,
            });
        }
    }
}
