<div class="modal-inner" [class.tiny]="data.tiny">
    <h1>{{data.title ?? 'Toelichting'}}</h1>
    <mat-form-field appearance="fill" class="w-100" *ngIf="!data.tiny">
        <mat-label>Toelichting</mat-label>
        <textarea [formControl]="form.controls.comment" matInput placeholder="Toelichting bij urenregistratie" rows="8"></textarea>
        <mat-error [control]="form.controls.comment"></mat-error>
    </mat-form-field>
    <simple-tiny *ngIf="data.tiny"
                 class="w-100 mb-3"
                 height="600"
                 [showMenuBar]="false"
                 [content]="form.controls.comment">
    </simple-tiny>
    <div class="buttons">
        <button (click)="applyAndClose()"
                mat-raised-button
                [disabled]="form.invalid"
                color="primary"
                class="planning-button confirm">
            Gereed
        </button>
        <button mat-raised-button color="basic"
                class="planning-button cancel"
                mat-dialog-close=""
                (click)="dialogRef.close()">
            Annuleren
        </button>
    </div>
</div>
