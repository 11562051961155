import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from './storage.class';
import {UserService, UserType} from './services/user/user.service';

@Injectable()
export class IsNotAuthenticated implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (LocalStorage.getUserToken() && UserService.userHasRights(UserType.USER)) {
            this.router.navigate(['']);
        } else if (LocalStorage.getUserToken()) {
            this.router.navigate(['/weekschedule']);
        }
        return true;
    }
}
