import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {Utils} from '../../utils.class';
import {formatDate} from '@angular/common';
import {RealisationService} from '../../services/realisation.service';
import {Realisation} from '../../classes/realisation';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {Entity} from '../../classes/entity.class';
import {ProjectService} from '../../services/project.service';
import {ProjectComment} from '../../classes/project-comment';
import {Project} from '../../classes/project.class';
import {AsphaltUsedListComponent} from '../../project-day-report/asphalt-used-list/asphalt-used-list.component';
import {ProjectUsedAsphalt} from '../../classes/project-used-asphalt.class';
import {AsphaltService} from '../../services/asphalt/asphalt.service';

@Component({
    selector: 'app-project-week-report',
    templateUrl: './project-week-report.component.html',
    styleUrls: ['./project-week-report.component.scss']
})
export class ProjectWeekReportComponent extends CodaltComponent implements OnInit, OnChanges {

    @Input() @HostBinding('class.print') print = false;
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() projectId: string;

    year: number;
    period: number;

    realisations: Realisation[];
    employees: User[];

    userGroupTotals: UserGroupTotals[];
    entityGroupTotals: EntityGroupTotals[];

    projectComments: ProjectComment[];
    days: {
        comment: ProjectComment,
        aa: string,
        date: Date,
        asphalt: ProjectUsedAsphalt[],
        adhesive: ProjectUsedAsphalt[],
    }[] = [];
    project: Project;
    @ViewChild('asphaltUsedList') asphaltUsedListComponent: AsphaltUsedListComponent;
    @ViewChild('asphaltAdhesiveList') asphaltAdhesiveListComponent: AsphaltUsedListComponent;

    functionGroupMap = new Map<string, string>([
        ['', 'Uitvoerders'],
        ['Grondwerker', 'Grondwerkers'],
        ['Machinist Mobiele Kraan', 'Kraanmachinisten'],
        ['Kraanbestuurder', 'Kraanmachinisten'],
        ['Chauffeur', 'Chauffeurs'],
        ['Straatmaker I', 'Stratenmakers'],
        ['Straatmaker II', 'Stratenmakers'],
        ['Vakman Verkeer', 'Verkeersmaatregelen'],
        ['Machinist GWW', 'Frees- en veegmachinisten'],
        ['Machinist Klein Materieel', 'Frees- en veegmachinisten'],
        ['Projectleider', 'Projectleider'],
        ['Uitvoerder', 'Uitvoerders'],
        ['Vrachtwagen', 'Transport'],
        ['Dieplader', 'Transport'],
        ['GM Asfalt overig', 'Asfaltmaterieel'],
        ['GM Veeg-zuig', 'Asfaltmaterieel'],
        ['GM Frees', 'Asfaltmaterieel'],
        ['GM Asfaltmachine', 'Asfaltmaterieel'],
        ['GM Wals', 'Asfaltmaterieel'],
        ['Frezen', 'Frezen'],
        ['GM Kraan', 'Kranen en Shovels'],
        ['GM Overig', 'Div. materieel (aantal per dag)'],
        ['Wieldumper', 'Div. materieel (aantal per dag)']
    ]);

    constructor(
        private userService: UserService,
        private projectService: ProjectService,
        private asphaltService: AsphaltService,
        private realisationService: RealisationService) {
        super();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        // Speed up PDF
        this.asphaltService.getAsphaltMills().then(() => {
            this.realisationService.getProjectSchedule(this.beginDate, this.endDate, this.projectId).subscribe(realisations => {
                this.realisations = realisations.data;
                this.processData();
                this.subscriptions.add(this.projectService.getProjectCommentsWeek(this.projectId, this.beginDate).subscribe(projectComments => {
                    this.projectComments = projectComments.data;
                    projectComments.data.forEach(projectComment => {
                        const day = this.days.find(d => Utils.getDateDate(d.date) === Utils.getDateDate(projectComment.date));
                        console.log(day, this.days, projectComment);
                        day.comment = projectComment;
                    });
                }));
            });
        });
    }

    processData() {
        this.userGroupTotals = [];
        const usersTotals = [] as UserWeekTotals[];
        const userIds = [...new Set(this.realisations.map(r => r.user_id) || [])].filter(r => !!r);
        userIds.forEach(userId => {
            const bookDate = new Date(this.beginDate);
            const userTotals = new UserWeekTotals();
            userTotals.days = [];
            userTotals.weekTotal = 0;
            const allRealisations = [];
            while (bookDate.getTime() <= this.endDate.getTime()) {
                const realisations = this.realisations.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookDate, 'yyyy-MM-dd', 'nl') && r.user_id === userId);
                allRealisations.push(...realisations);
                let totalMinutes = 0;
                if (realisations?.length) {
                    realisations.forEach(realisation => {
                        totalMinutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                        totalMinutes -= realisation.pause;
                    });

                    userTotals.user = realisations[0].user;
                    userTotals.group = this.functionGroupMap.get(userTotals.user?.function) ?? userTotals.user?.function;
                    userTotals.function = userTotals.user?.function;

                }
                userTotals.days.push({
                    total: totalMinutes,
                    date: new Date(bookDate)
                });
                userTotals.weekTotal += totalMinutes;
                userTotals.realisations = allRealisations;
                bookDate.setUTCDate(bookDate.getUTCDate() + 1);
            }
            usersTotals.push(userTotals);
        });
        const days = [] as Date[];
        const bookDate = new Date(this.beginDate);
        while (bookDate.getTime() <= this.endDate.getTime()) {
            days.push(new Date(bookDate));
            const day = {
                date: new Date(bookDate),
                comment: null,
                aa: null,
                asphalt: [],
                adhesive: []
            };
            this.days.push(day);
            this.subscriptions.add(this.projectService.getProjectAsphalt(this.projectId, new Date(bookDate)).subscribe(ua => {
                day.asphalt = ua.data.filter(pal => pal.application !== 'adhesive_base' && pal.application !== 'adhesive_bind');
                day.adhesive = ua.data.filter(pal => pal.application === 'adhesive_base' || pal.application === 'adhesive_bind');
            }));

            bookDate.setUTCDate(bookDate.getUTCDate() + 1);
        }
        console.log(days);
        const groups = [...new Set(usersTotals.map(r => r?.group) || [])].filter(r => !!r);
        groups.forEach(group => {
            const ugt = new UserGroupTotals();
            ugt.totals = usersTotals.filter(g => g.group === group);
            ugt.group = group;
            ugt.days = days;
            this.userGroupTotals.push(ugt);
        });


        this.entityGroupTotals = [];
        const entitiesTotals = [] as EntityWeekTotals[];
        const entityIds = [...new Set(this.realisations.map(r => r.entity_id) || [])].filter(r => !!r);

        entityIds.forEach(entityId => {
            const bookdate = new Date(this.beginDate);
            const entityTotals = new EntityWeekTotals();
            entityTotals.days = [];
            entityTotals.weekTotal = 0;
            const allRealisations = [];
            while (bookdate.getTime() <= this.endDate.getTime()) {
                const realisations = this.realisations.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookdate, 'yyyy-MM-dd', 'nl') && r.entity_id === entityId);
                allRealisations.push(...realisations);
                let totalMinutes = 0;
                if (realisations?.length) {
                    realisations.forEach(realisation => {
                        totalMinutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                    });

                    entityTotals.entity = realisations[0].entity;
                    entityTotals.group = this.functionGroupMap.get(entityTotals.entity?.function) ?? entityTotals.entity?.function;
                    entityTotals.function = entityTotals.entity?.function;
                }
                entityTotals.days.push({
                    total: totalMinutes,
                    date: new Date(bookdate)
                });
                entityTotals.realisations = allRealisations;
                entityTotals.weekTotal += totalMinutes;
                bookdate.setUTCDate(bookdate.getUTCDate() + 1);
            }
            entitiesTotals.push(entityTotals);
        });
        const entityGroups = [...new Set(entitiesTotals.map(r => r?.group) || [])].filter(r => !!r);
        entityGroups.forEach(group => {
            const ugt = new EntityGroupTotals();
            ugt.totals = entitiesTotals.filter(g => g.group === group);
            ugt.group = group;
            ugt.days = days;
            this.entityGroupTotals.push(ugt);
        });
        setTimeout(() => {
            document.body.appendChild(document.createElement('readyforpuppeteer'));
        });
    }

}

class UserWeekTotals {
    days: {
        date: Date,
        total: number
    }[];
    weekTotal: number;
    user: User;
    group: string;
    function: string;
    realisations: Realisation[];
}

class UserGroupTotals {
    days: Date[];
    totals: UserWeekTotals[];
    group: string;
}

class EntityWeekTotals {
    days: {
        date: Date,
        total: number
    }[];
    weekTotal: number;
    entity: Entity;
    group: string;
    function: string;
    realisations: Realisation[];
}

class EntityGroupTotals {
    days: Date[];
    totals: EntityWeekTotals[];
    group: string;
}
