import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {ActivatedRoute, Router} from '@angular/router';
import {RealisationService} from '../services/realisation.service';
import {CodaltComponent} from '../codalt.component';
import {formatDate} from '@angular/common';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Utils} from '../utils.class';
import {CommentDialogComponent} from './comment-dialog/comment-dialog.component';
import {TimePickerService} from '../services/time-picker.service';
import {User} from '../classes/user.class';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {AddRealisationDialogComponent} from '../hours-check/add-realisation-dialog/add-realisation-dialog.component';
import {LocalStorage} from '../storage.class';
import {Settings} from '../settings.class';
import {UserService, UserType} from '../services/user/user.service';

@Component({
    selector: 'app-hour-overview',
    templateUrl: './hour-overview.component.html',
    styleUrls: ['./hour-overview.component.scss']
})
export class HourOverviewComponent extends CodaltComponent implements OnInit {

    RealisationHourtype = RealisationHourtype;

    maxDate: Date;
    date: Date;
    user: User;
    realisations: Realisation[];
    entityRealisations: Realisation[];

    pauseToday: number;
    minutesToday: number;
    pauseThisWeek: number;
    minutesThisWeek: number;

    formPause: FormGroup<ControlsOf<{
        pause: number,
        changed: boolean
    }>> = new FormGroup({
        changed: new FormControl(false),
        pause: new FormControl()
    });
    form: FormArray<ControlsOf<fgRealisation>>;
    formMap: Map<Realisation, FormGroup<ControlsOf<fgRealisation>>>;
    entityRealisationsMap: Map<Realisation, Realisation[]>;

    approved = false;
    submitted = false;
    saving = false;
    lastReload = new Date().getTime();
    daySubscriptions = new Subscription();
    addAllowed = false;
    approvedCommentAllowed = false;
    overlapping = false;
    overlappingTimeout: any;

    backTo = '/weekschedule';

    constructor(private dialog: MatDialog,
                private timePickerService: TimePickerService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private confirmDialogService: ConfirmDialogService,
                private realisationService: RealisationService) {
        super();
        this.maxDate = Utils.setTime(new Date(), 0, 0);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string, userId: number, backTo: 'verrekening' }) => {
            const today = new Date();
            this.date = params.date ? new Date(params.date) : new Date(formatDate(today, 'yyyy-MM-dd', 'nl'));
            this.getData(params.userId);
            window.scroll(0, 0);
            if (params.backTo) {
                this.backTo = params.backTo;
            }
        }));

        window.onfocus = () => {
            if ((new Date().getTime() - this.lastReload) > 20000 && !this.form.dirty && !this.formPause.dirty) {
                this.lastReload = new Date().getTime();
                this.getData(this.user?.id);
            }
        };
    }

    add() {
        const ref = this.dialog.open(AddRealisationDialogComponent, {
            maxWidth: '450px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                realisations: this.realisations,
                bookdate: this.date,
                userId: this.user?.id ?? LocalStorage.user?.id
            }
        });
        this.daySubscriptions.add(ref.afterClosed().subscribe(realisation => {
            if (realisation) {
                this.getData(this.user?.id);
            }
        }));
    }

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.dirty) {
                this.confirmDialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen verwerpen?`,
                    'Hier blijven',
                    'Wijzigingen verwerpen').then(() => {
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    delete(realisation: Realisation) {
        this.confirmDialogService.confirm(
            'Registratie verwijderen',
            `Wil je de uren ${formatDate(realisation.begindate, 'EEE HH:mm', 'nl')}-${formatDate(realisation.enddate, 'EEE HH:mm', 'nl')} ${realisation.afas_project_id} verwijderen?`,
            'Verwijderen',
            'Behouden').then(() => {
            this.formMap.get(realisation).controls.removed.setValue(new Date());
        }, () => {

        });
    }


    restore(realisation: Realisation) {
        this.formMap.get(realisation).controls.removed.setValue(null);
        console.log(realisation);
    }

    private getData(userId?: number) {
        const now = new Date();
        now.setUTCHours(-3);
        const diff = (now.getTime() - this.date.getTime()) / 1000 / 60 / 60;
        this.addAllowed = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) || (diff < Settings.HOUR_EDIT_ALLOWED_HOURS);

        // approved comment is allowed for the whole week untill next sunday
        const previousMonday = new Date();
        Utils.setTime(previousMonday, 0, 0);
        previousMonday.setDate(previousMonday.getDate() - ((previousMonday.getDay() + 6) % 7) - 7);
        this.approvedCommentAllowed = now.getTime() > previousMonday.getTime();

        this.realisationService.getUserDaySchedule(this.date, userId).subscribe(userDaySchedule => {
            this.daySubscriptions?.unsubscribe();
            this.daySubscriptions = new Subscription();

            setTimeout(() => {
                this.saving = false;
            }, 300);
            this.form = new FormArray<ControlsOf<fgRealisation>>([]);
            const realisations = userDaySchedule.data.realisations.filter(r => !!r.user_id);
            this.entityRealisations = userDaySchedule.data.realisations.filter(r => !!r.entity_id);
            const formMap = new Map<Realisation, FormGroup<ControlsOf<fgRealisation>>>();
            const entityRealisationsMap = new Map<Realisation, Realisation[]>();

            this.user = userDaySchedule.data?.user;
            realisations.forEach(realisation => {
                const formgroup = new FormGroup<ControlsOf<fgRealisation>>({
                    id: new FormControl(realisation.id),
                    begindate: new FormControl(new Date(realisation.begindate)),
                    enddate: new FormControl(new Date(realisation.enddate)),
                    planning_id: new FormControl(realisation.planning_id),
                    updated_at: new FormControl(realisation.updated_at),
                    comment_user: new FormControl(realisation.comment_user),
                    comment_user_approved: new FormControl(realisation.comment_user_approved),
                    removed: new FormControl(realisation.removed)
                });
                this.daySubscriptions.add(formgroup.valueChanges.pipe(debounceTime(250)).subscribe(values => {
                    this.realisationService.saveRealisation(values as any).subscribe(result => {
                        result.data.realisation.begindate = new Date(result.data.realisation.begindate);
                        result.data.realisation.enddate = new Date(result.data.realisation.enddate);
                        Object.assign(realisation, result.data.realisation);
                        console.log(realisation);
                        formgroup.patchValue(result.data.realisation, {emitEvent: false, onlySelf: true});
                        this.updateTotals(result.data);
                        this.form.markAsPristine();
                    }, () => {
                        this.getData(userId);
                    });
                }));
                this.form.push(formgroup);
                formMap.set(realisation, formgroup);
                entityRealisationsMap.set(realisation, this.entityRealisations
                    .filter(er => er.planning_has_entity_id === (realisation.planning_has_entity_id ?? 'donotmapnull') || er.parent_realisation_id === realisation.id)
                    .filter(er => er.hourtype === RealisationHourtype.worktime)
                );
            });
            this.formMap = formMap;
            this.entityRealisationsMap = entityRealisationsMap;
            this.realisations = realisations.sort((a, b) => Utils.realisationSort(a) - Utils.realisationSort(b));

            this.updateTotals(userDaySchedule.data);
        });
    }

    private updateTotals(data: { weekTotal: { pause: number; minutes: number; }, dayTotal: { pause: number; minutes: number; } }) {
        this.approved = this.realisations.filter(r => r.approved).length === this.realisations.length && this.realisations.length > 0;
        this.submitted = this.realisations.filter(r => r.submitted).length === this.realisations.length && this.realisations.length > 0;
        this.pauseThisWeek = data.weekTotal?.pause ?? 0;
        this.minutesThisWeek = (data.weekTotal?.minutes ?? 0) - this.pauseThisWeek;
        this.pauseToday = data.dayTotal?.pause ?? 0;
        this.minutesToday = (data.dayTotal?.minutes ?? 0) - this.pauseToday;
        this.formPause.controls.pause.setValue(data.dayTotal?.pause ?? 0);

        if (this.overlappingTimeout) {
            clearTimeout(this.overlappingTimeout);
        }
        this.overlappingTimeout = setTimeout(() => this.checkOverlapping(), 100);
    }

    private checkOverlapping() {
        let overlapping = false;
        const nonRemovedRealisations = this.realisations.filter(r => !r.removed);
        nonRemovedRealisations.forEach((r, i) => {
            if (i > 0 && Utils.newDate(r.begindate) < Utils.newDate(nonRemovedRealisations[i - 1]?.enddate)) {
                overlapping = true;
            }
        });
        this.overlapping = overlapping;
    }

    editComment(fcComment: FormControl<string>, title?: string) {
        const ref = this.dialog.open(CommentDialogComponent, {
            maxWidth: '500px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                comment: fcComment.value,
                title
            }
        });
        ref.afterClosed().subscribe(comment => {
            if (comment !== undefined) {
                fcComment.setValue(comment);
            }
        });
    }

    editTime(realisation: Realisation, field: 'begindate' | 'enddate', addToCurrentTime = 0, next?: FormGroup<ControlsOf<fgRealisation>>) {
        const formGroup = this.formMap.get(realisation);
        if (this.approved) {
            return;
        }
        const extendToEarlier = this.realisations.filter(r => !r.removed).indexOf(realisation) === 0;

        this.timePickerService.editRealisationTime(realisation, field, formGroup, addToCurrentTime, extendToEarlier).subscribe(newDate => {
            if (newDate) {
                this.processTimeChange(realisation, newDate, formGroup, field, next);
                if (field === 'begindate' && newDate.getTime() > new Date(realisation.enddate).getTime()) {
                    const endDate = new Date(newDate);
                    do {
                        endDate.setMinutes(endDate.getMinutes() + 1);
                    } while (endDate.getMinutes() % 3 !== 0);
                    this.processTimeChange(realisation, endDate, formGroup, 'enddate');
                }
            }
        });
    }

    private processTimeChange(realisation: Realisation, newDate, formGroup: FormGroup<ControlsOf<fgRealisation>>, field: 'begindate' | 'enddate', next?: FormGroup<ControlsOf<fgRealisation>>) {
        const fromIndex = this.realisations.indexOf(realisation);
        const diff = Utils.getTimeOrNull(newDate) - Utils.getTimeOrNull(formGroup.get(field).value);
        if (realisation.hourtype === RealisationHourtype.travel_to) {
            const toIndex = this.realisations.findIndex(r => r.hourtype === RealisationHourtype.worktime);
            const items = this.realisations.slice(fromIndex, toIndex + 1);
            items.forEach((r, index) => {
                if (index !== 0 || field === 'begindate') {
                    const timeToSet = new Date(this.formMap.get(r).get('begindate').value);
                    timeToSet.setTime(timeToSet.getTime() + diff);
                    this.formMap.get(r).get('begindate').setValue(timeToSet);
                    this.formMap.get(r).markAsDirty();
                }
                if (index !== items.length - 1) {
                    const timeToSet = new Date(this.formMap.get(r).get('enddate').value);
                    timeToSet.setTime(timeToSet.getTime() + diff);
                    this.formMap.get(r).get('enddate').setValue(timeToSet);
                    this.formMap.get(r).markAsDirty();
                } else {
                    const start = new Date(this.formMap.get(r).get('begindate').value);
                    const end = new Date(this.formMap.get(r).get('enddate').value);
                    const diff = end.getTime() - start.getTime();
                    const oneHour = 3600000;
                    if (diff < oneHour) {
                        const toAdd = (oneHour - diff);
                        this.transferAllFutureTimes(r, 'enddate', toAdd);
                    }
                }
            });
        } else if ([RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(realisation.hourtype) && field === 'enddate') {
            this.transferAllFutureTimes(realisation, field, diff);
        } else {
            if (next && field === 'enddate' && new Date(formGroup.get(field).value).getTime() === new Date(next.controls.begindate.value).getTime()) {
                next.controls.begindate.setValue(new Date(newDate));
            }
            formGroup.get(field).setValue(newDate);
            formGroup.markAsDirty();
        }
    }

    transferAllFutureTimes(fromRealisation: Realisation, field: 'begindate' | 'enddate', timeInSeconds) {
        const fromIndex = this.realisations.indexOf(fromRealisation);
        const to = this.realisations.slice(fromIndex).find((r, index, realisations) => {
            return index < (realisations.length - 1) && new Date(r.enddate).getTime() !== new Date(realisations[index + 1].begindate).getTime();
        });
        const toIndex = to ? this.realisations.indexOf(to) : this.realisations.length - 1;
        this.realisations.slice(fromIndex, toIndex + 1).forEach((r, index) => {
            if (index !== 0 || field === 'begindate') {
                const timeToSet = new Date(this.formMap.get(r).get('begindate').value);
                timeToSet.setTime(timeToSet.getTime() + timeInSeconds);
                this.formMap.get(r).get('begindate').setValue(timeToSet);
                this.formMap.get(r).markAsDirty();
            }
            const endTimeToSet = new Date(this.formMap.get(r).get('enddate').value);
            endTimeToSet.setTime(endTimeToSet.getTime() + timeInSeconds);
            this.formMap.get(r).get('enddate').setValue(endTimeToSet);
            this.formMap.get(r).markAsDirty();
        });
    }

    submitHours() {
        this.saving = true;
        this.realisationService.submitRealisation(this.realisations).subscribe(() => {
            this.getData(this.user?.id);
        }, error => {
            this.confirmDialog.confirm('Fout',
                `Er ging iets fout bij het opslaan<br>${error.message}`, 'Sluiten', null).then(() => {
                this.getData(this.user?.id);
            });
        });
    }

    next() {
        if (this.date < this.maxDate) {
            this.date.setDate(this.date.getDate() + 1);
            const userId = this.user?.id ? `/${this.user.id}` : '';
            this.router.navigateByUrl(`hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}${userId}`);
        }
    }

    prev() {
        this.date.setDate(this.date.getDate() - 1);
        const userId = this.user?.id ? `/${this.user.id}` : '';
        this.router.navigateByUrl(`hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}${userId}`);
    }


    today() {
        this.date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'nl'));
        const userId = this.user?.id ? `/${this.user.id}` : '';
        this.router.navigateByUrl(`hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}${userId}`);
    }

    changePause() {
        const options = [{
            minutes: 60,
            name: '1 uur'
        }, {
            minutes: 45,
            name: '3 kwartier'
        }, {
            minutes: 30,
            name: 'Half uur'
        }, {
            minutes: 15,
            name: 'Kwartier'
        }, {
            minutes: 0,
            name: 'Geen'
        }];
        this.confirmDialogService.confirm(
            'Pauzeduur aanpassen', '', null, 'Annuleren', options
        ).then(data => {
            if (data) {
                this.formPause.controls.pause.setValue(data.minutes);
                this.formPause.controls.changed.setValue(true);
                this.realisationService.changePause(this.date, this.formPause.controls.pause.value, this.user?.id).subscribe(result => {
                    this.updateTotals(result.data);
                    this.formPause.markAsPristine();
                });
            }
        }, () => {

        });
    }

}

interface fgRealisation {
    id: number;
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    planning_id: number;
    updated_at: FormControl<Date>;
    comment_user: string;
    comment_user_approved: string;
    removed: FormControl<Date>;
}
