<div>
    <div class="d-flex align-items-center" mat-dialog-title>
        <div class="spacer title">Inhuur bedrijven</div>
        <div>
            <button mat-dialog-close mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
        </div>
    </div>
</div>
<div class="dialog-content">
    <div class="col-12">
        <table cdkDropList class="table table-hover">
            <tr>
                <th>
                    Naam
                </th>
                <th>
                    Telefoonnummer
                </th>
                <th>
                    E-mailadres
                </th>
            </tr>
            <tr (click)="open(entity)" *ngFor="let entity of hiringList" class="curpoint">
                <td>
                    {{entity.name}}
                </td>
                <td>
                    {{entity.phone}}
                </td>
                <td>
                    {{entity.email}}
                </td>
            </tr>
        </table>
    </div>
</div>
