import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {HiringService} from '../services/hiring.service';
import {Hiring} from '../classes/hiring';
import {EntityType, EntityTypeCode} from '../services/entities/entity-type.class';
import {EntitiesService} from '../services/entities/entities.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../services/dialog/dialog.service';
import {Entity} from '../classes/entity.class';
import {HiringCompanyEditDialogComponent} from './hiring-company-edit-dialog/hiring-company-edit-dialog.component';

@Component({
    selector: 'app-hiring-companies-dialog',
    templateUrl: './hiring-companies-dialog.component.html',
    styleUrls: ['./hiring-companies-dialog.component.scss']
})
export class HiringCompaniesDialogComponent implements OnInit, OnDestroy {

    forHiring = [EntityTypeCode.Wipetruck, EntityTypeCode.Cutter];

    hiringList: Hiring[];
    entityTypes: Map<number, EntityType>;

    private subscriptions = new Subscription();

    constructor(public dialogRef: MatDialogRef<HiringCompaniesDialogComponent>,
                public dialogService: DialogService,
                private hiringService: HiringService,
                private entitiesService: EntitiesService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.entitiesService.getTypesMap().subscribe(data => {
            this.entityTypes = data;
        }));
        this.subscriptions.add(this.hiringService.getList().subscribe(hiringList => {
            this.hiringList = hiringList;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    open(hiring: Hiring) {
        this.dialogService.open(HiringCompanyEditDialogComponent, hiring, {minHeight: 'auto'});
    }

    add(type: number) {
        const hiring = new Entity();
        hiring.entitytype_id = type;
        this.dialogService.open(HiringCompanyEditDialogComponent, hiring);
    }

    delete(event, hiring: Hiring) {
        event.stopPropagation();
        this.hiringService.delete(hiring.id).then(() => {

        }, () => {

        });
    }

}

export class HiringViewGroup {
    public type: number;
    public name: string;
    public companies: Hiring[];

    constructor(type: number, name: string, entities: Hiring[]) {
        this.type = type;
        this.name = name;
        this.companies = entities;
    }

}
