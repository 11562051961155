export class ProjectComment {
    id?: number;
    afas_project_id: string;
    date: Date;
    comment: string;
    saved_by_id?: number;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}
