import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user/user.service';
import {Planning} from '../classes/planning.class';
import {Subscription} from 'rxjs';
import {PlanningAsfaltteam} from '../classes/planningasfaltteam.class';
import {PlanningCutter} from '../classes/planningcutter.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {PlanningService} from '../services/planning/planning.service';
import {StatusService} from '../services/planning/status.service';
import {Status} from '../classes/status.class';
import {User} from '../classes/user.class';
import {ActivatedRoute} from '@angular/router';
import {EntitiesService} from '../services/entities/entities.service';
import {Entity} from '../classes/entity.class';
import {AsphaltService} from '../services/asphalt/asphalt.service';
import {Asphaltmill} from '../classes/asphaltmill.class';
import {PlanningSet} from '../classes/planningset.class';
import {PlanningDumper} from '../classes/planningdumper.class';

@Component({
    selector: 'app-planning-detail-print',
    templateUrl: './planning-detail-print.component.html',
    styleUrls: ['./planning-detail-print.component.scss']
})
export class PlanningDetailPrintComponent implements OnInit {

    entitiesMap: Map<number, Entity>;
    planning: Planning;
    mainPlanning: PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper;
    statusList: Status[];
    users: User[];
    entities: Entity[];
    mills: Asphaltmill[];
    viewModel = {
        team: true,
        cutterList: true,
        wipertruckList: true
    };
    private subscriptions = new Subscription();

    constructor(private userService: UserService,
                private planningService: PlanningService,
                private statusService: StatusService,
                private entitiesService: EntitiesService,
                private asphaltService: AsphaltService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.subscriptions.add(this.planningService.getSingle(
            +this.route.snapshot.params['id']
        ).subscribe((planning) => {
            this.planning = planning;
            this.mainPlanning = this.planningService.getMainPlanning(this.planning);

            setTimeout(() => {
                (window as any).print();
                (window as any).close();
            }, 1000);
        }));

        this.statusService.getList().then(statusList => {
            this.statusList = statusList;
        });

        this.subscriptions.add(this.userService.getList(true).subscribe(users => {
            this.users = users;
        }));

        this.subscriptions.add(this.entitiesService.getList().subscribe(entities => {
            this.entities = entities;
        }));

        this.subscriptions.add(this.entitiesService.getMap().subscribe(entitiesMap => {
            this.entitiesMap = entitiesMap;
        }));

        this.asphaltService.getAsphaltMills().then(mills => {
            this.mills = mills;
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
