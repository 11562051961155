import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AsphaltService} from '../../../services/asphalt/asphalt.service';
import {Asphaltmill} from '../../../classes/asphaltmill.class';
import {PlanningCutter} from '../../../classes/planningcutter.class';
import {PlanningAsphalt} from '../../../classes/planningasphalt.class';
import {Utils} from '../../../utils.class';
import {PlanningAsfaltteam} from '../../../classes/planningasfaltteam.class';
import {PlanningHasEntity} from '../../../classes/planning-has-entity.class';
import {Planning} from '../../../classes/planning.class';
import {Subscription} from 'rxjs';
import {PlanningService} from '../../../services/planning/planning.service';
import {EntityUnavailableService} from '../../../services/entities/entity-unavailable.service';
import {Settings} from '../../../settings.class';
import {DayTimeOptions} from '../../../classes/day-time-options';
import {AsphaltListItemComponent} from './asphalt-list-item/asphalt-list-item.component';

@Component({
    selector: 'app-asphalt-list',
    templateUrl: './asphalt-list.component.html',
    styleUrls: ['./asphalt-list.component.scss']
})
export class AsphaltListComponent implements OnInit {

    @Input() formsDisabled: boolean;
    @Input() mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity;
    @Input() planning: Planning;
    @Input() planningAsphaltList: PlanningAsphalt[];

    @ViewChildren(AsphaltListItemComponent)
    asphaltListItems: AsphaltListItemComponent[];
    truckEntitySubscriptions = new Subscription();
    maxUsedTrucks = new Map<object, number>();
    availableTrucks = new Map<object, number>();
    asphaltMills: Asphaltmill[];
    timeList: DayTimeOptions[] = [];
    @ViewChildren('millSelect')
    private millSelects: QueryList<any>;

    constructor(private asphaltService: AsphaltService,
                private planningService: PlanningService,
                private entityUnavailableService: EntityUnavailableService) {
    }

    trucks() {
        if (!this.planning) {
            return;
        }

        this.truckEntitySubscriptions.unsubscribe();
        this.truckEntitySubscriptions = new Subscription();

        this.planningAsphaltList.forEach(planningAsphalt => {
            if (planningAsphalt.time && this.mainPlanning.begindate && this.mainPlanning.enddate) {
                const beginDate = Utils.newDate(planningAsphalt.time);
                const next = this.planningAsphaltList.filter(p => new Date(p.time).getTime() > beginDate.getTime());
                const endDate = next.length > 0 ? Utils.newDate(next[0].time) : Utils.newDate(this.mainPlanning.enddate);

                this.truckEntitySubscriptions.add(this.planningService.getMaxUsedTrucksForRange(beginDate, endDate, planningAsphalt.planning_id, this.planning)
                    .subscribe(maxUsedTrucks => {
                        this.maxUsedTrucks.set(planningAsphalt, maxUsedTrucks);
                    }));
                this.truckEntitySubscriptions.add(this.entityUnavailableService.getTrucksAvailableForRange(beginDate, endDate)
                    .subscribe(availableTrucks => {
                        this.availableTrucks.set(planningAsphalt, availableTrucks);
                    }));
            } else {
                this.maxUsedTrucks.set(planningAsphalt, 0);
                this.availableTrucks.set(planningAsphalt, 0);
            }
        });
    }

    public updateStatus(statusId: number) {
        this.asphaltListItems.forEach(asphaltListItem => {
            asphaltListItem.updateStatus(statusId);
        });
    }

    ngOnInit() {
        if (typeof this.planningAsphaltList === 'undefined') {
            this.planningAsphaltList = [];
        }
        this.genStartTimesMap();

        this.asphaltService.getAsphaltMills().then(asphaltMill => {
            this.asphaltMills = asphaltMill;
        });

        this.trucks();
    }


    public disableForms() {
        this.asphaltListItems.forEach(asphaltListItem => {
            asphaltListItem.form.disable();
        });
    }

    public enableForms() {
        this.asphaltListItems.forEach(asphaltListItem => {
            asphaltListItem.form.enable();
        });
    }

    public reorder() {
        this.planningAsphaltList = this.planningAsphaltList.sort((a, b) => {
            return (new Date(a.time)).getTime() - (new Date(b.time)).getTime();
        });
    }

    public reValidateFields(daysDiff = 0) {
        this.asphaltListItems.forEach(asphaltListItem => {
            asphaltListItem.validateForms(daysDiff).then(() => {
            }, () => {
            });
        });
        this.genStartTimesMap();
    }

    public addAsphaltPlanning() {
        if (!this.planningAsphaltList) {
            this.planningAsphaltList = [];
        }
        const planningAsphalt = new PlanningAsphalt();
        planningAsphalt.planning_id = this.mainPlanning.planning_id;
        this.planningAsphaltList.push(planningAsphalt);
    }

    public validateForms(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Promise.all([
                ...this.asphaltListItems.map(pef => pef.validateForms())
            ]).then(() => {
                resolve(true);
            }, () => {
                this.asphaltListItems.forEach(asphaltListItem => {
                    if (!asphaltListItem.form.valid) {
                        for (const name of Object.keys(asphaltListItem.form.controls)) {
                            asphaltListItem.form.controls[name].markAsTouched();
                        }
                    }
                });
                reject();
            });
        });
    }

    public removeAsphaltPlanning(planning: PlanningAsphalt) {
        this.planningAsphaltList.splice(this.planningAsphaltList.indexOf(planning), 1);
    }

    public checkDirty(): boolean {
        let dirty = false;
        this.asphaltListItems.forEach(asphaltListItem => {
            if (!dirty) {
                dirty = asphaltListItem.form.dirty;
            }
        });
        return dirty;
    }

    public mainTimeChanged(origBegin, origEnd) {
        this.asphaltListItems.forEach(asphaltListItem => {
            const paTime = new Date(asphaltListItem.planningAsphalt.time).getTime();
            if (paTime === origBegin.getTime()) {
                asphaltListItem.planningAsphalt.time = new Date(this.mainPlanning.begindate);
                asphaltListItem.form?.get('time').setValue(asphaltListItem.planningAsphalt.time.getTime());
            }
            if (paTime === origEnd.getTime()) {
                asphaltListItem.planningAsphalt.time = new Date(this.mainPlanning.enddate);
                asphaltListItem.form?.get('time').setValue(asphaltListItem.planningAsphalt.time.getTime());
            }
        });
        this.genStartTimesMap();
    }

    private genStartTimesMap() {
        if (this.mainPlanning.begindate) {
            const begin = new Date(this.mainPlanning.begindate);
            begin.setUTCHours(begin.getUTCHours() - Settings.ASPHALT_SUBSTRACT_HOURS);
            this.timeList = Utils.createTimeRange(begin, this.mainPlanning.enddate, Settings.ASPHALT_INTERVAL_MINUTES);
        }
    }
}
