import {Pipe, PipeTransform} from '@angular/core';
import {Realisation, RealisationHourtype} from '../classes/realisation';

@Pipe({
    name: 'isTraveltime'
})
export class IsTraveltimePipe implements PipeTransform {

    transform(realisation: Realisation, ...args: unknown[]): unknown {
        return [RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(realisation.hourtype);
    }

}
