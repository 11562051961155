import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {LocalStorage} from '../../storage.class';
import {PasswordRequestValidationState} from './validation-state.enum';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UserService, UserType} from '../../services/user/user.service';

@Component({
    selector: 'app-restore-password',
    templateUrl: './restore-password.component.html',
    styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit, OnDestroy {

    public ValidationState = PasswordRequestValidationState;
    public form: FormGroup;
    public message;
    public validToken: PasswordRequestValidationState = PasswordRequestValidationState.Validating;
    public loading = false;
    private token: string;
    private subscriptions = new Subscription();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService) {
    }

    get password() {
        return this.form.get('password');
    }

    ngOnInit() {
        LocalStorage.logoutUser();

        this.form = new FormGroup({
            password: new FormControl('',
                [
                    Validators.required,
                    Validators.minLength(7),
                    Validators.pattern(/^(?=.*[!@#$&*0-9A-Z].*[!@#$&*0-9A-Z]).*$/)
                ]),
            passwordconf: new FormControl('', [
                Validators.required,
                this.matchFieldValidator(this.form, 'password')
            ])
        });
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.token = params['token'];
            this.authService.verifyResetToken(this.token).then(() => {
                this.validToken = PasswordRequestValidationState.Valid;
            }, () => {
                this.validToken = PasswordRequestValidationState.Invalid;
            });
        }));
    }

    matchFieldValidator(form: FormGroup, matchField: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.form) {
                const toValue = this.form.controls[matchField].value;
                const equal = toValue === control.value;
                if (equal) {
                    return null;
                } else {
                    return {'matchField': true};
                }
            }
            return null;
        };
    }

    setNewPassword() {
        if (this.form.valid) {
            this.loading = true;
            this.authService.restorePassword(this.token, this.form.value.password).subscribe(authResponse => {
                this.loading = false;
                LocalStorage.setUserToken(authResponse.access_token);
                LocalStorage.setUser(authResponse.user);
                if (UserService.userHasRights(UserType.USER)) {
                    this.router.navigate(['']);
                } else {
                    this.router.navigate(['/weekschedule']);
                }
            }, response => {
                this.loading = false;
                if (response['error']['status'] === 0) {
                    this.message = 'Kon de server niet bereiken';
                } else {
                    this.message = response['error']['error']['errormessage'] || response['error']['errormessage'];
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
