<div class="dialog-container">
    <h1 class="condensed dialog-title">Planning vastzetten</h1>
    <div class="dialog-content">
        <p>De planning is nu vastgezet tot en met {{fixedDate | date : 'EEEE d MMMM yyyy'}}.</p>

        <p *ngIf="mayFixPlanning">Wil je de planning vastzetten tot {{day.date | date : 'EEEE d MMMM yyyy'}}?</p>
    </div>
    <div class="d-flex flex-wrap" *ngIf="mayFixPlanning">
        <button (click)="onNoClick()" cdkFocusInitial mat-button>Annuleren</button>
        <div class="spacer"></div>
        <button (click)="fixPlanning()" color="primary" mat-raised-button>Zet vast</button>
    </div>
    <div *ngIf="!mayFixPlanning" class="d-flex flex-wrap">
        <div class="spacer"></div>
        <button (click)="onNoClick()" cdkFocusInitial color="primary" mat-button>Sluiten</button>
    </div>
</div>
