import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Employees} from '../afas-classes/employees.class';
import {Realization} from '../afas-classes/realization.class';
import {User} from '../classes/user.class';
import {PtRealization} from '../afas-classes/ptrealization.class';
import {map} from 'rxjs/operators';
import {Hourtype} from '../afas-classes/hourtype';
import {AfasUser} from '../afas-classes/afas-user';
import {Utils} from '../utils.class';
import {Illness} from '../afas-classes/illness.class';
import {Leave} from '../afas-classes/leave.class';
import {AfasProject} from '../afas-classes/afas-project';

@Injectable({
    providedIn: 'root'
})
export class AfasService {

    constructor(private apiService: ApiService) {
    }

    public getEmployees() {
        return this.apiService.getCall$<Employees[]>('afas/employees');
    }

    public getMaterieel() {
        return this.apiService.getCall$<Employees[]>('afas/materieel');
    }

    public getHourtypes() {
        return this.apiService.getCall$<Hourtype[]>('afas/hourtypes');
    }

    public getRealization(employeeId: string) {
        return this.apiService.getCall$<Realization[]>('afas/realization', employeeId ? {employeeId} : {});
    }

    public getProfile() {
        return this.apiService.getCall$<{ employee: Employees, user: User, afasuser: AfasUser }>('afas/profile');
    }

    public getLeaves(fromDate: Date, toDate: Date) {
        const begindate = Utils.newDate(fromDate);
        Utils.setTime(begindate, 0, 0);
        const enddate = Utils.newDate(toDate);
        Utils.setTime(enddate, 23, 59);
        return this.apiService.getCall$<Leave[]>('afas/leaves', {
            begindate: begindate.toISOString(),
            enddate: enddate.toISOString()
        });
    }

    public getIllness(fromDate: Date, toDate: Date) {
        const begindate = Utils.newDate(fromDate);
        Utils.setTime(begindate, 0, 0);
        const enddate = Utils.newDate(toDate);
        Utils.setTime(enddate, 23, 59);
        return this.apiService.getCall$<Illness[]>('afas/illness', {
            begindate: begindate.toISOString(),
            enddate: enddate.toISOString()
        });
    }

    public getProjects(search?: string) {
        let params = {};
        if (search) {
            params = {search};
        }
        return this.apiService.getCall$<AfasProject[]>('afas/projects', params).pipe(map(projects => {
            if (projects.data?.length) {
                const mainProjects = projects.data.filter(p => !p.hoofdproject);
                mainProjects.forEach(mainProject => {
                    mainProject.projects = projects.data.filter(p => p.hoofdproject === mainProject.projectId);
                    mainProject.projects.forEach(project => {
                        project.mainProject = mainProject.projectId + ' - ' + mainProject.description;
                    });
                });

            }
            return projects;
        }));
    }

    public saveRealization(re: Partial<PtRealization>) {
        re.Ap = true; // Geaccodeerd, moet true dan niet aanpasbaar in profit
        re.Pr = true; // Gereedgemeld, moet true dan niet aanpasbaar in profit
        return this.apiService.postCall$<boolean>('afas/realization', re);
    }
}
