<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Adressen</div>
    <div>
        <button mat-dialog-close mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>

<div class="row">
    <div class="col-5">
        <mat-list>
            <div mat-subheader class="pb-0 pt-0 curpoint" matRipple
                 (click)="selectedField = SelectedField.origin"
                 [class.active]="selectedField === SelectedField.origin">
                Start
            </div>
            <mat-list-item matRipple class="curpoint"
                           [class.active]="selectedField === SelectedField.origin"
                           (click)="selectedField = SelectedField.origin">
                <app-address-icon mat-list-icon
                                  [planning]="planning"
                                  [address]="form.value.origin"
                                  [userId]="user.id"
                                  [users]="[user]"></app-address-icon>
                <div mat-line>{{form.value.origin | split}}</div>
                <div mat-line>{{form.value.origin | split : 1}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader class="pb-1 pt-1 curpoint" matRipple
                 [class.active]="selectedField === SelectedField.work"
                 (click)="selectedField = SelectedField.work">
                Werklocatie
            </div>
            <mat-list-item matRipple class="curpoint"
                           [class.active]="selectedField === SelectedField.work || selectedField === SelectedField.work_begin"
                           (click)="selectedField = SelectedField.work_begin">
                <app-address-icon mat-list-icon
                                  [address]="form.value.work_begin"
                                  [planning]="planning"
                                  [userId]="user.id"
                                  [users]="[user]"></app-address-icon>
                <div mat-line>{{form.value.work_begin | split}}</div>
                <div mat-line>{{form.value.work_begin | split : 1}}</div>
            </mat-list-item>
            <mat-list-item matRipple class="curpoint"
                           [class.active]="selectedField === SelectedField.work || selectedField === SelectedField.work_end"
                           (click)="selectedField = SelectedField.work_end">
                <app-address-icon mat-list-icon
                                  [address]="form.value.work_end"
                                  [planning]="planning"
                                  [userId]="user.id"
                                  [users]="[user]"></app-address-icon>
                <div mat-line>{{form.value.work_end | split}}</div>
                <div mat-line>{{form.value.work_end | split : 1}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader class="pb-0 pt-0 curpoint" matRipple
                 (click)="selectedField = SelectedField.destination"
                 [class.active]="selectedField === SelectedField.destination">
                Einde
            </div>
            <mat-list-item matRipple class="curpoint"
                           [class.active]="selectedField === SelectedField.destination"
                           (click)="selectedField = SelectedField.destination">
                <app-address-icon mat-list-icon
                                  [address]="form.value.destination"
                                  [planning]="planning"
                                  [userId]="user.id"
                                  [users]="[user]"></app-address-icon>
                <div mat-line>{{form.value.destination | split}}</div>
                <div mat-line>{{form.value.destination | split : 1}}</div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="col-7">
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>Zoeken</mat-label>
            <input [formControl]="search" matInput placeholder="Zoeken" type="text" autocomplete="off">
            <mat-error></mat-error>
        </mat-form-field>
        <mat-action-list>
            <ng-container>
                <div mat-list-item matRipple class="p-2 curpoint" (click)="choose(user)">
                    <div class="row">
                        <div class="col-auto d-flex align-items-center pr-0">
                            <i class="fa-duotone fa-house-user"></i>
                        </div>
                        <div class="col">
                            {{user.street}} {{user.housenumber}}<br>
                            {{user.zipcode}} {{user.town}}
                        </div>
                    </div>
                </div>
                <div mat-list-item matRipple class="p-2 curpoint" (click)="choose(dvdsAddress)">
                    <div class="row">
                        <div class="col-auto d-flex align-items-center pr-0">
                            <img class="dvds-icon" src="/assets/images/logo-small.png">
                        </div>
                        <div class="col">
                            {{dvdsAddress.street}} {{dvdsAddress.housenumber}}<br>
                            {{dvdsAddress.zipcode}} {{dvdsAddress.town}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngFor="let address of addresses" mat-list-item matRipple class="p-2 curpoint" (click)="choose(address)">
                <div class="row">
                    <div class="col-auto d-flex align-items-center pr-0">
                        <i class="fa-duotone fa-hotel"></i>
                    </div>
                    <div class="col">
                        {{address}}
                    </div>
                </div>
            </div>
        </mat-action-list>
    </div>
</div>
<div class="d-flex flex-wrap pt-2 pb-2 justify-content-between">
    <button id="close-empl" (click)="close()" class="mr-2" color="secondary" mat-raised-button>Sluiten</button>
    <button (click)="save()" color="primary"
            mat-raised-button>
        Opslaan
    </button>
</div>
