import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ComponentType} from "@angular/cdk/overlay";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    private defaultDialogSettings = {
        maxWidth: '95vw',
        minWidth: '600px',
        minHeight: '600px',
        maxHeight: '95vh',
        disableClose: true,

    };

    constructor(public dialog: MatDialog) {
    }

    open(innerComponent: ComponentType<any>, data?: any, overrideConfig?: {}) {
        this.dialog.open(innerComponent, {
            data,
            ...this.defaultDialogSettings,
            ...overrideConfig,
            panelClass: 'dialog' + innerComponent.name.replace(/([A-Z])/g, '\-$1').toLowerCase(),
        });
    }
}
