import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PlanningAsfaltteam} from '../../../classes/planningasfaltteam.class';
import {AsphaltmillAsphalt} from '../../../classes/asphaltmillasphalt.class';
import {PlanningHasEntity} from '../../../classes/planning-has-entity.class';
import {Asphaltmill} from '../../../classes/asphaltmill.class';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {PlanningCutter} from '../../../classes/planningcutter.class';
import {Utils} from '../../../utils.class';
import {Planning} from '../../../classes/planning.class';
import {DayTimeOptions} from '../../../classes/day-time-options';
import {Observable} from 'rxjs';
import {ProjectUsedAsphalt} from '../../../classes/project-used-asphalt.class';
import {requiredConditional} from '../../../validators/required-conditional.validator';
import {AsphaltService} from '../../../services/asphalt/asphalt.service';

@Component({
    selector: 'tr[app-asphalt-used-list-item]',
    templateUrl: './asphalt-used-list-item.component.html',
    styleUrls: ['./asphalt-used-list-item.component.scss']
})
export class AsphaltUsedListItemComponent implements OnInit, OnChanges {

    @Input() timeList: DayTimeOptions[] = [];
    @Input() asphaltMills: Asphaltmill[];
    @Input() planningAsphalt: ProjectUsedAsphalt;
    @Input() formsDisabled: boolean;
    @Input() mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity;
    @Input() planning: Planning;
    @Input() isAdhesive = false;

    @Output() reOrder = new EventEmitter();
    @Output() delete = new EventEmitter();

    @ViewChild('millInput') millInput: ElementRef;

    @ViewChild('asphaltInput') asphaltInput: ElementRef;

    @ViewChild('tonsInput') tonsInput: ElementRef;

    @ViewChild('asphaltNameInput') asphaltNameInput: ElementRef;

    selectedMill: Asphaltmill;
    selectedAsphalt: AsphaltmillAsphalt;
    filteredMills: Observable<Asphaltmill[]>;
    filteredAsphalt: Observable<AsphaltmillAsphalt[]>;
    form: FormGroup;
    asphaltText = '';
    applicationList: { name: string; key: string; }[];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.asphaltMills) {
            this.doInit();
        }
    }

    public validateForms(daysDiff: number = 0): Promise<boolean> {

        return Utils.triggerValidationP(this.form);
    }

    ngOnInit() {
        this.applicationList = this.isAdhesive ? AsphaltService.applicationListAdhesive : AsphaltService.applicationListAsphalt;
    }

    doInit() {
        this.createForm();
        this.millSelection(this.planningAsphalt.asphaltmill_id);
        this.asphaltSelection(this.planningAsphalt.asphaltmill_asphalt_id, true);

        this.filteredMills = this.form.valueChanges
            .pipe(startWith(null))
            .pipe(map(val => {
                const millText = val ? val.mill : '';
                return this.asphaltMills.filter((asphaltMill) => new RegExp(millText, 'gi').test(asphaltMill.name));
            }));
        this.filteredAsphalt = this.form.valueChanges
            .pipe(startWith(null))
            .pipe(map(val => {
                let asphaltText = val ? val.asphaltname : null;
                if (this.selectedAsphalt && val && val.asphaltname === this.selectedAsphalt.asphalt.name) {
                    asphaltText = null;
                }
                if (!asphaltText) {
                    return this.selectedMill.asphalt;
                }
                return this.selectedMill.asphalt.filter((asphalt) =>
                    new RegExp(asphaltText, 'gi').test(asphalt.asphalt.name + ' ' + asphalt.code));
            }));
        if (!this.planningAsphalt.asphaltmill_id) {
            setTimeout(() => {
                if (this.isAdhesive) {
                    this.asphaltNameInput?.nativeElement.focus();
                } else {
                    this.millInput?.nativeElement.focus();
                }
            });
        }

    }

    asphaltTextChanged(event) {
        this.asphaltText = event.target.value;
        if (this.selectedAsphalt && event.target.value.length === 0) {
            this.form.get('asphaltname').setValue(this.asphaltText = this.selectedAsphalt.asphalt.name);
        } else {
            this.form.get('asphaltcode').setValue('');
        }
    }

    millSelectionChange(event: MatAutocompleteSelectedEvent) {
        this.millSelection(event.option.value.id);
        setTimeout(() => {
            this.asphaltInput.nativeElement.focus();
        });
    }

    asphaltSelectionChange(event: MatAutocompleteSelectedEvent) {
        this.asphaltSelection(event.option.value.id);
        if (this.selectedAsphalt) {
            this.form.get('asphaltname').setValue(this.asphaltText = this.selectedAsphalt.asphalt.name);
            this.form.get('asphaltcode').setValue(this.selectedAsphalt.code);
            this.tonsInput.nativeElement.focus();
        }
    }

    millSelection(asphaltMillId) {
        this.selectedMill = this.asphaltMills.find(mill => mill.id === asphaltMillId);
        if (this.selectedMill) {
            this.form.get('asphaltmill_id').setValue(this.selectedMill.id);
        }
    }

    asphaltSelection(asphaltId, init = false) {
        if (asphaltId && this.selectedMill) {
            this.selectedAsphalt = this.selectedMill.asphalt.find(asphalt => asphalt.id === asphaltId);
            if (this.selectedAsphalt) {
                this.form.get('asphaltmill_asphalt_id').setValue(this.selectedAsphalt.id);
                if (!init || !this.form.value['application']) {
                    if (this.selectedAsphalt.asphalt.name.toLowerCase().indexOf(' base ') !== -1) {
                        this.form.get('application').setValue('base');
                    } else if (this.selectedAsphalt.asphalt.name.toLowerCase().indexOf(' bin ') !== -1) {
                        this.form.get('application').setValue('bind');
                    }
                    if (this.selectedAsphalt.asphalt.name.toLowerCase().indexOf(' surf ') !== -1) {
                        this.form.get('application').setValue('surf');
                    }
                }
            } else {
                this.form.get('asphaltmill_asphalt_id').setValue(null);
            }
        }
    }


    emptystring() {
        return '';
    }

    public removeAsphaltPlanning() {
        this.delete.emit();
    }

    private createForm(): FormGroup {
        const requiredAdhesive = requiredConditional(this.isAdhesive);
        const requiredAsphalt = requiredConditional(!this.isAdhesive);
        this.form = new FormGroup({
            asphaltmill_id: new FormControl(this.planningAsphalt.asphaltmill_id, requiredAsphalt),
            asphaltname: new FormControl(this.planningAsphalt.asphaltname, [Validators.required, Validators.maxLength(255)]),
            asphaltmill_asphalt_id: new FormControl(this.planningAsphalt.asphaltmill_asphalt_id),
            asphaltcode: new FormControl(this.planningAsphalt.asphaltcode, requiredAsphalt),
            tons: new FormControl(this.planningAsphalt.tons, [requiredAsphalt, Validators.max(9999)]),
            thickness: new FormControl(this.planningAsphalt.thickness, [requiredAsphalt, Validators.max(9999)]),
            surface: new FormControl(this.planningAsphalt.surface, [Validators.required, Validators.max(9999)]),
            liter: new FormControl(this.planningAsphalt.liter, [requiredAdhesive, Validators.max(9999)]),
            temperature: new FormControl(this.planningAsphalt.temperature, [requiredAsphalt, Validators.max(9999)]),
            comment: new FormControl(this.planningAsphalt.comment, Validators.maxLength(300)),
            application: new FormControl(this.planningAsphalt.application, Validators.required)
        });
        this.asphaltText = this.planningAsphalt.asphaltname;
        this.form.valueChanges.pipe(debounceTime(25)).subscribe(formValues => {
            for (const [key, value] of Object.entries(formValues)) {
                if (value && key === 'time') {
                    if (typeof value === 'number') {
                        this.planningAsphalt[key] = new Date(value);
                    } else {
                        this.planningAsphalt[key] = null;
                    }
                    this.reOrder.emit();
                } else {
                    this.planningAsphalt[key] = value;
                }
            }
        });
        console.log(this.form);
        if (this.formsDisabled) {
            setTimeout(() => {
                this.form.disable();
            });
        }
        return this.form;
    }

}
