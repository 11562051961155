<div class="modal-inner">
    <h1>Tijd selecteren</h1>
    <div class="selectors">
        <div class="selector-box" [class.now-button]="showNowButton">
            <div class="time">:</div>
            <div class="currenttime" matRipple (click)="currentTime()" *ngIf="showNowButton">
                NU
            </div>
        </div>
        <div class="hours" (mousemove)="touchmove($event, 'hour')">
            <div class="hour"
                 id="hour{{hour.time}}"
                 [class.selected]="selectedhour == hour.time"
                 (click)="clickHour(hour.time)"
                 *ngFor="let hour of hours">
                <small style="pointer-events: none;">{{hour.day}}&nbsp;</small>{{hour.hour}}
            </div>
        </div>
        <div class="minutes" (mousemove)="touchmove($event, 'minute')">
            <div class="minute"
                 id="minute{{minute}}"
                 [class.selected]="selectedminute === minute"
                 (click)="clickMinute(minute)"
                 *ngFor="let minute of minutes">
                {{minute}}
            </div>
        </div>
        <div class="fader">

        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button color="primary" class="planning-button confirm" (click)="selectAndClose()">Gereed</button>
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="" (click)="dialogRef.close()">Annuleren</button>
    </div>
</div>
