<div class="controls">
    <div class="header">
        <app-header (clicked)="setCenterDate()"
                    [mainTitle]="environment.isCodalt ? 'Planning Codalt' : 'Planning DvdS'"
                    [subTitle]="environment.isCodalt ? 'Overzicht' : 'Frees- en transport'">
        </app-header>
    </div>
    <div class="indicator">
        <div class="team-selector flex-column d-flex" *ngIf="!print && !mobile">
            <app-planning-filter (selectedTeams)="selectedTeamsChange($event)"></app-planning-filter>
        </div>
        <div class="show-in-use" *ngIf="!print">
            <mat-checkbox [formControl]="fcShowSmallMaterials" class="mr-3">Pers/mat</mat-checkbox>
            <mat-checkbox [formControl]="fcShowInUse">Bezetting</mat-checkbox>
        </div>
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [print]="print"
                            [singleDate]="currentDate">
        </app-date-indicator>
    </div>
    <div class="print-pdf" *ngIf="!print">
        <button (click)="pdf()" color="primary" mat-icon-button>
            <i class="fa-light fa-file-pdf" *ngIf="!downloadingPdf"></i>
            <i class="fas fa-spinner fa-spin" *ngIf="downloadingPdf"></i>
        </button>
    </div>
    <div class="actions" *ngIf="!print">
        <app-settings-menu (selectedTeams)="selectedTeamsChange($event)"></app-settings-menu>
    </div>
    <div class="actions" *ngIf="print">
        PDF datum: {{now | date: 'd MMMM yyyy HH:mm'}}
    </div>
</div>
<div class="overview-and-side" [class.show-in-use]="fcShowInUse.value">
    <div class="overview">
        <div class="legend">
            <div *ngIf="!planningForms" class="loading">
                Laden...
            </div>
            <div *ngIf="planningForms && !dayIsFixed" class="loading">
                Deze dag is nog niet vastgezet in de weekplanning
            </div>
            <div *ngIf="dayIsFixed && planningForms?.length === 0" class="loading">
                Er zijn geen defintieve planningen voor deze dag
            </div>
        </div>

        <ng-container *ngFor="let main of planningForms">
            <table class="table table-center" [class.table-add-row]="!print" *ngIf="dayIsFixed">
                <tr class="invisible">
                    <td [style.width.px]="200"></td>
                    <td [style.width.px]="100"></td>
                    <td [style.width.px]="100"></td>
                    <td colspan="5"></td>
                    <td [style.width.px]="150"></td>
                    <td [style.width.px]="150"></td>
                    <td colspan="2"></td>
                </tr>
                <tr [class.red]="main.planning.status_id !== PlanningStatus.definitief">
                    <th colspan="12"
                        class="team"
                        *ngIf="main.planning.is_transport">
                        TRANSPORT
                        <small>diversen</small>
                    </th>
                    <ng-container *ngIf="!main.planning.is_transport">
                        <th class="project-id">
                            {{main.planning.afas_project_id}}
                        </th>
                        <th colspan="4"
                            class="team"
                            *ngIf="main.hasSets || (!main.planning.performer && !main.planning.asphalt_performer)"
                            (dblclick)="openPlanning(main.planning)">
                            {{EntityTypeMap.get(main.mainPlanning.entitytype)?.name}}
                            {{main.mainPlanning?.entity?.name}}
                            <small>{{(executors | byId: main.planning.asphalt_performer)?.name}} / {{(executors | byId: main.planning.performer)?.name}}</small>
                        </th>
                        <th colspan="4"
                            *ngIf="!main.hasSets && (main.planning.performer || main.planning.asphalt_performer)"
                            (dblclick)="openPlanning(main.planning)">
                            {{(executors | byId: main.planning.performer)?.name}}
                            <small>{{(executors | byId: main.planning.asphalt_performer)?.name}}</small>
                        </th>
                        <th *ngIf="main.hasSets || true">
                            {{main.mainPlanning?.begindate | date : 'EEE HH:mm'}}
                        </th>
                        <th *ngIf="main.hasSets || true">
                            {{main.mainPlanning?.enddate | date : 'HH:mm'}}
                        </th>
                        <th colspan="5" class="main-comment" [matTooltip]="main.planning.description">
                            {{main.planning.description}}
                        </th>
                    </ng-container>
                    <th width="300" *ngIf="main.planning.is_transport || fcShowSmallMaterials.value"></th>
                </tr>
                <tr *ngIf="main.planning.status_id == PlanningStatus.definitief">
                    <th [style.width.px]="300" colspan="1">Chauffeur</th>
                    <th [style.width.px]="150" colspan="2">Materieel</th>
                    <th [style.width.px]="100">Auto</th>
                    <th [style.width.px]="100">Dieplader</th>
                    <th [style.width.px]="100">Aanvang</th>
                    <th [style.width.px]="100">Tot</th>
                    <th [style.width.px]="40">Start</th>
                    <th [style.width.px]="300">Van</th>
                    <th [style.width.px]="300">Naar</th>
                    <th [style.width.px]="40">Stop</th>
                    <th [style.min-width.px]="300">Werkzaamheden</th>
                    <th [style.width.px]="200" *ngIf="main.planning.is_transport">Project</th>
                    <td [style.min-width.px]="250" [attr.rowspan]="main.entities | entitiesRows" class="employees-equipment" *ngIf="!main.planning.is_transport && this.fcShowSmallMaterials.value">
                        <div>
                            <div (click)="openEmployees(main.planning)" class="curpoint">
                                <b>Personeel</b><br>
                                <div *ngFor="let userPlanning of main.planning.user_planning" class="d-flex">
                                    <div class="ellipsis">
                                        {{userPlanning?.user?.name}}
                                    </div>
                                    <div class="col-auto pl-1 pr-0">
                                        <ng-container *ngIf="userPlanning?.begindate | isOtherDate : this.currentDate">
                                            {{userPlanning?.begindate | date : 'EE d MMM'}}
                                        </ng-container>
                                        {{userPlanning?.begindate | date : 'H:mm'}} {{userPlanning?.enddate | date : 'HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <div (click)="openEquipment(main.planning)" class="curpoint">
                                <b>Klein materieel</b><br>
                                <div *ngFor="let phe of main.planning.planning_has | entityType : 'visible_small' : true" class="d-flex">
                                    <div class="ellipsis">
                                        {{phe?.entity?.name}}
                                    </div>
                                    <div class="col-auto pl-1 pr-0">
                                        <ng-container *ngIf="phe?.begindate | isOtherDate : this.currentDate">
                                            {{phe?.begindate | date : 'EE d MMM'}}
                                        </ng-container>
                                        {{phe?.begindate | date : 'H:mm'}} {{phe?.enddate | date : 'HH:mm'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <ng-container *ngIf="main.planning.is_transport">
                    <tr class="leave-or-ill" *ngFor="let i of leaveOrIll">
                        <td colspan="1">
                            {{i.user?.name}}
                        </td>
                        <td colspan="12" [style.text-align]="'left'">
                            <ng-container *ngIf="i.leave">
                                {{i.leave.leaveDescr}} ({{i.leave.startDate | date : 'EEEE d MMMM yyyy HH:mm'}}
                                - {{i.leave.endDate | date : 'EEEE d MMMM yyyy HH:mm'}})
                            </ng-container>
                            <ng-container *ngIf="i.ill">
                                {{i.ill.absenceTypeDesc}} ({{i.ill.startDate | date : 'EEEE d MMMM yyyy'}} -
                                {{(i.ill.endDate | date : 'EEEE d MMMM yyyy') ?? 'onbekend'}})
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>

                <ng-container *ngFor="let pln of main.entities; let i = index;">
                    <ng-container *ngTemplateOutlet="row;context:{pln:pln.planning,fg:pln.fg,main:main,highlight:pln.highlight,i:i}"></ng-container>
                </ng-container>

                <tr class="add-row" *ngIf="!print">
                    <td colspan="14" *ngIf="main.planning.status_id !== PlanningStatus.definitief">
                        <button class="w-100" color="secondary" mat-button (click)="openPlanning(main.planning)">
                            Deze planning is nog niet definitief
                        </button>
                    </td>
                    <ng-container *ngIf="main.planning.status_id === PlanningStatus.definitief">
                        <td colspan="7" *ngIf="main.cutterTrucks+main.asphaltTrucks > 0">
                            <button (click)="addTrucks(main)" class="w-100" color="primary" mat-button>
                                {{main.cutterTrucks + main.asphaltTrucks}} vrachtwagens toevoegen <i class="fas fa-plus"></i>
                            </button>
                        </td>
                        <td [attr.colspan]="main.cutterTrucks+main.asphaltTrucks > 0 ? 7 : 14">
                            <button (click)="addChooseType(main)" class="w-100" color="primary" mat-button>
                                Regel toevoegen <i class="fas fa-plus"></i>
                            </button>
                        </td>
                    </ng-container>
                </tr>
            </table>
        </ng-container>

        <ng-template #row let-pln=pln let-main=main let-fg=fg let-highlight=highlight let-i=i>
            <tr class="empty-row"
                *ngIf="i > 0 && ((main.entities[i+1]?.planning.driver_user_id === pln.driver_user_id && main.entities[i-1]?.planning.driver_user_id !== pln.driver_user_id) ||
            (main.entities[i-1]?.planning.driver_user_id !== pln.driver_user_id && main.entities[i-2]?.planning.driver_user_id === main.entities[i-1]?.planning.driver_user_id))">
                <td [attr.colspan]="main.planning.is_transport ? 13 : this.fcShowSmallMaterials.value ? 12 : 11">
                    &nbsp;
                </td>
            </tr>
            <tr [formGroup]="fg"
                [class.highlight]="highlight"
                [class.selected]="fg.selected"
                (contextmenu)="openContextMenu($event, menuTrigger, spanTrigger)">
                <td *ngIf="(pln.entitytype?.has_lowloader || pln.entitytype?.has_truck || pln.entitytype?.has_driver) && pln.entity?.use_once"
                    [class.error-background]="fg.controls.driver_user_id.invalid">
                    <div class="d-flex">
                        <ng-select placeholder="Chauffeur"
                                   bindLabel="name"
                                   class="ng-dropdown-panel-left"
                                   bindValue="id"
                                   [items]="drivers"
                                   [formControl]="fg.controls.driver_user_id"
                                   [style.width.px]="150">
                            <ng-template ng-label-tmp let-item="item">
                                {{item.name}} <i *ngIf="item.default_stand == DefaultStand.Home" class="fa-duotone fa-house-user"></i>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}} <i *ngIf="item.default_stand == DefaultStand.Home" class="fa-duotone fa-house-user"></i>
                            </ng-template>
                        </ng-select>
                    </div>
                </td>

                <mat-menu #menu="matMenu" backdropClass="context-menu">
                    <button mat-menu-item *ngIf="!pln.entity_id" (click)="changeEntity(pln, main, pln.id)">Kies materieel</button>
                    <button mat-menu-item *ngIf="pln.entity_id" (click)="removeEntity(main, pln.id)">Verwijder materieel</button>
                    <button mat-menu-item (click)="duplicatePlanning(pln, main, fg, true)">Voorgaand invoegen</button>
                    <button mat-menu-item (click)="duplicatePlanning(pln, main, fg)">Opvolgend invoegen</button>
                    <button mat-menu-item (click)="deletePlanning(pln)" [disabled]="main.planning.entity_id === pln.entity_id"><i class="fa fa-trash"></i>
                        {{main.planning.entity_id === pln.entity_id ? 'Verwijder via weekplanning' : 'Regel verwijderen'}}
                    </button>
                </mat-menu>
                <td [attr.colspan]="pln.entity | transportPlanningCols : pln.entitytype : pln.entity?.use_once"
                    class="text-nowrap"
                    *ngIf="pln.entity_id"
                    [class.error-background]="fg.controls.entity_id.invalid"
                    [class.alert-multiple]="pln.entity_id | multiple : pln.id : currentDate : planning : entitiesMap">
                    <div
                        *ngIf="pln.entitytype?.id !== EntityTypeCode.Truck && pln.entitytype?.id !== EntityTypeCode.CutterTruck && pln.entitytype?.id !== EntityTypeCode.AsfaltTruck && pln.entity?.use_once"
                        [matTooltip]="pln.name"
                        class="material-piece">
                        {{pln.entity?.name ?? pln.hiring?.name ?? pln.name}}
                    </div>
                    <div class="d-flex"
                         *ngIf="(pln.entitytype_id === EntityTypeCode.Truck || pln.entitytype_id === EntityTypeCode.CutterTruck || pln.entitytype_id === EntityTypeCode.AsfaltTruck) && pln.entity?.use_once">
                        <ng-select [placeholder]="pln.name ?? 'Auto'" [formControl]="fg.controls.entity_id">
                            <ng-template ng-label-tmp let-item="item">{{(trucks | byId: item)?.name ?? item | licensePlate}}</ng-template>
                            <ng-option [value]="truck.id" *ngFor="let truck of trucks" [innerHTML]="truck.name | materialPiece"
                                       [disabled]="truck | entityUnavailable : pln : planning"></ng-option>
                        </ng-select>
                    </div>
                    <div class="d-flex" *ngIf="!pln.entity?.use_once">
                        <ng-select placeholder="Inhuurbedrijf {{(entityTypes | byId: pln.entitytype_id)?.name}}" [formControl]="fg.controls.hiring_id">
                            <ng-option [value]="h.id" *ngFor="let h of hiringMap.get(pln.entitytype_id)" [innerHTML]="h.name"></ng-option>
                        </ng-select>
                    </div>
                </td>
                <td *ngIf="!pln.entity?.use_once && pln.entity_id" class="text-input" width="150"
                    [attr.colspan]="pln.entity | transportPlanningCols : pln.entitytype ">
                    <input matInput placeholder="Inhuurnaam" type="text" formControlName="name">
                </td>
                <td *ngIf="!pln.entity_id" class="free-text">
                    {{pln.entitytype?.name}}
                    <small><br>nader te bepalen</small>
                </td>
                <td *ngIf="!pln.entity_id" class="text-input" colspan="4">
                    <input matInput placeholder="Nader te bepalen" type="text" formControlName="name">
                </td>
                <td *ngIf="pln.entitytype?.has_truck && pln.entity_id"
                    [class.error-background]="fg.controls.truck_entity_id.invalid"
                    [class.alert-multiple]="pln.truck_entity_id | multiple : pln.id : currentDate : planning : entitiesMap">
                    <div class="d-flex">
                        <ng-select placeholder="Auto" [formControl]="fg.controls.truck_entity_id">
                            <ng-template ng-label-tmp let-item="item">{{(trucks | byId: item)?.name ?? item | licensePlate}}</ng-template>
                            <ng-option [value]="truck.id" *ngFor="let truck of trucks" [innerHTML]="truck.name"></ng-option>
                        </ng-select>
                    </div>
                </td>
                <td *ngIf="pln.entitytype?.has_lowloader && pln.entity_id"
                    [class.error-background]="fg.controls.lowloader_entity_id.invalid"
                    [class.alert-multiple]="pln.lowloader_entity_id | multiple : pln.id : currentDate : planning : entitiesMap">
                    <div class="d-flex">
                        <ng-select placeholder="Dieplader" [formControl]="fg.controls.lowloader_entity_id"
                                   [clearable]="!fg.controls.lowloader_entity_id?.value">
                            <ng-template ng-label-tmp let-item="item">{{((lowLoaders | byId: item)?.name) ?? item | licensePlate}}</ng-template>
                            <ng-option [value]="lowloader.id" *ngFor="let lowloader of lowLoaders" [innerHTML]="lowloader.name"></ng-option>
                        </ng-select>
                    </div>
                </td>
                <ng-container formGroupName="date">
                    <td [class.error-background]="fg.errors && fg.errors.notAvailable"
                        colspan="2"
                        class="tabletime">
                        <div class="timepicker">
                            <mat-select formControlName="begintime" placeholder="van" [class.text-red]="fg.get('date').get('begintime')?.value | isNight">
                                <mat-select-trigger>
                                    <ng-container *ngIf="(fg.get('date').get('begintime')?.value | isSubsequent); else dateDisplay1">
                                        <small>aansluitend {{fg.get('date').get('begintime')?.value | date : 'HH:mm'}}</small>
                                    </ng-container>
                                    <ng-template #dateDisplay1>
                                        <small>{{fg.get('date').get('begintime')?.value | date : 'EEE'}}</small> {{fg.get('date').get('begintime')?.value | date : 'HH:mm'}}
                                    </ng-template>
                                </mat-select-trigger>
                                <ng-container>
                                    <mat-optgroup
                                        *ngFor="let day of startTimesEntity.get(pln)"
                                        label="{{day.datetime | date : 'EEEE d MMM'}}">
                                        <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                            <ng-container *ngIf="(time.datetime | isSubsequent); else dateDisplay2">
                                                Aansluitend
                                            </ng-container>
                                            <ng-template #dateDisplay2>
                                                {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                                            </ng-template>
                                        </mat-option>
                                    </mat-optgroup>
                                </ng-container>
                            </mat-select>
                            <mat-select formControlName="endtime" placeholder="tot" [class.text-red]="fg.get('date').get('endtime')?.value | isNight">
                                <mat-select-trigger>
                                    <ng-container *ngIf="(fg.get('date').get('endtime')?.value | isSubsequent); else dateDisplay7">
                                        <small>aansluitend {{fg.get('date').get('endtime')?.value | date : 'HH:mm'}}</small>
                                    </ng-container>
                                    <ng-template #dateDisplay7>
                                        <small>{{fg.get('date').get('endtime')?.value | date : 'EEE'}}</small> {{fg.get('date').get('endtime')?.value | date : 'HH:mm'}}
                                    </ng-template>
                                </mat-select-trigger>
                                <ng-container *ngIf="endTimesEntity.has(pln)">
                                    <mat-optgroup
                                        *ngFor="let day of endTimesEntity.get(pln)"
                                        [label]="day.datetime | date : 'EEEE d MMM'">
                                        <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                            {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                                        </mat-option>
                                    </mat-optgroup>
                                </ng-container>
                            </mat-select>
                        </div>
                    </td>
                </ng-container>
                <td class="text-input">
            <span style="position:fixed;" #spanTrigger class="context-menu-trigger" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
                  (menuOpened)="fg.selected=true" (menuClosed)="fg.selected=false"></span>
                    <app-transport-address [main]="main"
                                           [planning]="pln"
                                           [fc]="fg.get('transport_origin')"
                                           [isOrigin]="true"
                                           [hasNext]="main.entities[i-1]?.planning.driver_user_id === pln.driver_user_id && !!pln.driver_user_id"
                                           [fg]="fg"
                                           [users]="employees"
                                           placeholder="Startlocatie">
                    </app-transport-address>
                </td>
                <td class="text-input">
                    <app-transport-address [main]="main"
                                           [planning]="pln"
                                           [fc]="fg.get('transport_worksite')"
                                           [fg]="fg"
                                           [users]="employees"
                                           placeholder="Werklocatie">
                    </app-transport-address>
                </td>
                <td class="text-input">
                    <app-transport-address [main]="main"
                                           [planning]="pln"
                                           [fc]="fg.get('transport_worksite_end')"
                                           [fg]="fg"
                                           [users]="employees"
                                           placeholder="Werklocatie">
                    </app-transport-address>
                </td>
                <td class="text-input">
                    <app-transport-address [main]="main"
                                           [planning]="pln"
                                           [fc]="fg.get('transport_destination')"
                                           [fg]="fg"
                                           [hasNext]="main.entities[i+1]?.planning.driver_user_id === pln.driver_user_id && !!pln.driver_user_id"
                                           [users]="employees"
                                           placeholder="Eindlocatie">
                    </app-transport-address>
                </td>
                <td class="text-input description" (click)="textArea.focus()">
                    <textarea cdkTextareaAutosize #textArea placeholder="Omschrijving werkzaamheden" formControlName="comment"></textarea>
                </td>
                <td *ngIf="main.planning.is_transport">
                    <div class="d-flex">
                        <ng-select formControlName="afas_project_id"
                                   [typeahead]="searchAfasProjects$"
                                   [clearable]="true"
                                   [selectableGroup]="false"
                                   [items]="projects"
                                   placeholder="Project"
                                   groupBy="mainProject"
                                   bindLabel="name"
                                   bindValue="afas_project_id">
                            <ng-template ng-label-tmp let-item="item">
                                <b>{{item.afas_project_id}}</b> {{item.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <b>{{item.afas_project_id}}</b> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                </td>
            </tr>
        </ng-template>
    </div>
    <app-transport-in-use [currentDate]="currentDate"></app-transport-in-use>
</div>
