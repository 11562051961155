<div class="document">
    <ng-container *ngFor="let day of days">
        <div class="page" *ngIf="day.comment?.comment?.length > 0 || day.asphalt?.length > 0 || day.adhesive?.length > 0">
            <h2>Dagrapport {{day.date | date : 'EEEE dd MMMM YYYY'}} {{projectId}}</h2>

            <ng-container *ngIf="day.asphalt?.length > 0">
                <h2>
                    Asfalt
                </h2>
                <app-asphalt-used-list #asphaltUsedList
                                       [project]="project"
                                       [projectUsedAsphaltList]="day.asphalt"
                                       [date]="day.date"
                                       [print]="print"
                                       [formsDisabled]="true"></app-asphalt-used-list>
            </ng-container>

            <ng-container *ngIf="day.adhesive?.length > 0">
                <h2>
                    Kleeflagen
                </h2>
                <app-asphalt-used-list #asphaltAdhesiveList
                                       [projectUsedAsphaltList]="day.adhesive"
                                       [project]="project"
                                       [date]="day.date"
                                       [print]="print"
                                       [isAdhesive]="true"
                                       [formsDisabled]="true"></app-asphalt-used-list>
            </ng-container>

            <table class="table" *ngIf="day.comment?.comment?.length > 0">
                <tr>
                    <th>
                        Opmerkingen
                    </th>
                </tr>
                <tr>
                    <td>
                        <div [innerHTML]="day.comment?.comment">

                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>

    <h2>
        Weekrapport personeel
    </h2>
    <p *ngIf="userGroupTotals?.length === 0">
        Er zijn geen uren geboekt in week {{beginDate | date : 'w yyyy'}} op project {{projectId}}.
    </p>
    <div *ngFor="let userGroupTotal of userGroupTotals" class="user-table">
        <table class="table">
            <tr>
                <th class="name">
                    {{userGroupTotal.group}}
                </th>
                <th *ngFor="let day of userGroupTotal.days" class="day" matTooltip="{{day | date : 'EEE dd MMM y'}}">
                    {{day | date : 'EEE'}}
                </th>
                <th class="total">
                    Totaal
                </th>
                <th class="comment">
                    Opmerkingen
                </th>
            </tr>
            <tr *ngFor="let userTotals of userGroupTotal.totals;">
                <td class="name">
                    {{userTotals.user.name}}
                </td>
                <td *ngFor="let day of userTotals.days" class="day">
                    <ng-container *ngIf="day.total > 0">{{day.total | minutes}}</ng-container>
                </td>
                <td class="total">
                    {{userTotals.weekTotal | minutes}}
                </td>
                <td class="comment">
                    <div *ngFor="let realisation of userTotals.realisations">
                        <div *ngIf="realisation.comment_user">
                            <b>Toelichting medewerker {{realisation.begindate | date : "EEE H:mm"}}: </b>
                            <span [innerHTML]="realisation.comment_user | nl2br"></span>
                        </div>
                        <div *ngIf="realisation.comment_manager">
                            <b>Toelichting {{realisation.begindate | date : "EEE H:mm"}}: </b>
                            <span [innerHTML]="realisation.comment_manager | nl2br"></span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <h2>
        Weekrapport materieel
    </h2>
    <p *ngIf="entityGroupTotals?.length === 0">
        Er zijn geen uren geboekt in week {{beginDate | date : 'w yyyy'}} op project {{projectId}}.
    </p>
    <div *ngFor="let entityGroupTotal of entityGroupTotals" class="user-table entity-table">
        <table class="table">
            <tr>
                <th class="name" colspan="2">
                    {{entityGroupTotal.group}}
                </th>
                <th *ngFor="let day of entityGroupTotal.days" class="day" matTooltip="{{day | date : 'EEE dd MMM y'}}">
                    {{day | date : 'EEE'}}
                </th>
                <th class="total">
                    Totaal
                </th>
                <th class="comment">
                    Opmerkingen
                </th>
            </tr>
            <tr *ngFor="let userTotals of entityGroupTotal.totals;">
                <ng-container *ngIf="userTotals.entity.name | hasLicensePlate">
                    <td class="license-plate">
                        {{userTotals.entity.name | licensePlate}}
                    </td>
                    <td class="name small">
                        {{userTotals.entity.name | licensePlate : true}}
                    </td>
                </ng-container>
                <ng-container *ngIf="!(userTotals.entity.name | hasLicensePlate)">
                    <td class="name" colspan="2">
                        {{userTotals.entity.name | licensePlate : true}}
                    </td>
                </ng-container>
                <td *ngFor="let day of userTotals.days" class="day">
                    <ng-container *ngIf="day.total > 0">{{day.total | minutes}}</ng-container>
                </td>
                <td class="total">
                    {{userTotals.weekTotal | minutes}}
                </td>
                <td>
                    <div *ngFor="let realisation of userTotals.realisations">
                        <div *ngIf="realisation.comment_user">
                            <b>Toelichting: </b>
                            <span [innerHTML]="realisation.comment_user | nl2br"></span>
                        </div>
                        <div *ngIf="realisation.comment_manager">
                            <b>Toelichting uitvoerder: </b>
                            <span [innerHTML]="realisation.comment_manager | nl2br"></span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>

</div>
