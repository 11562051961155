<div class="modal-inner">
    <div class="stepper">
        <div [class.active]="activeStep==Step.Type"
             [class.filled]="form.value.type"
             *ngIf="!data.userId"
             (click)="setActiveStep(Step.Type)">
            <small>Type</small>
            {{form.value.type ? (form.value.type === 'user' ? 'Medewerker' : 'Materieel') : ''}}
        </div>
        <div [class.active]="activeStep==Step.Hourtype"
             [class.filled]="form.value.hourtype"
             (click)="setActiveStep(Step.Hourtype)"
             *ngIf="UserType.GENERAL_HOUR_CHECK | hasUserGroup">
            <small>Uursoort</small>
            {{form.value.hourtype | hourtype}}
        </div>
        <div [class.active]="activeStep==Step.Project"
             [class.filled]="activeStep==Step.Employee"
             (click)="setActiveStep(Step.Project)">
            <small>Project</small>
            {{form.value.projectId ?? '-'}}
        </div>
        <div [class.active]="activeStep==Step.Employee"
             *ngIf="!data.userId"
             (click)="setActiveStep(Step.Employee)">
            <small>{{form.value.type === 'user' ? 'Medewerker' : 'Materieel'}}</small>
        </div>
        <div [class.active]="activeStep==Step.Vehicle"
             *ngIf="data.userId"
             (click)="setActiveStep(Step.Vehicle)">
            <small>Voertuig</small>
        </div>
    </div>

    <div *ngIf="activeStep==Step.Project" class="modal-inner-inner">
        <h1>Kies een project</h1>
        <input placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
        <div class="results">
            <div class="result text-center" *ngIf="searching">
                aan het zoeken...
            </div>
            <div class="result text-center" *ngIf="!searching && projects?.length === 0">
                {{fcProjectSearch.value?.length > 0 ? 'project niet gevonden' : 'voer een projectnummer in'}}
            </div>
            <div class="result"
                 (click)="selectProject(project.projectId, project.planningId)"
                 *ngFor="let project of projects">
                <strong>{{project.projectId}}</strong> {{project.location}}
            </div>
        </div>
    </div>

    <div *ngIf="activeStep==Step.Vehicle" class="modal-inner-inner">
        <h1>Kies een voertuig ({{fcEntities.value?.length}} geselecteerd)</h1>
        <input placeholder="Zoeken..." [(ngModel)]="itemSearch">
        <div class="results">
            <mat-selection-list [formControl]="fcEntities">
                <mat-list-option *ngFor="let entity of entities" [value]="entity.id"
                                 [class.d-none]="itemSearch?.length > 0 && entity.name.toLowerCase().indexOf(itemSearch.toLowerCase()) === -1">
                    {{entity.name}}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>

    <div *ngIf="activeStep==Step.Type" class="modal-inner-inner">
        <h1>Kies een type</h1>
        <div class="results">
            <div class="choose-buttons mb-3">
                <button mat-raised-button class="planning-button cancel" (click)="setType('entity')">
                    <i class="fa-duotone fa-truck fa-2x"></i><br>
                    Materieel
                </button>
                <button mat-raised-button class="planning-button cancel" (click)="setType('user')">
                    <i class="fa-duotone fa-user fa-2x"></i><br>
                    Medewerker
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="activeStep==Step.Hourtype" class="modal-inner-inner">
        <h1>Kies een uursoort</h1>
        <div class="results">
            <div class="choose-buttons mb-3">
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.worktime);night=false">
                    <i class="fa-duotone fa-person-digging fa-2x"></i><br>
                    Werktijd dag
                </button>
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.worktime);night=true">
                    <i class="fa-duotone fa-person-digging fa-2x"></i><br>
                    Werktijd nacht
                </button>
            </div>
            <div class="choose-buttons mb-3">
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.driving_back)">
                    <i class="fa-duotone fa-truck fa-2x"></i><br>
                    Transporttijd
                </button>
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.travel_back)">
                    <i class="fa-duotone fa-car-side fa-2x mirror"></i><br>
                    Reistijd
                </button>
            </div>
            <div class="choose-buttons mb-3">
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.frost)">
                    <i class="fa-duotone fa-snowflakes fa-2x"></i><br>
                    Vorst
                </button>
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.day_off)">
                    <i class="fa-duotone fa-calendar-range fa-2x"></i><br>
                    Roostervrij
                </button>
            </div>
            <div class="choose-buttons mb-3">
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.illness)">
                    <i class="fa-duotone fa-face-thermometer fa-2x"></i><br>
                    Ziek
                </button>
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.sleep)">
                    <i class="fa-duotone fa-face-sleeping fa-2x"></i><br>
                    Slapen
                </button>
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.education)">
                    <i class="fa-duotone fa-school fa-2x"></i><br>
                    Scholing
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="activeStep==Step.Employee" class="modal-inner-inner">
        <h1>Kies {{form.value.type === 'user' ? 'medewerker' : 'materieel'}}</h1>
        <input placeholder="Zoeken..." [(ngModel)]="itemSearch">
        <div class="results" *ngIf="form.value.type === 'user'">
            <div class="result"
                 (click)="setUser(user.id)"
                 *ngFor="let user of empoyees | search : 'name' : itemSearch">
                <strong>{{user.name}}</strong> {{user.function}}
            </div>
        </div>
        <div class="results" *ngIf="form.value.type === 'entity'">
            <div class="result"
                 (click)="setEntity(entity)"
                 *ngFor="let entity of entities | search : 'name' : itemSearch">
                <strong>{{entity.name}}</strong>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button mat-raised-button
                class="planning-button confirm"
                color="primary"
                [disabled]="Step.Vehicle !== activeStep"
                *ngIf="data.userId"
                (click)="setVehicle()">Toevoegen
        </button>
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
