<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Inhuur bedrijven</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div mat-dialog-content *ngIf="!selectedWeek">
    <h2>Kies een week</h2>
    <table class="table table-bordered table-striped text-right table-hover">
        <thead>
        <tr>
            <th class="text-center" width="70">Week</th>
            <th>Van</th>
            <th>Tot</th>
        </tr>
        </thead>
        <tr (click)="showHire(week)" *ngFor="let week of weeks" class="curpoint">
            <td class="text-center">{{week.startDate | date: 'w'}}</td>
            <td>
                {{week.startDate | date: 'EEE d MMM y'}}
            </td>
            <td>
                {{week.endDate | date: 'EEE d MMM y'}}
            </td>
        </tr>
    </table>
    <i>Het is vandaag {{today | date:'EEEE d MMMM y'}}</i>
</div>
<div mat-dialog-content *ngIf="selectedWeek">
    <div class="d-flex align-items-center">
        <button (click)="selectedWeek = null" color="primary" mat-icon-button tabindex="-1"><i class="fas fa-chevron-left"></i></button>
        <h2 class="mb-0">Inhuur week {{selectedWeek.startDate | date: 'w'}}</h2>
    </div>
    <table class="table table-bordered table-striped table-hover">
        <ng-container *ngFor="let hire of hirePerCompany">
            <tr>
                <th>
                    <span (click)="copyToClipboard(hire.company?.name)" class="curpoint">{{hire.company?.name}}</span> -
                    <span (click)="copyToClipboard(hire.company?.phone)" class="curpoint">{{hire.company?.phone}}</span> -
                    <span (click)="copyToClipboard(hire.company?.email)" class="curpoint">{{hire.company?.email}}</span>
                </th>
            </tr>
            <tr>
                <td (click)="copyToClipboard(item.innerHTML)" #item class="curpoint">
                    <p>
                        Week {{selectedWeek.startDate | date: 'w'}}
                    </p>
                    <ng-container *ngFor="let hired of hire.hiring">
                        <b>{{hired.hired.begindate | date : 'EEEE d MMMM'}} {{hired.hired.entity?.entitytype?.name}} {{hired.hired.name}}</b><br>
                        {{hired.hired.comment}}
                        <ul>
                            <li>
                                Werk van {{hired.hired.begindate | date : 'HH:mm'}} tot {{hired.hired.enddate | date : 'HH:mm'}}
                            </li>
                            <li>
                                Locatie: {{hired.planning.location}}
                            </li>
                            <li>
                                Uitvoerder: {{hired.performer?.name}} {{hired.performer?.phone}}
                            </li>
                            <li>
                                Werknummer: {{hired.planning.worknumber ?? hired.planning.afas_project_id}}
                            </li>
                            <li *ngIf="hired.hired?.lowloader_entity_id">
                                Dieplader: {{hired.hired?.lowloader_entity?.name}}
                            </li>
                        </ul>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </table>
</div>
