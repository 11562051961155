export enum RealtimeType {
    planning = 'planning',
    entityunavailable = 'entityunavailable',
    entities = 'entities',
    planningfixed = 'planningfixed',
    users = 'users',
    autocomplete = 'autocomplete',
    hiring = 'hiring',
    project = 'project'
}
