<div class="settings" *ngIf="!mobile">
    <div class="trigger" [cdkMenuTriggerFor]="menu">
        <div class="icon">
            <i class="fa-solid fa-chevron-down"></i>
        </div>
        <div class="name">
            Toon ploegen
        </div>
    </div>
</div>

<div class="mobile-filter" *ngIf="mobile">
    <ng-container *ngTemplateOutlet="filter"></ng-container>
</div>

<ng-template #menu>
    <div class="menu" cdkMenu>
        <ng-container *ngTemplateOutlet="filter"></ng-container>
    </div>
</ng-template>

<ng-template #filter>
    <div>
        Selecteer ploegen
    </div>
    <mat-selection-list [formControl]="fcTeams">
        <mat-list-option checkboxPosition="before" *ngFor="let type of entityTypes" [value]="type.id">
            {{type.plural_name}}
        </mat-list-option>
    </mat-selection-list>
</ng-template>
