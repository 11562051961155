import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@ngneat/reactive-forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProjectService} from '../../services/project.service';
import {CodaltComponent} from '../../codalt.component';
import {Subscription} from 'rxjs';
import {debounceTime, first, startWith} from 'rxjs/operators';
import {Project} from '../../classes/project.class';

@Component({
    selector: 'app-add-project-dialog',
    templateUrl: './add-project-dialog.component.html',
    styleUrls: ['./add-project-dialog.component.scss']
})
export class AddProjectDialogComponent extends CodaltComponent implements OnInit {

    projects: Project[];

    fcProjectSearch = new FormControl<string>();

    searching = false;

    constructor(public dialogRef: MatDialogRef<AddProjectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    currentProjects: string[]
                },
                private projectService: ProjectService) {
        super();

    }

    ngOnInit(): void {
        let afasSubs = new Subscription();
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(startWith(), debounceTime(200)).subscribe(search => {
            afasSubs?.unsubscribe();
            this.searching = true;
            afasSubs = this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id && !this.data.currentProjects.includes(p.afas_project_id));
                this.searching = false;
            }, () => {
                this.searching = false;
            });
        }));
    }

    selectProject(project: Project) {
        this.dialogRef.close(project);
    }

}
