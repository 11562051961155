import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from './storage.class';

@Injectable()
export class IsAuthenticated implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const params = route.queryParams as { token: string };
        if (params.token) {
            LocalStorage.setUserToken(params.token);
        }
        if (!LocalStorage.getUserToken()) {
            this.router.navigate(['login']);
        }
        return true;
    }
}
