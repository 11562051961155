<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Gebruikers</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div class="dialog-content">
    <table class="table table-hover table-last-col">
        <thead>
        <tr>
            <th>
                Naam
            </th>
            <th>
                E-mailadres
            </th>
            <th>
                Groep
            </th>
            <th>
                Standaard standplaats
            </th>
            <th width="180">
                Reistijd werkdagen
            </th>
            <th width="180">
                Reistijd weekend
            </th>
            <th>

            </th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="edit(user)" *ngFor="let user of users" class="curpoint">
            <td>
                {{user.name}}
            </td>
            <td>
                {{user.email}}
            </td>
            <td>
                <ng-container *ngFor="let group of user.groups; let last = last">
                    {{UserGroups.get(group.group)}}{{last ? '' : ', '}}
                </ng-container>
            </td>
            <td>
                {{user.default_stand}}
            </td>
            <td>
                {{user.traveltime_workday}}
            </td>
            <td>
                {{user.traveltime_weekend}}
            </td>
            <td>
                <button (click)="deleteUser($event, user)" color="warn" mat-icon-button>
                    <i class="fas fa-trash"></i></button>
            </td>
        </tr>
        <tr *ngIf="false">
            <td colspan="3">
                <button (click)="edit()" class="w-100" color="primary" mat-button>
                    Een gebruiker toevoegen <i class="fas fa-plus"></i>
                </button>
            </td>
            <td></td>
        </tr>
        </tbody>
    </table>

</div>

