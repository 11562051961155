import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'mat-error',
    templateUrl: './field-error.component.html',
    styleUrls: ['./field-error.component.scss']
})
export class FieldErrorComponent implements OnInit, OnDestroy {

    @Input()
    public control: AbstractControl;
    public errors = [];
    @Input()
    customMessage: string;
    private subscription = new Subscription();
    private messages = new Map<string, ErrorMessage>([
        ['required', new ErrorMessage('Dit veld is verplicht')],
        ['iban', new ErrorMessage('Dit is geen correct IBAN')],
        ['maxlength', new ErrorMessage('Voer maximaal %1 karakters in', 'requiredLength')],
        ['minlength', new ErrorMessage('Voer minimaal %1 karakters in', 'requiredLength')],
        ['max', new ErrorMessage('Het maximum is %1', 'max')],
        ['notAvailable', new ErrorMessage(null)],
        ['userDeleted', new ErrorMessage('Deze gebruiker is verwijderd')],
        ['email', new ErrorMessage('Vul een e-mailadres in')],
        ['invalidPhone', new ErrorMessage('Vul een telefoonnummer in')],
        ['matDatepickerMin', new ErrorMessage('De datum is niet in de toekomst')],
        ['matDatepickerParse', new ErrorMessage('De datum is niet in de toekomst')],
        ['urlNotValid', new ErrorMessage('De ingevoerde url is geen valide url')],
        ['urlStatusCode', new ErrorMessage('De ingevoerde url lijkt niet te werken')],
        ['pattern', new ErrorMessage('De ingevoerde waarde is niet toegestaan', )]
    ]);

    constructor(private ngZone: NgZone) {
    }

    ngOnInit() {
        this.parseErrors();
        if (this.control) {
            this.subscription.add(this.control.parent.valueChanges.subscribe(status => {
                this.parseErrors();
            }));
            if (this.control.parent.parent) {
                this.subscription.add(this.control.parent.parent.valueChanges.subscribe(status => {
                    this.parseErrors();
                }));
            }
            this.subscription.add(this.control.statusChanges.subscribe(status => {
                this.parseErrors();
            }));
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private parseErrors() {
        this.errors = [];
        if (this.control && typeof this.control.errors !== 'undefined' && this.control.errors) {
            for (const [key, value] of Object.entries(this.control.errors)) {
                const message = this.messages.get(key);
                if (message) {
                    let error = message.message;
                    if (error) {
                        message.values.forEach((parseInVal, i) => {
                            error = error.replace(`%${i + 1}`, value[parseInVal]);
                        });
                    } else {
                        error = value;
                    }
                    this.errors.push(error);
                } else {
                    console.warn(`Validation error message for ${key} is not defined`);
                }
            }
        }
    }
}

export class ErrorMessage {
    public values: string[];

    constructor(
        public message: string,
        ...vals: string[]
    ) {
        this.values = vals;
    }
}
