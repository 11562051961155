<table [class.disabled]="formsDisabled" [class.table-last-col]="!formsDisabled" *ngIf="!formsDisabled" class="table table-center table-add-row w-100">
    <tr>
        <th *ngIf="isAdhesive">Kleeflaag</th>
        <th *ngIf="!isAdhesive">Molen</th>
        <th [matTooltip]="'asphalttype' | tt" *ngIf="!isAdhesive">Type asfalt</th>
        <th [matTooltip]="'asphaltcode' | tt" width="130" *ngIf="!isAdhesive">Code centrale</th>
        <th class="number-input" *ngIf="!isAdhesive">Ton</th>
        <th class="number-input" *ngIf="!isAdhesive">Dikte</th>
        <th class="number-input" width="150">Oppervlakte <small>m2</small></th>
        <th class="number-input" *ngIf="isAdhesive">Liter</th>
        <th class="number-input" *ngIf="!isAdhesive">Temp. asfalt</th>
        <th class="number-input">Toepassing</th>
        <th class="comment">Opmerking</th>
        <th *ngIf="!formsDisabled"></th>
    </tr>
    <tr (delete)="removeAsphaltPlanning(planningAsphalt)"
        *ngFor="let planningAsphalt of projectUsedAsphaltList"
        [asphaltMills]="asphaltMills"
        [formsDisabled]="formsDisabled"
        [mainPlanning]="mainPlanning"
        [planningAsphalt]="planningAsphalt"
        [planning]="planning"
        [isAdhesive]="isAdhesive"
        app-asphalt-used-list-item>
    </tr>
    <tr [hidden]="formsDisabled">
        <td [attr.colspan]="isAdhesive ? 5 : 9">
            <button (click)="addAsphaltPlanning()" class="w-100" color="primary" mat-button>
                Toevoegen <i class="fas fa-plus"></i>
            </button>
        </td>
        <td></td>
    </tr>
</table>
<table class="table table-center" *ngIf="formsDisabled" [class.print]="print">
    <tr>
        <th *ngIf="isAdhesive">Kleeflaag</th>
        <th *ngIf="!isAdhesive">
            Toepassing<br>
            <i>Molen</i>
        </th>
        <th *ngIf="!isAdhesive">
            Type asfalt<br>
            <i>Code centrale</i>
        </th>
        <th class="number-input" *ngIf="!isAdhesive">Ton</th>
        <th class="number-input" *ngIf="!isAdhesive">Dikte</th>
        <th class="number-input" width="150">Oppervlakte <small>m2</small></th>
        <th class="number-input" *ngIf="isAdhesive">Liter</th>
        <th class="number-input" *ngIf="!isAdhesive">Temp. asfalt</th>
        <th *ngIf="isAdhesive">Toepassing</th>
        <th class="comment">Opmerking</th>
    </tr>
    <tr *ngFor="let planningAsphalt of projectUsedAsphaltList">
        <td *ngIf="isAdhesive">{{planningAsphalt.asphaltname}}</td>
        <td *ngIf="asphaltMills && !isAdhesive">
            {{planningAsphalt.application | asphaltApplication}}<br>
            <i>{{(asphaltMills | byId:planningAsphalt.asphaltmill_id)?.name}}</i>
        </td>
        <td *ngIf="!isAdhesive">
            {{planningAsphalt.asphaltname}}<br>
            <i>{{planningAsphalt.asphaltcode}}</i>
        </td>
        <td class="number-input" *ngIf="!isAdhesive">{{planningAsphalt.tons | number : '0.0-2'}}</td>
        <td class="number-input" *ngIf="!isAdhesive">{{planningAsphalt.thickness | number : '0.0-2'}}</td>
        <td class="number-input">{{planningAsphalt.surface | number : '0.0-2'}}</td>
        <td class="number-input" *ngIf="isAdhesive">{{planningAsphalt.liter | number : '0.0-2'}}</td>
        <td class="number-input" *ngIf="!isAdhesive">{{planningAsphalt.temperature | number : '0.0-2'}}</td>
        <td *ngIf="isAdhesive">{{planningAsphalt.application | asphaltApplication}}</td>
        <td>{{planningAsphalt.comment}}</td>
    </tr>
</table>
