import {FormControl, FormGroup} from '@angular/forms';
import {of, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PlanningService} from '../services/planning/planning.service';
import {PlanningSet} from '../classes/planningset.class';
import {PlanningCutter} from '../classes/planningcutter.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {PlanningAsfaltteam} from '../classes/planningasfaltteam.class';

export const entityAvailableValidator = (planningService: PlanningService,
                                         planning?: (PlanningAsfaltteam | PlanningCutter | PlanningHasEntity | PlanningSet)) => {
    return (input: FormGroup | FormControl) => {
        if (planning?.begindate || !!input.get('begindate')?.value) {
            let begindate = new Date(planning?.begindate);
            let enddate = new Date(planning?.enddate);
            let entityId = input.value['entity_id'] || planning.entity_id;
            const planningId = planning?.planning_id || input.get('planning_id')?.value;
            if (input instanceof FormGroup) {
                if (!!input.get('enddate') && !!input.get('begindate')) {
                    begindate = new Date(input.get('begindate').value);
                    enddate = new Date(input.get('enddate').value);
                } else {
                    const dateFromGroup = typeof input.controls['begintime'] === 'undefined' ? input.controls['date'] as FormGroup : input;
                    if (!dateFromGroup.controls['begintime'].value) {
                        return of({notAvailable: ''});
                    }

                    if (dateFromGroup.controls['begintime'].value && dateFromGroup.controls['begintime'].value.length < 6) {
                        const startTime = (dateFromGroup.controls['begintime'].value).split(':');
                        const endTime = (dateFromGroup.controls['endtime'].value).split(':');
                        begindate.setHours(+startTime[0]);
                        begindate.setMinutes(+startTime[1]);
                        enddate.setHours(+endTime[0]);
                        enddate.setMinutes(+endTime[1]);
                        begindate.setSeconds(0);
                        enddate.setSeconds(0);
                    } else {
                        begindate.setTime(dateFromGroup.controls['begintime'].value);
                        enddate.setTime(dateFromGroup.controls['endtime'].value);
                    }
                }

            } else {
                entityId = input.value;
            }

            return timer(500).pipe(
                switchMap(() => planningService.checkEntityAvailability(
                    entityId,
                    planningId,
                    begindate,
                    enddate
                )),
                map(res => {
                    if (!!input.get('conflictText')) {
                        input.get('conflictText').setValue(res, {emitEvent: false, onlySelf: true});
                    }
                    return res ? {notAvailable: res} : null;
                })
            );
        }
        return of(null);
    };
};
