import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'hasLicensePlate'
})
export class HasLicensePlatePipe implements PipeTransform {

    transform(name: string, invert = false): boolean {
        const plate = name.match(/([A-Z]{1,3}|[0-9]{1,3})-([A-Z]{1,3}|[0-9]{1,3})-([A-Z]{1,3}|[0-9]{1,3})/);
        if (plate?.length > 0) {
            return true;
        }
        return false;
    }

}
