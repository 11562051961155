<ng-container *ngIf="form" [formGroup]="form">
    <td [class.empty]="!selectedMill" [matTooltip]="selectedMill?.name" class="cutter-selection">
        <div class="showname">{{selectedMill?.name}}</div>
        <input #millInput
               [matAutocomplete]="autoCompleteMill"
               autocomplete="off"
               formControlName="asphaltmill_id"
               matInput
               placeholder="Molen"
               type="text"/>
        <mat-autocomplete #autoCompleteMill="matAutocomplete"
                          (optionSelected)="millSelectionChange($event)"
                          [displayWith]="emptystring"
                          [panelWidth]="200"
                          autoActiveFirstOption>
            <mat-option *ngFor="let mill of filteredMills | async"
                        [class.mat-active]="selectedMill == mill"
                        [disabled]="!mill.asphalt || mill.asphalt.length === 0"
                        [value]="mill">
                {{mill.name}}
            </mat-option>
        </mat-autocomplete>
    </td>

    <td [class.empty]="!asphaltText || asphaltText.length === 0"
        [class.info]="form.get('asphaltname')?.value && (!selectedAsphalt || form.get('asphaltname').value !== selectedAsphalt.asphalt.name)"
        [matTooltip]="asphaltText ? asphaltText : ('asphalttype' + (selectedMill ? '' : '-nomill') | tt)"
        class="cutter-selection">
        <div class="showname">{{asphaltText}}</div>
        <input #asphaltInput
               (change)="asphaltTextChanged($event)"
               [matAutocomplete]="autoCompleteAsphalt"
               autocomplete="nofill"
               formControlName="asphaltname"
               matInput
               placeholder="Zoek naar asfalt..."
               type="text"/>
        <mat-autocomplete #autoCompleteAsphalt="matAutocomplete"
                          (optionSelected)="asphaltSelectionChange($event)"
                          [displayWith]="emptystring"
                          [panelWidth]="350"
                          autoActiveFirstOption>
            <ng-container *ngIf="selectedMill">
                <mat-option *ngFor="let asphalt of filteredAsphalt | async" [value]="asphalt">
                    ({{asphalt.code}}) {{asphalt.asphalt.name}}
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </td>
    <td [class.info]="form.get('asphaltcode')?.value && (!selectedAsphalt || form.get('asphaltcode').value !== selectedAsphalt.code)"
        [matTooltip]="'asphaltcode' | tt"
        class="number-input">
        <input autocomplete="off"
               formControlName="asphaltcode"
               matInput
               placeholder="Asfaltcode"
               type="text"/>
    </td>
    <td class="number-input">
        <input #tonsInput
               appDecimalInput
               autocomplete="off"
               formControlName="tons"
               matInput
               placeholder="0"
               type="text">
    </td>
    <td class="number-input">
        <input appDecimalInput autocomplete="off" formControlName="thickness" matInput placeholder="0" type="text">
    </td>
    <td [matTooltip]="'asphalt-trucks-time' | tt" class="tabletime one">
        <div class="timepicker">
            <mat-select formControlName="time" placeholder="Tijd">
                <mat-select-trigger>
                    <small>{{planningAsphalt.time | date:'EEE'}}</small> {{planningAsphalt.time | date : 'HH:mm'}}
                </mat-select-trigger>
                <mat-optgroup *ngFor="let day of timeList"
                              label="{{day.datetime | date : 'EEEE d MMM'}}">
                    <mat-option *ngFor="let time of day.options" [value]="time.time">
                        {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                    </mat-option>
                </mat-optgroup>
            </mat-select>

        </div>
    </td>
    <td [class.warning]="+planning.trucks > availableTrucks - maxUsedTrucks + (+planning.trucks)"
        [matTooltip]="'trucks-count' | tt"
        class="number-input">
        <div *ngIf="availableTrucks" class="available-trucks">
            {{availableTrucks - maxUsedTrucks + (+planningAsphalt.trucks || 0)}}
        </div>
        <input appDecimalInput autocomplete="off" formControlName="trucks" matInput placeholder="0" type="text">
    </td>
    <td [matTooltip]="planningAsphalt?.comment" class="text-input">
        <input autocomplete="off" formControlName="comment" matInput maxlength="300" placeholder="Opmerking" type="text">
    </td>
    <td *ngIf="!formsDisabled">
        <button (click)="removeAsphaltPlanning()"
                class="delete-button"
                color="warn"
                mat-icon-button
                matTooltip="Verwijder"
                matTooltipClass="warn-background"
                matTooltipPosition="right"
                tabindex="-1">
            <i class="fas fa-trash"></i>
        </button>
    </td>
</ng-container>
