<div>
    <div class="d-flex align-items-center" mat-dialog-title>
        <div class="spacer title">Materieel</div>
        <div>
            <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
        </div>
    </div>

    <mat-slide-toggle (change)="showFinished($event.checked)" class="mb-3">
        Toon materieel dat uit dienst is
    </mat-slide-toggle>
</div>
<div class="dialog-content">
    <div *ngFor="let entityGroup of entities">
        <div class="d-flex justify-content-center align-content-center">
            <h3 class="spacer">
                {{entityGroup.type.plural_name}}
            </h3>
        </div>
        <div class="pl-4">
            <table (cdkDropListDropped)="drop($event, entityGroup)"
                   cdkDropList
                   class="table table-hover"
                   [class.table-add-row]="!entityGroup.type.afas_name">
                <tr>
                    <th>
                        Naam
                    </th>
                    <th width="160">
                        Excl. transporttijd
                    </th>
                    <th *ngIf="entityGroup.type.has_driver">
                        Voorkeurschauffeur
                    </th>
                    <th *ngIf="entityGroup.type.has_lowloader">
                        Dieplader
                    </th>
                    <th *ngIf="entityGroup.type.has_preferred_team">
                        Voorkeursploeg
                    </th>
                    <th *ngIf="entityGroup.type.has_truck">
                        Trekkend voertuig
                    </th>
                    <th>
                        Begindatum
                    </th>
                    <th>
                        Einddatum
                    </th>
                </tr>
                <tr (click)="openEntity(entity)" *ngFor="let entity of entityGroup.entities" cdkDrag class="curpoint">
                    <td class="drag">
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-th-list"></i>
                        </div>
                        {{entity.name}}
                    </td>
                    <td class="text-center">
                        <i *ngIf="entity.exclude_driving_hours" class="fa fa-check"></i>
                    </td>
                    <td *ngIf="entityGroup.type.has_driver">
                        {{(users | byId : entity.driver_user_id)?.name}}
                    </td>
                    <td *ngIf="entityGroup.type.has_lowloader">
                        {{(allEntities | byId : entity.lowloader_entity_id)?.name}}
                    </td>
                    <td *ngIf="entityGroup.type.has_preferred_team">
                        {{(allEntities | byId : entity.team_entity_id)?.name}}
                    </td>
                    <td *ngIf="entityGroup.type.has_truck">
                        {{(allEntities | byId : entity.truck_entity_id)?.name}}
                    </td>
                    <td class="datetime">
                        {{entity.begindate | date : 'd MMMM yyyy'}}
                    </td>
                    <td class="datetime">
                        {{entity.enddate | date : 'd MMMM yyyy'}}
                    </td>
                </tr>
                <tr *ngIf="!entityGroup.type.afas_name">
                    <td [attr.colspan]="3 + (extraColsMap.get(entityGroup.type.id) ?? 0)">
                        <button (click)="addEntity(entityGroup.type)" class="w-100" color="primary" mat-button>
                            {{entityGroup.type.name}} toevoegen <i class="fas fa-plus"></i>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>

</div>
