import {Injectable} from '@angular/core';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeService} from '../realtime/realtime.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {EntityUnavailable} from '../../classes/entityunavailable.class';
import {EntitiesService} from './entities.service';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityTypeCode} from './entity-type.class';

@Injectable({
    providedIn: 'root'
})
export class EntityUnavailableService extends RealtimeService<EntityUnavailable> {

    constructor(
        private entitiesService: EntitiesService,
        protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.entityunavailable);
    }

    public itemInDaterange(entityUnavailable: EntityUnavailable, fromDate: Date, toDate: Date) {
        const fromTime = new Date(fromDate).getTime();
        const toTime = new Date(toDate).getTime();
        return new Date(entityUnavailable.begindate).getTime() < toTime
            && new Date(entityUnavailable.enddate).getTime() >= fromTime;
    }

    public getTrucksAvailableForRange(fromDate: Date, toDate: Date) {
        const trucks$ = this.entitiesService.getByType(EntityTypeCode.Truck);
        const unavailable$ = this.getFilteredList(fromDate, toDate);

        return combineLatest(trucks$, unavailable$).pipe(map(([trucks, unavailable]) => {
            const startTime = fromDate.getTime();
            const endTime = toDate.getTime();

            let todayTrucks = trucks.filter(t => t.use_once).filter(t => new Date(t.begindate).getTime() <= startTime
                && (t.enddate === null || new Date(t.enddate).getTime() >= endTime));

            todayTrucks = todayTrucks.filter(t => {
                return unavailable.filter(u => u.entity_id === t.id)
                    .filter(u => {
                        return new Date(u.begindate).getTime() <= endTime
                            && new Date(u.enddate).getTime() >= startTime;
                    }).length === 0;
            });

            return todayTrucks.length;
        }));
    }
}
