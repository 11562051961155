import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {UserService, UserType} from '../services/user/user.service';
import {Utils} from '../utils.class';
import {OwnHourEditAllowedPipe} from '../hour-overview/hour-edit-allowed.pipe';
import {LocalStorage} from '../storage.class';

@Pipe({
    name: 'notEditable'
})
export class NotEditablePipe implements PipeTransform {

    transform(realisation: Realisation, ...args: unknown[]): unknown {

        if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) && !realisation.paid) {
            return;
        }

        if (realisation.paid) {
            return 'Niet aanpasbaar omdat de uren al uitbetaald zijn';
        }

        const beginDate = new Date(realisation.begindate);
        const today = new Date();
        const endOfDay = Utils.setTime(new Date(today), 23, 59);
        if (endOfDay.getTime() < beginDate.getTime()) {
            return 'Niet aanpasbaar omdat de uren in de toekomst liggen';
        }

        if ((new OwnHourEditAllowedPipe()).transform(realisation)) {
            return;
        }

        if (realisation.user_id
            && LocalStorage.user.id !== realisation.user_id
            && !realisation.submitted
            && UserService.userHasRights(UserType.HOUR_REGISTER, realisation.user)) {
            const diff = (today.getTime() - beginDate.getTime()) / 1000 / 60 / 60;
            const maxDiff = 24;
            if (diff < maxDiff) {
                return 'Niet aanpasbaar omdat de uren niet zijn ingediend';
            }
        }

        const editUntil = Utils.setTime(new Date(beginDate), 23, 59);
        if (UserService.userHasRights(UserType.ASPHALTEXECUTOR)) {
            const w = 3 - (editUntil.getDay() === 0 ? 7 : editUntil.getDay());
            editUntil.setDate(editUntil.getDate() + (7 + w));
            if (editUntil.getTime() < today.getTime()) {
                return 'Niet aanpasbaar omdat de uren voor woensdag 6:00 zijn';
            }
        } else {
            if ([4, 5].includes(editUntil.getDay())) {
                editUntil.setDate(editUntil.getDate() + 2);
            } else if (editUntil.getDay() === 6) {
                editUntil.setDate(editUntil.getDate() + 1);
            }
            editUntil.setDate(editUntil.getDate() + 2);
            if (editUntil.getTime() < today.getTime()) {
                return 'Niet aanpasbaar omdat de uren ouder zijn dan 2 werkdagen';
            }
        }
        return;
    }

}
