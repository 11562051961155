import {Component, HostBinding, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {Utils} from '../utils.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormControl} from '@ngneat/reactive-forms';
import {Project} from '../classes/project.class';
import {RealisationService} from '../services/realisation.service';
import {formatDate} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';

@Component({
    selector: 'app-project-week-report-dialog',
    templateUrl: './project-week-report-config.component.html',
    styleUrls: ['./project-week-report-config.component.scss']
})
export class ProjectWeekReportConfigComponent extends CodaltComponent implements OnInit {

    beginDate: Date;
    endDate: Date;
    today = new Date();
    year: number;
    week = 1;
    @HostBinding('class.print') print = false;

    fcProjectId = new FormControl<string>();
    project: Project;

    projects: Project[];

    downloadingPdf = false;

    constructor(private activatedRoute: ActivatedRoute,
                private realisationService: RealisationService,
                private router: Router,
                private title: Title) {
        super();
        this.title.setTitle('Weekrapport');
    }

    ngOnInit(): void {
        this.subscriptions.add(this.fcProjectId.valueChanges.subscribe(fcProjectId => {
            if (!this.print) {
                this.router.navigateByUrl(`project-rapport/${fcProjectId ?? ''}/${this.week}/${this.year}`);
            }
            this.project = this.projects?.find(p => p.afas_project_id === fcProjectId);
        }));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { project, period, year }) => {
            const queryParams = this.activatedRoute.snapshot.queryParams as { print };
            this.print = !!queryParams?.print;
            const monday = new Date();
            if (params?.year) {
                monday.setFullYear(params.year);
            }
            Utils.setTime(monday, 0, 0);
            const year = monday.getFullYear();
            monday.setMonth(0);
            monday.setDate(1);
            monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
            if (year > monday.getFullYear()) {
                monday.setDate(monday.getDate() + 7);
            }
            this.beginDate = new Date(monday);

            const period = +(params.period ?? formatDate(new Date(), 'w', 'nl'));
            this.beginDate.setDate(this.beginDate.getDate() + (7 * (period - 1)));
            this.week = period;

            this.endDate = new Date(this.beginDate);
            this.endDate.setDate(this.endDate.getDate() + 6);
            this.year = this.beginDate.getFullYear();
            this.fcProjectId.setValue(params.project);

            this.subscriptions.add(this.realisationService.getProjectsPeriod(this.beginDate, this.endDate).subscribe(projects => {
                this.projects = projects.data;
                if (!this.fcProjectId.value) {
                    this.fcProjectId.setValue(this.projects[0]?.afas_project_id);
                }
                if (!this.project) {
                    this.project = this.projects?.find(p => p.afas_project_id === this.fcProjectId.value);
                }
            }));
        }));
    }

    prevProject() {
        const index = this.projects.findIndex(e => e.afas_project_id === this.fcProjectId.value);
        if (index) {
            this.router.navigateByUrl(`project-rapport/${this.projects[index - 1].afas_project_id ?? ''}/${this.week}/${this.year}`);
        }
    }

    nextProject() {
        const index = this.projects.findIndex(e => e.afas_project_id === this.fcProjectId.value);
        if (this.projects[index + 1] !== undefined) {
            this.router.navigateByUrl(`project-rapport/${this.projects[index + 1].afas_project_id ?? ''}/${this.week}/${this.year}`);
        }
    }

    next() {
        let week = this.week + 1;
        let year = this.year;
        if (week > 52) {
            week = 1;
            year += 1;
        }
        this.router.navigateByUrl(`project-rapport/${this.fcProjectId.value ?? ''}/${week}/${year}`);
    }

    prev() {
        let week = this.week - 1;
        let year = this.year;
        if (week < 1) {
            week = 52;
            year -= 1;
        }
        this.router.navigateByUrl(`project-rapport/${this.fcProjectId.value ?? ''}/${week}/${year}`);
    }

    pdf() {
        this.downloadingPdf = true;
        const filename = `weekReport_${this.fcProjectId.value}-week_${this.week}-${this.year}.pdf`;
        this.subscriptions.add(this.realisationService.getWeekReportPdf(this.fcProjectId.value, this.week, this.year).subscribe((data: HttpResponse<any>) => {
            saveAs(data, filename);
            this.downloadingPdf = false;
        }, () => {
            this.downloadingPdf = false;
        }));
    }


}
