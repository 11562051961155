<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Bewerk {{title}}</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div *ngIf="form" class="row">
    <form [formGroup]="form">
        <mat-form-field class="col-12" id="name">
            <input formControlName="name" matInput name="name" placeholder="Naam" type="text" autocomplete="entity-names">
            <mat-error>Dit veld is verplicht</mat-error>
        </mat-form-field>

        <div class="col-12" id="entitytypes">
            <ng-select formControlName="entitytypes" placeholder="Types" [clearable]="true" [multiple]="true">
                <ng-option [value]="type.id" *ngFor="let type of entitytypes">{{type.name}}</ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="driver_user_id" *ngIf="viewState.driver">
            <ng-select formControlName="driver_user_id" placeholder="Voorkeurschauffeur" [clearable]="true">
                <ng-option [value]="driver.id" *ngFor="let driver of truckDrivers">{{driver.name}}</ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="team_entity_id" *ngIf="viewState.preferredTeam">
            <ng-select formControlName="team_entity_id" placeholder="Voorkeursploeg" [clearable]="true">
                <ng-option [value]="entity.id" *ngFor="let entity of entityTeams">{{entity.name}}</ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="truck_entity_id" *ngIf="viewState.truck">
            <ng-select formControlName="truck_entity_id" placeholder="Trekkend voertuig" [clearable]="true">
                <ng-option [value]="entity.id" *ngFor="let entity of entityTrucks">{{entity.name}}</ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="lowloader_entity_id" *ngIf="viewState.lowloader">
            <ng-select formControlName="lowloader_entity_id" placeholder="Dieplader" [clearable]="true">
                <ng-option [value]="entity.id" *ngFor="let entity of entityLowloaders">{{entity.name}}</ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="default_employees" *ngIf="viewState.employees">
            <ng-select formControlName="default_employees" placeholder="Standaard personeel" [multiple]="true" [clearable]="true">
                <ng-option [value]="employee.id" *ngFor="let employee of employees">
                    {{employee.name}} ({{employee.function}})
                </ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="default_performer_id" *ngIf="viewState.performer">
            <ng-select formControlName="default_performer_id" placeholder="Standaard uitvoerder" [clearable]="true">
                <ng-option [value]="employee.id" *ngFor="let employee of executors">
                    {{employee.name}}
                </ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="default_asphalt_performer_id" *ngIf="viewState.asphaltPerformer">
            <ng-select formControlName="default_asphalt_performer_id" placeholder="Standaard asfaltuitvoerder" [clearable]="true">
                <ng-option [value]="employee.id" *ngFor="let employee of asphaltExecutors">
                    {{employee.name}}
                </ng-option>
            </ng-select>
        </div>

        <div class="col-12" id="afas_employee_id">
            <ng-select formControlName="afas_employee_id" placeholder="Afas entiteit" [clearable]="true">
                <ng-option [value]="entity.employeeId" *ngFor="let entity of afasMaterieel">{{entity.birthName}} ({{entity.functionDesc}})</ng-option>
            </ng-select>
        </div>

        <mat-form-field class="col-6">
            <input (focus)="pickerStart.open()" [matDatepicker]="pickerStart" [max]="form.get('enddate').value"
                   formControlName="begindate"
                   matInput placeholder="Beschikbaar vanaf">
            <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
            <mat-error [control]="form.get('begindate')" customMessage="{{title}} {{entity.name}} is al ingepland voor deze datum">

            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
            <input (focus)="pickerEnd.open()"
                   [matDatepicker]="pickerEnd"
                   [min]="form.get('begindate').value"
                   formControlName="enddate"
                   matInput placeholder="Beschikbaar tot">
            <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
            <mat-error [control]="form.get('enddate')" customMessage="{{title}} {{entity.name}} is al ingepland na deze datum">

            </mat-error>
        </mat-form-field>
        <div class="col-12 mb-2">
            <mat-checkbox formControlName="exclude_driving_hours">
                Uren registreren exclusief reistijd
            </mat-checkbox>
        </div>
    </form>
    <div class="col-12">
        <div>
            <h4>Onbeschikbaarheid</h4>
        </div>
        <table class="table table-center table-add-row table-last-col">
            <tr>
                <th>
                    Reden
                </th>
                <th class="datetime">
                    Niet beschikbaar vanaf
                </th>
                <th class="datetime">
                    Niet beschikbaar tot
                </th>
                <th></th>
            </tr>
            <ng-container *ngFor="let entityUnavailable of entity.entity_unavailable">
                <tr
                    [class.form-submitted]="submitted"
                    [formGroup]="unavailablePlanningForm.get(entityUnavailable)">
                    <td>
                        <input formControlName="description" matInput name="description" placeholder="Reden"
                               type="text">
                    </td>
                    <td class="tabledatepicker">
                        <div>
                            <input (focus)="pickerStart.open()" [matDatepicker]="pickerStart"
                                   [max]="unavailablePlanningForm.get(entityUnavailable).get('enddate').value"
                                   formControlName="begindate"
                                   matInput placeholder="Niet beschikbaar vanaf">
                            <mat-datepicker-toggle [for]="pickerStart" matSuffix tabIndex="-1"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                        </div>
                    </td>
                    <td class="tabledatepicker">
                        <div>
                            <input (focus)="pickerEnd.open()" [matDatepicker]="pickerEnd"
                                   [min]="unavailablePlanningForm.get(entityUnavailable).get('begindate').value"
                                   formControlName="enddate"
                                   matInput placeholder="Niet beschikbaar tot">
                            <mat-datepicker-toggle [for]="pickerEnd" abIndex="-1" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </div>
                    </td>
                    <td>
                        <button (click)="deleteUnavailable($event, entityUnavailable)" class="delete-button" color="warn"
                                mat-icon-button>
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="unavailablePlanningForm.get(entityUnavailable).errors && unavailablePlanningForm.get(entityUnavailable).errors.notAvailable">
                    <td colspan="3">
                        <mat-error>
                            {{title}} {{entity.name}} is al ingepland in deze periode
                        </mat-error>
                    </td>
                    <td></td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="3">
                    <button (click)="addUnavailable()" class="w-100" color="primary" mat-button>
                        Onbeschikbaarheid toevoegen <i class="fas fa-plus"></i>
                    </button>
                </td>
                <td></td>
            </tr>
        </table>
        <div class="d-flex justify-content-between mt-5">
            <button (click)="close()" mat-button>Annuleren</button>

            <button (click)="save()" [disabled]="saving" color="primary" mat-raised-button>
                Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
            </button>
        </div>
    </div>
</div>
