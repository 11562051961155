<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Bestellijst asfalt genereren</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div mat-dialog-content>
    <div *ngIf="!planning">
        <h2>Kies een week</h2>
        <table class="table table-bordered table-striped text-right">
            <thead>
            <tr>
                <th class="text-center" width="70">Week</th>
                <th>Van</th>
                <th>Tot</th>
            </tr>
            </thead>
            <tr (click)="chooseMill(week)" *ngFor="let week of weeks" class="curpoint">
                <td class="text-center">{{week.startDate | date: 'w'}}</td>
                <td>
                    {{week.startDate | date: 'EEE dd MMM y'}}
                </td>
                <td>
                    {{week.endDate | date: 'EEE dd MMM y'}}
                </td>
            </tr>
        </table>
        <i>Het is vandaag {{today | date:'EEEE dd MMMM y'}}</i>
    </div>
    <div *ngIf="planning">
        <h1>
            Asfaltlijst week {{selectedWeek.startDate | date:'w'}}
            <small><i>{{selectedWeek.startDate | date: 'dd MMM'}} - {{selectedWeek.endDate | date: 'dd MMM y'}}</i></small>
        </h1>
        <h3>Kies een molen</h3>
        <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th>Asfaltmolen</th>
                <th class="text-right">Bestellingen</th>
                <th class="text-right">Totaal tonnage</th>
                <th width="50"></th>
            </tr>
            </thead>
            <tr (click)="generateList(mill)" *ngFor="let mill of mills" class="curpoint">
                <td>{{mill.name}}</td>
                <td class="text-right">{{orders.get(mill.id)?.orderCount || '-'}}</td>
                <td class="text-right">{{orders.get(mill.id)?.tonsOrdered || '-'}}</td>
                <td class="text-center">
                    <i class="fas fa-download" *ngIf="!downloading.has(mill.id) && !downloaded.has(mill.id)"></i>
                    <i class="fas fa-spinner fa-spin" *ngIf="downloading.has(mill.id)"></i>
                    <i class="fas fa-check" *ngIf="downloaded.has(mill.id) && !downloading.has(mill.id)"></i>
                </td>
            </tr>
        </table>
        <br><br>
        <a (click)="otherWeek()" class='curpoint'>&laquo; Kies een andere week</a>
    </div>
</div>