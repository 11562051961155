import {Pipe, PipeTransform} from '@angular/core';
import {Planning} from './classes/planning.class';
import {Utils} from './utils.class';
import {Entity} from './classes/entity.class';

@Pipe({
    name: 'multiple'
})
export class MultiplePipe implements PipeTransform {

    transform(entityId: number, ownPlanningId: number, date: Date, planning: Planning[], entitiesMap: Map<number, Entity>): boolean {
        let multiple = false;
        if (entityId && entitiesMap.get(entityId)?.use_once) {
            planning?.forEach(pln => {
                pln.planning_has?.forEach(plnh => {
                    if (plnh.id !== ownPlanningId && (
                        plnh.entity_id === entityId ||
                        plnh.truck_entity_id === entityId ||
                        plnh.lowloader_entity_id === entityId
                    ) && Utils.getDateOrNull(plnh.begindate) === Utils.getDateOrNull(date)) {
                        multiple = true;
                    }
                });
            });
        }

        return multiple;
    }

}
