import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {

    constructor(public dialog: MatDialog) {
    }

    confirm<T>(title, text, confirm = 'Oké', cancel = 'Annuleren', options?: T[]): Promise<T> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            maxWidth: '400px',
            minWidth: '250px',
            disableClose: true,
            data: {
                title,
                text,
                confirm,
                cancel,
                options
            }
        });

        return new Promise((resolve, reject) => {
            const subs = new Subscription();

            subs.add(dialogRef.componentInstance.optionChoosen.subscribe(option => {
                resolve(option as any as T);
                dialogRef.close();
                subs.unsubscribe();
            }));
            subs.add(dialogRef.afterClosed().subscribe(result => {
                if (result && resolve) {
                    resolve(null);
                } else if (reject) {
                    reject(null);
                }
                subs.unsubscribe();
            }));
        });
    }
}
