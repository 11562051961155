import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replaceIf'
})
export class ReplaceIfPipe implements PipeTransform {

    transform(value: any, matches: any, replaceWith: any): any {
        return value === matches ? replaceWith : value;
    }

}
