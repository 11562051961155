import {Planning} from './planning.class';
import {User} from './user.class';
import {PlanningHasEntity} from './planning-has-entity.class';
import {UserPlanning} from './user-planning';
import {Entity} from './entity.class';
import {Settlement} from './settlement.class';
import {Project} from './project.class';

export class Realisation {
    id?: number;
    bookdate: Date;
    begindate: Date;
    enddate: Date;
    travel?: boolean;
    user_id: number;
    parent_realisation_id?: number;
    user?: User;
    entity_id?: number;
    entity?: Entity;
    planning_id: number;
    planning?: Planning;
    planning_has_entity_id?: number;
    planning_has?: PlanningHasEntity;
    user_planning?: UserPlanning;
    user_planning_id?: number;
    afas_project_id?: string;
    adjusted?: Date;
    submitted?: Date;
    confirmed?: Date;
    approved?: Date;
    removed?: Date;
    skip_performer?: boolean;
    paid?: Date;
    kms?: number;
    pause?: number;
    pause_adjusted?: boolean;
    saved_by_id?: number;
    comment_user?: string;
    comment_user_approved?: string;
    comment_user_approved_handled?: Date;
    comment_manager?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;

    dayOff?: boolean;
    dayInvisible?: boolean;

    hourtype?: RealisationHourtype;
    text_prepend?: string;
    text_append?: string;
    traveltime?: number;

    settlements?: Settlement[];
    project?: Project;
}

export enum RealisationHourtype {
    worktime = 'worktime',
    driving_to = 'driving_to',
    driving_back = 'driving_back',
    travel_to = 'travel_to',
    travel_back = 'travel_back',
    frost = 'frost',
    day_off = 'day_off',
    illness = 'illness',
    sleep = 'sleep',
    education = 'education'
}
