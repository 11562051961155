import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Hiring} from '../classes/hiring';
import {RealtimeService} from './realtime/realtime.service';
import {WebsocketService} from './websocket/websocket.service';
import {RealtimeType} from './realtime/realtime-type.enum';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityTypeCode} from './entities/entity-type.class';

@Injectable({
    providedIn: 'root'
})
export class HiringService extends RealtimeService<Hiring> {

    constructor(protected apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.hiring);
    }

    getList(beginDate?: Date, endDate?: Date): Observable<Hiring[]> {
        return super.getList().pipe(map(a => a.sort((a, b) => a.order - b.order))).pipe(map(list => {
            return list;
        }));
    }

    getByType(code: EntityTypeCode): Observable<Hiring[]> {
        return this.getList().pipe(map(hire => hire.filter(hiring => hiring.entitytypes.map(e => e.id).includes(+code))));
    }

    itemInDaterange(hiring: Hiring, fromDate: Date, toDate: Date) {
        throw new Error('Method not implemented.');
    }

    changeOrder(hirings: Hiring[]): Promise<boolean> {
        return this.apiService.postCall(`${this.type}/change-order`, hirings);
    }

    save(entity: Hiring): Promise<Hiring> {
        return this.apiService.postCall(this.type, entity);
    }

    get(id: number): Promise<Hiring> {
        return this.apiService.getCall(`${this.type}/${id}`);
    }

    delete(id: number): Promise<Hiring> {
        return this.apiService.deleteCall(`${this.type}/${id}`);
    }
}
