<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Planning exporteren als PDF</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div mat-dialog-content>
    <h2>Kies een week</h2>
    <table class="table table-bordered table-striped text-right">
        <thead>
        <tr>
            <th class="text-center" width="70">Week</th>
            <th>Van</th>
            <th>Tot</th>
        </tr>
        </thead>
        <tr (click)="generateList(week)" *ngFor="let week of weeks" class="curpoint">
            <td class="text-center">{{week.startDate | date: 'w'}}</td>
            <td>
                {{week.startDate | date: 'EEE dd MMM y'}}
            </td>
            <td>
                {{week.endDate | date: 'EEE dd MMM y'}}
            </td>
            <td class="text-center">
                <i *ngIf="!downloading.has(week) && !downloaded.has(week)" class="fas fa-download"></i>
                <i *ngIf="downloading.has(week)" class="fas fa-spinner fa-spin"></i>
                <i *ngIf="downloaded.has(week) && !downloading.has(week)" class="fas fa-check"></i>
            </td>
        </tr>
    </table>
    <i>Het is vandaag {{today | date:'EEEE dd MMMM y'}}</i>
</div>