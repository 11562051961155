import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class ConfirmDialogData {
    title: string;
    text: string;
    confirm: string;
    cancel: string;
    options?: { name: string; icon?: string; bgColor?: string; disabled?: boolean; subtext?: string; }[];
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    @Output() optionChoosen = new EventEmitter<{ name: string; }>();

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    }

    ngOnInit() {

    }

    optionClicked(option: { name: string; }) {
        this.optionChoosen.emit(option);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

