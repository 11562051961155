import {of, timer} from 'rxjs';
import {UserPlanningService} from '../services/planning/user-planning.service';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {map, switchMap} from 'rxjs/operators';
import {formatDate} from '@angular/common';

export const employeeAvailableValidator = (userPlanningService: UserPlanningService) => {
    return (input: FormGroup<ControlsOf<{
        begindate: FormControl<Date>,
        enddate: FormControl<Date>,
        user_id: FormControl<number>,
        planning_id: FormControl<number>,
        conflictText
    }>>) => {
        if (input.controls.begindate.value && input.controls.enddate.value) {
            return timer(500).pipe(
                switchMap(() => userPlanningService.checkAvailability(
                    input.controls.begindate.value,
                    input.controls.enddate.value,
                    input.controls.user_id.value,
                    input.controls.planning_id.value
                )),
                map(res => {
                    if (res?.data?.length) {
                        let text = `De inplanning conflicteert met:\n `;
                        res.data.forEach(userPlanning => {
                            const from = formatDate(userPlanning.begindate, 'HH:mm', 'nl');
                            const to = formatDate(userPlanning.enddate, 'HH:mm', 'nl');
                            const entity = userPlanning?.entity?.name ? ` (${userPlanning.entity.name})` : '';
                            text += `Project ${userPlanning?.planning?.afas_project_id ?? userPlanning?.afas_project_id}${entity} van ${from} tot ${to}`;
                        });
                        input.controls.conflictText.setValue(text, {emitEvent: false, onlySelf: true});
                    }
                    return res?.data?.length > 0 ? {notAvailable: true} : null;
                })
            );
        }
        return of(null);
    };
};
