import {Pipe, PipeTransform} from '@angular/core';
import {Entity} from './classes/entity.class';
import {EntityType} from './services/entities/entity-type.class';

@Pipe({
    name: 'transportPlanningCols'
})
export class TransportplanningColsPipe implements PipeTransform {

    transform(entity: Entity, entitytype: EntityType, condition?: boolean): any {
        if (condition === false || !entitytype) {
            return 1;
        }
        const extraCols = [
            (entitytype.has_truck || entitytype.has_lowloader || entitytype.has_driver) && entity?.use_once,
            !entity?.use_once,
            entitytype.has_lowloader,
            entitytype.has_truck
        ].filter(p => !!p).length;
        return 5 - extraCols;
    }

}
