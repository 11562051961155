import {Component, NgZone, OnInit} from '@angular/core';
import {Utils} from '../utils.class';
import {RealisationService} from '../services/realisation.service';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {CodaltComponent} from '../codalt.component';
import {Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {UserService, UserType} from '../services/user/user.service';

@Component({
    selector: 'app-week-schedule',
    templateUrl: './week-schedule.component.html',
    styleUrls: ['./week-schedule.component.scss']
})
export class WeekScheduleComponent extends CodaltComponent implements OnInit {

    maxDate: Date;

    overviewStepSize = 7;
    beginDate: Date;
    endDate: Date;

    realisations: Realisation[];

    showPlanning = false;

    constructor(private zone: NgZone,
                private router: Router,
                private realisationService: RealisationService) {
        super();
        const monday = new Date();
        monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
        Utils.setTime(monday, 0, 0);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        this.beginDate = new Date(monday);
        this.endDate = new Date(sunday);
        this.loadData();
        this.showPlanning = UserService.userHasRights(UserType.USER);
        this.maxDate = this.realisationService.calcMaxDate();
    }

    private loadData() {
        this.realisationService.getUserSchedule(this.beginDate, this.endDate).subscribe(realisations => {
            this.realisations = realisations.data
                .filter(r => ![RealisationHourtype.travel_back, RealisationHourtype.travel_to].includes(r.hourtype))
                .filter(r => Utils.getTimeOrNull(r.begindate) < this.maxDate.getTime());
            for (let i = 0; i <= 6; i++) {
                const loopDay = Utils.setTime(new Date(this.beginDate), 0, 0);
                loopDay.setDate(loopDay.getDate() + i);
                const realisation = this.realisations.find(r => Utils.dateString(r.bookdate) === Utils.dateString(loopDay));
                if (realisation === undefined) {
                    this.realisations.push({
                        planning_id: 0,
                        user_id: 1,
                        dayOff: true,
                        dayInvisible: loopDay.getTime() > this.maxDate.getTime(),
                        bookdate: loopDay,
                        begindate: loopDay,
                        enddate: loopDay
                    });
                }
            }
            this.realisations = this.realisations.sort((a, b) => Utils.realisationSort(a) - Utils.realisationSort(b));
            this.realisations.map(r => r.begindate = new Date(r.begindate));
        });
    }

    ngOnInit(): void {
        window.onfocus = () => {
            this.loadData();
        };
    }

    next() {
        this.beginDate.setDate(this.beginDate.getDate() + this.overviewStepSize);
        this.beginDate = new Date(this.beginDate);
        this.endDate.setDate(this.endDate.getDate() + this.overviewStepSize);
        this.endDate = new Date(this.endDate);
        this.loadData();
    }

    prev() {
        this.beginDate.setDate(this.beginDate.getDate() - this.overviewStepSize);
        this.beginDate = new Date(this.beginDate);
        this.endDate.setDate(this.endDate.getDate() - this.overviewStepSize);
        this.endDate = new Date(this.endDate);
        this.loadData();
    }

    open(planning: Realisation) {
        if (!planning.dayOff && planning.begindate < this.maxDate) {
            this.router.navigateByUrl(`/weekschedule/${formatDate(planning.begindate, 'yyyy-MM-dd', 'nl')}`)
        }
    }

}
