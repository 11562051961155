import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Group} from '../classes/group.class';
import {UserGroups, UserService, UserType} from '../services/user/user.service';
import {LocalStorage} from '../storage.class';
import {User} from '../classes/user.class';
import {AfasService} from '../services/afas.service';
import {Employees} from '../afas-classes/employees.class';
import {Realization} from '../afas-classes/realization.class';
import {AfasAuthService} from '../services/afas-auth.service';
import {Settings} from '../settings.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PtRealization} from '../afas-classes/ptrealization.class';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {formatDate} from '@angular/common';
import {environment} from 'src/environments/environment';
import {Hourtype} from '../afas-classes/hourtype';
import {AfasUser} from '../afas-classes/afas-user';
import {AfasProject} from '../afas-classes/afas-project';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

    saving = false;
    title = '';
    UserGroups = UserGroups;
    UserType = UserType;
    form: FormGroup<any>;
    relForm: FormGroup<ControlsOf<PtRealization>>;
    userGroupKeys = [...UserGroups.keys()];
    groups: Group[];
    employees: Employees[];
    realization: Realization[];
    hourtypes: Hourtype[];
    employee: Employees;
    afasUser: AfasUser;
    public requestOtpError: string = null;
    public validateOtpError: string = null;
    public user: string;
    public otp: string;
    public environmentCode = '92272.';
    public result: Object = {};
    public Settings = Settings;
    projects: AfasProject[];
    materieel: Employees[];
    otpRequest = false;
    otpRequesting = false;
    otpRequested = false;
    otpValidating = false;
    loadingRealization = false;
    savingRealization = false;
    fcEmployee: FormControl<string>;
    environment = environment;
    private subscriptions = new Subscription();

    constructor(private userService: UserService,
                private authService: AfasAuthService,
                private afasService: AfasService,
                private confirmDialogService: ConfirmDialogService,
                private elRef: ElementRef) {
    }

    ngOnInit() {
        this.loadProfile();
        this.fcEmployee = new FormControl<string>();
        this.subscriptions.add(this.fcEmployee.valueChanges.subscribe(() => {
            this.loadRealization();
        }));
    }

    loadRealization() {
        if (UserService.userHasRights(UserType.ADMIN)) {
            this.loadingRealization = true;
            this.realization = [];
            this.subscriptions.add(this.afasService.getRealization(this.fcEmployee?.value).subscribe(emp => {
                this.loadingRealization = false;
                this.realization = emp.data;
            }, () => {
                this.loadingRealization = false;
            }));
        }
    }

    loadProfile() {
        this.subscriptions.add(this.afasService.getProfile().subscribe(emp => {
            this.employee = emp.data.employee;
            this.afasUser = emp.data.afasuser;
            this.createForm(emp.data.user);
            this.createRealizationForm();
            if (UserService.userHasRights(UserType.ADMIN)) {
                this.loadAfas();
            }
            this.loadRealization();
        }, () => {
            this.createForm(new User());
        }));
    }

    loadAfas() {
        this.subscriptions.add(this.afasService.getProjects().subscribe((projectResponse) => {
            this.projects = projectResponse.data?.filter(p => !!p.hoofdproject);
        }));

        this.subscriptions.add(this.afasService.getEmployees().subscribe((projectResponse) => {
            this.employees = projectResponse.data;
        }));

        this.subscriptions.add(this.afasService.getMaterieel().subscribe((materieelResponse) => {
            this.materieel = materieelResponse.data;
        }));
        this.subscriptions.add(this.afasService.getHourtypes().subscribe(hourtypes => {
            this.hourtypes = hourtypes.data;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    save() {
        Object.assign(LocalStorage.user, this.form.value);
        this.saving = true;
        this.userService.saveUser(LocalStorage.user).then(user => {
            this.saving = false;
        }, error => {
            this.saving = false;
        });
    }

    deleteTokens() {
        this.confirmDialogService.confirm(
            'Koppeling met Afas verwijderen',
            'Weet je zeker dat je de koppeling van Afas aan de planning account wilt verwijderen?',
            'Ontkoppelen', 'Behouden').then(() => {
            this.authService.deleteExisting().then(() => {
                this.loadProfile();
            });
        }, () => {
        });
    }

    // When username does not exists, Afas still replies success
    requestOtp() {
        this.requestOtpError = null;
        this.otpRequesting = true;

        this.authService.requestOtp(this.environmentCode + this.user).then((data) => {
            if (data['response'] == 201) {
                this.otpRequested = true;
                this.otpRequest = false;
                this.result = data;
                setTimeout(() => {
                    let a = this.elRef.nativeElement.querySelector(`#passwordField`);
                    a.focus();
                }, 250);
            } else {
                console.log(data);
                this.requestOtpError = JSON.stringify(data);
            }
        }, (data) => {
            if (data.data && data.data.errormessage) {
                this.requestOtpError = data.data.errormessage;
            } else {
                this.requestOtpError = JSON.stringify(data);
            }
            console.log(this.requestOtpError, data);
        });
    }

    // Request OTP (one-time-password) based on the Afas Profit Username
    // Will send an OTP to the user by e-mail if username excists and permissions avaiable

    // Validate the entered OTP (one-time-password) by retrieving the user that is authenticating
    validateOtp() {
        this.validateOtpError = null;
        this.otpValidating = true;

        this.authService.validateOtp(this.environmentCode + this.user, this.otp).then((data) => {
            if (data['response'] == 201) {
                this.result = data;
                this.loadProfile();
                this.reset();
                this.otpRequest = false;
            } else {
                this.otpValidating = false;
                console.log(data, this.validateOtpError);
                // Invalid password
                if (data['response'] == 204) {
                    this.validateOtpError = 'Dit wachtwoord is niet juist.';
                } else {
                    this.validateOtpError = JSON.stringify(data);
                }
                console.log(data, this.validateOtpError);
            }
        }, (data) => {
            this.otpValidating = false;
            if (data.data && data.data.errormessage) {
                this.validateOtpError = data.data.errormessage;
            } else {
                this.validateOtpError = JSON.stringify(data);
            }
            console.log(data, this.validateOtpError);
        });
    }

    // Reset complete process so you can start over
    reset() {
        this.otpRequest = true;
        this.otpRequested = false;
        this.otpRequesting = false;
        this.validateOtpError = null;
        this.requestOtpError = null;
        this.user = null;
        this.otp = null;
        this.result = {};
    }

    public createRealizationForm() {
        this.relForm = new FormGroup<ControlsOf<PtRealization>>({
            DaTi: new FormControl<string>(formatDate(new Date(), 'yyyy-MM-dd', 'nl')),
            VaIt: new FormControl('1'),
            ItCd: new FormControl('GW'), // ItemCode
            StId: new FormControl('1'),
            PrId: new FormControl(),
            EmId: new FormControl(this.employee?.employeeId),
            Ds: new FormControl('test via codalt'),
            Qu: new FormControl(7.25)
        });
    }

    saveRealization() {
        this.savingRealization = true;
        this.afasService.saveRealization(this.relForm.value as PtRealization).subscribe(() => {
            this.savingRealization = false;
            this.loadRealization();
        }, () => {
            this.savingRealization = false;
        });
    }

    private createForm(user: User) {
        this.title = user.id ? 'aanpassen' : 'toevoegen';
        this.form = new FormGroup({
            name: new FormControl(user.name, [Validators.required, Validators.minLength(3)]),
            email: new FormControl(user.email, [Validators.required, Validators.email])
        });
    }

}

interface UserEditParams {
    userId: number;
}
