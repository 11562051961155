<div class="controls">
    <div class="header">
        <app-header [print]="print"
                    [mainTitle]="'Planning DvdS'"
                    subTitle="Personeel">
        </app-header>
    </div>
    <div class="indicator">
        <div class="team-selector flex-column d-flex">
            <div class="settings">
                <div class="trigger" [cdkMenuTriggerFor]="menu">
                    <div class="icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                    <div class="name">
                        Selecteer projecten
                    </div>
                </div>
            </div>
        </div>
        <div class="night-planning">
            <mat-checkbox [formControl]="fcNightPlanning" color="primary">Nacht</mat-checkbox>
        </div>
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [fromDate]="fromDate"
                            [primaryWeekEnd]="toDate"
                            [primaryWeekStart]="fromDate"
                            [print]="print"
                            [toDate]="toDate">
        </app-date-indicator>
    </div>
    <div *ngIf="!print" class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="employee-planning-overview" *ngIf="projectDaysList" [class.night]="fcNightPlanning.value">
    <table class="table">
        <thead class="table-head">
        <tr class="projects">
            <td colspan="2" class="table-head-title">
                Project:
            </td>
            <ng-container *ngFor="let projectDays of projectDaysList">
                <td [colSpan]="projectDays.days.length"
                    *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)"
                    class="project"
                    [matTooltip]="projectDays.project?.name">
                    {{projectDays.project?.afas_project_id ?? projectDays.worknumber}}<br>
                    <small>{{projectDays.project?.name}}</small>
                </td>
            </ng-container>
        </tr>
        <tr class="project-info">
            <td colspan="2" class="table-head-title">
                Plaats:
            </td>
            <ng-container *ngFor="let projectDays of projectDaysList">
                <td [colSpan]="projectDays.days.length"
                    *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                    <input [matAutocomplete]="auto" *ngIf="projectDays.project?.afas_project_id"
                           [formControl]="projectDays.form.controls.location" matInput placeholder="Locatie" type="text">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="location-complete"
                                      (optionSelected)="addressSelected($event, projectDays.form)">
                        <mat-option *ngFor="let option of addresses" [value]="option.formattedAddress">
                            {{option.formattedAddress}}
                        </mat-option>
                    </mat-autocomplete>
                </td>
            </ng-container>
        </tr>
        <tr class="project-info">
            <td colspan="2" class="table-head-title">
                Uitvoerder:
            </td>
            <ng-container *ngFor="let projectDays of projectDaysList">
                <td [colSpan]="projectDays.days.length"
                    *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                    <mat-select [formControl]="projectDays.form.controls.performer_id" placeholder="Uitvoerder" *ngIf="projectDays.project?.afas_project_id">
                        <mat-option *ngFor="let executor of executors" [value]="executor.id">{{executor.name}}</mat-option>
                    </mat-select>
                </td>
            </ng-container>
        </tr>
        <tr class="project-info">
            <td colspan="2" class="table-head-title">
                Projectleider
            </td>
            <ng-container *ngFor="let projectDays of projectDaysList">
                <td [colSpan]="projectDays.days.length"
                    *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                    <mat-select [formControl]="projectDays.form.controls.projectmanager_id" placeholder="Projectleider"
                                *ngIf="projectDays.project?.afas_project_id">
                        <mat-option *ngFor="let projectManager of projectManagers"
                                    [value]="projectManager.id">
                            {{projectManager.name}}
                        </mat-option>
                    </mat-select>
                </td>
            </ng-container>
        </tr>
        <tr class="days">
            <th colspan="2" class="table-head-title">
                Personeel
            </th>
            <ng-container *ngFor="let projectDays of projectDaysList">
                <ng-container *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                    <th *ngFor="let day of projectDays.days; let first = first; let last = last"
                        class="day day-{{(day.day | date : 'E')}}"
                        [class.highlight]="highlightDay === projectDays.project?.afas_project_id + (day.day | date : 'E')"
                        matTooltip="{{day.day | date : 'E d MMM y'}}"
                        [class.last]="last"
                        [class.first]="first">
                        {{(day.day | date : 'E') | slice : 0 : 1}}
                    </th>
                </ng-container>
            </ng-container>
        </tr>
        </thead>
        <ng-container *ngFor="let team of teams; let index = index; let last = last">
            <tr class="employee-function"
                *ngIf="!last && (index === 0 || team.entityType.plural_name !== teams[index - 1].entityType.plural_name)">
                <td [colSpan]="2" class="function-name">
                    {{team.entityType.plural_name}}
                </td>
                <td [colSpan]="projectDaysList.length * 7">

                </td>
            </tr>
            <tr class="employee-row">
                <td class="employee-name font-weight-bold" (click)="team.showUsers = !team.showUsers">
                    {{team.entity.name}}
                </td>
                <td class="day day-count" (click)="team.showUsers = !team.showUsers">
                    <i class="fa-light fa-chevron-right" *ngIf="!team.showUsers"></i>
                    <i class="fa-light fa-chevron-down" *ngIf="team.showUsers"></i>
                </td>
                <ng-container *ngFor="let projectDays of projectDaysList">
                    <ng-container *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                        <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                            (click)="addTeamPlanning($event, day, team)"
                            (mouseover)="mouseOverTeam(day, team.entity)"
                            class="day"
                            [class.last]="last"
                            [class.first]="first">
                            {{projectDayTeamMap.get((day.day | date : 'yyyy-MM-dd') + '-' + team.entity.id + '-' + projectDays.project?.afas_project_id)?.user_planning?.length}}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <ng-container *ngIf="team.showUsers">
                <tr class="employee-row" *ngFor="let user of team.users; let index = index; let last = last">
                    <td class="employee-name" [class.highlight]="highlight === 'u-' + user.id">
                        {{user.lastname}}, {{user.firstname}} {{user.insertion}}
                    </td>
                    <td class="day day-count"
                        [class.warn]="userWeekCount.get(user.id) < 5"
                        matTooltip="Ingepland op {{userWeekCount.get(user.id)}} dagen">
                        {{userWeekCount.get(user.id)}}
                    </td>
                    <ng-container *ngFor="let projectDays of projectDaysList">
                        <ng-container *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                            <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                                class="day"
                                [class.highlight]="highlight === 't-'+team.entity.id+'-'+(day.day | date : 'yyyy-MM-dd')"
                                [class.night]="day.day | employeeIsNight : projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)"
                                (mouseover)="mouseOver(day, user)"
                                (click)="addUserPlanningTeam($event, day, team, user)"
                                [class.empty]="!day.project?.afas_project_id"
                                [matTooltip]="blockedMap?.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id)?.description"
                                [class.last]="last"
                                [class.first]="first">
                                <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'planning'"
                                   class="fa-light fa-truck">
                                </i>
                                <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'userPlanning'"
                                   class="fa-light fa-users">
                                </i>
                                <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'project'"
                                   class="fa-light fa-user-helmet-safety">
                                </i>
                                <i *ngIf="blockedMap?.has((day.day | date : 'yyyy-MM-dd') + '-' + user.id)"
                                   class="fa-regular {{blockedMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id).icon}}">
                                </i>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let user of users; let index = index; let last = last">
            <tr class="employee-function"
                *ngIf="!last && (index === 0 || user.function !== users[index - 1].function)">
                <td [colSpan]="2" class="function-name">
                    {{user.function}}
                </td>
                <td [colSpan]="projectDaysList.length * 7">

                </td>
            </tr>
            <tr class="employee-row">
                <td class="employee-name" [class.highlight]="highlight === 'u-' + user.id">
                    {{user.lastname}}, {{user.firstname}} {{user.insertion}}
                </td>
                <td class="day day-count"
                    [class.warn]="userWeekCount.get(user.id) < 5"
                    matTooltip="Ingepland op {{userWeekCount.get(user.id)}} dagen">
                    {{userWeekCount.get(user.id)}}
                </td>
                <ng-container *ngFor="let projectDays of projectDaysList">
                    <ng-container *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                        <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                            class="day"
                            [class.night]="day.day | employeeIsNight : projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)"
                            (mouseover)="mouseOver(day, user)"
                            (click)="addUserPlanningProject($event, day, projectDayUserMap.get(formatDate(day.day, 'yyyy-MM-dd', 'nl') + '-' + user.id + '-' + projectDays.project?.afas_project_id), user)"
                            [class.empty]="!day.project?.afas_project_id"
                            [matTooltip]="blockedMap?.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id)?.description"
                            [class.last]="last"
                            [class.first]="first">
                            <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'planning'"
                               class="fa-light fa-truck">
                            </i>
                            <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'userPlanning'"
                               class="fa-light fa-users">
                            </i>
                            <i *ngIf="projectDayUserMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id + '-' + projectDays.project?.afas_project_id)?.type === 'project'"
                               class="fa-light fa-user-helmet-safety">
                            </i>
                            <i *ngIf="blockedMap?.has((day.day | date : 'yyyy-MM-dd') + '-' + user.id)"
                               class="fa-regular {{blockedMap.get((day.day | date : 'yyyy-MM-dd') + '-' + user.id).icon}}">
                            </i>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-container>
        <tr class="employee-function">
            <td [colSpan]="2" class="function-name">
                Klein materieel
            </td>
            <td [colSpan]="projectDaysList?.length * 7">

            </td>
        </tr>
        <ng-container *ngFor="let entity of smallMaterial; let index = index; let last = last">
            <tr class="employee-row">
                <td [colSpan]="2" class="employee-name"
                    [class.highlight]="highlight === 'e-' + entity.id">
                    <div>{{entity.name}}</div>
                </td>
                <ng-container *ngFor="let projectDays of projectDaysList">
                    <ng-container *ngIf="!fcProjectIds.value?.length || (projectDays.project?.afas_project_id | valueInArray : fcProjectIds.value)">
                        <td *ngFor="let day of projectDays.days; let first = first; let last = last"
                            class="day"
                            [class.night]="day.day | entityIsNight : projectDayMaterialMap.get((day.day | date : 'yyyy-MM-dd') + '-' + entity.id + '-' + projectDays.project?.afas_project_id)"
                            (mouseover)="mouseOverEntity(day,entity)"
                            (click)="addEntityPlanning($event, day, entity)"
                            [class.empty]="!(day | projectDayHasAnyTeam :  teams : projectDayTeamMap : projectDayTeamMap?.size)"
                            [class.last]="last"
                            [class.first]="first">
                            <i *ngIf="projectDayMaterialMap.has((day.day | date : 'yyyy-MM-dd') + '-' + entity.id + '-' + projectDays.project?.afas_project_id)"
                               class="fa-light fa-truck-plow">
                            </i>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-container>
    </table>
</div>
<div class="action-buttons">
    <button mat-fab color="accent"
            (click)="addProject()"
            matTooltip="Project toevoegen"
            matTooltipPosition="left"
            [disabled]="saving || rollingBack">
        <i class="fa-light fa-plus fa-2x"></i>
    </button>
    <button mat-fab color="accent"
            matTooltipPosition="left"
            matTooltip="Wijzigingen ongedaan maken"
            [disabled]="rollingBack || saving || (!userPlanningsToSave?.length && !planningHasToSave?.length)"
            (click)="undo()">
        <i class="fa-light fa-rotate-left fa-2x" *ngIf="!rollingBack"></i>
        <i class="fas fa-spinner fa-spin fa-2x" *ngIf="rollingBack"></i>
    </button>
    <button mat-fab color="primary"
            matTooltipPosition="left"
            matTooltip="Wijzigingen opslaan"
            [disabled]="saving || (!userPlanningsToSave?.length && !planningHasToSave?.length && !formDirty)"
            (click)="save()">
        <i class="fa-light fa-floppy-disk fa-2x" *ngIf="!saving"></i>
        <i class="fas fa-spinner fa-spin fa-2x" *ngIf="saving"></i>
    </button>
</div>
<ng-template #menu>
    <div class="menu" cdkMenu>
        <ng-container *ngTemplateOutlet="filter"></ng-container>
    </div>
</ng-template>

<ng-template #filter>
    <div>
        Selecteer projecten
    </div>
    <mat-selection-list [formControl]="fcProjectIds">
        <mat-list-option checkboxPosition="before" *ngFor="let projectDays of projectDaysList" [value]="projectDays.project.afas_project_id">
            {{projectDays.project.afas_project_id}}
        </mat-list-option>
    </mat-selection-list>
</ng-template>
