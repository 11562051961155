import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'licensePlate'
})
export class LicensePlatePipe implements PipeTransform {

    transform(name: string, invert = false): string {
        if (name && typeof name === 'string') {
            const plate = name.match(/([A-Z]{1,3}|[0-9]{1,3})-([A-Z]{1,3}|[0-9]{1,3})-([A-Z]{1,3}|[0-9]{1,3})/);
            if (plate?.length > 0) {
                if (invert) {
                    return name.replace(plate[0], '');
                }
                return plate[0];
            }
        }
        return name;
    }

}
