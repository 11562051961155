import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {PlanningFixed} from '../../classes/planningfixed.class';
import {RealtimeService} from '../realtime/realtime.service';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';

@Injectable({
    providedIn: 'root'
})
export class PlanningFixedService extends RealtimeService<PlanningFixed> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.planningfixed);
    }

    public setFixed(fixedUntil: Date): Promise<PlanningFixed> {
        return this.apiService.postCall('planning/fixed', {fixedUntil});
    }

    public itemInDaterange(entity: PlanningFixed, fromDate: Date, toDate: Date) {
        return true;
    }
}
