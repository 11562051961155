import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {Utils} from '../utils.class';

@Pipe({
    name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform {

    transform(value: Realisation, check?: 'begindate' | 'enddate', ...triggerPipeOnChange: any[]): boolean {
        return Utils.realisationTimeDifference(value, check);
    }

}
