import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {formatDate} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {RealisationService} from '../services/realisation.service';
import {Utils} from '../utils.class';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {User} from '../classes/user.class';
import {combineLatest, concat, of, Subscription} from 'rxjs';
import {Settlement} from '../classes/settlement.class';
import {SettlementsService} from '../services/settlements.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Hourtype} from '../afas-classes/hourtype';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {MinutesPipe} from '../pipes/minutes.pipe';
import {LocationService} from '../services/location.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-period-settlement',
    templateUrl: './period-settlement.component.html',
    styleUrls: ['./period-settlement.component.scss']
})
export class PeriodSettlementComponent extends CodaltComponent implements OnInit, OnChanges {

    today = new Date();

    @Input() print = false;

    days: FormArray<ControlsOf<FgWeekDay>>;
    weeks: Week[];

    @Input() beginDate: Date;
    @Input() endDate: Date;

    settlements: Settlement[];
    realisations: Realisation[];
    @Input() user: User;
    @Input() daytypeCounts: { type: string; count: number; minutes: number; }[];


    userSubscriptions = new Subscription();

    fcHourTypeMap = new Map<string, string>([
        ['200', 'overtime200'],
        ['200Z', 'overtime200'],
        ['200V', 'overtime200'],
        ['150', 'overtime150'],
        ['150Z', 'overtime150'],
        ['150V', 'overtime150'],
        ['145', 'overtime145'],
        ['140', 'overtime140'],
        ['135', 'overtime135'],
        ['130', 'overtime130'],
        ['125', 'overtime125'],
        ['100', 'overtime100'],
        ['S100', 'overtime100'],
        ['S100W', 'overtime100'],
        ['100B', 'overtime100'],
        ['SDW', 'overtime100'],
        ['150V', 'bonus50'],
        ['145V', 'bonus45'],
        ['135V', 'bonus35'],
        ['130V', 'bonus30'],
        ['2 ', 'normal'],
        ['RV', 'normal'],
        ['V', 'normal'],
        ['Z', 'normal'],
        ['S', 'normal'],
        ['SD', 'normal'],
        ['Vorst', 'normal'],
        ['RU', 'traveltime']
    ]);
    contractHourTypeCodesWork = [
        '2 '
    ];
    contractHourTypeCodes = [
        ...this.contractHourTypeCodesWork, 'RV', 'V', 'Z', 'Vorst', 'S', 'SD'
    ];
    overtimeHourTypeCodes = [
        '200', '200Z', '150', '150Z', '145', '140', '135', '130', '125', '100', '100B'
    ];

    fcNamePeriodTotalMap: Map<string, number>;

    hourtypes: Hourtype[];

    dayDetail: WeekDay;
    hoverWeekButton?: string;

    caoBouwInfra = false;

    settling = false;

    constructor(private realisationService: RealisationService,
                private settlementService: SettlementsService,
                private confirmDialogService: ConfirmDialogService,
                private settlementsService: SettlementsService,
                private locationService: LocationService,
                private router: Router,
                private title: Title) {
        super();
        this.title.setTitle('Periodeverrekening' + environment.titleAppend);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getData();
    }

    ngOnInit(): void {

    }

    editHours(date: Date, userId: number) {
        this.router.navigate([
            `/hours/${formatDate(date, 'yyyy-MM-dd', 'nl')}/${userId}`,
            {backTo: window.location.pathname}
        ]);
    }

    private getData() {
        this.userSubscriptions?.unsubscribe();
        this.userSubscriptions = new Subscription();
        this.dayDetail = null;

        const getData = () => {
            this.userSubscriptions.add(combineLatest([
                this.realisationService.getUserSchedule(this.beginDate, this.endDate, this.user.id),
                this.settlementService.getUserSettlements(this.beginDate, this.endDate, this.user.id)
            ]).subscribe(([realisations, settlements]) => {
                this.realisations = realisations.data;
                this.settlements = settlements.data;
                this.parseData();
            }));
        };

        if (this.hourtypes) {
            getData();
        } else {
            this.subscriptions.add(this.settlementsService.getHourtypes().subscribe(hourtypes => {
                this.hourtypes = hourtypes.data;
                getData();
            }));
        }

    }

    showDayDetails(event, day: WeekDay) {
        event.preventDefault();

        this.dayDetail = day;
    }

    createSettlementFromRealisation(realisation: Realisation, minutes: number, type: string, from: Date, to: Date, subStart = 0, subEnd = 0) {
        const settlement = new Settlement();
        settlement.user_id = realisation.user_id;
        settlement.bookdate = realisation.bookdate;
        settlement.realisation_id = realisation.id;
        settlement.minutes = minutes;
        settlement.hourtype_code = type;
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        from = new Date(from);
        to = new Date(to);
        if (subStart) {
            from.setMinutes(from.getMinutes() + subStart);
        }
        if (subEnd) {
            to.setMinutes(to.getMinutes() - subEnd);
        }

        settlement.description = `${formatDate(from, 'd MMM yyyy HH:mm', 'nl')} - `;
        if (from.getDate() === to.getDate()) {
            settlement.description += `${formatDate(to, 'HH:mm', 'nl')}`;
        } else {
            settlement.description += `${formatDate(to, 'd MMM yyyy HH:mm', 'nl')}`;
        }
        if (realisation.hourtype === RealisationHourtype.sleep) {
            settlement.description = `Slaapdag ${settlement.description}`;
        }
        if ([RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(realisation.hourtype) && realisation.text_prepend && realisation.text_append) {
            settlement.description += ` van ${realisation.text_prepend} naar ${realisation.text_append}`;
        }
        return settlement;
    }

    settlePeriodHours() {
        this.settling = true;
        const settle = (counter: number) => {
            this.settleWeekHours(this.weeks[counter]).then(() => {
                if ((this.weeks.length - 1) > counter) {
                    settle(counter + 1);
                } else {
                    this.settling = false;
                }
            })
        };
        settle(0);
    }

    removeWeek(week: Week) {
        if (!week.settling) {
            week.settling = true;
            this.confirmDialogService.confirm(
                'Wil je alle nacalculatieregels verwijderen?',
                `We verwijderen alle nacalculatieregels voor ${this.user.name} in week ${week.weeknumber} uit Afas.<br>Doe dit alleen als deze periode nog niet verloond is.<br><b>Deze actie kan niet teruggedraaid worden.</b>`,
                'Regels verwijderen',
                'Annuleren'
            ).then(() => {
                const all$ = [];
                let loadCount = 0;
                week.weekDays[0].settling = true;
                week.weekDays.forEach(weekDay => {
                    all$.push(this.settlementsService.deleteDay(weekDay.date, this.user.id));
                });
                concat(...all$).subscribe(() => {
                    week.weekDays[loadCount].settling = false;
                    loadCount++;
                    if (loadCount === week.weekDays.length) {
                        week.settling = false;
                        this.hoverWeekButton = null;
                        this.getData();
                    } else {
                        week.weekDays[loadCount].settling = true;
                    }
                });
            }, () => {
                week.settling = false;
            });
        }
    }

    settleWeekHours(week: Week) {
        week.settling = true;
        return new Promise((resolve) => {
            if (week.sendToAfas) {
                week.settling = false;
                resolve(null);
                return;
            }
            const settle = (counter: number) => {
                this.settleDayHours(week.weekDays[counter]).then(() => {
                    if ((week.weekDays.length - 1) > counter) {
                        settle(counter + 1);
                    } else {

                        if (this.user.group === 'CHAU') {
                            // als chauffeur en uren tekort, pakken van andere dag in de week goedkoopste uurtype
                            const contractMinutesDay = (this.user.contract_hours / 5) * 60;

                            const daysWithShortage = week.weekDays
                                .filter(d => d.settlements
                                        .filter(s => this.contractHourTypeCodes.includes(s.hourtype_code))
                                        .reduce((sum, current) => sum + current.minutes, 0) < contractMinutesDay &&
                                    d.date.getDay() > 0 && d.date.getDay() < 6
                                );

                            daysWithShortage.forEach(dayWithShortage => {
                                const totalNormalMinutes = dayWithShortage.settlements
                                    .filter(s => this.contractHourTypeCodes.includes(s.hourtype_code))
                                    .reduce((sum, current) => sum + current.minutes, 0);
                                let contractMinutesToFew = (contractMinutesDay - totalNormalMinutes);
                                if (contractMinutesToFew > 0) {
                                    const daysUsed = [] as WeekDay[];
                                    week.weekDays.forEach(day => {
                                        let someChange = false;
                                        day.settlements.filter(s => this.overtimeHourTypeCodes.includes(s.hourtype_code))
                                            .sort((a, b) => {
                                                const aa = `${(a.hourtype_code.replace('Z', ''))}`;
                                                const bb = `${(b.hourtype_code.replace('Z', ''))}`;
                                                return aa.localeCompare(bb);
                                            }).forEach((settlement) => {
                                            if (contractMinutesToFew > 0) {
                                                someChange = true;
                                                const subtract = Math.min(contractMinutesToFew, settlement.minutes);
                                                settlement.minutes = settlement.minutes - subtract;
                                                settlement.description = `${settlement.description} - ${(new MinutesPipe()).transform(subtract)} voor ${formatDate(dayWithShortage.date, 'd MMM yyyy', 'nl')}`;
                                                contractMinutesToFew -= subtract;
                                            }
                                        });
                                        if (someChange) {
                                            daysUsed.push(day);
                                            day.settling = true;
                                            this.saveSettlements(day, day.settlements, () => {
                                                day.settling = false;
                                            });
                                        }
                                    });
                                    if (daysUsed.length) {
                                        const settlement = new Settlement();
                                        settlement.user_id = this.user.id;
                                        settlement.bookdate = formatDate(dayWithShortage.date, 'yyyy-MM-dd', 'nl') as any;
                                        settlement.minutes = (contractMinutesDay - totalNormalMinutes);
                                        settlement.hourtype_code = '2 ';
                                        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                                        settlement.description = 'Aanvullen tot 8 uur met overuren van ';
                                        daysUsed.forEach((du, i) => {
                                            settlement.description += ` ${formatDate(du.date, 'EEEE', 'nl')}`;
                                            if (i < (daysUsed.length - 1)) {
                                                settlement.description += ',';
                                            }
                                        });
                                        dayWithShortage.settlements.push(settlement);
                                        dayWithShortage.settling = true;
                                        this.saveSettlements(dayWithShortage, dayWithShortage.settlements, () => {
                                            dayWithShortage.settling = false;
                                        });
                                    }
                                }
                            });
                        }

                        week.settling = false;
                        resolve(true);
                    }
                });
            };
            settle(0);
        });
    }

    settleDayHours(day: WeekDay) {
        day.settling = true;
        return new Promise((resolve) => {
            if (day.sendToAfas) {
                day.settling = false;
                resolve(null);
                return;
            }
            const settlements = [] as Settlement[];
            const minDayHours = (8 * 60);

            let totals = [] as timeTypeRealisationSettle[];

            const caoBouwInfra = this.user.group !== 'CHAU';

            day.realisations
                .filter(r => [RealisationHourtype.driving_to, RealisationHourtype.driving_back, RealisationHourtype.worktime, RealisationHourtype.travel_back, RealisationHourtype.travel_to].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);

                    const startEarly = Utils.setTime(new Date(endDate), 0, 0);
                    const endEarly = Utils.setTime(new Date(endDate), 6, 0);
                    const startNight = Utils.setTime(new Date(beginDate), caoBouwInfra ? 20 : 18, 0);
                    const midNight = Utils.setTime(new Date(beginDate), 24, 0);

                    let nightMinutes = 0;
                    let earlyMinutes = 0;

                    // nacht
                    if (beginDate.getTime() < midNight.getTime() && endDate.getTime() > startNight.getTime()) {
                        const calcUntil = endDate.getTime() > midNight.getTime() ? midNight : endDate;
                        const calcFrom = beginDate.getTime() < startNight.getTime() ? startNight : beginDate;
                        const minutes = Utils.minuteDuration(calcUntil, calcFrom);
                        totals.push({
                            from: calcFrom,
                            to: calcUntil,
                            minutes,
                            type: TimeOnDay.Night,
                            realisation
                        });
                        nightMinutes += minutes;
                        ``
                    }
                    // ochtend
                    if (beginDate.getTime() < endEarly.getTime() && endDate.getTime() > startEarly.getTime()) {
                        const calcFrom = beginDate.getTime() < startEarly.getTime() ? startEarly : beginDate;
                        const calcUntil = endDate.getTime() > endEarly.getTime() ? endEarly : endDate;
                        const minutes = Utils.minuteDuration(calcUntil, calcFrom);
                        totals.push({
                            from: calcFrom,
                            to: calcUntil,
                            minutes,
                            type: TimeOnDay.Early,
                            realisation
                        });
                        earlyMinutes += minutes;
                    }
                    // normaal
                    const minutesNormal = Utils.minuteDuration(endDate, beginDate) - (nightMinutes + earlyMinutes);
                    if (minutesNormal) {
                        const from = new Date(beginDate);
                        const to = new Date(endDate);
                        let minutesNormalFirst = 0;
                        if (from.getTime() < startEarly.getTime() && from.getTime() < startNight.getTime()) {
                            const normalFromFirst = new Date(from);
                            const normalToFirst = new Date(Math.min(startNight.getTime(), startEarly.getTime()));
                            const minutes = Utils.minuteDuration(normalToFirst, normalFromFirst);
                            totals.push({
                                from: normalFromFirst,
                                to: normalToFirst,
                                minutes,
                                type: TimeOnDay.Normal,
                                realisation
                            });
                            minutesNormalFirst += minutes;
                        }

                        if (minutesNormalFirst) {
                            from.setMinutes(from.getMinutes() + (nightMinutes + earlyMinutes));
                        } else {
                            from.setMinutes(from.getMinutes() + earlyMinutes);
                            to.setMinutes(to.getMinutes() - nightMinutes);
                        }

                        if ((minutesNormal - minutesNormalFirst) > 0) {
                            const minutes = minutesNormal - minutesNormalFirst;
                            let fromNormalSec = new Date(from);
                            if (minutesNormalFirst) {
                                fromNormalSec = new Date(to);
                                fromNormalSec.setMinutes(fromNormalSec.getMinutes() - minutes);
                            }
                            totals.push({
                                from: fromNormalSec,
                                to,
                                minutes,
                                type: TimeOnDay.Normal,
                                realisation
                            });
                        }
                    }
                });

            // slaapuren
            day.realisations
                .filter(r => [RealisationHourtype.sleep].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    const hourCode = day.date.getDay() > 0 && day.date.getDay() < 6 ? 'SD' : 'SDW';
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, hourCode, beginDate, endDate);
                    settlements.push(settlement);
                });


            //scholing
            const totalMinutesEducation = day.realisations
                .filter(r => [RealisationHourtype.education].includes(r.hourtype))
                .map(r => Utils.minuteDuration(r.enddate, r.begindate))
                .reduce((sum, current) => sum + current, 0);


            day.realisations
                .filter(r => [RealisationHourtype.education].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);

                    let settledMinutes = settlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0);
                    const tMinutes = settledMinutes + minutes;

                    let aboveContract = 0;
                    let normal = minutes;

                    if (tMinutes > day.fg.value.contractHours || day.fg.value.contractHours === 0) {
                        const minInContractLeft = Math.max(day.fg.value.contractHours - settledMinutes, 0);
                        aboveContract = minutes - minInContractLeft;
                        normal = minInContractLeft;
                    }

                    if (normal) {
                        settlements.push(this.createSettlementFromRealisation(realisation, normal, 'S', beginDate, endDate));
                    }
                    if (aboveContract) {
                        const hourCode = day.date.getDay() > 0 && day.date.getDay() < 6 ? 'S100' : 'S100W';
                        settlements.push(this.createSettlementFromRealisation(realisation, aboveContract, hourCode, beginDate, endDate));
                    }
                });


            let pauseToSettle = day.fg.value.pause;
            if (day.date.getDay() > 0 && day.date.getDay() < 6) {
                let settledMinutes = settlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0);
                settledMinutes = settledMinutes - pauseToSettle;
                totals.sort((a, b) => {
                    return Utils.getTimeOrNull(a.to) - Utils.getTimeOrNull(b.to);
                }).forEach(total => {
                    const tMinutes = settledMinutes + total.minutes;
                    let minutesFrom12Hours = 0;
                    let aboveContract = 0;
                    let normal = total.minutes;
                    if (tMinutes > day.fg.value.contractHours) {
                        const minInContractLeft = Math.max(day.fg.value.contractHours - settledMinutes, 0);
                        normal = minInContractLeft;
                        aboveContract = total.minutes - minInContractLeft;
                        if (caoBouwInfra) {
                            minutesFrom12Hours = Math.max(tMinutes - 660, 0);
                            aboveContract = aboveContract - minutesFrom12Hours;
                        }
                    }
                    if (normal) {
                        let hourType = '2 ';
                        if (total.type === TimeOnDay.Night) {
                            hourType = caoBouwInfra ? '130V' : '135V';
                            if (caoBouwInfra && day.date.getDay() === 5 && [total.to.getDay(), total.from.getDay()].includes(5)) {
                                hourType = '150Z';
                            }
                        }
                        if (total.type === TimeOnDay.Early) {
                            hourType = caoBouwInfra ? '130V' : '145V';
                            if (day.date.getDay() === 5 && new Date(total.to).getDay() === 6) {
                                hourType = '150Z';
                            }
                        }

                        const settlement = this.createSettlementFromRealisation(total.realisation, normal, hourType, total.from, total.to)
                        settlements.push(settlement);
                        if (hourType !== '2 ') {
                            const settl = this.createSettlementFromRealisation(total.realisation, normal, '2 ', total.from, total.to);
                            settl.bonus = settlement; // even bij pause eruit filteren hierop
                            settlement.normal = settl;
                            settlements.push(settl);
                        }
                    }
                    if (aboveContract) {
                        //6:00 - 8:00 check bij bouwInfra, dan 130 bij TimeOnDay.Normal
                        let totalMinutesHigh = 0;
                        if (total.type === TimeOnDay.Normal && caoBouwInfra) {
                            const calcFrom = new Date(total.from);
                            calcFrom.setMinutes(calcFrom.getMinutes() + (normal ?? 0));
                            const highOvertimeStart = Utils.setTime(new Date(total.from), 6, 0);
                            const highOVertimeEnd = Utils.setTime(new Date(total.from), 8, 0);
                            if (calcFrom.getTime() < highOVertimeEnd.getTime() && total.to.getTime() > highOvertimeStart.getTime()) {
                                totalMinutesHigh = Utils.minuteDuration(highOVertimeEnd, calcFrom);
                            }
                        }

                        let hourType = caoBouwInfra ? '125' : '130';
                        if (this.user.function === 'Uitvoerder') {
                            hourType = '100B';
                        }
                        if (total.type === TimeOnDay.Night) {
                            hourType = caoBouwInfra ? '130' : '135';

                            if (this.user.function !== 'Uitvoerder' && caoBouwInfra && total.from.getDay() === 5) {
                                hourType = '150';
                            }
                        }
                        if (total.type === TimeOnDay.Early) {
                            hourType = caoBouwInfra ? new Date(total.to).getDay() === 1 ? '200' : '130' : '145';
                            if (day.date.getDay() === 5 && new Date(total.to).getDay() === 6) {
                                hourType = '150';
                            }
                        }
                        if (totalMinutesHigh) {
                            let hourTypeHigh = new Date(total.to).getDay() === 1 ? '200' : '130';
                            settlements.push(this.createSettlementFromRealisation(total.realisation, totalMinutesHigh, hourTypeHigh, total.from, total.to));
                        }
                        if (aboveContract - totalMinutesHigh > 0) {
                            settlements.push(this.createSettlementFromRealisation(total.realisation, aboveContract - totalMinutesHigh, hourType, total.from, total.to));
                        }
                    }
                    if (minutesFrom12Hours) {
                        let hourType = '150';
                        if (this.user.function === 'Uitvoerder') {
                            hourType = total.type === TimeOnDay.Normal ? '100B' : '130';
                        }
                        settlements.push(this.createSettlementFromRealisation(total.realisation, minutesFrom12Hours, hourType, total.from, total.to));
                    }
                    settledMinutes += normal + aboveContract;
                });
            } else {
                totals.forEach(total => {
                    let hourType = total.from.getDay() === 0 ? '200Z' : '150Z';
                    hourType = day.date.getDay() === 0 ? '200Z' : hourType;
                    settlements.push(this.createSettlementFromRealisation(total.realisation, total.minutes, hourType, total.from, total.to));
                });
            }

            // pause verrekenen
            settlements
                .filter(s => !s.bonus && s.hourtype_code !== 'SD')
                .sort((a, b) => {
                    let aa = a.hourtype_code.indexOf('V') !== -1 ? `${(+a.hourtype_code.replace('V', '')) - 100}` : a.hourtype_code;
                    let bb = b.hourtype_code.indexOf('V') !== -1 ? `${(+b.hourtype_code.replace('V', '')) - 100}` : b.hourtype_code;
                    aa = aa.indexOf('S') !== -1 ? '1' : aa;
                    bb = bb.indexOf('S') !== -1 ? '1' : bb;
                    return aa.padStart(3, '0').localeCompare(bb.padStart(3, '0'));
                })
                .forEach((settlement) => {
                    if (pauseToSettle > 0) {
                        const subtract = Math.min(pauseToSettle, settlement.minutes);
                        settlement.minutes = settlement.minutes - subtract;
                        settlement.description = `${settlement.description} - ${(new MinutesPipe()).transform(subtract)} pauze`;
                        pauseToSettle -= subtract;
                        if (settlement.normal) {
                            settlement.normal.minutes = settlement.minutes;
                            settlement.normal.description = `${settlement.normal.description} - ${(new MinutesPipe()).transform(subtract)} pauze`;
                        }
                    }
                    settlement.bonus = null;
                    settlement.normal = null;
                });


            // vorst
            day.realisations
                .filter(r => [RealisationHourtype.frost].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, 'Vorst', beginDate, endDate);
                    settlements.push(settlement);
                });

            //ziek
            day.realisations
                .filter(r => [RealisationHourtype.illness].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, 'Z', beginDate, endDate);
                    settlements.push(settlement);
                });

            //vrij
            day.realisations
                .filter(r => [RealisationHourtype.day_off].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, 'V', beginDate, endDate);
                    settlements.push(settlement);
                });

            // als bouw CAO, aanvullen tot 8 uur
            if (caoBouwInfra && day.date.getDay() > 0 && day.date.getDay() < 6) {
                const totalMinutesDay = settlements.filter(s => ['2 ', 'V', 'Z', 'Vorst', 'S', 'SP', 'G', 'RV', 'SD'].includes(s.hourtype_code)).map(s => s.minutes).reduce((sum, current) => sum + current, 0);
                if (totalMinutesDay < minDayHours) {
                    const settlement = new Settlement();
                    settlement.user_id = this.user.id;
                    settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
                    settlement.minutes = minDayHours - totalMinutesDay;
                    settlement.hourtype_code = '2 ';
                    settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                    settlement.description = 'Aanvullen tot 8 uur (CAO)';
                    settlements.push(settlement);

                    const lastSettlement = settlements.map(s => {
                        s.realisation = this.realisations.find(r => r.id === s.realisation_id)
                        return s;
                    })
                        .filter(s => !!s.realisation && s.hourtype_code !== '2 ')
                        .filter(s => [RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(s.realisation.hourtype))
                        .sort((a, b) => Utils.getTimeOrNull(b.realisation.enddate) - Utils.getTimeOrNull(a.realisation.enddate))[0];
                    if (lastSettlement) {
                        const beginDate = new Date(lastSettlement.realisation.begindate);
                        const endDate = new Date(lastSettlement.realisation.enddate);
                        const startEarly = Utils.setTime(new Date(endDate), 0, 0);
                        const endEarly = Utils.setTime(new Date(endDate), 6, 0);
                        if (beginDate.getTime() < endEarly.getTime() && endDate.getTime() > startEarly.getTime()) {
                            const settlement = new Settlement();
                            settlement.user_id = this.user.id;
                            settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
                            settlement.minutes = minDayHours - totalMinutesDay;
                            settlement.hourtype_code = lastSettlement.hourtype_code;
                            settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                            settlement.description = 'Aanvullen tot 8 uur (CAO)';
                            settlements.push(settlement);
                        }
                    }
                }
            }

            //verblijfskostenvergoeding
            if (!caoBouwInfra) {
                const workSettlements = settlements.filter(s => [...this.contractHourTypeCodesWork, ...this.overtimeHourTypeCodes].includes(s.hourtype_code));
                const totalMinutesDay = workSettlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0) + day.fg.value.pause;
                if (totalMinutesDay > 240) {

                    const firstRealisation = day.realisations
                        .filter(r => [RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(r.hourtype))
                        .sort((a, b) => Utils.getTimeOrNull(a.enddate) - Utils.getTimeOrNull(b.enddate))[0];

                    let totalMinutesVBK01 = 0;
                    let totalMinutesVBK05 = 0;
                    let totalMinutesVBK10 = 0;

                    const realisations = day.realisations.filter(r => [RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(r.hourtype));
                    realisations.forEach(realisation => {
                        const beginDate = new Date(realisation.begindate);
                        const endDate = new Date(realisation.enddate);
                        let minutesVBK05 = 0;
                        if (new Date(firstRealisation.begindate).getHours() < 14) {
                            const datesToUse = [endDate];
                            if (beginDate.getDate() !== endDate.getDate()) {
                                datesToUse.push(beginDate);
                            }
                            datesToUse.forEach(dateToUse => {
                                const startVK05 = Utils.setTime(new Date(dateToUse), 18, 0);
                                const endVK05 = Utils.setTime(new Date(dateToUse), 24, 0);
                                if (beginDate.getTime() < endVK05.getTime() && endDate.getTime() > startVK05.getTime()) {
                                    const calcFrom = beginDate.getTime() < startVK05.getTime() ? startVK05 : beginDate;
                                    const calcUntil = endDate.getTime() > endVK05.getTime() ? endVK05 : endDate;
                                    minutesVBK05 = Utils.minuteDuration(calcUntil, calcFrom);
                                }
                                totalMinutesVBK05 += minutesVBK05;
                            });

                        }

                        totalMinutesVBK01 += Utils.minuteDuration(endDate, beginDate) - minutesVBK05;

                    });
                    if (new Date(firstRealisation.begindate).getHours() >= 14 && totalMinutesDay >= 720) {
                        totalMinutesVBK10 = 60;
                    }

                    if (totalMinutesVBK01) {
                        settlements.push(this.accommodationCostsSettlement(day, totalMinutesVBK01, 'VBK01'));
                    }
                    if (totalMinutesVBK05) {
                        settlements.push(this.accommodationCostsSettlement(day, totalMinutesVBK05, 'VBK05'));
                    }
                    if (totalMinutesVBK10) {
                        settlements.push(this.accommodationCostsSettlement(day, totalMinutesVBK10, 'VBK10'));
                    }

                }
            }


            // reistijd
            if (this.user.traveltime_workday === 'Staffel' && day.date.getDay() > 0 && day.date.getDay() < 6 && day.fg.value.hoursToReward > 0) {
                const userAdress = this.user.zipcode;
                const realisationStart = day.realisations.find(r => r.hourtype === RealisationHourtype.worktime);
                if (realisationStart) {
                    const origin = realisationStart?.user_planning?.origin ?? userAdress;
                    if (realisationStart.planning?.location && !/\w+/g.test(realisationStart.planning?.location)) {
                        realisationStart.planning.location = null;
                    }
                    const workBegin =
                        realisationStart?.user_planning?.work_begin ??
                        realisationStart?.planning_has?.transport_origin ??
                        realisationStart.planning?.location ??
                        realisationStart.project?.location;
                    if (origin && workBegin) {
                        const realisationEnd = [...day.realisations].reverse().find(r => r.hourtype === RealisationHourtype.worktime);
                        const destination = realisationEnd?.user_planning?.destination ?? userAdress;
                        const workEnd = realisationEnd.user_planning?.work_end ??
                            realisationEnd.planning_has?.transport_destination ??
                            realisationEnd.planning?.location ??
                            realisationEnd.project?.location;

                        const workBegin$ = this.locationService.autoRoute(origin, workBegin);
                        const workEnd$ = workEnd ? this.locationService.autoRoute(workEnd, destination) : of({data: {kms: 0, from: '', to: ''}});
                        combineLatest(workBegin$, workEnd$)
                            .subscribe(([travelTo, travelFrom]) => {
                                const highestResult = travelTo.data?.kms > travelFrom.data?.kms ? travelTo.data : travelFrom.data;
                                if (highestResult?.kms > 0) {
                                    const minutes = this.calculateTravelTimeFromStaffelKms(highestResult.kms);
                                    if (minutes) {
                                        settlements.push(this.travelSettlement(day, minutes, highestResult.from, highestResult.to, highestResult.kms));
                                    }
                                } else {
                                    let addresses = '';
                                    if (!travelTo.data) {
                                        addresses += origin + '<br>' + workBegin + '<br>';
                                    }
                                    if (!travelFrom.data) {
                                        addresses += workEnd + '<br>' + destination + '<br>';
                                    }
                                    let reference = '';
                                    if (realisationStart.user_planning) {
                                        reference = 'Personeelsplanning of ingepland personeel: #' + realisationStart.user_planning.id;
                                    } else if (realisationStart.planning_has) {
                                        reference = 'Transportplanning: #' + realisationStart.planning_has.id;
                                    } else if (realisationStart.planning?.location) {
                                        reference = 'Asfaltplanning: #' + realisationStart.planning.id;
                                    } else {
                                        reference = 'Project (via personeelsplanning): #' + realisationStart.planning.afas_project_id;
                                    }
                                    this.confirmDialogService.confirm('Kon geen kilometers berekenen',
                                        'Er lijkt iets mis te zijn met een van de volgende adressen:<br> ' + addresses +
                                        `<br><br>Datum: ${formatDate(realisationStart.begindate, 'yyyy-MM-dd HH:mm', 'nl')}` +
                                        `<br>Gebruiker: ${this.user.name} #${this.user.id}<br>` + reference,
                                        'Sluiten', null).then(() => {
                                    });
                                    console.error('No route for', {realisationStart, realisationEnd, origin, workBegin, workEnd, destination});
                                }

                                this.saveSettlements(day, settlements, () => {
                                    day.settling = false;
                                    resolve(true);
                                });
                            });
                    } else {
                        this.saveSettlements(day, settlements, () => {
                            day.settling = false;
                            resolve(true);
                        });
                    }
                } else {
                    this.saveSettlements(day, settlements, () => {
                        day.settling = false;
                        resolve(true);
                    });
                }
            } else {
                this.saveSettlements(day, settlements, () => {
                    day.settling = false;
                    resolve(true);
                });

            }
        });
    }

    private calculateTravelTimeFromStaffelKms(kms: number) {
        const oneHourMinutes = 60;
        if (['Balkman', 'Asfalt Medewerker', 'Voorman Asfalt', 'Machinist Asfalt'].includes(this.user.function)) {
            if ((kms >= 30 && kms < 51) || kms >= 106) {
                const refSpeed = 60; // op basis van 60km/h
                return oneHourMinutes * (kms / refSpeed);
            }
            if (kms >= 51 && kms < 60) {
                return oneHourMinutes;
            }
            if (kms >= 60 && kms < 71) {
                return oneHourMinutes * 1.2;
            }
            if (kms >= 71 && kms < 82) {
                return oneHourMinutes * 1.4;
            }
            if (kms >= 82 && kms < 93) {
                return oneHourMinutes * 1.5;
            }
            if (kms >= 93 && kms < 106) {
                return oneHourMinutes * 1.8;
            }
        } else {
            if (kms >= 125) {
                return oneHourMinutes * 2;
            }
            if (kms >= 75) {
                return oneHourMinutes;
            }
        }
        return 0;
    }

    private saveSettlements(day: WeekDay, settlements: Settlement[], savedFunc?: () => any) {
        day.settlements = settlements;
        this.settlementService.saveMulti(settlements, formatDate(day.date, 'yyyy-MM-dd', 'nl') as any, this.user.id).subscribe(result => {
            day.settlements = result.data;
            this.fcHourTypeMap.forEach(fcName => {
                day.fg.controls[fcName].reset();
            });
            this.settlementsToForm(day.settlements, day.fg);
            if (savedFunc) {
                savedFunc();
            }
        });
    }

    private travelSettlement(day: WeekDay, minutes, from, to, kms) {
        const settlement = new Settlement();
        settlement.user_id = this.user.id;
        settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
        settlement.minutes = minutes;
        settlement.hourtype_code = 'RU';
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        settlement.description = `Reisuren van ${from} naar ${to} (${Math.ceil(kms)} km)`;
        return settlement;
    }

    private accommodationCostsSettlement(day: WeekDay, minutes, hourtypeCode) {
        const settlement = new Settlement();
        settlement.user_id = this.user.id;
        settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
        settlement.minutes = minutes;
        settlement.hourtype_code = hourtypeCode;
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        settlement.description = `Verblijfskostenvergoeding`;
        return settlement;
    }

    private settlementsToForm(settlements: Settlement[], fg: FormGroup<ControlsOf<FgWeekDay>>) {
        settlements.forEach(settlement => {
            settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
            const fcName = this.fcHourTypeMap.get(settlement.hourtype_code);
            if (fcName) {
                const fc = fg.get(fcName) as FormControl<number>;
                let currentValue = fc.value ?? 0;
                currentValue += settlement.minutes;
                fc.setValue(currentValue);
            }
        });
        const remaining = fg.value.hoursToReward ? Math.max(fg.value.hoursToReward, fg.value.contractHours) - fg.value.contractHours : 0;
        fg.controls.remaining.setValue(remaining);
    }

    private parseData() {
        this.weeks = [];
        this.days = new FormArray([]);
        this.fcNamePeriodTotalMap = new Map<string, number>();
        this.daytypeCounts.forEach(dtc => {
            dtc.count = 0;
            dtc.minutes = 0;
        });
        let interval = 1;
        let week = new Week();
        const bookdate = new Date(this.beginDate);
        const caoBouwInfra = this.user.group !== 'CHAU';
        this.caoBouwInfra = caoBouwInfra;
        let contractHoursLeft = 0;
        while (bookdate.getTime() < this.endDate.getTime()) {

            if (interval === 4) {
                week.weeknumber = formatDate(bookdate, 'w', 'nl');
            }
            const realisations = this.realisations.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookdate, 'yyyy-MM-dd', 'nl'));

            let alreadyPaid = (this.user.contract_hours / 5) * 60;
            if (bookdate.getDay() === 6 || bookdate.getDay() === 0) {
                alreadyPaid = 0;
            }
            let totalMinutes = 0;
            let pause = 0;
            let beginDate = null;
            let endDate = null;

            realisations.forEach(realisation => {
                totalMinutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                pause += realisation.pause;
                beginDate = beginDate ? new Date(Math.min(Utils.getTimeOrNull(realisation.begindate), Utils.getTimeOrNull(beginDate))) : new Date(realisation.begindate);
                endDate = new Date(Math.max(Utils.getTimeOrNull(realisation.enddate), Utils.getTimeOrNull(endDate)));
                if (!endDate || endDate.getTime() < new Date(realisation.enddate).getTime()) {
                    endDate = new Date(realisation.enddate);
                }
            });

            const hoursToReward = totalMinutes - pause;

            const dayTypeMap = {
                [RealisationHourtype.travel_to]: 'Reistijd',
                [RealisationHourtype.travel_back]: 'Reistijd',
                [RealisationHourtype.worktime]: 'Werkdag',
                ['empty']: 'Weekend',
                [RealisationHourtype.illness]: 'Ziek',
                [RealisationHourtype.sleep]: 'Slaapdag',
                [RealisationHourtype.frost]: 'Vorst',
                [RealisationHourtype.education]: 'Scholing',
                [RealisationHourtype.day_off]: 'Verlof',
                [RealisationHourtype.driving_to]: 'Werkdag',
                [RealisationHourtype.driving_back]: 'Werkdag',
            };

            let dayType = '';
            Object.keys(dayTypeMap).forEach(hourType => {
                dayType = realisations.find(r => r.hourtype === hourType) ? dayTypeMap[hourType] : dayType;
                if (!this.daytypeCounts.find(dtc => dtc.type === dayTypeMap[hourType])) {
                    this.daytypeCounts.push({type: dayTypeMap[hourType], count: 0, minutes: 0});
                }
            });
            if (dayType === dayTypeMap[RealisationHourtype.worktime] && (bookdate.getDay() === 0 || bookdate.getDay() === 6)) {
                dayType = 'Weekend';
            }

            if (dayType?.length > 0) {
                this.daytypeCounts.find(x => x.type === dayType).count++;

                Object.keys(dayTypeMap).forEach(hourType => {
                    let minutesOfHourType = 0;
                    realisations.filter(r => r.hourtype === hourType).forEach(realisation => {
                        minutesOfHourType += Utils.minuteDuration(realisation.enddate, realisation.begindate) - realisation.pause;
                    });
                    let addToType = dayTypeMap[hourType];
                    if (hourType === RealisationHourtype.worktime && dayType === 'Weekend') {
                        addToType = dayType;
                    }
                    this.daytypeCounts.find(x => x.type === addToType).minutes += minutesOfHourType;
                });
            }

            const interruptions = Utils.minuteDuration(endDate, beginDate) - totalMinutes;

            const fg = new FormGroup<ControlsOf<ControlsOf<FgWeekDay>>>(
                {
                    dayType: new FormControl(dayType),
                    begindate: new FormControl(beginDate ? formatDate(beginDate, 'H:mm', 'nl') : null),
                    enddate: new FormControl(endDate ? formatDate(endDate, 'H:mm', 'nl') : null),
                    interruptions: new FormControl(interruptions === 0 ? null : interruptions),
                    hours: new FormControl(totalMinutes),
                    pause: new FormControl(pause),
                    hoursToReward: new FormControl(hoursToReward),
                    contractHours: new FormControl(alreadyPaid),
                    remaining: new FormControl(),

                    traveltime: new FormControl(),

                    normal: new FormControl(),
                    bonus30: new FormControl(),
                    bonus35: new FormControl(),
                    bonus45: new FormControl(),
                    bonus50: new FormControl(),

                    overtime100: new FormControl(),
                    overtime125: new FormControl(),
                    overtime130: new FormControl(),
                    overtime135: new FormControl(),
                    overtime140: new FormControl(),
                    overtime145: new FormControl(),
                    overtime150: new FormControl(),
                    overtime200: new FormControl()
                }
            );
            fg.disable();

            const weekDay = new WeekDay();
            weekDay.realisations = realisations;
            weekDay.settlements = [];
            const settlements = this.settlements.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookdate, 'yyyy-MM-dd', 'nl'));
            this.settlementsToForm(settlements, fg);
            weekDay.settlements.push(...settlements);
            weekDay.sendToAfas = !!weekDay.settlements.find(s => !!s.sent_to_afas);

            let weekdayCounted = false;
            const worktimeOnBookdate = !!weekDay.settlements.find(settlement => !!settlement.realisation && settlement.hourtype_code === '2 ');
            weekDay.settlements
                .filter(settlement => !settlement.realisation && settlement.hourtype_code === '2 ')
                .forEach(settlement => {
                    this.daytypeCounts.find(dtc => dtc.type === dayTypeMap[RealisationHourtype.worktime]).minutes += settlement.minutes;
                    if (!weekdayCounted && settlement.minutes > 0 && !worktimeOnBookdate) {
                        weekdayCounted = true;
                        this.daytypeCounts.find(dtc => dtc.type === dayTypeMap[RealisationHourtype.worktime]).count++;
                    }
                });

            weekDay.fg = fg;
            weekDay.date = new Date(bookdate);
            weekDay.week = week;
            weekDay.hasUnapprovedRealisations = realisations
                .filter(r => ((!!r.comment_user_approved && !r.comment_user_approved_handled) || !r.approved) && !r.removed)?.length > 0;
            this.days.push(fg);
            if (weekDay.sendToAfas) {
                week.sendToAfas = true;
            }
            week.weekDays.push(weekDay);
            bookdate.setUTCDate(bookdate.getUTCDate() + 1);
            interval++;

            if (interval > 7) {
                contractHoursLeft = 0;
                this.weeks.push(week);
                week = new Week();
                interval = 1;
            }
        }
        this.weeks.forEach(week => {
            week.weekDays.forEach(day => {
                this.userSubscriptions.add(day.fg.valueChanges.subscribe(values => {
                    this.calculateTotals(Object.keys(values));
                }));
            });
        });
        this.weeks[0].weekDays[0].fg.updateValueAndValidity();
        setTimeout(() => {
            document.body.appendChild(document.createElement('readyforpuppeteer'));
        });
    }

    calculateTotals(formControlKeys) {
        let totalOvertime = 0;
        for (const key of formControlKeys) {
            const total = this.weeks.flatMap(w => w.weekDays.flatMap(weekDay => weekDay.fg.controls[key].value)).reduce((sum, current) => sum + current, 0);
            this.fcNamePeriodTotalMap.set(key, total);
            if (key.indexOf('overtime') !== -1) {
                totalOvertime += total;
            }
        }
        this.fcNamePeriodTotalMap.set('overtime', totalOvertime);
    }
}

class Week {
    weeknumber: string;
    weekDays: WeekDay[] = [];
    settling = false;
    sendToAfas = false;
}

class WeekDay {
    date: Date;
    type: string;
    fg: FormGroup<ControlsOf<ControlsOf<FgWeekDay>>>;
    realisations: Realisation[];
    settlements: Settlement[];
    sendToAfas: boolean;
    hasUnapprovedRealisations: boolean;
    settling = false;
    week: Week;
}

class FgWeekDay {
    dayType: string;
    begindate: string;
    enddate: string;
    interruptions: number;
    hours: number;
    pause: number;
    hoursToReward: number;
    contractHours: number;
    remaining: number

    traveltime: number;

    normal: number;
    bonus30: number;
    bonus35: number;
    bonus45: number;
    bonus50: number;

    overtime100: number;
    overtime125: number;
    overtime130: number;
    overtime135: number;
    overtime140: number;
    overtime145: number;
    overtime150: number;
    overtime200: number;

}

class timeTypeRealisationSettle {
    realisation: Realisation;
    minutes: number;
    from: Date;
    to: Date;
    type: TimeOnDay;
}

enum TimeOnDay {
    Normal, Night, Early
}
