<div>
    <div class="d-flex align-items-center" mat-dialog-title>
        <div class="spacer title">Asfalt</div>
        <div>
            <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
        </div>
    </div>

    <div class="pb-3">
        <button (click)="openImportDialog()" color="primary" mat-raised-button>Importeren</button>
        <button (click)="export()" class="ml-2" color="primary" mat-raised-button>Template downloaden</button>
    </div>
</div>
<div class="dialog-content">
    <div class="d-flex justify-content-center align-content-center">
        <h3 class="spacer">
            Asfaltsoorten per molen
        </h3>
    </div>
    <table class="table table-hover table-last-col">
        <tr>
            <th>
                Molen
            </th>
            <th>
                Soort
            </th>
            <th>
                Centrale Code
            </th>
            <th></th>
        </tr>
        <ng-container *ngFor="let mill of asphaltMills">
            <tr *ngFor="let asphalt of mill.asphalt; let i = index;">
                <td>
                    {{mill.name}}
                </td>
                <td>
                    {{asphalt.asphalt?.name}}
                </td>
                <td>
                    {{asphalt.code}}
                </td>
                <td>
                    <button (click)="deleteAsfaltMillAsphalt(asphalt)" class="delete-button" color="warn" mat-icon-button>
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-container>
    </table>
</div>
