import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Planning} from '../../classes/planning.class';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Utils} from '../../utils.class';
import {Validators} from '@angular/forms';
import {EntitiesService} from '../../services/entities/entities.service';
import {Entity} from '../../classes/entity.class';
import {PlanningService} from '../../services/planning/planning.service';
import {EntityType} from '../../services/entities/entity-type.class';
import {UserPlanning} from '../../classes/user-planning';
import {entityAvailableValidator} from '../../validators/entity-available.validator';
import {localEntityAvailableEquipmentValidator} from '../../validators/local-entity-available-equipment.validator';

@Component({
    selector: 'app-equipment-dialog',
    templateUrl: './equipment-dialog.component.html',
    styleUrls: ['./equipment-dialog.component.scss']
})
export class EquipmentDialogComponent implements OnInit, OnDestroy {

    saving: boolean;
    form: FormArray<ControlsOf<FormGroupPlanningEquipment>>;
    entities: Entity[];
    entitiesMap: Map<number, Entity>;
    entityTypeMap: Map<number, EntityType>;
    filteredEntities: Entity[];
    sums: { entitytypeId: number; amount: number; }[] = [];
    fcEntity = new FormControl();
    planning: Planning;
    private subscriptions = new Subscription();

    constructor(public dialogRef: MatDialogRef<EquipmentDialogComponent>,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                @Inject(MAT_DIALOG_DATA) data: {
                    planning: Planning
                },
                private planningService: PlanningService,
                private entitiesService: EntitiesService) {
        this.planning = data.planning;

        this.subscriptions.add(this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                this.close();
            }
        }));
    }

    ngOnInit(): void {
        this.form = new FormArray([]);

        this.subscriptions.add(this.entitiesService.getMap().subscribe(entitiesMap => {
            this.entitiesMap = entitiesMap;
        }));
        this.subscriptions.add(this.entitiesService.getList().subscribe(entities => {
            this.entities = entities.filter(e => !!e.entitytypes.find(e => e.visible_small));
            this.filterEmployees();
        }));

        this.subscriptions.add(this.entitiesService.getTypesMap().subscribe(entitytypeMap => {
            this.entityTypeMap = entitytypeMap;
            this.planning.planning_has.filter(p => entitytypeMap.get(p.entitytype_id).visible_small).forEach(planningHas => {
                const fg = new FormGroup<ControlsOf<FormGroupPlanningEquipment>>({
                    id: new FormControl(planningHas.id),
                    entitytype_id: new FormControl(planningHas.entitytype_id),
                    entity_id: new FormControl(planningHas.entity_id),
                    planning_id: new FormControl(planningHas.planning_id),
                    begindate: new FormControl(planningHas.begindate),
                    enddate: new FormControl(planningHas.enddate),
                    conflictText: new FormControl(''),
                    comment: new FormControl(planningHas.comment),
                    updated_at: new FormControl(planningHas.updated_at)
                }, [], [
                    localEntityAvailableEquipmentValidator(this.planningService, this.entitiesMap),
                    entityAvailableValidator(this.planningService)
                ]);
                this.subscriptions.add(fg.controls.begindate.valueChanges.subscribe(() => {
                    fg.controls.enddate.updateValueAndValidity();
                }));
                this.subscriptions.add(fg.controls.enddate.valueChanges.subscribe(enddate => {
                    const begindate = new Date(fg.controls.begindate.value);
                    enddate = new Date(enddate);
                    begindate.setSeconds(0);
                    const endTime = new Date(begindate);
                    endTime.setHours(enddate.getHours());
                    endTime.setMinutes(enddate.getMinutes());

                    if (endTime < begindate && enddate.getUTCDate() !== begindate.getUTCDate() + 1) {
                        enddate.setUTCDate(enddate.getUTCDate() + 1);
                        fg.controls.enddate.setValue(enddate, {emitEvent: false, onlySelf: true});
                    }
                    if (endTime > begindate && enddate.getUTCDate() !== begindate.getUTCDate()) {
                        enddate.setUTCDate(begindate.getUTCDate());
                        fg.controls.enddate.setValue(enddate, {emitEvent: false, onlySelf: true});
                    }
                }));
                this.form.push(fg);
            });
            this.calculateSums();
        }));
        setTimeout(() => {
            (document.querySelector('#close-empl') as HTMLElement).focus();
        }, 400);

    }

    filterEmployees() {
        const currentEmployees = this.form.value.map(e => e.entity_id);
        this.filteredEntities = this.entities?.filter(e => currentEmployees.indexOf(e.id) === -1);
    }

    calculateSums() {
        this.filterEmployees();
        this.sums = [];
        this.form.value.forEach(entity => {
            const sum = this.sums.find(p => p.entitytypeId == entity.entitytype_id);
            if (sum) {
                sum.amount++;
            } else {
                this.sums.push({
                    entitytypeId: entity.entitytype_id,
                    amount: 1
                });
            }
        });
    }

    addEquipment(entityId: number) {
        const fg = new FormGroup<ControlsOf<FormGroupPlanningEquipment>>({
            id: new FormControl(),
            entity_id: new FormControl(entityId),
            entitytype_id: new FormControl(this.entitiesMap.get(entityId).entitytypes[0].id),
            planning_id: new FormControl(this.planning.id),
            begindate: new FormControl(Utils.mainPlanning(this.planning).begindate, Validators.required),
            enddate: new FormControl(Utils.mainPlanning(this.planning).enddate, Validators.required),
            conflictText: new FormControl(),
            comment: new FormControl(),
            updated_at: new FormControl()
        }, [], [
            localEntityAvailableEquipmentValidator(this.planningService, this.entitiesMap),
            entityAvailableValidator(this.planningService)
        ]);
        this.fcEntity.reset();
        this.form.push(fg);
        this.calculateSums();
    }

    delete(formgroup: FormGroup<ControlsOf<FormGroupPlanningEquipment>>) {
        this.confirmDialog.confirm('Verwijderen',
            `Weet u zeker dat u ${this.entitiesMap.get(formgroup.value.entity_id).name} wilt verwijderen van de planning?`, 'Ja').then(() => {
            this.form.controls.splice(this.form.controls.indexOf(formgroup), 1);
            this.calculateSums();
        }, () => {

        });
    }

    save() {
        this.saving = true;
        Utils.triggerValidationP(this.form).then(() => {
            this.planningService.saveMultiple(this.form.value as any as UserPlanning, this.planning.id).subscribe(() => {
                this.saving = false;
                this.dialogRef.close();
            }, () => {
                this.saving = false;
            });
        }, () => {
            this.saving = false;
        });
    }

    close() {
        if (this.form.dirty) {
            this.confirmDialog.confirm('Niet opgeslagen wijzigingen',
                `Er zijn niet opgeslagen wijzigingen, wilt u deze alsnog opslaan?`, 'Opslaan', 'Niet opslaan').then(() => {
                this.save();
            }, () => {
                this.dialogRef.close();
            });
        } else {
            this.dialogRef.close();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}

export interface FormGroupPlanningEquipment {
    id: number;
    updated_at: FormControl<Date>;
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    entitytype_id: number;
    entity_id: number;
    planning_id: number;
    comment: string;
    conflictText: string;
}
