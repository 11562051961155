import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'valueInArray'
})
export class ValueInArrayPipe implements PipeTransform {

    transform(value: any, list: any[]): unknown {
        return list.includes(value);
    }

}
