import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LocalStorage} from './storage.class';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';

@Injectable()
export class CodaltInterceptor implements HttpInterceptor {

    public static loadingCount = 0;

    constructor(private confirmDialogService: ConfirmDialogService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + LocalStorage.getUserToken(),
                Accept: 'application/json'
            }
        });

        return next.handle(request).pipe(tap((response) => {
            if (response.type === HttpEventType.Sent) {
                setTimeout(() => {
                    CodaltInterceptor.loadingCount++;
                });
            }
            if (response.type === HttpEventType.Response) {
                setTimeout(() => {
                    CodaltInterceptor.loadingCount--;
                });
            }
        }, (error) => {
            if (error.status === 500 && error.error.message !== 'Too Many Attempts.') {
                let errorMessage = '';
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                }
                this.confirmDialogService.confirm('Er is een fout opgetreden', (errorMessage ? '' : error.message) + '<br><br>' + errorMessage, 'Sluiten', null);
            }
            setTimeout(() => {
                CodaltInterceptor.loadingCount--;
            });
        }));
    }
}



