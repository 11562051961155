<table [class.disabled]="formsDisabled" [class.table-last-col]="!formsDisabled" *ngIf="!formsDisabled" class="table table-center table-add-row">
    <tr>
        <th colspan="3">Type</th>
        <th>Ontvangst</th>
        <th *ngIf="UserType.WORKPLANNER | hasUserGroup">Machinist</th>
        <th [matTooltip]="'cutter-tons' | tt" class="number-input">Ton</th>
        <th class="number-input">Dikte <small>mm</small></th>
        <th [matTooltip]="'trucks-count' | tt" class="number-input">Vrachtwagens</th>
        <th [matTooltip]="'cutter-time' | tt" class="tabledate">Datum</th>
        <th [matTooltip]="'cutter-time' | tt" class="tabletime">Tijdsblok</th>
        <th class="comment">Opmerking</th>
        <th *ngIf="!formsDisabled"></th>
    </tr>
    <tr *ngFor="let planning of planningEntitiesList" [formGroup]="planningEntitiesForm.get(planning)">
        <td [attr.colspan]="!planning.entity || planning.entity.use_once ? '3': '1'"
            [class.use-multiple]="planning.entity && !planning.entity.use_once"
            [matTooltip]="planning?.entity?.name"
            class="cutter-selection">
            <div class="d-flex">

                <mat-select formControlName="entity_id" placeholder="Frees">
                    <mat-option *ngFor="let cutter of entities"
                                [disabled]="cutter.notAvailable || (cutter.use_once && cutter.usedHere && cutter.id !== planningEntitiesForm.get(planning).get('entity_id').value)"
                                [matTooltip]="'cutter-na' + (cutter.notAvailable ? '-na' : cutter.usedHere && cutter.id !== planningEntitiesForm.get(planning).get('entity_id').value ? '-here' : '') | tt"
                                [value]="cutter.id">
                        {{cutter.name}}
                    </mat-option>
                </mat-select>
            </div>
        </td>
        <td *ngIf="planning.entity && !planning.entity.use_once" class="cutter-selection">
            <div class="d-flex">
                <mat-select formControlName="hiring_id" placeholder="Inhuur">
                    <mat-option *ngFor="let hiring of hiringList" [value]="hiring.id">
                        {{hiring.name}}
                    </mat-option>
                </mat-select>
            </div>
        </td>
        <td *ngIf="planning.entity && !planning.entity.use_once" class="text-input hire-name">
            <input autocomplete="off" formControlName="name" matInput placeholder="Naam" type="text">
        </td>
        <td [matTooltip]="planning?.location" class="text-input">
            <input autocomplete="off" formControlName="location" matInput placeholder="Locatie" type="text">
        </td>
        <td *ngIf="UserType.WORKPLANNER | hasUserGroup"
            [matTooltip]="planning.driver_user_id ? (drivers | byId: planning.driver_user_id)?.name : planning?.driver"
            class="select driver">
            <ng-select
                placeholder="Chauffeur"
                formControlName="driver_user_id">
                <ng-option [value]="user.id" *ngFor="let user of drivers" [innerHTML]="user.name"></ng-option>
            </ng-select>
        </td>
        <td class="number-input">
            <input appDecimalInput autocomplete="off" formControlName="tons" matInput placeholder="0" type="text">
        </td>
        <td class="number-input">
            <input appDecimalInput autocomplete="off" autocomplete="off" formControlName="thickness" matInput placeholder="0" type="text">
        </td>
        <td [class.warning]="+planning.trucks > availableTrucks.get(planning) - maxUsedTrucks.get(planning) + (+planning.trucks || 0)"
            [matTooltip]="'trucks-count' | tt"
            class="number-input">
            <div class="available-trucks">
                {{availableTrucks.get(planning) - maxUsedTrucks.get(planning) + (+planning.trucks || 0)}}
            </div>
            <input appDecimalInput autocomplete="off" formControlName="trucks" matInput placeholder="0" type="text">
        </td>
        <ng-container formGroupName="date">
            <td>
                <input (focus)="pickerStart.open()"
                       (click)="pickerStart.open()"
                       [matDatepicker]="pickerStart"
                       formControlName="date"
                       matInput
                       [max]="mainPlanning.enddate"
                       [min]="minDatePicker"
                       placeholder="Datum">
                <mat-datepicker #pickerStart></mat-datepicker>
            </td>
            <td [class.error-background]="planningEntitiesForm.get(planning).errors && planningEntitiesForm.get(planning).errors.notAvailable"
                [matTooltip]="'cutter-time' + (planningEntitiesForm.get(planning).get('entity_id').value ? '' : '-disabled') | tt"
                class="tabletime">
                <div class="timepicker">
                    <mat-select formControlName="begintime" placeholder="van">
                        <mat-select-trigger>
                            <small>{{planning.begindate | date : 'EEE'}}</small> {{planning.begindate | date : 'HH:mm'}}
                        </mat-select-trigger>
                        <ng-container *ngIf="planningEntitiesForm.get(planning).get('entity_id').value">
                            <mat-optgroup
                                *ngFor="let day of startTimesEntity.get(planning)"
                                label="{{day.datetime | date : 'EEEE d MMM'}}">
                                <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                    {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                    <span>-</span>
                    <mat-select formControlName="endtime" placeholder="tot">
                        <mat-select-trigger>
                            <small>{{planning.enddate | date : 'EEE'}}</small> {{planning.enddate | date : 'HH:mm'}}
                        </mat-select-trigger>
                        <ng-container *ngIf="endTimesEntity.has(planning)">
                            <mat-optgroup
                                *ngFor="let day of endTimesEntity.get(planning)"
                                label="{{day.datetime | date : 'EEEE d MMM'}}">
                                <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                    {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                </div>
            </td>
        </ng-container>

        <td [matTooltip]="planning?.comment" class="text-input">
            <input autocomplete="off" formControlName="comment" matInput maxlength="300" placeholder="Opmerking" type="text">
        </td>
        <td *ngIf="!formsDisabled">
            <button (click)="removeEntityPlanning(planning)"
                    class="delete-button"
                    color="warn"
                    mat-icon-button
                    matTooltip="Verwijder frees"
                    matTooltipClass="warn-background"
                    matTooltipPosition="right"
                    tabindex="-1">
                <i class="fas fa-trash"></i>
            </button>
        </td>
    </tr>
    <tr [hidden]="formsDisabled">
        <td [colSpan]="(UserType.WORKPLANNER | hasUserGroup) ? 11 : 10">
            <button (click)="addEntityPlanning()" *ngIf="!addButtonDisabled" class="w-100"
                    color="primary" mat-button>
                Frees toevoegen <i class="fas fa-plus"></i>
            </button>
            <div *ngIf="addButtonDisabled" [class.warn-color]="warnIconLightUp" class="p-2">
                <i class="fas fa-exclamation-triangle"></i> Er zijn geen frezen beschikbaar
            </div>
        </td>
        <td>
        </td>
    </tr>
</table>
<table class="table table-center" *ngIf="formsDisabled">
    <tr>
        <th>Type</th>
        <th>Ontvangst</th>
        <th>Machinist</th>
        <th class="number-input">Ton</th>
        <th class="number-input">Dikte <small>mm</small></th>
        <th class="number-input">Vrachtwagens</th>
        <th class="tabletime">Tijdsblok</th>
        <th>Opmerking</th>
    </tr>
    <tr *ngFor="let cutter of planning.planning_cutters">
        <td *ngIf="(entities | byId:cutter.entity_id)?.use_once">
            {{(entities | byId:cutter.entity_id)?.name}}
        </td>
        <td *ngIf="!(entities | byId:cutter.entity_id)?.use_once">
            {{cutter.name}}
        </td>
        <td>{{cutter.location}}</td>
        <td>{{cutter.driver}}</td>
        <td>{{cutter.tons}}</td>
        <td>{{cutter.thickness}}</td>
        <td>{{cutter.trucks}}</td>
        <td>{{cutter.begindate | date:'H:mm'}} - {{cutter.enddate | date:'H:mm'}}</td>
        <td>{{cutter.comment}}</td>
    </tr>
</table>
