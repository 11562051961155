import {Component, OnInit} from '@angular/core';
import {PlanningCutter} from '../../../classes/planningcutter.class';
import {EntitiesService} from '../../../services/entities/entities.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {entityAvailableValidator} from '../../../validators/entity-available.validator';
import {PlanningService} from '../../../services/planning/planning.service';
import {EntitiesListAbstractComponent} from '../entities-list-abstract.component';
import {EntityUnavailableService} from '../../../services/entities/entity-unavailable.service';
import {Subscription} from 'rxjs';
import {Utils} from '../../../utils.class';
import {EntityTypeCode} from '../../../services/entities/entity-type.class';
import {debounceTime} from 'rxjs/operators';
import {RequiredRule} from '../entities-list-abstract-component.required-rules';
import {DateAdapter} from '@angular/material/core';
import {DateSmallAdapter} from '../../../date-adapters/date-small-adapter';
import {HiringService} from '../../../services/hiring.service';
import {disabledWhenFinal} from '../planning-detail-dialog.required-rules';
import {User} from '../../../classes/user.class';
import {UserService, UserType} from '../../../services/user/user.service';
import {localUserAvailableValidator} from '../../../validators/local-user-available.validator';
import {PlanningHasEntity} from '../../../classes/planning-has-entity.class';
import {ProjectService} from '../../../services/project.service';

@Component({
    selector: 'app-cutter-list',
    templateUrl: './cutter-list.component.html',
    styleUrls: ['./cutter-list.component.scss'],
    providers: [{
        provide: DateAdapter, useClass: DateSmallAdapter
    }]
})
export class CutterListComponent extends EntitiesListAbstractComponent<PlanningCutter> implements OnInit {

    public truckEntitySubscriptions = new Subscription();
    public maxUsedTrucks = new Map<PlanningCutter, number>();
    public availableTrucks = new Map<PlanningCutter, number>();
    drivers: User[];

    constructor(protected entitiesService: EntitiesService,
                protected entityUnavailableService: EntityUnavailableService,
                private userService: UserService,
                protected hiringService: HiringService,
                protected planningService: PlanningService,
                protected projectService: ProjectService) {
        super(
            PlanningCutter,
            EntityTypeCode.Cutter,
            entityUnavailableService,
            entitiesService,
            hiringService,
            planningService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.trucks();

        if (UserService.userHasRights(UserType.WORKPLANNER)) {
            this.userService.getByType(UserType.TRUCK_DRIVER).subscribe(drivers => {
                this.drivers = drivers;
            });
        }
    }

    removeEntityPlanning(planning: PlanningCutter) {
        super.removeEntityPlanning(planning);
        this.trucks();
    }

    trucks() {
        if (!this.planning) {
            return;
        }

        this.truckEntitySubscriptions.unsubscribe();
        this.truckEntitySubscriptions = new Subscription();

        this.planningEntitiesList.forEach(cutter => {
            if (cutter.begindate && cutter.enddate) {
                const beginDate = Utils.newDate(cutter.begindate);
                const endDate = Utils.newDate(cutter.enddate);

                this.truckEntitySubscriptions.add(this.planningService.getMaxUsedTrucksForRange(beginDate, endDate, cutter.planning_id, this.planning)
                    .subscribe(maxUsedTrucks => {
                        this.maxUsedTrucks.set(cutter, maxUsedTrucks);
                    }));
                this.truckEntitySubscriptions.add(this.entityUnavailableService.getTrucksAvailableForRange(beginDate, endDate)
                    .subscribe(availableTrucks => {
                        this.availableTrucks.set(cutter, availableTrucks);
                    }));
            } else {
                this.maxUsedTrucks.set(cutter, 0);
                this.availableTrucks.set(cutter, 0);
            }
        });
    }

    addEntityPlanning() {
        super.addEntityPlanning();
        this.trucks();
    }

    protected createForm(planningCutter: PlanningCutter): FormGroup {
        const fgDriverUserId = new FormControl(
            planningCutter.driver_user_id,
            {
                asyncValidators: localUserAvailableValidator(
                    planningCutter as PlanningHasEntity,
                    this.planningService,
                    this.projectService,
                    !UserService.userHasRights(UserType.WORKPLANNER)
                )
            }
        );

        const formGroup = new FormGroup({
            entity_id: new FormControl({
                value: planningCutter.entity_id,
                disabled: disabledWhenFinal(this) && !!planningCutter.id
            }, Validators.required),
            hiring_id: new FormControl(planningCutter.hiring_id, RequiredRule(this, planningCutter)),
            name: new FormControl(planningCutter.name),
            location: new FormControl(planningCutter.location, Validators.required),
            driver_user_id: fgDriverUserId,
            comment: new FormControl(planningCutter.comment, Validators.maxLength(300)),
            tons: new FormControl(planningCutter.tons, Validators.required),
            thickness: new FormControl(planningCutter.thickness, Validators.required),
            trucks: new FormControl(planningCutter.trucks, Validators.required),
            date: new FormGroup({
                date: new FormControl({
                    value: planningCutter.begindate,
                    disabled: disabledWhenFinal(this) && !!planningCutter.id
                }, RequiredRule(this)),
                begintime: new FormControl({
                    value: Utils.getTimeOrNull(planningCutter.begindate),
                    disabled: disabledWhenFinal(this) && !!planningCutter.id
                }, RequiredRule(this)),
                endtime: new FormControl({
                    value: Utils.getTimeOrNull(planningCutter.enddate),
                    disabled: disabledWhenFinal(this) && !!planningCutter.id
                }, RequiredRule(this))
            })
        }, [], [entityAvailableValidator(this.planningService, planningCutter)]);
        this.subscriptions.add(formGroup.statusChanges.pipe(debounceTime(25)).subscribe(() => {
            for (const [key, value] of Object.entries(formGroup.value)) {
                if (typeof value === 'object' && value && key === 'date') {
                    this.convertFormDate(planningCutter, value);
                } else {
                    if (formGroup.get(key).valid) {
                        planningCutter[key] = value;
                    } else if (!formGroup.disabled) {
                        console.error(planningCutter.id, value, key, planningCutter[key], planningCutter.id);
                    }
                }
            }
            this.trucks();
            this.checkAddPossibility();
        }));

        this.subscriptions.add(formGroup.get('date').get('date').valueChanges.pipe(debounceTime(25)).subscribe(value => {
            const oldDate = new Date(planningCutter.begindate);
            const newDate = new Date(value);
            Utils.setTime(oldDate, 0, 0);
            Utils.setTime(newDate, 0, 0);
            const diffDays = Math.round(((newDate.getTime() - oldDate.getTime()) / 36e5) / 24);
            if (diffDays) {
                const newBeginDate = new Date(planningCutter.begindate);
                newBeginDate.setDate(newBeginDate.getDate() + diffDays);
                const newEndDate = new Date(planningCutter.enddate);
                newEndDate.setDate(newEndDate.getDate() + diffDays);
                planningCutter.begindate = newBeginDate;
                planningCutter.enddate = newEndDate;
                formGroup.get('date').get('endtime').setValue(newEndDate.getTime());
                formGroup.get('date').get('begintime').setValue(newBeginDate.getTime());
            }
        }));

        this.subscriptions.add(formGroup.get('date').valueChanges.pipe(debounceTime(25)).subscribe(value => {
            this.convertFormDate(planningCutter, value);
            this.planningEntitiesList.forEach(planning => {
                this.genStartTimesMap(planning);
                if (planning.begindate) {
                    this.genEndTimesMap(planning);
                }
            });
        }));
        this.subscriptions.add(formGroup.get('entity_id').valueChanges.pipe(debounceTime(25)).subscribe(value => {
            planningCutter.entity_id = value;
            if (planningCutter.entity_id) {
                planningCutter.entity = this.entities?.filter(p => p.id === planningCutter.entity_id)[0];
            } else {
                planningCutter.entity = null;
            }
            this.genStartTimesMap(planningCutter);
            if (planningCutter.begindate) {
                this.genEndTimesMap(planningCutter);
            }
        }));
        if (this.formsDisabled) {
            setTimeout(() => {
                formGroup.disable();
            });
        }
        return formGroup;
    }

}
