import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RealisationService} from '../services/realisation.service';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {Planning} from '../classes/planning.class';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../utils.class';
import {CommentDialogComponent} from '../hour-overview/comment-dialog/comment-dialog.component';
import {TimePickerService} from '../services/time-picker.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PlanningService} from '../services/planning/planning.service';
import {UserService, UserType} from '../services/user/user.service';
import {NotEditablePipe} from './not-editable.pipe';
import {debounceTime} from 'rxjs/operators';
import {AddRealisationDialogComponent} from './add-realisation-dialog/add-realisation-dialog.component';
import {Project} from '../classes/project.class';
import {ProjectService} from '../services/project.service';
import {LocalStorage} from '../storage.class';
import {Subscription} from 'rxjs';
import {CheckHoursFilterPipe} from './FunctionFilter.pipe';

@Component({
    selector: 'app-hours-check',
    templateUrl: './hours-check.component.html',
    styleUrls: ['./hours-check.component.scss']
})
export class HoursCheckComponent extends CodaltComponent implements OnInit {

    realisations: Realisation[];
    RealisationHourtype = RealisationHourtype;
    date: Date;
    form: FormArray<ControlsOf<FgRealisation>>;
    formMap: Map<Realisation, FormGroup<ControlsOf<FgRealisation>>>;
    planningRealisationsList: PlanningRealisations[];
    allowEdit = true;
    disableNext = false;
    fcHideApproved = new FormControl(false);

    userPauseMap: Map<number, number>;
    userMinutesMap: Map<number, number>;
    entityMinutesMap: Map<number, number>;
    changedPauseUserId: number;

    saving = false;
    saved = false;

    lastReload = new Date().getTime();

    highlight?: number;

    userFunctions: string[];

    filteredEmployees = 0;
    totalEmployees = 0;

    fcUserFunction = new FormControl<string[]>(LocalStorage.userFunctionFilter);
    fcHourType = new FormControl<string[]>(LocalStorage.hourTypeFilter);

    daySubscriptions: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
                private timePickerService: TimePickerService,
                private dialog: MatDialog,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private userService: UserService,
                private planningService: PlanningService,
                private projectService: ProjectService,
                private realisationService: RealisationService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.fcHourType.valueChanges.subscribe(hourtypes => {
            LocalStorage.hourTypeFilter = hourtypes;
            this.calcFilteredEmployees();
        }));

        this.subscriptions.add(this.fcUserFunction.valueChanges.subscribe(functions => {
            LocalStorage.userFunctionFilter = functions;
            this.calcFilteredEmployees();
        }));
        this.subscriptions.add(this.fcHideApproved.valueChanges.subscribe(() => {
            this.calcFilteredEmployees();
        }));
        this.subscriptions.add(this.userService.userFunctions(false).subscribe(functions => {
            this.userFunctions = functions.data;
        }));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string }) => {
            this.daySubscriptions?.unsubscribe();
            this.daySubscriptions = new Subscription();
            const today = new Date();
            let paramDate = params.date;
            if (this.maxDate(1) < new Date(params.date)) {
                paramDate = null;
            }
            if (this.maxDate() < new Date(params.date)) {
                this.disableNext = true;
            }
            this.date = paramDate ? new Date(paramDate) : new Date(formatDate(today, 'yyyy-MM-dd', 'nl'));

            this.getData();
        }));

        window.onfocus = () => {
            if ((new Date().getTime() - this.lastReload) > 300000 && !this.form.dirty && !this.saving) {
                this.lastReload = new Date().getTime();
                this.getData();
            }
        };
    }

    calcFilteredEmployees() {
        this.filteredEmployees = 0;
        this.totalEmployees = 0;
        this.planningRealisationsList.forEach(planningRealisation => {
            this.totalEmployees += planningRealisation.userRealisations.length;

            this.filteredEmployees += (new CheckHoursFilterPipe).transform(planningRealisation.userRealisations, this.fcUserFunction.value, this.fcHourType.value, this.fcHideApproved.value).length;
        });
    }

    private getData(scrollTo?: number) {
        this.planningRealisationsList = null;
        this.daySubscriptions.add(this.realisationService.getScheduleDay(this.date).subscribe(realisations => {
            this.realisations = realisations.data;
            this.form = new FormArray<ControlsOf<FgRealisation>>([]);
            this.formMap = new Map<Realisation, FormGroup<ControlsOf<FgRealisation>>>();
            const planningRealisationsList = [] as PlanningRealisations[];
            const afasProjectIds = [...new Set(realisations.data.map(Utils.realisationPlanningId) || [])];
            const hasGenHourCheck = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK);
            afasProjectIds.forEach(afasProjectId => {
                const planning = realisations.data.find(r => r.planning?.afas_project_id === afasProjectId)?.planning;
                const userPlanning = realisations.data.find(r => r.user_planning?.afas_project_id === afasProjectId)?.user_planning;
                const project = planning?.project ?? realisations.data.find(r => r.afas_project_id === afasProjectId)?.project ?? userPlanning?.project;

                const planningRealisations = realisations.data
                    .filter(r => Utils.realisationPlanningId(r) === afasProjectId)
                    // Only show driving hours when GENERAL_HOUR_CHECK
                    .filter(r => hasGenHourCheck || ![RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(r.hourtype))
                    // Hide realisations removed by GENERAL_HOUR_CHECK
                    .filter(r => hasGenHourCheck || !r.removed || r.comment_manager?.length > 0)
                    // Filter is_team entities, skip realisations without planning_has
                    .filter(r => !r.planning_has?.entitytype?.is_team || !r.planning_has_entity_id)
                    .sort((a, b) => {
                        const beginDate = (r: Realisation) => Utils.getTimeOrNull(r.planning_has?.begindate ?? r.user_planning?.begindate ?? r.begindate);
                        return beginDate(a) - beginDate(b);
                    }).sort((a, b) => (a.user ?? a.entity)?.name.localeCompare((b.user ?? b.entity)?.name));

                planningRealisationsList.push({
                    userRealisations: planningRealisations
                        // Only show travel hours to NON GENERAL_HOUR_CHECK when user is not allowed to register it for themselves
                        .filter(r => {
                            const isRegisteredTravel = UserService.userHasRights(UserType.HOUR_REGISTER, r.user)
                                && [RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(r.hourtype);
                            return !!r.user_id && (hasGenHourCheck || !isRegisteredTravel);
                        }),
                    entityRealisations: planningRealisations.filter(pr => !!pr.entity_id && pr.entity?.use_once),
                    entityHiringRealisations: planningRealisations.filter(r => !!r.entity_id && !r.entity?.use_once && `${r.entity?.name}` !== 'GEEN'),
                    planning,
                    project,
                    mainPlanningRealisation: planningRealisations
                        .sort((a, b) => Utils.minuteDuration(b.enddate, b.begindate) - Utils.minuteDuration(a.enddate, a.begindate))
                        .find(r => ((!!planning?.entity_id && r.entity_id === planning?.entity_id) || !planning?.entity_id) && r.hourtype === RealisationHourtype.worktime),
                    showMaterial: realisations.data.findIndex(r => Utils.realisationPlanningId(r) === afasProjectId && r.id === scrollTo) !== -1,
                    showStaff: true,
                    showHiredMaterial: false
                });
            });

            realisations.data.forEach(realisation => {
                const formGroup = this.createRealisationForm(realisation);
                this.form.push(formGroup);
                this.formMap.set(realisation, formGroup);
            });
            this.recalculatePauses();
            this.daySubscriptions.add(this.form.valueChanges.subscribe(() => {
                this.saved = false;
            }));
            if (scrollTo) {
                this.highlight = scrollTo;
                setTimeout(() => {
                    this.highlight = null;
                }, 1500);
                setTimeout(() => {
                    document.querySelector(`#realisation-${scrollTo}`).scrollIntoView();
                    setTimeout(() => {
                        window.scrollBy(0, -120);
                    });
                });
            }
            this.planningRealisationsList = planningRealisationsList;
            this.calcFilteredEmployees();
        }));

    }

    private recalculatePauses() {
        const newUserPauseMap = new Map<number, number>();
        const newEntityMinutesMap = new Map<number, number>();
        const newUserMinutesMap = new Map<number, number>();
        this.realisations.forEach(realisation => {
            if (!realisation.removed) {
                if (realisation.user_id) {
                    let pause = newUserPauseMap.get(realisation.user_id) || 0;
                    pause += realisation.pause;
                    newUserPauseMap.set(realisation.user_id, pause);
                    const pauseChanged = this.userPauseMap?.get(realisation.user_id) !== pause;
                    if (this.userPauseMap?.has(realisation.user_id) && pauseChanged) {
                        this.changedPauseUserId = realisation.user_id;
                        setTimeout(() => {
                            this.changedPauseUserId = null;
                        }, 1500);
                    }

                    let minutes = newUserMinutesMap.get(realisation.user_id) || 0;
                    minutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                    newUserMinutesMap.set(realisation.user_id, minutes);
                } else {
                    let minutes = newEntityMinutesMap.get(realisation.entity_id) || 0;
                    minutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                    newEntityMinutesMap.set(realisation.entity_id, minutes);
                }
            }
        });
        this.entityMinutesMap = newEntityMinutesMap;
        this.userPauseMap = newUserPauseMap;
        this.userMinutesMap = newUserMinutesMap;
    }

    private createRealisationForm(realisation: Realisation) {
        const fg = new FormGroup<ControlsOf<FgRealisation>>({
            id: new FormControl(realisation.id),
            approved: new FormControl(realisation.approved),
            comment_user_approved_handled: new FormControl(realisation.comment_user_approved_handled),
            skip_performer: new FormControl(realisation.skip_performer),
            begindate: new FormControl(realisation.begindate),
            enddate: new FormControl(realisation.enddate),
            planning_id: new FormControl(realisation.planning_id),
            updated_at: new FormControl(realisation.updated_at),
            comment_manager: new FormControl(realisation.comment_manager),
            removed: new FormControl(realisation.removed)
        });

        this.daySubscriptions.add(fg.controls.begindate.valueChanges.subscribe(begindate => {
            realisation.begindate = new Date(begindate);
        }));
        this.daySubscriptions.add(fg.controls.enddate.valueChanges.subscribe(enddate => {
            realisation.enddate = enddate;
        }));

        this.daySubscriptions.add(fg.valueChanges.pipe(debounceTime(100)).subscribe(fgChanged => {
            const setValues = (updatedRealisation: Realisation) => {
                Object.assign(realisation, updatedRealisation);
                fg.setValue({
                    id: updatedRealisation.id,
                    planning_id: updatedRealisation.planning_id,
                    updated_at: updatedRealisation.updated_at,
                    approved: updatedRealisation.approved,
                    comment_user_approved_handled: updatedRealisation.comment_user_approved_handled,
                    skip_performer: updatedRealisation.skip_performer,
                    begindate: updatedRealisation.begindate,
                    enddate: updatedRealisation.enddate,
                    comment_manager: updatedRealisation.comment_manager,
                    removed: updatedRealisation.removed
                });
                fg.markAsPristine();
                this.calcFilteredEmployees();
                this.recalculatePauses();
            };

            if (fg.dirty) {
                this.daySubscriptions.add(this.realisationService.approveRealisation(fgChanged as any).subscribe(
                    updatedRealisation => setValues(updatedRealisation.data),
                    (error) => {
                        if (error.message === 'Conflict') {
                            setValues(error.data);
                        } else {
                            setTimeout(() => {
                                console.log('RELAOD QQ');
                                window.location.reload();
                            });
                        }
                    }));
            }
        }));

        return fg;
    }

    approve(realisation: Realisation) {
        if (realisation.approved && realisation.comment_user_approved && !realisation.comment_user_approved_handled) {
            this.formMap.get(realisation).controls.comment_user_approved_handled.setValue(new Date());
        } else {
            this.formMap.get(realisation).controls.approved.setValue(new Date());
            this.formMap.get(realisation).controls.skip_performer.setValue(false);
        }
        this.formMap.get(realisation).markAsDirty();
        const entityRealisations = this.planningRealisationsList.find(pr => (pr.project?.afas_project_id ?? null) === Utils.realisationPlanningId(realisation))?.entityRealisations || [];

        entityRealisations.filter(er => er.planning_has_entity_id === (realisation.planning_has_entity_id ?? 'donotmapnull') || er.parent_realisation_id === realisation.id)
            .forEach(entityRealisation => {
                const fgEntityRealisation = this.formMap.get(entityRealisation);
                if (fgEntityRealisation) {
                    fgEntityRealisation.controls.approved.setValue(new Date());
                    fgEntityRealisation.controls.skip_performer.setValue(false);
                    fgEntityRealisation.markAsDirty();
                }
            });
    }

    skipPerformer(realisation) {
        this.formMap.get(realisation).controls.skip_performer.setValue(true);
        this.formMap.get(realisation).controls.approved.setValue(null);
        this.formMap.get(realisation).markAsDirty();
        if (this.formMap.get(realisation).controls.skip_performer.value) {
            this.editComment(realisation, true);
        }
    }

    editProjectComment(event, project: Project) {
        this.router.navigate(['project-dagrapport', project.afas_project_id, formatDate(this.date, 'yyyy-MM-dd', 'nl')]);
    }

    restore(realisation: Realisation) {
        this.formMap.get(realisation).controls.removed.setValue(null);
        this.formMap.get(realisation).controls.approved.setValue(null);
        this.formMap.get(realisation).controls.skip_performer.setValue(false);

        this.formMap.get(realisation).markAsDirty();
    }

    remove(realisation: Realisation) {
        this.formMap.get(realisation).controls.removed.setValue(new Date());
        if (!UserService.userHasRights(UserType.GENERAL_HOUR_CHECK)) {
            this.skipPerformer(realisation);
        } else {
            this.approve(realisation);
        }
    }

    editComment(realisation: Realisation, required = false) {
        const fcComment = this.formMap.get(realisation).controls.comment_manager;
        const ref = this.dialog.open(CommentDialogComponent, {
            maxWidth: '500px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                comment: fcComment.value,
                required
            }
        });
        this.daySubscriptions.add(ref.afterClosed().subscribe(comment => {
            if (comment !== undefined) {
                fcComment.setValue(comment);
                realisation.comment_manager = fcComment.value;
                this.formMap.get(realisation).markAsDirty();
            }
            if (required && !comment) {
                this.formMap.get(realisation).controls.approved.setValue(null);
                this.formMap.get(realisation).controls.removed.setValue(null);
                this.formMap.get(realisation).markAsDirty();
            }
        }));
    }

    editProjectTimes(event, planningRealisations: PlanningRealisations, field, addToCurrentTime = 0) {
        event.stopPropagation();
        if (planningRealisations.mainPlanningRealisation) {
            const realisations = [...planningRealisations.entityRealisations || [], ...planningRealisations.entityHiringRealisations || [], ...planningRealisations.userRealisations || []];
            const equalRealisations = realisations.filter(r =>
                new Date(r.begindate).getTime() === new Date(planningRealisations.mainPlanningRealisation.begindate).getTime()
                && new Date(r.enddate).getTime() === new Date(planningRealisations.mainPlanningRealisation.enddate).getTime()
                && !(new NotEditablePipe()).transform(r)
            );

            if (equalRealisations.length) {
                const formGroup = this.formMap.get(equalRealisations[0]);
                this.daySubscriptions.add(this.timePickerService.editRealisationTime(equalRealisations[0], field, formGroup, addToCurrentTime).subscribe(newDate => {
                    if (newDate) {
                        equalRealisations.forEach(realisation => {
                            const fgEntityRealisation = this.formMap.get(realisation);
                            fgEntityRealisation.get(field).setValue(new Date(newDate));
                            fgEntityRealisation.markAsDirty();
                        });
                        this.formMap.get(planningRealisations.mainPlanningRealisation).get(field).setValue(new Date(newDate));
                        planningRealisations.mainPlanningRealisation[field] = new Date(newDate);
                    }
                }));
            }
        }
    }

    editTime(realisation: Realisation, field, addToCurrentTime = 0) {
        const formGroup = this.formMap.get(realisation);
        this.daySubscriptions.add(this.timePickerService.editRealisationTime(realisation, field, formGroup, addToCurrentTime).subscribe(newDate => {
            if (newDate) {
                let newEndDate: Date = null;
                if (field === 'begindate' && newDate.getTime() > new Date(realisation.enddate).getTime()) {
                    newEndDate = new Date(newDate);
                    do {
                        newEndDate.setMinutes(newEndDate.getMinutes() + 1);
                    } while (newEndDate.getMinutes() % 3 !== 0);
                }


                const fgRealisation = formGroup;
                const entityRealisations = this.planningRealisationsList.find(pr => (pr.project?.afas_project_id ?? null) === Utils.realisationPlanningId(realisation))?.entityRealisations || [];
                entityRealisations
                    .filter(er => er.planning_has_entity_id === realisation.planning_has_entity_id || er.parent_realisation_id === realisation.id)
                    .filter(er => er.hourtype === realisation.hourtype)
                    .forEach(entityRealisation => {
                        const fgEntityRealisation = this.formMap.get(entityRealisation);
                        if (fgEntityRealisation) {
                            const origBeginMatches = Utils.getTimeOrNull(realisation.begindate) === Utils.getTimeOrNull(entityRealisation.begindate);
                            const origEndMatches = Utils.getTimeOrNull(realisation.enddate) === Utils.getTimeOrNull(entityRealisation.enddate);
                            if (origBeginMatches && origEndMatches) {
                                fgEntityRealisation.get(field).setValue(new Date(newDate));
                                if (newEndDate) {
                                    fgEntityRealisation.get('enddate').setValue(new Date(newEndDate));
                                }
                            }
                        }
                    });
                fgRealisation.get(field).setValue(newDate);
                if (newEndDate) {
                    fgRealisation.get('enddate').setValue(newEndDate);
                }
                formGroup.markAsDirty();
            }
        }));
    }

    maxDate(add?: number) {
        const maxDate = Utils.setTime(new Date(), 0, 0);
        maxDate.setDate(maxDate.getDate() + (7 - maxDate.getDay()));
        const nextWeekFromDay = 4;
        if (new Date().getDay() > nextWeekFromDay || (new Date().getDay() === nextWeekFromDay && new Date().getHours() > 11)) {
            maxDate.setDate(maxDate.getDate() + 7);
        }
        if (add) {
            maxDate.setDate(maxDate.getDate() + add);
        }
        return maxDate;
    }

    next() {
        const maxDate = this.maxDate();
        if (this.date < maxDate) {
            this.date.setDate(this.date.getDate() + 1);
            this.router.navigateByUrl(`check-hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
        } else {
            this.disableNext = true;
        }
    }

    prev() {
        this.disableNext = false;
        this.date.setDate(this.date.getDate() - 1);
        this.router.navigateByUrl(`check-hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    add() {
        const ref = this.dialog.open(AddRealisationDialogComponent, {
            maxWidth: '450px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                realisations: this.realisations,
                bookdate: this.date
            }
        });
        this.daySubscriptions.add(ref.afterClosed().subscribe(realisation => {
            if (realisation) {
                this.getData(realisation.id);
            }
        }));
    }

    changePause(userId: number) {
        const options = [{
            minutes: 60,
            name: '1 uur'
        }, {
            minutes: 45,
            name: '3 kwartier'
        }, {
            minutes: 30,
            name: 'Half uur'
        }, {
            minutes: 15,
            name: 'Kwartier'
        }, {
            minutes: 0,
            name: 'Geen'
        }];
        this.confirmDialog.confirm(
            'Pauzeduur aanpassen', '', null, 'Annuleren', options
        ).then(data => {
            if (data) {
                this.daySubscriptions.add(this.realisationService.changePause(this.date, data.minutes, userId).subscribe(result => {
                    this.userPauseMap.set(userId, data.minutes);
                }));
            }
        }, () => {

        });
    }

}

interface FgRealisation {
    id: number;
    approved: FormControl<Date>;
    comment_user_approved_handled: FormControl<Date>;
    removed: FormControl<Date>;
    skip_performer: boolean;
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    planning_id: number;
    comment_manager: string;
    updated_at: FormControl<Date>;
}

interface PlanningRealisations {
    planning: Planning;
    project: Project;
    mainPlanningRealisation: Realisation;
    userRealisations: Realisation[];
    entityRealisations: Realisation[];
    entityHiringRealisations: Realisation[];
    showHiredMaterial: boolean;
    showMaterial: boolean;
    showStaff: boolean;
}
