import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'datetimeBefore'
})
export class DatetimeBeforePipe implements PipeTransform {

    transform(value: Date, before: Date): unknown {
        if (value && before) {
            const val = new Date(value);
            const bef = new Date(before);

            return val.getTime() < bef.getTime();
        }
        return false;
    }

}
