export class TimeOption {
    datetime: Date;
    time: number;
    notAvailable = false;

    constructor(d: Date) {
        this.datetime = d;
        this.time = d.getTime();
    }
}
