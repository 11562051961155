import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../services/auth/auth.service';
import {UserService, UserType} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../services/dialog/dialog.service';
import {UsersOverviewComponent} from '../users/users-overview/users-overview.component';
import {PlanningListComponent} from '../planning-list/planning-list.component';
import {AsphaltExportDialogComponent} from '../asphalt-export-dialog/asphalt-export-dialog.component';
import {EntitiesOverviewComponent} from '../entities-overview/entities-overview.component';
import {AsphaltComponent} from '../asphalt/asphalt.component';
import {PlanningExportDialogComponent} from '../planning-export-dialog/planning-export-dialog.component';
import {HirePeopleDialogComponent} from '../hire-people-dialog/hire-people-dialog.component';
import {environment} from '../../environments/environment';
import {ProjectmanagerReportDialogComponent} from '../projectmanager-report-dialog/projectmanager-report-dialog.component';
import {HiringCompaniesDialogComponent} from '../hiring-companies-dialog/hiring-companies-dialog.component';
import {HiredCompaniesDialogComponent} from '../hired-companies-dialog/hired-companies-dialog.component';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-settings-menu',
    templateUrl: './settings-menu.component.html',
    styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent implements OnInit {

    @Output()
    public selectedTeams = new EventEmitter<number[]>();

    viewModel = new HomeViewModel();
    authenticatedUser: User;

    mobile = false;

    location = location;

    constructor(public dialog: MatDialog,
                public dialogService: DialogService,
                private confirmDialogService: ConfirmDialogService,
                private breakpointObserver: BreakpointObserver,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.viewModel.updateViewSate();
        this.authService.getUserDetails().subscribe(user => {
            this.authenticatedUser = user.data;
        });
        this.breakpointObserver.observe('(max-width: 722px)').subscribe(match => {
            this.mobile = match.matches;
        });
    }

    logout() {
        this.confirmDialogService.confirm('Uitloggen', 'Weet je zeker dat je wilt uitloggen?', 'Uitloggen', 'Hier blijven').then(() => {
            this.authService.logout();
        }, () => {
        });
    }

    exportAsphalt() {
        this.dialogService.open(AsphaltExportDialogComponent);
    }

    projectmanagerReport() {
        this.dialogService.open(ProjectmanagerReportDialogComponent, null, {minHeight: '300px'});
    }

    exportPlanningPdf() {
        this.dialogService.open(PlanningExportDialogComponent);
    }

    planningList() {
        this.dialogService.open(PlanningListComponent);
    }

    users() {
        this.dialogService.open(UsersOverviewComponent);
    }

    entities() {
        this.dialogService.open(EntitiesOverviewComponent);
    }

    asphalt() {
        this.dialogService.open(AsphaltComponent);
    }

    hirePeople() {
        this.dialogService.open(HirePeopleDialogComponent);
    }

    hiringCompanies() {
        this.dialogService.open(HiringCompaniesDialogComponent);
    }

    hiredCompanies() {
        this.dialogService.open(HiredCompaniesDialogComponent);
    }

}

export class HomeViewModel {
    users = false;
    entities = false;
    asphalt = false;
    projectWeekReport = false;
    planningTransport = false;
    dayCheckRealisations = false;
    planningEmployee = false;
    planningpdf = false;
    planningList = false;
    hirePeople = false;
    hiringCompanies = false;
    projectmanagerReport = false;
    checkHours = false;

    updateViewSate() {
        this.planningEmployee = UserService.userHasRights(UserType.EMPLOYEE_PLANNING);
        this.planningTransport = UserService.userHasRights(UserType.WORKPLANNER);
        this.users = UserService.userHasRights(UserType.WORKPLANNER);
        this.entities = UserService.userHasRights(UserType.WORKPLANNER);
        this.asphalt = UserService.userHasRights(UserType.ASPHALTCOORDINATOR);
        this.planningpdf = UserService.userHasRights(UserType.USER) && window?.innerWidth > 700;
        this.projectmanagerReport = UserService.userHasRights(UserType.ADMIN);
        this.planningList = UserService.userHasRights(UserType.PROJECTMANAGER) && !environment.isCodalt;
        this.hirePeople = UserService.userHasRights(UserType.ASPHALTCOORDINATOR);
        this.hiringCompanies = UserService.userHasRights(UserType.WORKPLANNER);
        this.dayCheckRealisations = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK);
        this.checkHours = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) || UserService.userHasRights(UserType.EXECUTOR) || UserService.userHasRights(UserType.ASPHALTEXECUTOR);
        this.projectWeekReport = UserService.userHasRights(UserType.EXECUTOR) || UserService.userHasRights(UserType.PROJECTMANAGER);
    }
}
