import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodaltComponent} from '../../codalt.component';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Validators} from '@angular/forms';

@Component({
    selector: 'app-comment-dialog',
    templateUrl: './comment-dialog.component.html',
    styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent extends CodaltComponent implements OnInit {

    form: FormGroup<ControlsOf<{ comment: string }>>;

    constructor(public dialogRef: MatDialogRef<CommentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    comment: string,
                    required: boolean,
                    tiny: boolean,
                    title?: string
                }) {
        super();
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            comment: new FormControl(this.data.comment)
        });
        if (this.data.required) {
            this.form.controls.comment.addValidators(Validators.required);
        }
    }

    applyAndClose() {
        this.dialogRef.close(this.form.controls.comment.value);
    }

}
