import {Entity} from './entity.class';
import {Planning} from './planning.class';
import {EntityType} from '../services/entities/entity-type.class';

export class PlanningTruck {
    public entitytype_id: number;
    entitytype?: EntityType;

    public entity_id: number;

    public planning_id: number;

    public begindate: Date;

    public enddate: Date;

    public name: string;

    public driver: string;

    public location: string;

    public comment: string;

    public created_at: Date;

    public updated_at: Date;

    public id: number;

    public entity: Entity;

    public planning: Planning;

    driver_user_id?: number;


    transport_origin: string;

    transport_destination: string;

}
