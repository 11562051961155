import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {UserService} from '../../services/user/user.service';
import {Entity} from '../../classes/entity.class';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {AfasService} from '../../services/afas.service';
import {Settlement} from '../../classes/settlement.class';
import {SettlementsService} from '../../services/settlements.service';
import {Hourtype} from '../../afas-classes/hourtype';
import {AfasProject} from '../../afas-classes/afas-project';

@Component({
    selector: 'app-edit-settlement-dialog',
    templateUrl: './edit-settlement-dialog.component.html',
    styleUrls: ['./edit-settlement-dialog.component.scss']
})
export class EditSettlementDialogComponent extends CodaltComponent implements OnInit {

    projects: AfasProject[];

    form: FormGroup<ControlsOf<SettlementFormGroup>>;

    entities: Entity[];
    step = 'project';
    hourtypes: Hourtype[];

    fcProjectSearch = new FormControl<string>();

    searching = false;
    itemSearch: string;

    settlement: Settlement;

    constructor(public dialogRef: MatDialogRef<EditSettlementDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    settlement: Settlement,
                    bookdate: Date,
                    editOnly: string
                },
                private settlementsService: SettlementsService,
                private userService: UserService,
                private afasService: AfasService) {
        super();
        this.settlement = data.settlement;
        if (data.editOnly) {
            this.step = data.editOnly;
        }
        this.setCurrentProjects();
    }


    private setCurrentProjects() {
        this.projects = [];
    }

    ngOnInit(): void {
        this.form = new FormGroup<ControlsOf<SettlementFormGroup>>({
            id: new FormControl(this.settlement.id),
            afas_project_id: new FormControl(this.settlement.afas_project_id),
            afas_employee_id: new FormControl(this.settlement.afas_employee_id),
            hourtype_code: new FormControl(this.settlement.hourtype_code),
            minutes: new FormControl(this.settlement.minutes),
            amount: new FormControl(this.settlement.amount),
            bookdate: new FormControl(this.settlement.bookdate),
            updated_at: new FormControl(this.settlement.updated_at)
        });

        this.subscriptions.add(this.settlementsService.getHourtypes().subscribe(hourtypes => {
            this.hourtypes = hourtypes.data;
        }));

        let afasSubs = new Subscription();
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            afasSubs?.unsubscribe();
            if (search) {
                this.searching = true;
                afasSubs = this.afasService.getProjects(search).subscribe((projectResponse) => {
                    this.projects = projectResponse.data;
                    this.searching = false;
                }, () => {
                    this.searching = false;
                });
            }
        }));

        const s = this.settlement;
        const defaultProject = s.afas_project_id ?? s.realisation?.afas_project_id ?? s.realisation?.planning?.afas_project_id ?? s.realisation?.planning?.worknumber;
        if (defaultProject?.length > 0) {
            this.fcProjectSearch.setValue(defaultProject);
        }
    }

    saveAndClose() {
        this.subscriptions.add(this.settlementsService.save(this.form.value as any).subscribe(() => {
            this.dialogRef.close();
        }));
    }

}

interface SettlementFormGroup {
    id: number;
    afas_project_id: string;
    afas_employee_id: string;
    hourtype_code: string;
    minutes: number;
    amount?: string;
    bookdate: FormControl<Date>;
    updated_at: FormControl<Date>;
}
