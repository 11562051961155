import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlanningDay} from '../../classes/planning-day.class';
import {Planning} from '../../classes/planning.class';
import {PlanningOverviewViewModel} from '../planning-overview-view-model';
import {Utils} from '../../utils.class';
import {environment} from '../../../environments/environment';
import {Settings} from '../../settings.class';

@Component({
    selector: 'app-day',
    templateUrl: './day.component.html',
    styleUrls: ['./day.component.scss']
})
export class DayComponent implements OnInit {

    @Input() entityIdPlanningCount: Map<number, number>;

    @Input()
    public viewModel: PlanningOverviewViewModel;

    @Input()
    public day: PlanningDay;

    @Input()
    public fixedUntil: Date;

    @Input() print: boolean;

    @Input()
    public fromDate: Date;

    @Input()
    public toDate: Date;

    @Input()
    public primaryWeekStart: Date;

    @Input()
    public primaryWeekEnd: Date;

    @Output()
    public openPlanning = new EventEmitter<Planning>();

    @Output()
    public mouseEnterPlanning = new EventEmitter<PlanningDay>();

    @Output()
    public mouseLeavePlanning = new EventEmitter<PlanningDay>();

    @Output()
    public setFixed = new EventEmitter<PlanningDay>();

    public isFixed = false;

    public isNonFocussedWeek = false;

    environment = environment;
    Settings = Settings;

    constructor() {
    }

    ngOnInit() {
        this.isFixed = new Date(this.fixedUntil).getTime() >= new Date(this.day.date).getTime();
        this.isNonFocussedWeek = this.day.date.getTime() < Utils.getTimeOrNull(this.primaryWeekStart)
            || this.day.date.getTime() >= Utils.getTimeOrNull(this.primaryWeekEnd);
    }

    open(planning: Planning) {
        this.openPlanning.emit(planning);
    }

    mouseEnter(day: PlanningDay) {
        if (this.viewModel.lockPlanning) {
            this.mouseEnterPlanning.emit(day);
        }
    }

    mouseLeave(day: PlanningDay) {
        if (this.viewModel.lockPlanning) {
            this.mouseLeavePlanning.emit(day);
        }
    }


}
