import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';
import {UserPlanning} from '../classes/user-planning';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';

@Pipe({
    name: 'employeeIsNight'
})
export class EmployeeIsNightPipe implements PipeTransform {

    transform(day: Date, projectDayUser: {
        type: 'userPlanning' | 'planning' | 'project',
        userPlanning?: UserPlanning[],
        planningHas?: PlanningHasEntity[]
    }): unknown {

        let upCount = projectDayUser?.userPlanning?.filter(up => Utils.isNight(up.begindate, up.enddate))?.length;
        upCount += projectDayUser?.planningHas?.filter(up => Utils.isNight(up.begindate, up.enddate))?.length;


        return upCount > 0;
    }

}
