import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlanningService} from "../services/planning/planning.service";
import {Utils} from "../utils.class";
import {Planning} from "../classes/planning.class";
import {User} from "../classes/user.class";
import {Entity} from "../classes/entity.class";
import {MatDialogRef} from "@angular/material/dialog";
import {saveAs} from 'file-saver';
import {HttpResponse} from "@angular/common/http";
import {formatDate} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-asphalt-order-list',
    templateUrl: './planning-export-dialog.component.html',
    styleUrls: ['./planning-export-dialog.component.scss']
})
export class PlanningExportDialogComponent implements OnInit, OnDestroy {

    planning: Planning[];
    users: Map<number, User>;
    sets: Map<number, Entity>;
    weeks: WeekOption[] = [];
    today = new Date();
    downloading = new Map<WeekOption, boolean>();
    downloaded = new Map<WeekOption, boolean>();
    private subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<PlanningExportDialogComponent>,
        private planningService: PlanningService) {
    }

    ngOnInit() {
        let monday = new Date();

        // Calculate the monday
        monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
        Utils.setTime(monday, 0, 0);
        let sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        this.weeks.push({
            startDate: new Date(monday),
            endDate: new Date(sunday)
        });
        for (let i = 1; i < 7; i++) {
            monday.setDate(monday.getDate() + 7);
            sunday.setDate(sunday.getDate() + 7);
            this.weeks.push({
                startDate: new Date(monday),
                endDate: new Date(sunday)
            });
        }
    }

    generateList(week: WeekOption) {
        this.downloading.set(week, true);
        const filename = 'Asfaltplanning_'
            + '_' + formatDate(week.startDate, 'dd-MM-yyyy', 'nl')
            + '_' + formatDate(week.endDate, 'dd-MM-yyyy', 'nl')
            + '.pdf';
        this.planningService.generatePdf(week.startDate).subscribe((data: HttpResponse<any>) => {
            saveAs(data, filename);
            this.downloading.delete(week);
            this.downloaded.set(week, true);
        });
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}

interface WeekOption {
    startDate: Date;
    endDate: Date;
}
