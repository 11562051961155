<div class="schedule-navbar">
    <div>
        <button mat-icon-button color="white" (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content">
        <p class="large">Mijn planning - Week {{beginDate | date : 'w'}}</p>
        <p class="small">{{beginDate | date : 'd MMM'}} - {{endDate | date : 'd MMM y'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="schedule">
    <div class="d-flex justify-content-center pt-1">
        <a mat-raised-button color="accent" href="tel:+31646788962">
            <i class="fa-regular fa-phone"></i> Ian
        </a>
        <button mat-button color="primary" class="mt-1" routerLink="/" *ngIf="showPlanning">
            Planning
        </button>
        <button mat-button color="primary" class="mt-1" routerLink="/logout">
            Uitloggen
        </button>
    </div>
    <div class="items">
        <div *ngFor="let realisation of realisations; last as last; index as index; let first = first"
             (click)="open(realisation)"
             class="schedule-item"
             [class.dayoff]="realisation.dayOff || realisation.begindate > maxDate"
             [class.connect-next]="!last && (realisation.begindate | date : 'd') === (realisations[index + 1].begindate | date : 'd')"
             [class.connect-prev]="!first && (realisation.begindate | date : 'd') === (realisations[index - 1].begindate | date : 'd')">
            <div class="container wrapper">
                <div class="content">
                    <div class="day">
                        {{realisation.begindate | date : 'EEEE d MMMM'}}
                    </div>
                    <div class="time" *ngIf="!realisation.dayOff">
                        {{realisation.begindate | date : 'HH:mm'}} - {{realisation.enddate | date : 'HH:mm'}}
                    </div>
                    <div class="time dayoff" *ngIf="realisation.dayOff && !realisation.dayInvisible">
                        niet ingepland
                    </div>
                    <div class="time dayoff" *ngIf="realisation.dayInvisible">
                        nog niet beschikbaar
                    </div>
                    <div class="project" *ngIf="!realisation.dayOff && realisation.begindate < maxDate">
                        <b>{{realisation.hourtype | hourtype}}</b>
                        Project {{realisation.planning?.afas_project_id ?? realisation.afas_project_id ?? realisation?.user_planning?.project?.afas_project_id}}
                        - {{realisation.project?.name ?? realisation.planning?.project?.name}}<br>
                        <b>{{realisation?.project?.location ?? realisation?.user_planning?.project?.location ?? realisation?.planning?.location ?? ''}}</b><br>
                        <ng-container *ngIf="realisation.planning_has">
                            <i *ngIf="realisation.planning_has.comment">{{realisation.planning_has.comment}}<br></i>
                            {{realisation.planning_has.transport_worksite ?? realisation.planning_has.transport_destination ?? realisation.planning_has.transport_origin}}
                        </ng-container>
                    </div>
                </div>
                <button mat-icon-button *ngIf="!realisation.dayOff && realisation.begindate < maxDate">
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container">
        <div class="buttons">
            <button mat-raised-button color="accent"
                    class="planning-button"
                    routerLink="/check-hours"
                    *ngIf="(UserType.EXECUTOR | hasUserGroup) || (UserType.ASPHALTEXECUTOR | hasUserGroup) || (UserType.GENERAL_HOUR_CHECK | hasUserGroup)">
                Urencontrole
            </button>
            <button mat-raised-button color="primary"
                    routerLink="/hours"
                    class="planning-button"
                    *ngIf="UserType.HOUR_REGISTER | hasUserGroup">
                Urenregistratie
            </button>
        </div>
    </div>
</div>
