import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {UserService, UserType} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {Entity} from '../../classes/entity.class';
import {EntitiesService} from '../../services/entities/entities.service';
import {debounceTime} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {RealisationService} from '../../services/realisation.service';
import {ProjectService} from '../../services/project.service';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-add-realisation-dialog',
    templateUrl: './add-realisation-dialog.component.html',
    styleUrls: ['./add-realisation-dialog.component.scss']
})
export class AddRealisationDialogComponent extends CodaltComponent implements OnInit {

    RealisationHourtype = RealisationHourtype;

    projects: {
        projectId: string,
        location: string,
        planningId?: number;
    }[] = [];

    form: FormGroup<ControlsOf<newRealisation>> = new FormGroup<ControlsOf<newRealisation>>({
        entityId: new FormControl(),
        userId: new FormControl(),
        projectId: new FormControl(),
        planningId: new FormControl(),
        type: new FormControl(),
        hourtype: new FormControl()
    });

    empoyees: User[];
    entities: Entity[];

    fcProjectSearch = new FormControl<string>();
    fcEntities = new FormControl<number[]>();
    itemSearch?: string;

    searching = false;

    activeStep: Step = Step.Type;
    Step = Step;
    night = false;

    constructor(public dialogRef: MatDialogRef<AddRealisationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    realisations: Realisation[],
                    bookdate: Date,
                    userId?: number
                },
                private realisationService: RealisationService,
                private entityService: EntitiesService,
                private userService: UserService,
                private entitiesService: EntitiesService,
                private afprojectServicesService: ProjectService) {
        super();
        this.setCurrentProjects();
    }

    private setCurrentProjects() {
        if (this.data.userId) {
            this.subscriptions.add(this.afprojectServicesService.getRecentProject(+this.data.userId).subscribe((projects) => {
                this.projects = projects.data.map(p => {
                    return {projectId: p.afas_project_id, location: p.location ?? p.name};
                });
            }));
            const entities$ = this.entityService.getList();
            const dayEntityIds$ = this.realisationService.getDayEntities(this.data.bookdate, +this.data.userId);

            this.subscriptions.add(combineLatest(entities$, dayEntityIds$).subscribe(([entities, dayEntityIds]) => {
                this.entities = entities.filter(e => !!e.afas_employee_id);
                let defaultEntityIds = dayEntityIds.data;
                defaultEntityIds = this.entities.filter(e => defaultEntityIds.indexOf(e.id) !== -1).map(e => e.id);
                if (defaultEntityIds.length === 0 && !!this.data.userId) {
                    defaultEntityIds = this.entities.filter(e => e.driver_user_id === this.data.userId).map(e => e.id);
                }
                this.fcEntities.setValue(defaultEntityIds);
            }));
        } else {
            this.projects = [];
            const projects = [...new Set(this.data.realisations?.map(r => r.planning?.afas_project_id ?? r.planning?.worknumber) || [])].filter(p => !!p);
            projects.forEach(project => {
                const realisation = this.data.realisations.find(r => r.planning?.afas_project_id === project || r.planning?.worknumber === project);
                this.projects.push({
                    projectId: project,
                    location: realisation?.planning?.location,
                    planningId: realisation?.planning?.id
                });
            });
        }
    }

    setActiveStep(step: Step) {
        if (step === Step.Hourtype && (!this.form.value.type || this.form.value.type === 'entity')) {
            return;
        }
        if (step === Step.Project && (!this.form.value.type || !this.form.value.hourtype || NON_PROJECT_HOURTYPES.indexOf(this.form.value.hourtype) !== -1)) {
            return;
        }
        if (step === Step.Employee && (!this.form.value.type || !this.form.value.projectId || !this.form.value.hourtype)) {
            return;
        }
        if (step === Step.Vehicle && (!this.form.value.type || !this.form.value.projectId || !this.form.value.hourtype || !this.data.userId)) {
            return;
        }

        this.activeStep = step;
    }

    setType(type: 'entity' | 'user') {
        this.form.controls.type.setValue(type);
        if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) && type === 'user') {
            this.form.controls.hourtype.setValue(null);
            this.activeStep = Step.Hourtype;
        } else {
            this.setHourtype(RealisationHourtype.worktime);
        }
    }

    setHourtype(hourtype: RealisationHourtype) {
        this.form.controls.hourtype.setValue(hourtype);
        if (NON_PROJECT_HOURTYPES.indexOf(this.form.value.hourtype) !== -1 || OPTIONAL_PROJECT_HOURTYPES.indexOf(this.form.value.hourtype) !== -1) {
            this.form.controls.projectId.setValue(null);
            this.form.controls.planningId.setValue(null);
            if (this.data.userId) {
                this.setUser(this.data.userId);
            } else {
                this.activeStep = Step.Employee;
            }
        } else {
            this.activeStep = Step.Project;
        }
    }

    ngOnInit(): void {
        if (this.data.userId) {
            this.setType('user');
        }
        this.subscriptions.add(this.form.controls.type.valueChanges.subscribe(type => {
            if (type === 'entity') {
                this.subscriptions.add(this.entityService.getList().subscribe(entities => {
                    this.entities = entities.filter(e => !!e.afas_employee_id);
                }));
            } else {
                this.subscriptions.add(this.userService.getList(false, false).subscribe(users => {
                    this.empoyees = users;
                }));
            }
        }));
        let afasSubs = new Subscription();
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            afasSubs?.unsubscribe();
            if (search) {
                this.searching = true;
                afasSubs = this.afprojectServicesService.searchProjects(search).subscribe((projectResponse) => {
                    this.projects = projectResponse.data?.filter(p => !!p.parent_id).map(p => {
                        return {projectId: p.afas_project_id, location: p.name};
                    });
                    this.searching = false;
                }, () => {
                    this.searching = false;
                });
            } else {
                this.setCurrentProjects();
            }
        }));

        this.subscriptions.add(this.fcEntities.valueChanges.subscribe(() => {
            const getSorter = (entity: Entity) => (this.fcEntities.value.indexOf(entity.id) !== -1 ? 100000 : 0) + entity.id;

            this.entities = this.entities.sort((a, b) => getSorter(b) - getSorter(a));
        }));
    }

    selectProject(projectId, planningId?: number) {
        this.form.controls.projectId.setValue(projectId);
        this.form.controls.planningId.setValue(planningId);
        if (this.data.userId) {
            this.activeStep = Step.Vehicle;
        } else {
            this.activeStep = Step.Employee;
        }
    }

    setVehicle() {
        if (this.data.userId) {
            this.setUser(this.data.userId);
        }
    }

    setEntity(entity: Entity) {
        this.form.controls.entityId.setValue(entity.id);
        this.createRealisation();
    }

    setUser(userId: number) {
        this.form.controls.userId.setValue(userId);
        this.createRealisation();
    }

    createRealisation() {
        const realisation = new Realisation();
        realisation.user_id = this.form.value.userId;
        realisation.entity_id = this.form.value.entityId;
        realisation.afas_project_id = this.form.value.projectId;
        realisation.hourtype = this.form.value.hourtype ?? RealisationHourtype.worktime;
        realisation.bookdate = this.data.bookdate;

        // Find the previous realisation for the current user on the current bookdate excluding removed realisations
        const previousRealisation = this.data.realisations?.filter(r => r.user_id === realisation.user_id && Utils.matchesDate(r.bookdate, realisation.bookdate) && !r.removed)
            .sort((a, b) => Utils.realisationSort(a) - Utils.realisationSort(b)).slice(-1).pop();

        let beginDate = new Date(previousRealisation?.enddate ?? this.data.bookdate);
        if (!previousRealisation) {
            if (this.night) {
                Utils.setTime(beginDate, 20, 30);
            } else {
                Utils.setTime(beginDate, 7, 0);
            }
        }
        let endDate = new Date(beginDate);

        if (previousRealisation) {
            endDate.setUTCHours(endDate.getUTCHours() + 1);
        } else if (this.form.value.planningId) {
            const planning = this.data.realisations.find(r => r.planning_id === this.form.value.planningId).planning;
            const largestPlanningRealisation = this.data.realisations.filter(r => r.planning?.id === planning.id)
                .sort((a, b) => Utils.minuteDuration(b.enddate, b.begindate) - Utils.minuteDuration(a.enddate, a.begindate));
            const mainPlanning = this.data.realisations.find(r => r.planning?.id === planning.id && r.entity_id === planning.entity_id)
                ?? largestPlanningRealisation[0];
            realisation.planning_id = planning.id;
            beginDate = new Date(mainPlanning.begindate);
            endDate = new Date(mainPlanning.enddate);
        } else {
            const pauseHourTypes = [RealisationHourtype.worktime, RealisationHourtype.education];
            let durationMinutes = 60 * 8;
            if (pauseHourTypes.indexOf(realisation.hourtype) !== -1) {
                durationMinutes += 30;
            }
            endDate.setMinutes(endDate.getMinutes() + durationMinutes);
        }
        realisation.begindate = beginDate;
        realisation.enddate = endDate;
        realisation.bookdate = this.data.bookdate ?? beginDate;

        this.realisationService.saveRealisation(realisation).subscribe(result => {
            if (realisation.hourtype === RealisationHourtype.worktime && this.fcEntities.value?.length > 0) {
                const realisations = [];
                this.fcEntities.value.forEach(entityId => {
                    const entityRealisation = JSON.parse(JSON.stringify(realisation)) as Realisation;
                    entityRealisation.entity_id = entityId;
                    entityRealisation.user_id = null;
                    entityRealisation.parent_realisation_id = result.data.realisation.id;
                    entityRealisation.hourtype = RealisationHourtype.worktime;
                    realisations.push(entityRealisation);
                });

                const realisations$ = realisations.map(r => this.realisationService.saveRealisation(r));
                combineLatest(realisations$).subscribe(() => {
                    this.dialogRef.close(result.data.realisation);
                });
            } else {
                this.dialogRef.close(result.data.realisation);
            }
        });
    }

}

interface newRealisation {
    projectId: string,
    userId: number,
    entityId: number,
    planningId: number,
    type: 'entity' | 'user'
    hourtype: RealisationHourtype;
}

export const NON_PROJECT_HOURTYPES = [
    RealisationHourtype.day_off,
    RealisationHourtype.frost,
    RealisationHourtype.illness
];
export const OPTIONAL_PROJECT_HOURTYPES = [
    RealisationHourtype.education
];

export enum Step {
    Project, Hourtype, Employee, Type, Vehicle
}
