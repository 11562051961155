import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Realisation} from '../classes/realisation';
import {formatDate} from '@angular/common';
import {User} from '../classes/user.class';
import {Utils} from '../utils.class';
import {LocalStorage} from '../storage.class';
import {UserType} from './user/user.service';
import {Project} from '../classes/project.class';

@Injectable({
    providedIn: 'root'
})
export class RealisationService {

    constructor(protected apiService: ApiService) {

    }

    getWeekReportPdf(afasProjectId: string, period: number, year: number) {
        return this.apiService.getBlobCall(`realisation/week-report-pdf/${afasProjectId}/${period}/${year}`);
    }

    getProjectsPeriod(beginDate: Date, endDate: Date) {
        let params = {
            beginDate: new Date(beginDate).toISOString(),
            endDate: new Date(endDate).toISOString()
        } as any;
        return this.apiService.getCall$<Project[]>('realisation/period/projects', params);
    }

    getDayEntities(date: Date, userId: number) {
        return this.apiService.getCall$<number[]>('realisation/day-entities', {
            date: formatDate(date, 'yyyy-MM-dd', 'nl'),
            userId
        });
    }

    getProjectSchedule(beginDate: Date, endDate: Date, afas_project_id?: string) {
        let params = {
            beginDate: new Date(beginDate).toISOString(),
            endDate: new Date(endDate).toISOString()
        } as any;
        if (afas_project_id) {
            params = {
                ...params,
                afas_project_id
            };
        }
        return this.apiService.getCall$<Realisation[]>('realisation/schedule/project', params);
    }

    calcMaxDate(add?: number) {
        const maxDate = Utils.setTime(new Date(), 23, 59);
        if (!LocalStorage.getUser() || LocalStorage.getUser().groups.map(g => g.group).includes(UserType.TRUCK_DRIVER)) {
            maxDate.setUTCDate(maxDate.getUTCDate() + 1);
            while (maxDate.getDay() < 1 || maxDate.getDay() > 5) {
                maxDate.setUTCDate(maxDate.getUTCDate() + 1);
            }
        } else {
            maxDate.setUTCDate(maxDate.getUTCDate() + (7 - maxDate.getDay()));
            const nextWeekFromDay = 5;
            if (new Date().getDay() >= nextWeekFromDay) {
                maxDate.setUTCDate(maxDate.getUTCDate() + 7);
            }
            if (add) {
                maxDate.setUTCDate(maxDate.getUTCDate() + add);
            }
        }
        return maxDate;
    }

    getScheduleDay(date) {
        return this.apiService.getCall$<Realisation[]>('realisation/schedule/day', {
            date: formatDate(new Date(date), 'yyyy-MM-dd', 'nl')
        });
    }

    getUserSchedule(beginDate: Date, endDate: Date, userId?: number) {
        let params = {
            beginDate: formatDate(new Date(beginDate), 'yyyy-MM-dd', 'nl'),
            endDate: formatDate(new Date(endDate), 'yyyy-MM-dd', 'nl')
        } as any;
        if (userId) {
            params = {
                ...params,
                userId
            };
        }
        return this.apiService.getCall$<Realisation[]>('realisation/schedule/user', params);
    }

    getUserDaySchedule(date, userId?: number) {
        let params = {
            date: new Date(date).toISOString()
        } as any;
        if (userId) {
            params = {
                ...params,
                userId
            };
        }
        return this.apiService.getCall$<{
            realisations: Realisation[],
            weekTotal: DayWeekTotal,
            dayTotal: DayWeekTotal,
            user?: User
        }>('realisation/schedule/user/day', params);
    }

    submitRealisation(realisations: Realisation[]) {
        return this.apiService.postCall$('realisation/schedule/submit', {realisations});
    }

    saveRealisation(realisation: Realisation) {
        return this.apiService.postCall$<{ realisation: Realisation, weekTotal: DayWeekTotal, dayTotal: DayWeekTotal }>('realisation/schedule/save', realisation);
    }

    approveRealisation(realisation: Realisation) {
        return this.apiService.postCall$<Realisation>('realisation/schedule/approve', realisation);
    }

    changePause(date: Date, pause: number, userId?: number) {
        let params = {
            date,
            pause
        } as any;
        if (userId) {
            params = {
                ...params,
                userId
            };
        }
        return this.apiService.postCall$<{ realisation: Realisation, weekTotal: DayWeekTotal, dayTotal: DayWeekTotal }>('realisation/schedule/pause', params);
    }
}

interface DayWeekTotal {
    minutes: number;
    pause: number;
};
