import {Planning} from './planning.class';
import {AsphaltmillAsphalt} from './asphaltmillasphalt.class';

export class PlanningAsphalt {

    id: number;

    public asphaltmill_asphalt_id: number;

    public planning_id: number;

    public asphaltmill_id: number;

    public asphaltname: string;

    public asphaltcode: string;

    public tons: number;

    public thickness: number;

    public trucks: number;

    public comment: string;

    public time: Date;

    public created_at: Date;

    public updated_at: Date;

    public planning: Planning;

    public asphaltmill_asphalt: AsphaltmillAsphalt;

}
