import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EntitiesService} from '../services/entities/entities.service';
import {UserService, UserType} from '../services/user/user.service';
import {PlanningService} from '../services/planning/planning.service';
import {Utils} from '../utils.class';
import {Subscription} from 'rxjs';
import {User} from '../classes/user.class';
import {formatDate} from '@angular/common';


@Component({
    selector: 'app-projectmnager-report-dialog',
    templateUrl: './projectmanager-report-dialog.component.html',
    styleUrls: ['./projectmanager-report-dialog.component.scss']
})
export class ProjectmanagerReportDialogComponent implements OnInit {

    weeks: WeekOption[] = [];
    projectManagers: User[];
    reportMap = new Map<string, number>();
    private subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<ProjectmanagerReportDialogComponent>,
        private entitiesService: EntitiesService,
        private usersService: UserService,
        private userService: UserService,
        private planningService: PlanningService) {
    }

    ngOnInit() {
        const monday = new Date();

        this.planningService.getProjectmangerReport().then((report) => {
            const formattedData = report as ReportData[];
            formattedData.forEach((reportData) => {
                const key = reportData.projectmanager + '_' + reportData.date;
                this.reportMap.set(key, reportData.count);
            });
        });
        this.subscriptions.add(this.userService.getByType(UserType.PROJECTMANAGER).subscribe(users => this.projectManagers = users));

        // Calculate the monday
        monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
        Utils.setTime(monday, 0, 0);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);

        for (let i = 0; i < 7; i++) {
            const datesInWeek = [];
            const dateInWeek = new Date(monday);
            for (let z = 0; z < 7; z++) {
                datesInWeek.push(formatDate(new Date(dateInWeek), 'yyyy-MM-dd', 'nl'));
                dateInWeek.setDate(dateInWeek.getDate() + 1);
            }

            this.weeks.push({
                startDate: new Date(monday),
                endDate: new Date(sunday),
                key: Utils.formatDate(new Date(monday), 'w_y'),
                dates: datesInWeek
            });

            monday.setDate(monday.getDate() + 7);
            sunday.setDate(sunday.getDate() + 7);
        }
    }

    close() {
        this.dialogRef.close();
        this.subscriptions.unsubscribe();
    }

}

interface WeekOption {
    startDate: Date;
    endDate: Date;
    key?: string;
    dates: Date[];
}

interface ReportData {
    projectmanager: number;
    count: number;
    week: number;
    year: number;
    date: string;
}
