import {Component, HostBinding, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PlanningService, TonsForDate} from '../../../services/planning/planning.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tons',
    templateUrl: './tons.component.html',
    styleUrls: ['./tons.component.scss']
})
export class TonsComponent implements OnInit, OnDestroy {

    public tons: TonsForDate;
    @HostBinding('class.print') @Input() print: boolean;
    @Input()
    private fromDate: Date;
    @Input()
    private toDate: Date;
    @Input()
    private date: Date;
    private tonSubscription: Subscription;

    constructor(private planningService: PlanningService) {
    }

    ngOnInit() {
        this.subscribeToTons();
    }

    ngOnDestroy() {
        if (this.tonSubscription) {
            this.tonSubscription.unsubscribe();
        }
    }

    private subscribeToTons() {
        if (this.tonSubscription) {
            this.tonSubscription.unsubscribe();
        }
        this.tonSubscription = this.planningService
            .getTons(this.fromDate, this.toDate, this.date).subscribe(tons => this.tons = tons);
    }
}
