<div class="schedule-navbar">
    <div>
        <button mat-icon-button color="white" (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content">
        <p class="large">{{date | date : 'd MMM'}}</p>
        <p class="small">{{date | date : 'EEEE'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()" [disabled]="disableNext">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="hours-check first" *ngIf="!planningRealisationsList?.length">
    <div class="header">
        <div class="container-xl">
            <div class="content justify-content-center" *ngIf="planningRealisationsList?.length === 0">
                Geen planningen waarvoor je uitvoerder bent op deze dag
            </div>
            <div class="content justify-content-center" *ngIf="!planningRealisationsList">
                Aan het laden...
            </div>
        </div>
    </div>
</div>
<div class="hours-check first" *ngIf="planningRealisationsList?.length">
    <div class="header">
        <div class="container-xl">
            <div class="content justify-content-center">
                <mat-checkbox [formControl]="fcHideApproved">
                    Verberg goedgekeurde uren
                </mat-checkbox>
                <mat-form-field appearance="outline" *ngIf="userFunctions">
                    <mat-label>Filter op functie</mat-label>
                    <mat-select multiple [formControl]="fcUserFunction">
                        <mat-option *ngFor="let userFunction of userFunctions" [value]="userFunction">
                            {{userFunction}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="userFunctions && UserType.GENERAL_HOUR_CHECK | hasUserGroup">
                    <mat-label>Filter op uursoort</mat-label>
                    <mat-select multiple [formControl]="fcHourType">
                        <mat-option [value]="RealisationHourtype.worktime">
                            Werktijd
                        </mat-option>
                        <mat-option [value]="RealisationHourtype.illness">
                            Ziek
                        </mat-option>
                        <mat-option value="{{RealisationHourtype.driving_to}}|{{RealisationHourtype.driving_back}}">
                            Transporttijd
                        </mat-option>
                        <mat-option value="{{RealisationHourtype.travel_to}}|{{RealisationHourtype.travel_back}}">
                            Reistijd
                        </mat-option>
                        <mat-option [value]="RealisationHourtype.frost">
                            Vorst
                        </mat-option>
                        <mat-option [value]="RealisationHourtype.day_off">
                            Roostervrij
                        </mat-option>
                        <mat-option [value]="RealisationHourtype.sleep">
                            Slapen
                        </mat-option>
                        <mat-option [value]="RealisationHourtype.education">
                            Scholing
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="content justify-content-center">
                <small>
                    Er {{filteredEmployees === 1 ? 'wordt' : 'worden'}}
                    {{filteredEmployees}} van {{totalEmployees}}
                    medewerker{{totalEmployees === 1 ? '' : 's'}} getoond
                </small>
            </div>
        </div>
    </div>
</div>

<div class="hours-check"
     [class.last]="last"
     [class.disabled]="!allowEdit"
     *ngFor="let planningRealisations of planningRealisationsList; let first = first; let last = last">
    <div class="header"
         [class.empty]="!(planningRealisations.userRealisations | checkHoursFilter : fcUserFunction.value : fcHourType.value)?.length"
         (click)="planningRealisations.showStaff = !planningRealisations.showStaff">
        <div class="container-xl">
            <div class="content">
                <div class="name-wrapper">
                    <div class="name">Personeel
                        ({{(planningRealisations.userRealisations | checkHoursFilter : fcUserFunction.value : fcHourType.value)?.length}})
                    </div>
                </div>
                <div class="prefix">
                    <button mat-icon-button color="primary" class="icon-button" [class.active]="planningRealisations.showStaff">
                        <i class="fas fa-chevron-down" *ngIf="planningRealisations.userRealisations?.length"></i>
                        <i class="fa-solid fa-dash" *ngIf="!planningRealisations.userRealisations?.length"></i>
                    </button>
                </div>
                <div class="times">
                    <div class="time" (click)="editProjectTimes($event, planningRealisations, 'begindate',-1)">
                        {{planningRealisations.mainPlanningRealisation?.begindate | date : 'HH:mm'}}
                    </div>
                    <div class="between">
                        <div class="top">{{planningRealisations.project?.contractor ?? planningRealisations.planning?.contractor}}</div>
                        <div class="bottom">
                            {{planningRealisations.project?.afas_project_id ?? planningRealisations.planning?.worknumber}}
                        </div>
                    </div>
                    <div class="time end" (click)="editProjectTimes($event, planningRealisations, 'enddate', 1)">
                        {{planningRealisations.mainPlanningRealisation?.enddate | date : 'HH:mm'}}
                    </div>
                </div>
                <div class="buttons">
                    <div class="comment" *ngIf="planningRealisations.project">
                        <button (click)="editProjectComment($event, planningRealisations.project)"
                                mat-icon-button
                                color="primary"
                                class="planning-button">
                            <i class="fas fa-file-lines"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="list" *ngIf="planningRealisations.showStaff">
        <div
            *ngFor="let realisation of planningRealisations.userRealisations | checkHoursFilter : fcUserFunction.value : fcHourType.value : fcHideApproved.value; let even = even; let index = index"
            [class.warn]="!even"
            [class.first-group]="realisation.user_id !== planningRealisations.userRealisations[index - 1]?.user_id"
            [class.last-group]="realisation.user_id !== planningRealisations.userRealisations[index + 1]?.user_id"
            [class.highlight]="highlight === realisation.id"
            id="realisation-{{realisation.id}}"
            class="list-item">
            <ng-container *ngTemplateOutlet="realisationRow;context:{realisation:realisation,project:planningRealisations.project}"></ng-container>
        </div>
    </div>
    <div class="header"
         [class.empty]="!planningRealisations.entityRealisations?.length"
         (click)="planningRealisations.showMaterial = !planningRealisations.showMaterial">
        <div class="container-xl">
            <div class="content">
                <div class="name-wrapper">
                    <div class="name">Materieel ({{planningRealisations.entityRealisations?.length}})</div>
                </div>
                <div class="prefix">
                    <button mat-icon-button color="primary" class="icon-button" [class.active]="planningRealisations.showMaterial">
                        <i class="fas fa-chevron-down" *ngIf="planningRealisations.entityRealisations?.length"></i>
                        <i class="fa-solid fa-dash" *ngIf="!planningRealisations.entityRealisations?.length"></i>
                    </button>
                </div>
                <div class="times">
                    <div class="time ">
                        {{planningRealisations.mainPlanningRealisation?.begindate | date : 'HH:mm'}}
                    </div>
                    <div class="between">
                        <div class="top">{{planningRealisations.project?.contractor ?? planningRealisations.planning?.contractor}}</div>
                        <div class="bottom">
                            {{planningRealisations.project?.afas_project_id ?? planningRealisations.planning?.worknumber}}
                        </div>
                    </div>
                    <div class="time end">
                        {{planningRealisations.mainPlanningRealisation?.enddate | date : 'HH:mm'}}
                    </div>
                </div>
                <div class="buttons">
                    <div class="comment" *ngIf="planningRealisations.project">
                        <button (click)="editProjectComment($event, planningRealisations.project)"
                                mat-icon-button
                                color="primary"
                                class="planning-button">
                            <i class="fas fa-file-lines"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="list" *ngIf="planningRealisations.showMaterial">
        <div *ngFor="let realisation of planningRealisations.entityRealisations; let even = even"
             id="realisation-{{realisation.id}}"
             [class.warn]="!even"
             class="list-item first-group last-group">
            <ng-container *ngTemplateOutlet="realisationRow;context:{realisation:realisation,project:planningRealisations.project}"></ng-container>
        </div>
    </div>
    <div class="header"
         *ngIf="planningRealisations.entityHiringRealisations?.length"
         (click)="planningRealisations.showHiredMaterial = !planningRealisations.showHiredMaterial">
        <div class="container-xl">
            <div class="content">
                <div class="name-wrapper">
                    <div class="name">Inhuur materieel ({{planningRealisations.entityHiringRealisations?.length}})</div>
                </div>
                <div class="prefix">
                    <button mat-icon-button color="primary" class="icon-button" [class.active]="planningRealisations.showHiredMaterial">
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
                <div class="times">
                    <div class="time">
                        {{planningRealisations.mainPlanningRealisation?.begindate | date : 'HH:mm'}}
                    </div>
                    <div class="between">
                        <div class="top">{{planningRealisations.project?.contractor ?? planningRealisations.planning?.contractor}}</div>
                        <div class="bottom">
                            {{planningRealisations.project?.afas_project_id ?? planningRealisations.planning?.worknumber}}
                        </div>
                    </div>
                    <div class="time end">
                        {{planningRealisations.mainPlanningRealisation?.enddate | date : 'HH:mm'}}
                    </div>
                </div>
                <div class="buttons"></div>
            </div>
        </div>
    </div>
    <div class="list" *ngIf="planningRealisations.showHiredMaterial">
        <div *ngFor="let realisation of planningRealisations.entityHiringRealisations; let even = even"
             [class.warn]="!even"
             class="list-item first-group last-group">
            <ng-container *ngTemplateOutlet="realisationRow;context:{realisation:realisation,project:planningRealisations.project}"></ng-container>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container-xl">
        <div class="buttons">
            <button mat-raised-button color="accent" class="planning-button" routerLink="/weekschedule">
                Weekoverzicht
            </button>
            <button mat-raised-button color="accent" class="planning-button" routerLink="/dagcontrole/{{date | date : 'yyyy-MM-dd'}}"
                    *ngIf="UserType.GENERAL_HOUR_CHECK | hasUserGroup">
                Dagcontrole
            </button>
            <button mat-raised-button color="primary" class="planning-button" (click)="add()"
                    *ngIf="UserType.ADD_REALISATION | hasUserGroup">
                Toevoegen
            </button>
        </div>
    </div>
</div>

<ng-template #realisationRow let-realisation=realisation let-project=project>
    <div class="container-xl">
        <div class="content"
             [class.removed]="!!realisation.removed"
             [class.approved]="!!realisation.approved"
             [class.not-editable]="realisation | notEditable"
             [class.pb-0]="realisation.comment_user || realisation.comment_manager">
            <div class="name-wrapper" *ngIf="realisation?.entity_id">
                <div class="name">
                    <ng-container *ngIf="realisation?.entity?.use_once">
                        {{realisation?.entity?.name}}
                    </ng-container>
                    <ng-container *ngIf="!realisation?.entity?.use_once">
                        {{realisation?.planning_has?.name}}
                        <small>{{realisation?.planning_has?.entitytype?.name}}</small>
                    </ng-container>

                </div>
                <div class="caption">
                    {{realisation?.planning_has?.driver_user?.name}}
                    <ng-container *ngIf="!realisation?.entity?.use_once">
                        {{realisation?.planning_has?.hiring?.name}}
                    </ng-container>
                </div>
                <div class="no-edit-reason" *ngIf="realisation | notEditable">
                    {{realisation | notEditable}}
                </div>
            </div>
            <div class="name-wrapper" *ngIf="realisation?.user_id">
                <div class="name"> {{realisation.user.name}}</div>
                <div class="caption" *ngIf="realisation.hourtype === RealisationHourtype.worktime">
                    <ng-container *ngIf="realisation?.planning_has?.entity?.use_once">
                        {{realisation?.planning_has?.entity?.name}}
                    </ng-container>
                    <ng-container *ngIf="!realisation?.planning_has?.entity?.use_once">
                        {{realisation?.planning_has?.name}}
                        <small>{{realisation?.planning_has?.entitytype?.name}}</small>
                    </ng-container>
                </div>
                <div *ngIf="realisation?.planning_has?.comment" class="comment">
                    {{realisation?.planning_has?.comment}}
                </div>
                <div class="no-edit-reason" *ngIf="realisation | notEditable">
                    {{realisation | notEditable}}
                </div>
                <div class="no-edit-reason" *ngIf="realisation.removed && !(realisation | notEditable)">
                    Deze registratie is verwijderd {{!realisation.approved ? 'door de gebruiker' : ''}}
                </div>
            </div>
            <div class="prefix">
                <ng-container *ngIf="realisation | isWorktime">
                    <button *ngIf="!(realisation | timeDifference)"
                            mat-icon-button disabled
                            [class.valid]="(UserType.HOUR_REGISTER | hasUserGroup : realisation.user) && realisation.submitted"
                            [class.invalid]="realisation.user_id && (UserType.HOUR_REGISTER | hasUserGroup : realisation.user) && !realisation.submitted"
                            class="icon-button">
                        <i class="fa-light fa-circle-check"
                           matTooltip="Volgens planning {{realisation.submitted ? 'ingediend' : ''}}"
                           *ngIf="(!(UserType.HOUR_REGISTER | hasUserGroup : realisation.user) || !realisation.user_id ) || realisation.submitted"></i>
                        <i class="fa-light fa-user-clock"
                           matTooltip="Volgens planning maar niet ingediend"
                           *ngIf="realisation.user_id && (UserType.HOUR_REGISTER | hasUserGroup : realisation.user) && !realisation.submitted"></i>
                    </button>
                    <button *ngIf="realisation | timeDifference"
                            mat-icon-button disabled
                            class="icon-button invalid">
                        <i class="fa-light fa-circle-exclamation"
                           *ngIf="realisation.user_planning"
                           matTooltip="Niet volgens planning, originele tijd {{realisation.user_planning?.begindate | date : 'HH:mm'}} tot {{realisation.user_planning?.enddate | date : 'HH:mm'}}"></i>
                        <i class="fa-light fa-circle-exclamation"
                           *ngIf="realisation.planning_has && !realisation.user_planning"
                           matTooltip="Niet volgens planning, originele tijd {{realisation.planning_has?.begindate | date : 'HH:mm'}} tot {{realisation.planning_has?.enddate | date : 'HH:mm'}}"></i>
                    </button>
                </ng-container>
                <button *ngIf="realisation | isTraveltime"
                        mat-icon-button disabled
                        class="icon-button">
                    <i class="fa-light fa-car-building"></i>
                </button>
                <button *ngIf="realisation.hourtype === RealisationHourtype.frost"
                        mat-icon-button disabled
                        matTooltip="Vorst"
                        class="icon-button">
                    <i class="fa-light fa-snowflake"></i>
                </button>
                <button *ngIf="realisation.hourtype === RealisationHourtype.day_off"
                        mat-icon-button disabled
                        matTooltip="Roostrvrij"
                        class="icon-button">
                    <i class="fa-light fa-calendar-range"></i>
                </button>
                <button *ngIf="realisation.hourtype === RealisationHourtype.illness"
                        mat-icon-button disabled
                        matTooltip="Ziek"
                        class="icon-button">
                    <i class="fa-light fa-face-thermometer"></i>
                </button>
                <button *ngIf="realisation.hourtype === RealisationHourtype.sleep"
                        mat-icon-button disabled
                        matTooltip="Slaapdag"
                        class="icon-button">
                    <i class="fa-light fa-face-sleeping"></i>
                </button>
                <button *ngIf="realisation.hourtype === RealisationHourtype.education"
                        mat-icon-button disabled
                        matTooltip="Scholing"
                        class="icon-button">
                    <i class="fa-light fa-school"></i>
                </button>
                <div class="counts">
                    <div>
                        <div class="pause pl-1 pr-1 ">
                            <i class="fas fa-clock"></i><br>
                            <ng-container *ngIf="realisation?.user_id">
                                {{(userMinutesMap?.get(realisation.user_id) - userPauseMap?.get(realisation.user_id)) | minutes}}
                            </ng-container>
                            <ng-container *ngIf="!realisation?.user_id">
                                {{entityMinutesMap?.get(realisation.entity_id) | minutes}}
                            </ng-container>
                        </div>
                    </div>
                    <div
                        *ngIf="realisation?.user_id && (realisation.hourtype === RealisationHourtype.worktime || realisation.hourtype === RealisationHourtype.education)">
                        <div class="pause pl-1 pr-1 curpoint" matRipple (click)="changePause(realisation.user_id)" [class.changed]="changedPauseUserId === realisation.user_id">
                            <i class="fas fa-mug-saucer mr-0"></i><br>
                            {{userPauseMap?.get(realisation.user_id) | minutes}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="break-mobile"></div>
            <div class="times">
                <div class="time"
                     [matTooltipDisabled]="(realisation | isTraveltime) || !(realisation | timeDifference : 'begindate' : formMap.get(realisation).value.begindate)"
                     matTooltip="Geplande tijd: {{realisation.user_planning?.begindate | date : 'HH:mm'}}{{realisation.planning_has?.begindate | date : 'HH:mm'}}"
                     (click)="editTime(realisation, 'begindate',-1)"
                     [class.difference]="!(realisation | isTraveltime) && (realisation | timeDifference : 'begindate' : formMap.get(realisation).value.begindate)">
                    {{formMap.get(realisation).value.begindate | date : 'HH:mm'}}
                </div>
                <div class="between">
                    <div class="top" *ngIf="realisation.hourtype !== RealisationHourtype.worktime">{{realisation.hourtype | hourtype}}</div>
                    <div class="top" *ngIf="project?.contractor || realisation.planning?.contractor">
                        {{project?.contractor ?? realisation.planning?.contractor}}
                    </div>
                    <div class="bottom" *ngIf="project?.afas_project_id || realisation.planning?.worknumber">
                        {{project?.afas_project_id ?? realisation.planning?.worknumber}}
                    </div>
                </div>
                <div class="time end"
                     [matTooltipDisabled]="(realisation | isTraveltime) || !(realisation | timeDifference : 'enddate' : formMap.get(realisation).value.enddate)"
                     matTooltip="Geplande tijd: {{realisation.user_planning?.enddate | date : 'HH:mm'}}{{realisation.planning_has?.enddate | date : 'HH:mm'}}"
                     (click)="editTime(realisation, 'enddate', 1)"
                     [class.difference]="!(realisation | isTraveltime) && (realisation | timeDifference : 'enddate' : formMap.get(realisation).value.enddate)">
                    {{formMap.get(realisation).value.enddate | date : 'HH:mm'}}
                </div>
            </div>
            <div class="buttons">
                <div class="toggles">
                    <div class="toggle"
                         [class.checked]="formMap.get(realisation).value.skip_performer && formMap.get(realisation).value.comment_manager"
                         (click)="skipPerformer(realisation)">
                        <i class="fa-regular fa-question"></i>
                    </div>
                    <div class="toggle"
                         *ngIf="!realisation.comment_user_approved"
                         [class.checked]="formMap.get(realisation).value.approved"
                         (click)="approve(realisation)">
                        <i class="fa-regular fa-check"></i>
                    </div>
                    <div class="toggle"
                         *ngIf="realisation.comment_user_approved"
                         [class.checked]="formMap.get(realisation).value.comment_user_approved_handled"
                         (click)="approve(realisation)">
                        <i class="fa-regular fa-check"></i>
                    </div>

                </div>
                <div class="comment">
                    <button (click)="editComment(realisation)"
                            mat-icon-button
                            color="primary"
                            class="planning-button">
                        <i class="fas fa-comment"></i>
                    </button>
                </div>
                <div class="remove">
                    <button (click)="restore(realisation)"
                            mat-icon-button
                            color="primary"
                            *ngIf="realisation.removed"
                            class="planning-button">
                        <i class="fas fa-trash-undo"></i>
                    </button>
                    <button (click)="remove(realisation)"
                            mat-icon-button
                            color="primary"
                            *ngIf="!realisation.removed"
                            class="planning-button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

            </div>
        </div>
        <div class="content justify-content-end pt-0" *ngIf="realisation.comment_user || realisation.comment_manager || realisation.comment_user_approved">
            <div class="comment">
                <div class="d-flex" *ngIf="realisation.comment_user">
                    <div class="mt-2">
                        <b>Toelichting: </b>
                        <span [innerHTML]="realisation.comment_user | nl2br"></span>
                    </div>
                </div>
                <div class="d-flex" *ngIf="realisation.comment_manager">
                    <div>
                        <b>Toelichting uitvoerder: </b>
                        <span [innerHTML]="realisation.comment_manager | nl2br"></span>
                    </div>
                </div>
                <div class="d-flex" *ngIf="realisation.comment_user_approved">
                    <div>
                        <b>Opmerking na goedkeuring: </b>
                        <span [innerHTML]="realisation.comment_user_approved | nl2br"></span>
                        <ng-container *ngIf="realisation.comment_user_approved_handled">
                            <br>Verwerkt: {{realisation.comment_user_approved_handled | date: 'd MMMM yyyy HH:mm'}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
