import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Utils} from '../utils.class';

@Pipe({
    name: 'entityIsNight'
})
export class EntityIsNightPipe implements PipeTransform {

    transform(day: Date, planningHasEntity: PlanningHasEntity[]): unknown {

        return planningHasEntity?.filter(up => Utils.isNight(up.begindate, up.enddate))?.length;
    }

}
