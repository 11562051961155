<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Inhuur mensen</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div mat-dialog-content *ngIf="!selectedWeek">
    <h2>Kies een week</h2>
    <table class="table table-bordered table-striped text-right table-hover">
        <thead>
        <tr>
            <th class="text-center" width="70">Week</th>
            <th>Van</th>
            <th>Tot</th>
        </tr>
        </thead>
        <tr (click)="showHire(week)" *ngFor="let week of weeks" class="curpoint">
            <td class="text-center">{{week.startDate | date: 'w'}}</td>
            <td>
                {{week.startDate | date: 'EEE dd MMM y'}}
            </td>
            <td>
                {{week.endDate | date: 'EEE dd MMM y'}}
            </td>
        </tr>
    </table>
    <i>Het is vandaag {{today | date:'EEEE dd MMMM y'}}</i>
</div>
<div mat-dialog-content *ngIf="selectedWeek">
    <div class="d-flex align-items-center">
        <button (click)="selectedWeek = null" color="primary" mat-icon-button tabindex="-1"><i class="fas fa-chevron-left"></i></button>
        <h2 class="mb-0">Inhuur week {{selectedWeek.startDate | date: 'w'}}</h2>
    </div>
    <table class="table table-bordered table-striped text-right table-hover">
        <ng-container *ngFor="let day of planningsPerDay" class="curpoint">
            <tr>
                <th class="text-left" colspan="2">
                    {{day.day | date: 'EEEE dd MMMM'}}
                </th>
                <th>
                    Aantal
                </th>
            </tr>
            <ng-container *ngFor="let planning of day.plannings">
                <tr *ngFor="let planningEntity of planning.planning_hire_people">
                    <td width="120">
                        {{planningEntity.begindate | date: 'HH:mm'}}
                        -
                        {{planningEntity.enddate | date: 'HH:mm'}}
                    </td>
                    <td>
                        {{entitiesMap.get(planningEntity.entity_id).name}}
                    </td>
                    <td>
                        {{planningEntity.count}}
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </table>
</div>
