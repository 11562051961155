import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'materialPiece'
})
export class MaterialPiecePipe implements PipeTransform {

    transform(name: string): string {
        if (!name) {
            return '';
        }
        return name.replace(/(\(.+\))/, "<span class='material-piece'>$1</span>");
    }

}
