import {Pipe, PipeTransform} from '@angular/core';
import {Entity} from '../classes/entity.class';
import {Planning} from '../classes/planning.class';
import {Project} from '../classes/project.class';
import {formatDate} from '@angular/common';

@Pipe({
    name: 'projectDayHasAnyTeam'
})
export class ProjectDayHasAnyTeamPipe implements PipeTransform {

    transform(
        day: {
            day: Date,
            project: Project
        },
        teams: { entity: Entity, }[],
        projectDayTeamMap: Map<string, Planning>,
        trigger?: any
    ): unknown {
        return !!teams.find(team => projectDayTeamMap.has(`${formatDate(day.day, 'yyyy-MM-dd', 'nl')}-${team.entity.id}-${day.project?.afas_project_id}`))?.entity;
    }

}
