import {Pipe, PipeTransform} from '@angular/core';
import {AsphaltService} from '../services/asphalt/asphalt.service';

@Pipe({
    name: 'asphaltApplication'
})
export class AsphaltApplicationPipe implements PipeTransform {

    transform(value: string): string {
        const application = [...AsphaltService.applicationListAdhesive, ...AsphaltService.applicationListAsphalt].find(a => a.key === value);
        if (application) {
            return application.name;
        }
        return '';
    }

}
