import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {User} from '../../../classes/user.class';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Address, LocationService} from '../../../services/location.service';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {FormGroupUserPlanning} from '../employees-dialog.component';
import {Planning} from '../../../classes/planning.class';

@Component({
    selector: 'app-address-dialog',
    templateUrl: './address-dialog.component.html',
    styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit {


    SelectedField = SelectedField;
    selectedField = SelectedField.work;
    user: User;
    planning: Planning;
    controls: FormGroup<ControlsOf<FormGroupUserPlanning>>;

    form = new FormGroup<ControlsOf<{
        origin: string,
        destination: string,
        work_begin: string,
        work_end: string,
    }>>({
        origin: new FormControl(),
        destination: new FormControl(),
        work_begin: new FormControl(),
        work_end: new FormControl()
    });
    to = false;

    dvdsAddress: Address;

    addresses: string[];

    search: FormControl<string> = new FormControl();

    constructor(public dialogRef: MatDialogRef<AddressDialogComponent>,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                @Inject(MAT_DIALOG_DATA) data: {
                    control: FormGroup<ControlsOf<FormGroupUserPlanning>>,
                    user: User,
                    planning: Planning
                },
                private locationService: LocationService) {
        this.controls = data.control;
        this.user = data.user;
        this.planning = data.planning;
        this.form.controls.origin.setValue(this.controls.value.origin);
        if (!this.form.value.origin) {
            this.choose(this.user, SelectedField.origin);
        }
        this.form.controls.destination.setValue(this.controls.value.destination);
        if (!this.form.value.destination) {
            this.choose(this.user, SelectedField.destination);
        }
        this.form.controls.work_begin.setValue(this.controls.value.work_begin);
        if (!this.form.value.work_begin) {
            this.choose(this.planning?.location, SelectedField.work_begin);
        }
        this.form.controls.work_end.setValue(this.controls.value.work_end);
        if (!this.form.value.work_end) {
            this.choose(this.planning?.location, SelectedField.work_end);
        }
    }

    ngOnInit(): void {
        this.dvdsAddress = this.locationService.dvdsAddress;
        let locationSearch = new Subscription();
        this.search.valueChanges.pipe(debounceTime(200)).subscribe(term => {
            this.addresses = [];
            locationSearch?.unsubscribe();
            locationSearch = new Subscription();
            locationSearch.add(this.locationService.getLocations(term += ' Nederland').subscribe(result => {
                result.resourceSets.forEach(res => {
                    res.resources.forEach(address => {
                        this.addresses.push(address.address.formattedAddress);
                    });
                });
            }));
        });
    }

    save() {
        this.controls.get(SelectedField.origin).setValue(this.form.get(SelectedField.origin).value);
        this.controls.get(SelectedField.destination).setValue(this.form.get(SelectedField.destination).value);
        this.controls.get(SelectedField.work_begin).setValue(this.form.get(SelectedField.work_begin).value);
        this.controls.get(SelectedField.work_end).setValue(this.form.get(SelectedField.work_end).value);
        this.dialogRef.close();
    }

    choose(address: any | Address, selectedField?: SelectedField) {
        if (!selectedField) {
            selectedField = this.selectedField;
        }
        address = address?.street ? this.locationService.formatAddress(address) : address;
        if (selectedField === SelectedField.work) {
            this.form.get(SelectedField.work_end).setValue(address);
            this.form.get(SelectedField.work_begin).setValue(address);
        } else {
            this.form.get(selectedField).setValue(address);
        }
    }

    close() {
        this.dialogRef.close();
    }

}

enum SelectedField {
    origin = 'origin',
    destination = 'destination',
    work_begin = 'work_begin',
    work_end = 'work_end',
    work = 'work'
}
