import {Pipe, PipeTransform} from '@angular/core';
import {Realisation, RealisationHourtype} from '../classes/realisation';

@Pipe({
    name: 'isWorktime'
})
export class IsWorktimePipe implements PipeTransform {

    transform(realisation: Realisation, ...args: unknown[]): unknown {
        return [RealisationHourtype.worktime, RealisationHourtype.driving_to, RealisationHourtype.driving_back].includes(realisation.hourtype);
    }

}
