import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {TimePickerService} from '../services/time-picker.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PlanningService} from '../services/planning/planning.service';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {bufferCount, concatMap, tap} from 'rxjs/operators';
import {combineLatest, forkJoin, from} from 'rxjs';
import {SettlementsService} from '../services/settlements.service';
import {Settlement} from '../classes/settlement.class';
import {Hourtype} from '../afas-classes/hourtype';
import {FormControl} from '@angular/forms';
import {EditSettlementDialogComponent} from './edit-settlement-dialog/edit-settlement-dialog.component';

@Component({
    selector: 'app-settlements-send',
    templateUrl: './settlements-send.component.html',
    styleUrls: ['./settlements-send.component.scss']
})
export class SettlementsSendComponent extends CodaltComponent implements OnChanges {

    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() user: User;
    allSettlements: Settlement[];
    hourtypes: Hourtype[];
    fcHideSent = new FormControl(false);
    fcShowOnlyMissing = new FormControl(false);
    itemsToSend = 0;
    sentCount = 0;
    correctCount = 0;

    constructor(private activatedRoute: ActivatedRoute,
                private timePickerService: TimePickerService,
                private dialog: MatDialog,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private planningService: PlanningService,
                private userService: UserService,
                private settlementsService: SettlementsService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getData();
    }

    private getData() {
        this.allSettlements = [];
        const userSettlements$ = this.settlementsService.getUserSettlements(this.beginDate, this.endDate, this.user.id);
        const hourtypes$ = this.settlementsService.getHourtypes();
        this.subscriptions.add(combineLatest([userSettlements$, hourtypes$]).subscribe(([settlements, hourtypes]) => {
            this.hourtypes = hourtypes.data;
            settlements.data
                .forEach(settlement => {
                    settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                    settlement.missing_values = !(settlement.afas_employee_id ?? settlement.realisation?.user?.afas_employee_id ?? settlement.user?.afas_employee_id);
                });
            this.allSettlements = settlements.data.sort((a, b) => (a.bookdate + a.description + a.id).localeCompare(b.bookdate + b.description + b.id));
            this.calculateItemsToSend();
        }));
    }

    calculateItemsToSend() {
        this.itemsToSend = this.allSettlements.filter(s => !s.missing_values && !s.afas_ptrealization_id && s.minutes !== 0).length;
        this.sentCount = this.allSettlements.filter(s => !!s.afas_ptrealization_id).length;
        this.correctCount = this.allSettlements.filter(s => !s.missing_values).length;
    }

    editSettlement(settlement: Settlement, editOnly: 'project' | 'hourtype') {
        if ((settlement.afas_error || !settlement.send_to_afas) && !settlement.afas_ptrealization_id) {
            const ref = this.dialog.open(EditSettlementDialogComponent, {
                maxWidth: '500px',
                width: '100vw',
                maxHeight: '100%',
                disableClose: false,
                panelClass: 'comment-edit-dialog',
                data: {
                    settlement,
                    bookdate: this.beginDate,
                    editOnly
                }
            });
            ref.afterClosed().subscribe(() => {
                this.getData();
            });
        }
    }

    sendAllToAfas() {
        const allSett$ = [];
        this.allSettlements.forEach(settlement => {
            if (!settlement.missing_values && !settlement.afas_ptrealization_id) {
                settlement.afas_error = null;
                settlement.afas_project_id = settlement.afas_project_id ?? settlement.realisation?.afas_project_id ?? settlement.realisation?.planning?.afas_project_id ?? settlement.realisation?.user_planning?.afas_project_id;
                settlement.afas_employee_id = settlement.afas_employee_id ?? settlement.user?.afas_employee_id;
                settlement.sent_to_afas = null;
                settlement.send_to_afas = new Date();
                allSett$.push(this.settlementsService.sendSingle(settlement).pipe(tap(response => {
                    Object.assign(settlement, response.data);
                    this.calculateItemsToSend();
                })));
            }
        });
        this.subscriptions.add(from(allSett$).pipe(bufferCount(2), concatMap(b => forkJoin(b))).subscribe());
    }

    sendToAfas(settlement: Settlement) {
        settlement.afas_error = null;
        settlement.afas_project_id = settlement.afas_project_id ?? settlement.realisation?.afas_project_id ?? settlement.realisation?.planning?.afas_project_id ?? settlement.realisation?.user_planning?.afas_project_id;
        settlement.afas_employee_id = settlement.afas_employee_id ?? settlement.user?.afas_employee_id;
        settlement.sent_to_afas = null;
        settlement.send_to_afas = new Date();
        this.settlementsService.sendSingle(settlement).subscribe(response => {
            Object.assign(settlement, response.data);
            this.calculateItemsToSend();
        });
    }
}

