<div class="controls">
    <div class="header">
        <app-header (clicked)="setCenterDate()"
                    [print]="print"
                    [mainTitle]="environment.isCodalt ? 'Planning Codalt' : 'Planning DvdS'"
                    [subTitle]="environment.isCodalt ? 'Overzicht' : 'Dashboard'">
        </app-header>
    </div>
    <div class="indicator">
        <div class="team-selector flex-column d-flex" *ngIf="!mobile">
            <app-planning-filter (selectedTeams)="selectedTeamsChange($event)"></app-planning-filter>
        </div>
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [fromDate]="fromDate"
                            [primaryWeekEnd]="primaryWeekEnd"
                            [primaryWeekStart]="primaryWeekStart"
                            [print]="print"
                            [toDate]="toDate">
        </app-date-indicator>
    </div>
    <div *ngIf="!print" class="actions">
        <app-settings-menu (selectedTeams)="selectedTeamsChange($event)"></app-settings-menu>
    </div>
</div>
<div [class.print]="print" class="planning-overview">
    <div [class.print]="print" class="legend">
        <div class="fakedate"></div>
        <div *ngIf="!planningEntities" class="loading">
            Laden...
        </div>
        <div *ngFor="let entity of planningEntities; let i = index;"
             [class.no-plannings]="!entityIdPlanningCount.get(entity.id)"
             [class.big]="entity.entitytypes[0]?.is_team"
             [class.divider]="entity.entitytypes[0]?.id !== planningEntities[i+1]?.entitytypes[0]?.id"
             [matTooltip]="'entitytype-' + entity.entitytype_id | tt"
             class="entity">
            <div class="icon"><i [class]="entity.entitytypes[0]?.icon"></i></div>
            {{entity.name}}
        </div>
        <div [matTooltip]="'trucks' | tt" class="trucks">
        </div>
        <div [matTooltip]="'tons' | tt" class="tons">
        </div>
    </div>
    <div class="days">
        <app-day (mouseEnterPlanning)="mouseEnterDate($event)"
                 (mouseLeavePlanning)="mouseLeaveDate($event)"
                 (openPlanning)="openPlanning($event)"
                 (setFixed)="setFixed($event)"
                 *ngFor="let day of planningDays"
                 [day]="day"
                 [entityIdPlanningCount]="entityIdPlanningCount"
                 [fixedUntil]="fixedUntil"
                 [fromDate]="fromDate"
                 [primaryWeekEnd]="primaryWeekEnd"
                 [primaryWeekStart]="primaryWeekStart"
                 [print]="print"
                 [toDate]="toDate"
                 [viewModel]="viewModel">
        </app-day>
    </div>
</div>
