import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Planning} from "../../classes/planning.class";
import {Platform} from "@angular/cdk/platform";

@Component({
    selector: 'app-planning-detail-container',
    templateUrl: './planning-detail-container.component.html',
    styleUrls: ['./planning-detail-container.component.scss']
})
export class PlanningDetailContainerComponent implements OnInit, AfterViewInit {

    loadChild = false;

    constructor(@Inject(MAT_DIALOG_DATA) public planning: Planning, public platform: Platform,
                public dialogRef: MatDialogRef<PlanningDetailContainerComponent>) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        let timeOut = null;
        if (this.platform.IOS || this.platform.SAFARI) {
            timeOut = 200;
        }
        setTimeout(() => {
            this.loadChild = true;
        }, timeOut);
    }

}
