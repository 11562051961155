import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'split'
})
export class SplitPipe implements PipeTransform {

    transform(value: string, index = 0, splitOn = ','): unknown {
        const splitted = value?.split(splitOn,);
        if (splitted && typeof splitted[index] !== 'undefined') {
            return splitted[index];
        }
        return '';
    }

}
