import {PlanningStatus} from '../../planning-status.enum';
import {PlanningDetailDialogComponent} from './planning-detail-dialog.component';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {EntitiesListAbstractComponent} from './entities-list-abstract.component';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';

export class EntitiesListAbstractComponentRules {

    static dateRule = (component: PlanningDetailDialogComponent) => {
        return component.planning.status_id !== PlanningStatus.bakvanwessel;
    };

    static begintimeRule = EntitiesListAbstractComponentRules.dateRule;
    static endtimeRule = EntitiesListAbstractComponentRules.dateRule;

    static hiring_idRule = (component: PlanningDetailDialogComponent,
                       entityHas: PlanningCutter | PlanningHasEntity) => {
        return entityHas.entity && !entityHas.entity.use_once;
    };

}


export function RequiredRule(component: EntitiesListAbstractComponent<any>,
                             entityHas?: PlanningCutter | PlanningHasEntity): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const getControlName = (c: AbstractControl): string | null => {
            if (c && c.parent) {
                const formGroup = c.parent.controls;
                return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
            }
        };

        // controlName is empty on initializing because parent has not been set yet
        const controlName = getControlName(control);
        if (controlName) {
            const rule = EntitiesListAbstractComponentRules[controlName + 'Rule'];
            if (!rule) {
                throw new Error(('No rule defined for ' + controlName + 'Rule, please define rule in entities-list-abstract-component.required-rules.ts'));
            }

            if (
                (control.value == null || control.value === undefined
                    || (Array.isArray(control.value) && control.value.length < 1)) &&
                rule.call(null, component, entityHas)
            ) {
                return {required: true};
            }
            return null;
        }
        return {required: true};
    };


}
