import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {CodaltComponent} from '../codalt.component';
import {ProjectService} from '../services/project.service';
import {ProjectComment} from '../classes/project-comment';
import {Utils} from 'src/app/utils.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Project} from '../classes/project.class';
import {AsphaltUsedListComponent} from './asphalt-used-list/asphalt-used-list.component';
import {combineLatest} from 'rxjs';
import {PlanningService} from '../services/planning/planning.service';
import {debounceTime, first} from 'rxjs/operators';
import {EntitiesService} from '../services/entities/entities.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-project-day-report',
    templateUrl: './project-day-report.component.html',
    styleUrls: ['./project-day-report.component.scss']
})
export class ProjectDayReportComponent extends CodaltComponent implements OnInit {

    projectComments: ProjectComment[];

    form: FormGroup<ControlsOf<{ comment: string }>>;

    saving = false;

    date = new Date();
    projectId: string;
    project: Project;

    @Input() print = false;

    @ViewChild('asphaltUsedList') asphaltUsedListComponent: AsphaltUsedListComponent;
    @ViewChild('asphaltAdhesiveList') asphaltAdhesiveListComponent: AsphaltUsedListComponent;

    private currentComment: ProjectComment;

    constructor(private activatedRoute: ActivatedRoute,
                private planningService: PlanningService,
                private entitiesService: EntitiesService,
                private confirmDialogService: ConfirmDialogService,
                private router: Router,
                private projectService: ProjectService) {
        super();
    }

    next() {
        this.date.setDate(this.date.getDate() + 1);
        this.router.navigateByUrl(`project-dagrapport/${this.projectId}/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    prev() {
        this.date.setDate(this.date.getDate() - 1);
        this.router.navigateByUrl(`project-dagrapport/${this.projectId}/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    calculateAdhesives() {
        const list = this.asphaltUsedListComponent.projectUsedAsphaltList;
        const types = [
            {application: 'base', name: 'Modimuls'},
            {application: 'bind', name: 'Emulsie'}
        ];
        types.forEach(type => {
            const typeCount = list.filter(l => l.application === type.application)
                .reduce((sum, current) => sum + current.surface, 0);
            if (typeCount > 0) {
                this.asphaltAdhesiveListComponent.projectUsedAsphaltList.push({
                    afas_project_id: this.projectId,
                    date: this.date,
                    surface: typeCount,
                    asphaltname: type.name,
                    liter: Math.round(100 * typeCount * .2) / 100,
                    application: 'adhesive_' + type.application
                });
            }
        });
    }

    copyAsphalt() {
        const nextDay = Utils.newDate(this.date);
        nextDay.setUTCDate(nextDay.getUTCDate() + 1);
        const entities$ = this.entitiesService.getMap();
        const planning$ = this.planningService.getFilteredList(this.date, nextDay).pipe(debounceTime(50)).pipe(first());
        combineLatest([entities$, planning$]).subscribe(([entitiesMap, plannings]) => {
            const z = plannings.filter(planning => planning.asphalt_list?.length > 0).map(planning => {
                const main = this.planningService.getMainPlanning(planning);
                main.entity = entitiesMap.get(main.entity_id);
                let dateString = formatDate(main.begindate, 'EEE dd MMM yyyy HH:mm', 'nl') + ' - ';
                if (Utils.getDateDate(main.begindate) !== Utils.getDateDate(main.enddate)) {
                    dateString += formatDate(main.enddate, 'EEE dd MMM yyyy', 'nl');
                }
                dateString += formatDate(main.enddate, 'HH:mm', 'nl');
                return {
                    name: planning.afas_project_id + ' - ' + main.entity.name + ' - ' + (planning.asphalt_list?.length ?? 0) + ' asfaltregels',
                    subtext: dateString,
                    planning,
                    main,
                    bgColor: planning.afas_project_id === this.projectId ? '#dedce9' : null
                };
            });
            this.confirmDialogService.confirm('Kies een project', null, 'Sluiten', null, z).then(result => {
                this.asphaltUsedListComponent.projectUsedAsphaltList.push(...result.planning.asphalt_list.map(al => {
                    return {
                        asphaltmill_asphalt_id: al.asphaltmill_asphalt_id,
                        afas_project_id: this.projectId,
                        date: this.date,
                        asphaltmill_id: al.asphaltmill_id,
                        asphaltname: al.asphaltname,
                        asphaltcode: al.asphaltcode,
                        tons: al.tons,
                        surface: null,
                        application: null,
                        thickness: al.thickness,
                        comment: al.comment
                    };
                }));
            });
        });
    }


    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { project, date }) => {
            this.projectId = params.project;
            this.date = new Date(params.date);
            this.loadData();
        }));
    }

    loadData() {
        this.subscriptions.add(this.projectService.getProject(this.projectId).subscribe(project => {
            this.project = project.data;
        }));

        this.subscriptions.add(this.projectService.getProjectAsphalt(this.projectId, this.date).subscribe(ua => {
            this.asphaltUsedListComponent.projectUsedAsphaltList = ua.data.filter(pal => pal.application !== 'adhesive_base' && pal.application !== 'adhesive_bind');
            this.asphaltAdhesiveListComponent.projectUsedAsphaltList = ua.data.filter(pal => pal.application == 'adhesive_base' || pal.application == 'adhesive_bind');
        }));

        this.subscriptions.add(this.projectService.getProjectCommentsWeek(this.projectId, this.date).subscribe(projectComments => {
            this.currentComment = projectComments.data.find(pc => Utils.dateString(pc.date) === Utils.dateString(this.date)) ?? new ProjectComment();
            this.form = new FormGroup({
                comment: new FormControl(this.currentComment?.comment)
            });
            this.projectComments = projectComments.data.filter(p => p !== this.currentComment);
        }));
    }

    applyAndClose() {
        Promise.all([
            this.asphaltUsedListComponent.validateForms(),
            this.asphaltAdhesiveListComponent.validateForms()
        ]).then(() => {
            this.saving = true;
            this.currentComment.afas_project_id = this.projectId;
            this.currentComment.date = this.date;
            this.currentComment.comment = this.form.controls.comment.value;
            this.subscriptions.add(combineLatest([this.projectService.saveProjectAsphalt(
                this.projectId,
                this.date,
                [...this.asphaltUsedListComponent.projectUsedAsphaltList, ...this.asphaltAdhesiveListComponent.projectUsedAsphaltList]),
                this.projectService.saveProjectComment(this.currentComment)
            ]).subscribe(() => {
                this.saving = false;
                this.loadData();
            }));
        }, () => {
            this.confirmDialogService.confirm('Fout in formulier',
                'Niet alle velden zijn juist gevuld. Verbeter deze velden en probeer opnieuw op te slaan.',
                'Sluiten', null).then(() => {

            });
        });

    }

}
