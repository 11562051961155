import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';
import {formatDate} from '@angular/common';

@Pipe({
    name: 'minutes'
})
export class MinutesPipe implements PipeTransform {

    transform(value: number, ...args: unknown[]): string {
        if (value && typeof value === 'number') {
            let calcVal = value;
            let remain = 0;
            if (value >= 1440) {
                remain = value - (value % 1440);
                calcVal = (value % 1440);
            }
            const date = Utils.setTime(new Date(), 0, 0).setMinutes(Math.abs(calcVal));
            const time = formatDate(date, 'H:mm', 'nl').split(':');
            return `${calcVal < 0 ? '-' : ''}${+time[0] + (remain / 60)}:${time[1]}`;
        }
        return '0:00';
    }

}
