import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {HiringService} from '../../services/hiring.service';
import {Hiring} from '../../classes/hiring';
import {EntitiesService} from '../../services/entities/entities.service';
import {EntityType} from '../../services/entities/entity-type.class';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';

@Component({
    selector: 'app-hiring-company-edit-dialog',
    templateUrl: './hiring-company-edit-dialog.component.html',
    styleUrls: ['./hiring-company-edit-dialog.component.scss']
})
export class HiringCompanyEditDialogComponent implements OnInit {

    title: string;
    saving = false;
    submitted = false;
    entityTypes: EntityType[];

    form: FormGroup<ControlsOf<{
        name: string,
        phone: string,
        email: string,
        entityTypes: any;
    }>>;

    constructor(@Inject(MAT_DIALOG_DATA) public hiring: Hiring,
                private confirmDialog: ConfirmDialogService,
                public dialogRef: MatDialogRef<HiringCompanyEditDialogComponent>,
                private hiringService: HiringService,
                private entitiesService: EntitiesService) {
    }

    ngOnInit(): void {
        if (this.hiring) {
            this.createForm(this.hiring);
        }
        this.entitiesService.getTypes().subscribe(types => {
            this.entityTypes = types.data.filter(type => !type.is_team);
        });
    }

    createForm(hiring: Hiring) {
        this.form = new FormGroup({
            name: new FormControl(hiring.name),
            phone: new FormControl(hiring.phone),
            email: new FormControl(hiring.email),
            entityTypes: new FormControl(hiring.entitytypes.map(e => e.id))
        });

        this.form.controls.name.disable();
        this.form.controls.phone.disable();
        this.form.controls.email.disable();
    }

    save() {
        this.submitted = true;
        this.saving = true;
        if (this.form.valid) {
            Object.assign(this.hiring, this.form.value);
            this.hiringService.save(this.hiring).then(hiring => {
                this.saving = false;
                this.close();
            }, error => {
                this.saving = false;
                this.confirmDialog.confirm(
                    'Fout tijdens het opslaan',
                    'Er is iets fout gegaan tijdens het opslaan',
                    'Oké', null);
            });
        } else {
            for (const name of Object.keys(this.form.controls)) {
                this.form.controls[name].markAsTouched();
            }
            this.saving = false;
        }

    }

    close() {
        this.dialogRef.close();
    }

}
