<div class="weeks" [class.print]="print">
    <div class="head">
        <div class="part">
            <div class="cell wide">Dag</div>
            <div class="cell wide">Datum</div>
            <div class="cell wide">Invulling</div>
        </div>
        <div class="part mr-0">
            <div class="cell">Start</div>
            <div class="cell">Stop</div>
            <div class="cell">Onderbreking</div>
            <div class="cell">Uren</div>
            <div class="cell">Pauze</div>
            <div class="cell">Uren te verlonen</div>


        </div>
        <div class="buttons">
            <div class="week-button"
                 matTooltip="Hele periode verlonen"
                 (mouseover)="hoverWeekButton = 'all'"
                 (mouseout)="hoverWeekButton = null">
                <button mat-icon-button color="primary" [disabled]="settling" (click)="settlePeriodHours()" *ngIf="!print">
                    <i class="fa-light fa-calendars" *ngIf="!settling"></i>
                    <i class="fas fa-spinner fa-spin" *ngIf="settling"></i>
                </button>
            </div>
        </div>
        <div class="part">
            <div class="cell">Reeds uitbetaald</div>
            <div class="cell">Nog te verrekenen</div>
            <div class="cell">Reistijd</div>
        </div>
        <div class="part">
            <div class="cell" *ngIf="caoBouwInfra">Toeslag 30%</div>
            <div class="cell" *ngIf="!caoBouwInfra">Toeslag 35%</div>
            <div class="cell" *ngIf="!caoBouwInfra">Toeslag 45%</div>
            <div class="cell">Toeslag 50%</div>
        </div>
        <div class="part">
            <div class="cell">Overuren 100%</div>
            <div class="cell" *ngIf="caoBouwInfra">Overuren 125%</div>
            <div class="cell">Overuren 130%</div>
            <div class="cell" *ngIf="!caoBouwInfra">Overuren 135%</div>
            <div class="cell" *ngIf="!caoBouwInfra">Overuren 145%</div>
            <div class="cell">Zaterdag 150%</div>
            <div class="cell">Zondag 200%</div>
        </div>
    </div>
    <div *ngFor="let week of weeks" class="week">
        <div class="weeknumber">Week {{week.weeknumber}}</div>
        <div class="days">
            <div *ngFor="let day of week.weekDays; let index = index; let first = first;  let last = last" class="day"
                 (contextmenu)="showDayDetails($event, day)"
                 [class.paid]="day.sendToAfas"
                 [class.show-detail]="day === dayDetail"
                 [class.weekend]="index >= 5">
                <div class="part">
                    <div class="cell wide">
                        <i class="fa fa-triangle-exclamation mr-2" *ngIf="day.hasUnapprovedRealisations"
                           matTooltip="Er zijn niet goedgekeurde uren op deze dag"></i>
                        {{day.date | date : day.hasUnapprovedRealisations ? 'EEE' : 'EEEE'}}
                    </div>
                    <div class="cell wide">{{day.date | date : 'dd-MM-yyyy'}}</div>
                    <input class="cell wide" type="text" [formControl]="day.fg.controls.dayType">
                </div>
                <div class="part mr-0">
                    <input class="cell" type="text" [formControl]="day.fg.controls.begindate">
                    <input class="cell" type="text" [formControl]="day.fg.controls.enddate">
                    <app-minutes-input class="cell"
                                       [matTooltip]="day.fg.value.interruptions < 0 ? 'Bij negatieve onderbrekingen zijn er overlappende uren geboekt': ''"
                                       [formControl]="day.fg.controls.interruptions"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.hours"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.pause"></app-minutes-input>
                    <app-minutes-input class="cell hours-to-reward" [formControl]="day.fg.controls.hoursToReward"></app-minutes-input>

                </div>
                <div class="buttons">
                    <div class="week-button"
                         *ngIf="week.sendToAfas && !print"
                         matTooltip="Hele week terugdraaien"
                         (mouseover)="hoverWeekButton = week.weeknumber"
                         (mouseout)="hoverWeekButton = null">
                        <button *ngIf="first" [disabled]="week.settling" mat-icon-button color="primary" (click)="removeWeek(week)">
                            <i class="fa-light fa-trash" *ngIf="!week.settling"></i>
                            <i class="fas fa-spinner fa-spin" *ngIf="week.settling"></i>
                        </button>
                    </div>
                    <div class="week-button"
                         *ngIf="!week.sendToAfas && !print"
                         matTooltip="Hele week verlonen"
                         (mouseover)="hoverWeekButton = week.weeknumber"
                         (mouseout)="hoverWeekButton = null">
                        <button *ngIf="first" [disabled]="week.settling" mat-icon-button color="primary" (click)="settleWeekHours(week)">
                            <i class="fa-light fa-right-to-bracket" *ngIf="!week.settling"></i>
                            <i class="fas fa-spinner fa-spin" *ngIf="week.settling"></i>
                        </button>
                    </div>
                    <div class="cell" *ngIf="!print"
                         [class.first]="first"
                         [class.last]="last">
                        <button [disabled]="week.settling"
                                class="hour-edit-button"
                                mat-icon-button color="primary"
                                (click)="editHours(day.date, user.id)">
                            <i class="fas fa-spinner fa-spin" *ngIf="day.settling"></i>
                            <i class="fa-regular fa-user-pen" *ngIf="!day.settling"></i>
                        </button>
                    </div>
                </div>
                <div class="part">
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.normal"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.remaining"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.traveltime"></app-minutes-input>
                </div>
                <div class="part">

                    <app-minutes-input class="cell" *ngIf="caoBouwInfra" [formControl]="day.fg.controls.bonus30"></app-minutes-input>
                    <app-minutes-input class="cell" *ngIf="!caoBouwInfra" [formControl]="day.fg.controls.bonus35"></app-minutes-input>
                    <app-minutes-input class="cell" *ngIf="!caoBouwInfra" [formControl]="day.fg.controls.bonus45"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.bonus50"></app-minutes-input>
                </div>
                <div class="part">
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.overtime100"></app-minutes-input>
                    <app-minutes-input class="cell" *ngIf="caoBouwInfra" [formControl]="day.fg.controls.overtime125"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.overtime130"></app-minutes-input>
                    <app-minutes-input class="cell" *ngIf="!caoBouwInfra" [formControl]="day.fg.controls.overtime135"></app-minutes-input>
                    <app-minutes-input class="cell" *ngIf="!caoBouwInfra" [formControl]="day.fg.controls.overtime145"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.overtime150"></app-minutes-input>
                    <app-minutes-input class="cell" [formControl]="day.fg.controls.overtime200"></app-minutes-input>
                </div>
            </div>
        </div>
    </div>
    <div class="footer" *ngIf="fcNamePeriodTotalMap">
        <div class="part">
            <div class="cell wide triple">
            </div>
        </div>
        <div class="part mr-0">
            <div class="cell large"></div>
            <div class="cell large"></div>
            <div class="cell large"></div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('hours') | minutes}}</div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('pause') | minutes}}</div>
            <div class="cell">
                <div class="total">{{fcNamePeriodTotalMap.get('hoursToReward') | minutes}}</div>
                <span class="caption">Totaal</span>
            </div>

        </div>
        <div class="buttons">
            <div class="cell">

            </div>
        </div>
        <div class="part">
            <div class="cell">
                <div class="total">{{fcNamePeriodTotalMap.get('normal') | minutes}}</div>
                <span class="caption">Contracturen</span>
            </div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('remaining') | minutes}}</div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('traveltime') | minutes}}</div>
        </div>
        <div class="part">

            <div class="cell large" *ngIf="caoBouwInfra">{{fcNamePeriodTotalMap.get('bonus30') | minutes}}</div>
            <div class="cell large" *ngIf="!caoBouwInfra">{{fcNamePeriodTotalMap.get('bonus35') | minutes}}</div>
            <div class="cell large" *ngIf="!caoBouwInfra">{{fcNamePeriodTotalMap.get('bonus45') | minutes}}</div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('bonus50') | minutes}}</div>
        </div>
        <div class="part">
            <div class="cell large">{{fcNamePeriodTotalMap.get('overtime100') | minutes}}</div>
            <div class="cell large" *ngIf="caoBouwInfra">{{fcNamePeriodTotalMap.get('overtime125') | minutes}}</div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('overtime130') | minutes}}</div>
            <div class="cell large" *ngIf="!caoBouwInfra">{{fcNamePeriodTotalMap.get('overtime135') | minutes}}</div>
            <div class="cell large" *ngIf="!caoBouwInfra">{{fcNamePeriodTotalMap.get('overtime145') | minutes}}</div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('overtime150') | minutes}}</div>
            <div class="cell large">{{fcNamePeriodTotalMap.get('overtime200') | minutes}}</div>
        </div>
        <div class="part">
            <div class="cell">
                <div class="total">{{fcNamePeriodTotalMap.get('overtime') | minutes}}</div>
                <span class="caption">Overuren</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="print">
    Document gegenereerd op: {{today | date : 'EEEE d MMMM y HH:mm'}}
</div>

<div class="day-details" *ngIf="dayDetail">
    <div class="head">
        <h2>
            Resulterende verloningen {{dayDetail.date | date : 'EEEE d MMMM yyyy'}}
        </h2>
        <button mat-icon-button color="primary" (click)="dayDetail = null">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <table class="table">
        <tr>
            <th>
                Tijd
            </th>
            <th>
                Uursoort
            </th>
            <th>
                Beschrijving
            </th>
        </tr>
        <tr *ngFor="let settlement of dayDetail.settlements">
            <td>
                {{settlement.minutes | minutes}}
            </td>
            <td>
                {{settlement.hourtype?.description}} ({{settlement.hourtype?.code}})
            </td>
            <td>
                {{settlement.description}}
            </td>
        </tr>
    </table>
</div>
