import {Entity} from './entity.class';
import {Planning} from './planning.class';
import {Hiring} from './hiring';
import {EntityType} from '../services/entities/entity-type.class';

export class PlanningAsfaltteam {
    entitytype_id: number;
    entity_id: number;
    entitytype?: EntityType;
    planning_id: number;
    driver_user_id: number;
    truck_entity_id: number;
    lowloader_entity_id: number;
    lowloader_entity?: Entity;
    begindate: Date;
    enddate: Date;
    name: string;
    comment: string;
    created_at: Date;
    updated_at: Date;
    id: number;
    count: number;
    hiring_id: number;
    hiring?: Hiring;
    entity: Entity;
    planning: Planning;
    origin: string;
    destination: string;
}
