<table class="table">
    <thead>
    <tr>
        <th>Bestuurder</th>
        <th width="100">Materieel</th>
        <th colspan="2">Ingepland</th>
    </tr>
    </thead>
    <ng-container *ngFor="let type of types">
        <thead *ngIf="typeMap.get(type.id)?.length > 0">
        <tr>
            <th class="text-center" colspan="4">{{type.name}}</th>
        </tr>
        </thead>
        <tr *ngFor="let entity of typeMap.get(type.id)">
            <td [class.downlight]="!planningMap.get(entity.id) && usedDrivers.indexOf(entity.driver_user_id) !== -1">
                <ng-container *ngIf="!planningMap.get(entity.id)">
                    {{(users | byId: entity.driver_user_id)?.name}}
                </ng-container>
                <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                    {{(users | byId: phe.driver_user_id)?.name}}<br>
                </ng-container>
            </td>
            <td>
                <div class="entity-name">
                    {{entity.name | licensePlate}}
                </div>
            </td>
            <td>
                <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                    {{phe.afas_project_id ?? (plannings | byId : phe.planning_id)?.afas_project_id ?? (plannings | byId : phe.planning_id)?.worknumber ?? '???'}}<br>
                </ng-container>
            </td>
            <td>
                <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                    <ng-container *ngIf="Utils.getDateDate(phe.begindate) !== Utils.getDateDate(currentDate)">
                        {{phe.begindate | date : 'EEE'}}
                    </ng-container>
                    {{phe.begindate | date : 'HH:mm'}}-{{phe.enddate | date : 'HH:mm'}}<br>
                </ng-container>
            </td>
        </tr>
        <ng-container *ngIf="type.id === 430004">
            <thead>
            <tr>
                <th class="text-center" colspan="4">Niet ingeplande chauffeurs & machinisten</th>
            </tr>
            </thead>
            <ng-container *ngFor="let driver of drivers">
                <tr *ngIf="usedDrivers.indexOf(driver.id) === -1">
                    <td>
                        {{driver.name}}
                    </td>
                    <td colspan="3">
                        {{driver.function}}
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>
</table>

