import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {EmployeePlanningComponent} from './employee-planning.component';

@Injectable()
export class CanDeactivateEmployeePlanning<T extends EmployeePlanningComponent> implements CanDeactivate<T> {

    constructor() {
    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.userPlanningsToSave?.length > 0) {
            return component.openChangesBackActionCheck();
        }
        return true;
    }
}
