<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Inhuur</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div *ngIf="form" class="row">
    <form [formGroup]="form" class="col-12">
        <mat-form-field class="w-100" id="name">
            <input [formControl]="form.controls.name" matInput name="name" placeholder="Naam" type="text">
        </mat-form-field>
        <mat-form-field class="w-100" id="phone">
            <input [formControl]="form.controls.phone" matInput name="phone" placeholder="Telefoon" type="text">
        </mat-form-field>
        <mat-form-field class="w-100" id="email">
            <input [formControl]="form.controls.email" matInput name="email" placeholder="E-mailadres" type="text">
        </mat-form-field>

        <h3>Verhuur van materieel</h3>
        <mat-selection-list [formControl]="form.controls.entityTypes">
            <mat-list-option *ngFor="let entityType of entityTypes"
                             [value]="entityType.id"
                             checkboxPosition="before">
                {{entityType.name}}
            </mat-list-option>
        </mat-selection-list>
    </form>
    <div class="col-12">
        <div class="d-flex justify-content-between mt-5">
            <button (click)="close()" mat-button>Annuleren</button>

            <button (click)="save()" [disabled]="saving" color="primary" mat-raised-button>
                Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
            </button>
        </div>
    </div>
</div>
