import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from './utils.class';

@Pipe({
    name: 'isSubsequent'
})
export class IsSubsequentPipe implements PipeTransform {

    transform(date: Date): boolean {
        if (date) {
            return Utils.newDate(date).getUTCMinutes() % 15 !== 0;
        }
        return false;
    }

}
