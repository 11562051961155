import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {Utils} from '../utils.class';
import {formatDate} from '@angular/common';
import {FormControl} from '@ngneat/reactive-forms';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';

@Component({
    selector: 'app-settlement',
    templateUrl: './settlement.component.html',
    styleUrls: ['./settlement.component.scss']
})
export class SettlementComponent extends CodaltComponent implements OnInit {

    @HostBinding('class.print') print = false;

    beginDate: Date;
    endDate: Date;

    year: number;
    period: number;

    fcUserId = new FormControl<number>();

    employees: User[];
    user: User;

    screenContent: 'verrekening' | 'boekingsregels';

    daytypeCounts: { type: string; count: number; minutes: number; }[] = [];

    constructor(private userService: UserService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private title: Title) {
        super();
        this.title.setTitle('Periodeverrekening' + environment.titleAppend);
    }

    ngOnInit(): void {
        const queryParams = this.activatedRoute.snapshot.queryParams as { print };
        this.print = !!queryParams?.print;
        this.subscriptions.add(this.fcUserId.valueChanges.subscribe(userId => {
            this.router.navigateByUrl(`periode/${this.screenContent}/${userId}/${this.period}/${this.year}${this.print ? '?print=true' : ''}`);
        }));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { userId, period, year, type }) => {
            this.screenContent = params.type;
            const monday = new Date();
            if (params?.year) {
                monday.setFullYear(params.year);
            }
            Utils.setTime(monday, 0, 0);
            const year = monday.getFullYear();
            monday.setMonth(0);
            monday.setDate(1);
            monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
            if (year > monday.getFullYear()) {
                monday.setDate(monday.getDate() + 7);
            }
            let today = new Date();
            if (params.period) {
                const period = +params.period;
                today = new Date(monday);
                today.setDate(today.getDate() + (28 * (period - 1)));
            }
            let currentWeek = +formatDate(today, 'w', 'nl');
            if (today.getDay() === 0) {
                currentWeek -= 1;
            }
            currentWeek--;
            const periodStartWeek = (currentWeek - (currentWeek % 4));
            this.beginDate = new Date(monday);
            this.beginDate.setDate(this.beginDate.getDate() + (periodStartWeek * 7));
            this.endDate = new Date(this.beginDate);
            this.endDate.setDate(this.endDate.getDate() + 28);
            this.period = Math.floor((currentWeek / 4) + 1);
            this.year = this.beginDate.getFullYear();
            if (params?.userId) {
                this.user = this.employees?.find(e => e.id === +params.userId);
                this.fcUserId.setValue(+params.userId);
            }
        }));

        this.subscriptions.add(this.userService.getList(false, false).subscribe(users => {
            this.employees = users;
            if (!this.fcUserId.value) {
                this.fcUserId.setValue(users.find(u => !!u.afas_employee_id).id);
            } else if (!this.user) {
                this.user = this.employees?.find(e => e.id === +this.fcUserId.value);
            }
        }));
    }


    prevEmployee() {
        const index = this.employees.findIndex(e => e.id === this.fcUserId.value);
        if (index) {
            this.router.navigateByUrl(`periode/${this.screenContent}/${this.employees[index - 1].id}/${this.period}/${this.year}`);
        }
    }

    nextEmployee() {
        const index = this.employees.findIndex(e => e.id === this.fcUserId.value);
        if (this.employees[index + 1] !== undefined) {
            this.router.navigateByUrl(`periode/${this.screenContent}/${this.employees[index + 1].id}/${this.period}/${this.year}`);
        }
    }

    next() {
        let period = this.period + 1;
        let year = this.year;
        if (period > 13) {
            period = 1;
            year += 1;
        }
        this.router.navigateByUrl(`periode/${this.screenContent}/${this.user.id}/${period}/${year}`);
    }

    prev() {
        let period = this.period - 1;
        let year = this.year;
        if (period < 1) {
            period = 13;
            year -= 1;
        }
        this.router.navigateByUrl(`periode/${this.screenContent}/${this.user.id}/${period}/${year}`);
    }

}
