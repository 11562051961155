import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tt'
})
export class TooltipPipe implements PipeTransform {

    private tooltipValues = new Map<string, string>([
        ['trucks', 'Deze rij geeft het aantal beschikbare vrachtwagens aan per dagdeel. Het minst tegelijk beschikbare aantal gedurende het dagdeel wordt getoond.'],
        ['tons', 'Deze rij geeft het totaal aantal tonnen als grafiek weer. De witte staaf is geleverd asfalt, de blauwe staaf afgevoerd/gefreesd asfalt. De schaal van het diagram is aangepast aan met maximale tonnage van de weergegeven periode.'],
        ['entitytype-430005', 'De planning van een ploeg wordt i.t.t. een frees niet op tijd weergegeven, omdat een ploeg maximaal 12 uur per dag kan worden ingezet.'],
        ['asphalt-trucks-time', 'De vrachtwagens worden gereserveerd tot de volgende bestelling. Als twee bestellingen op hetzelfde tijdstip zijn, wordt de som van de vrachtwagens gereserveerd.'],
        ['trucks-count', 'Het cijfer rechtsbovenin geeft het aantal beschikbare vrachtwagens weer. Vrachtwagens in dit veld worden niet meegerekend.'],
        ['cutter-time-disabled', 'Kies eerst een frees'],
        ['cutter-time', 'Een frees mag de dag voor de ploeg ingezet worden, maar niet na de ploeg'],
        ['cutter-na-na', 'Deze frees is niet beschikbaar'],
        ['cutter-na-here', 'Je hebt deze frees al ingepland'],
        ['cutter-tons', 'Het aantal ton gefreesd asfalt wordt in het diagram onderin het overzichtsscherm getoond als lichtblauwe staaf.'],
        ['asphalttype', 'Alleen asfaltsoorten die geleverd worden door de gekozen molen worden weergegeven'],
        ['asphalttype-nomill', 'Selecteer eerst een asfaltmolen'],
        ['asphaltcode', 'De code kan worden aangepast door een excel te uploaden via Instellingen => Asfalt'],
        ['wipetrucktime-na', 'Kies eer steen veeg/zuigwagen']
    ]);

    transform(value: any, ...args: any[]): any {
        if (!this.tooltipValues.has(value)) {
            console.warn('No tooltip configured for ' + value);
        }
        return this.tooltipValues.get(value);
    }

}
