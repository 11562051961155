import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Status} from '../../classes/status.class';

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    private urlPrefix = 'status';
    private statusList: Status[] = [];

    constructor(private apiService: ApiService) {
    }

    public getList(): Promise<Status[]> {
        return new Promise((resolve, reject) => {
            if (this.statusList && this.statusList.length > 0) {
                resolve(this.statusList);
            } else {
                this.apiService.getCall(this.urlPrefix).then((status: Status[]) => {
                    this.statusList = status;
                    resolve(status);
                }, err => {
                    reject(err);
                });
            }
        });
    }
}
