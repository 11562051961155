import {Component, HostListener, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PlanningService} from '../services/planning/planning.service';
import {ActivatedRoute} from '@angular/router';
import {UserService, UserType} from '../services/user/user.service';
import {combineLatest, concat, from, Subscription} from 'rxjs';
import {Utils} from '../utils.class';
import {Planning} from '../classes/planning.class';
import {User} from '../classes/user.class';
import {UserPlanning} from '../classes/user-planning';
import {LocationService} from '../services/location.service';
import {UserPlanningService} from '../services/planning/user-planning.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PlanningDetailDialogService} from '../services/dialog/planning-detail-dialog.service';
import {EntitiesService} from '../services/entities/entities.service';
import {AfasService} from '../services/afas.service';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Entity} from '../classes/entity.class';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {debounceTime, tap} from 'rxjs/operators';
import {ProjectService} from '../services/project.service';
import {Project} from '../classes/project.class';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AddProjectDialogComponent} from './add-project-dialog/add-project-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {EntityType} from '../services/entities/entity-type.class';
import {Settings} from '../settings.class';

@Component({
    selector: 'app-employee-planning',
    templateUrl: './employee-planning.component.html',
    styleUrls: ['./employee-planning.component.scss']
})
export class EmployeePlanningComponent extends CodaltComponent implements OnInit {

    readonly formatDate = formatDate;

    fcNightPlanning = new FormControl();

    fcProjectIds = new FormControl<string[]>();

    planningUserDatesMap = new Map<number, string[]>();
    projectUserDatesMap = new Map<number, string[]>();

    projects: Project[];
    planningList: Planning[];
    usersMap: Map<number, User>;
    entitiesMap: Map<number, Entity>;
    entityTypeMap: Map<number, EntityType>;
    entities: Entity[];

    print = false;
    fromDate: Date;
    toDate: Date;

    projectDaysList: {
        project: Project,
        worknumber?: string,
        form: FormGroup<ControlsOf<{
            location: string,
            location_housenumber: string,
            location_place: string,
            location_postcode: string,
            location_straat: string,
            performer_id: number,
            projectmanager_id: number
        }>>,
        days: {
            day: Date,
            project: Project
        }[]
    }[];

    smallMaterial: Entity[];
    users: User[];
    teams: {
        entityType: EntityType,
        entity: Entity,
        users: User[],
        showUsers: boolean
    }[];

    projectManagers: User[];
    executors: User[];

    dateHasUserMap = new Map<string, boolean>();
    projectDayTeamMap: Map<string, Planning> = new Map<string, Planning>();

    projectDayMaterialMap: Map<string, PlanningHasEntity[]> = new Map<string, PlanningHasEntity[]>();

    projectDayUserMap: Map<string, {
        type: 'userPlanning' | 'planning' | 'project',
        userPlanning?: UserPlanning[],
        planningHas?: PlanningHasEntity[]
    }> = new Map<string, { type: 'userPlanning' | 'planning' | 'project'; userPlanning?: UserPlanning[]; planningHas?: PlanningHasEntity[] }>();
    userWeekCount: Map<number, number> = new Map<number, number>();
    blockedMap: Map<string, { icon: string; description: string; }>;

    planningsToSave: Planning[] = [];
    userPlanningsToSave: UserPlanning[] = [];
    planningHasToSave: PlanningHasEntity[] = [];
    formDirty = false;
    saving = false;
    rollingBack = false;

    addresses: {
        addressLine,
        adminDistrict,
        adminDistrict2,
        countryRegion,
        formattedAddress,
        locality,
        postalCode
    }[];

    highlightDay: string;
    highlight: string;

    constructor(private confirmDialogService: ConfirmDialogService,
                private planningService: PlanningService,
                private projectService: ProjectService,
                private activatedRoute: ActivatedRoute,
                private locationService: LocationService,
                private userPlanningService: UserPlanningService,
                private afasService: AfasService,
                private planningDetailDialogService: PlanningDetailDialogService,
                private entitiesService: EntitiesService,
                private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private userService: UserService) {
        super();
        const today = new Date();
        this.fromDate = Utils.setTime(new Date(Utils.getMonday(today)), 0, 0);
        this.toDate = Utils.setTime(new Date(this.fromDate), 23, 59);
        this.toDate.setDate(this.toDate.getDate() + 6);
    }

    ngOnInit(): void {
        this.getData();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.planningsToSave?.length > 0 || this.userPlanningsToSave?.length > 0 || this.planningHasToSave?.length > 0) {
            $event.returnValue = true;
        }
    }

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.planningsToSave?.length > 0 || this.userPlanningsToSave?.length > 0 || this.planningHasToSave?.length > 0) {
                this.confirmDialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen verwerpen?`,
                    'Hier blijven',
                    'Wijzigingen verwerpen').then(() => {
                    resolve(false);
                }, () => {
                    this.userPlanningsToSave = [];
                    this.planningHasToSave = [];
                    this.planningsToSave = [];
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    mouseOverTeam(day: {
        day: Date,
        project: Project
    }, entity: Entity) {
        this.highlightDay = day.project?.afas_project_id + formatDate(day.day, 'E', 'nl');
        this.highlight = `t-${entity.id}-${day.day.getDate()}`;
    }

    mouseOver(day: {
        day: Date,
        project: Project
    }, user: User) {
        this.highlightDay = day.project?.afas_project_id + formatDate(day.day, 'E', 'nl');
        this.highlight = `u-${user.id}`;
    }

    mouseOverEntity(day: {
        day: Date,
        project: Project
    }, entity: Entity) {
        this.highlightDay = day.project?.afas_project_id + formatDate(day.day, 'E', 'nl');
        this.highlight = `e-${entity.id}`;
    }

    makeKey(date: Date, id: number, project: string) {
        return `${formatDate(date, 'yyyy-MM-dd', 'nl')}-${id}-${project}`;
    }


    addEntityPlanning(event,
                      day: {
                          day: Date,
                          project: Project
                      },
                      entity: Entity) {
        event.preventDefault();
        const key = this.makeKey(day.day, entity.id, day.project?.afas_project_id);
        let planningHasList = this.projectDayMaterialMap.get(key);

        if (planningHasList?.length) {
            if (planningHasList?.length > 1) {
                this.confirmDialogService.confirm('Meer keren ingepland',
                    `Dit materieel is op deze dag meerdere keren ingepland en kan daarom alleen via de transport of weekplanning aangepast worden.`, 'Sluiten', null).then(() => {
                });
                return;
            } else {
                planningHasList[0].deleted_at = new Date();
                if (!this.planningHasToSave.includes(planningHasList[0])) {
                    this.planningHasToSave.push(planningHasList[0]);
                }
                this.projectDayMaterialMap.delete(key);
            }
        } else {
            const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
            if (
                !this.projectDayMaterialMap.has(this.makeKey(day.day, entity.id, day.project?.afas_project_id)) &&
                projectsIds.map(projectId => this.projectDayMaterialMap.has(this.makeKey(day.day, entity.id, projectId))).find(p => p)
            ) {
                this.confirmDialogService.confirm(
                    'Al ingepland op deze dag',
                    `Het materieel is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                    'Sluiten', null).then(() => {
                });
                return;
            }

            const teamEntity = this.teams.find(team => this.projectDayTeamMap.has(this.makeKey(day.day, team.entity.id, day.project?.afas_project_id)))?.entity;
            let planning = this.projectDayTeamMap.get(this.makeKey(day.day, teamEntity?.id, day.project?.afas_project_id));
            if (planning) {
                const begindate = new Date(Utils.mainPlanning(planning).begindate);
                const enddate = new Date(Utils.mainPlanning(planning).enddate);
                const planningHas = new PlanningHasEntity();
                planningHas.entity_id = entity.id;
                planningHas.entitytype_id = entity.entitytypes[0].id;
                planningHas.entitytype = entity.entitytypes[0];
                planningHas.begindate = begindate;
                planningHas.enddate = enddate;
                planningHas.planning_id = planning.id;
                planningHas.planning = planning;
                let currentMaterial = this.projectDayMaterialMap.get(key);
                if (!currentMaterial) {
                    currentMaterial = [];
                }
                currentMaterial.push(planningHas);
                this.projectDayMaterialMap.set(key, currentMaterial);
                this.planningHasToSave.push(planningHas);
            }
        }
    }

    addProject() {
        const ref = this.dialog.open(AddProjectDialogComponent, {
            maxWidth: '400px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                currentProjects: [
                    ...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])
                ]
            }
        });
        this.subscriptions.add(ref.afterClosed().subscribe(project => {
            if (project) {
                const projectDays = this.createProjectDays(project.afas_project_id, [project]);
                const projectsIds = [
                    ...new Set([project.afas_project_id, ...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])
                ]
                    .filter(p => !!p)
                    .sort((a, b) => a.padEnd(8, '0').localeCompare(b.padEnd(8, '0')));
                if (!this.projectDaysList) {
                    this.projectDaysList = [];
                }
                this.projectDaysList.splice(projectsIds.indexOf(project.afas_project_id), 0, projectDays);
            }
        }));
    }

    addTeamPlanning(event,
                    day: {
                        day: Date,
                        project: Project
                    },
                    team: {
                        entityType: EntityType,
                        entity: Entity,
                        users: User[],
                        showUsers: boolean
                    }) {
        event.preventDefault();
        const key = this.makeKey(day.day, team.entity.id, day.project?.afas_project_id);
        let planning = this.projectDayTeamMap.get(key);
        if (planning && !planning?.id) {
            this.planningsToSave.splice(this.planningsToSave.indexOf(planning), 1);
            this.projectDayTeamMap.delete(key);
            planning.user_planning.forEach(up => {
                const key = this.makeKey(day.day, up.user_id, day.project?.afas_project_id);
                this.projectDayUserMap.delete(key);
                this.userPlanningsToSave.splice(this.userPlanningsToSave.indexOf(up), 1);
            });
        } else {
            const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
            if (!planning) {
                if (projectsIds.map(projectId => !!this.projectDayTeamMap.get(
                    this.makeKey(day.day, team.entity.id, projectId))?.entity?.use_once).find(p => p)) {
                    this.confirmDialogService.confirm(
                        'Al ingepland op deze dag',
                        `Deze ploeg is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                        'Sluiten', null).then(() => {
                    });
                    return;
                }
                planning = this.createPlanning(team.entity, team.entityType, day);
            }

            const begindate = new Date(Utils.mainPlanning(planning).begindate);
            const enddate = new Date(Utils.mainPlanning(planning).enddate);

            team.users.forEach(user => {

                if (!this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id)) &&
                    !projectsIds.map(projectId => this.projectDayUserMap.has(this.makeKey(day.day, user.id, projectId))).find(p => p)
                ) {
                    this.addUserPlanningToPlanningTeam(planning, user, begindate, enddate, day);
                }
            });
        }
    }

    private createPlanning(entity: Entity, entityType: EntityType, day: { day: Date; project: Project }) {
        const planning = this.planningService.newPlanningByType(entityType.id);
        const mainPlanning = this.planningService.getMainPlanning(planning);
        const fromDate = new Date(day.day);
        const toDate = new Date(day.day);
        const startTime = 60 * (this.fcNightPlanning.value ? Settings.DEFAULT_START_NIGHT : Settings.DEFAULT_START);
        fromDate.setMinutes(startTime);
        toDate.setMinutes(startTime);
        toDate.setMinutes(toDate.getMinutes() + (60 * Settings.DEFAULT_DURATION_TEAM));
        mainPlanning.begindate = fromDate;
        mainPlanning.enddate = toDate;
        mainPlanning.entity = entity;
        mainPlanning.entity_id = entity.id;
        planning.entity_id = entity.id;
        planning.afas_project_id = day.project.afas_project_id;
        planning.worknumber = day.project.afas_project_id;
        planning.location = day.project.location;
        planning.performer = day.project.performer_id;
        planning.projectmanager = day.project.projectmanager_id;
        planning.asphalt_performer = day.project.asphalt_performer_id;
        planning.contractor = day.project.contractor;
        planning.status_id = 3;
        planning.user_planning = [];
        this.planningsToSave.push(planning);
        const key = this.makeKey(day.day, entity.id, day.project?.afas_project_id);
        this.projectDayTeamMap.set(key, planning);
        return planning;
    }

    private addUserPlanningToPlanningTeam(planning: Planning, user: User, begindate: Date, enddate: Date, day: { day: Date; project: Project }) {
        const userPlanning = new UserPlanning();
        userPlanning.planning_id = planning.id;
        userPlanning.planning = planning;
        userPlanning.user_id = user.id;
        userPlanning.begindate = begindate;
        userPlanning.enddate = enddate;
        userPlanning.work_begin = day.project.location;
        userPlanning.work_end = day.project.location;
        userPlanning.origin = this.locationService.formatAddress(user as any);
        userPlanning.destination = this.locationService.formatAddress(user as any);

        this.userPlanningsToSave.push(userPlanning);

        const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
        if (!this.projectDayUserMap.has(key)) {
            let count = this.userWeekCount.get(userPlanning.user_id) ?? 0;
            count++;
            this.userWeekCount.set(userPlanning.user_id, count);
        }
        this.projectDayUserMap.set(key, {
            type: 'userPlanning',
            userPlanning: [userPlanning]
        });
        planning.user_planning.push(userPlanning);
    }

    addUserPlanningTeam(event,
                        day: {
                            day: Date,
                            project: Project
                        },
                        team: {
                            entityType: EntityType,
                            entity: Entity,
                            users: User[],
                            showUsers: boolean
                        },
                        user: User) {
        event.preventDefault();
        const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
        if (
            !this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id)) &&
            projectsIds.map(projectId => this.projectDayUserMap.has(this.makeKey(day.day, user.id, projectId))).find(p => p)
        ) {
            this.confirmDialogService.confirm(
                'Al ingepland op deze dag',
                `De persoon is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        const userProjectDay = this.projectDayUserMap.get(this.makeKey(day.day, user.id, day.project?.afas_project_id));
        if (userProjectDay?.type === 'planning') {
            this.confirmDialogService.confirm(
                'Chauffeurs kunnen alleen via de transportplanning geplant worden',
                `Deze persoon is op deze dag al ingepland als chauffeur en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        if (userProjectDay && (userProjectDay.userPlanning?.length || userProjectDay.planningHas?.length)) {
            if (userProjectDay.userPlanning?.length === 1) {
                userProjectDay.userPlanning[0].deleted_at = new Date();
                if (!this.userPlanningsToSave.includes(userProjectDay.userPlanning[0])) {
                    this.userPlanningsToSave.push(userProjectDay.userPlanning[0]);
                }
                const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
                if (this.projectDayUserMap.has(key)) {
                    this.projectDayUserMap.delete(key);
                    let count = this.userWeekCount.get(user.id) ?? 0;
                    count--;
                    this.userWeekCount.set(user.id, count);
                }
                const planning = this.projectDayTeamMap.get(this.makeKey(day.day, team.entity.id, day.project?.afas_project_id));
                if (planning?.user_planning) {
                    planning.user_planning.splice(planning.user_planning.indexOf(userProjectDay.userPlanning[0]), 1);
                }
            } else {
                this.confirmDialogService.confirm('Meer keren ingepland',
                    `De persoon is op deze dag en dit project meerdere keren ingepland en kan daarom alleen via de transport of weekplanning aangepast worden.`, 'Sluiten', null).then(() => {
                });
                return;
            }
        } else {
            let planning = this.projectDayTeamMap.get(this.makeKey(day.day, team.entity.id, day.project?.afas_project_id));
            if (!planning) {
                if (projectsIds.map(projectId => !!this.projectDayTeamMap.get(this.makeKey(day.day, team.entity.id, projectId))?.entity?.use_once).find(p => p)) {
                    this.confirmDialogService.confirm(
                        'Al ingepland op deze dag',
                        `Deze ploeg is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                        'Sluiten', null).then(() => {
                    });
                    return;
                }
                planning = this.createPlanning(team.entity, team.entityType, day);
            }
            const begindate = new Date(Utils.mainPlanning(planning).begindate);
            const enddate = new Date(Utils.mainPlanning(planning).enddate);
            this.addUserPlanningToPlanningTeam(planning, user, begindate, enddate, day);
        }
    }

    addUserPlanningProject(event,
                           day: {
                               day: Date,
                               project: Project
                           },
                           userProjectDay: {
                               type: 'userPlanning' | 'planning' | 'project',
                               userPlanning?: UserPlanning[],
                               planningHas?: PlanningHasEntity[]
                           },
                           user: User
    ) {
        event.preventDefault();

        if (!day.project?.afas_project_id) {
            this.confirmDialogService.confirm(
                'Onbekend project',
                `Voor deze planning is nog geen juist Afas project gekozen.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
        if (
            !this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id)) &&
            projectsIds.map(projectId => this.projectDayUserMap.has(this.makeKey(day.day, user.id, projectId))).find(p => p)
        ) {
            this.confirmDialogService.confirm(
                'Al ingepland op deze dag',
                `De persoon is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        if (userProjectDay?.type === 'planning') {
            if ((userProjectDay.userPlanning?.length + userProjectDay.planningHas?.length) === 1) {
                const project = this.planningList.find(p => p.id === (userProjectDay?.userPlanning[0]?.planning_id ?? userProjectDay?.planningHas[0]?.planning_id))
                this.planningDetailDialogService.openPlanning(project);
            } else {
                this.confirmDialogService.confirm(
                    'Meerdere keren ingepland',
                    `De persoon is op deze dag en dit project meerdere keren ingepland en kan daarom
                    alleen via de transport of weekplanning aangepast worden.`,
                    'Sluiten', null).then(() => {
                });
            }
        } else {
            if (this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id))) {
                if (userProjectDay.userPlanning?.length === 1) {
                    userProjectDay.userPlanning[0].deleted_at = new Date();
                    if (!this.userPlanningsToSave.includes(userProjectDay.userPlanning[0])) {
                        this.userPlanningsToSave.push(userProjectDay.userPlanning[0]);
                    }
                    const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);

                    if (this.projectDayUserMap.has(key)) {
                        this.projectDayUserMap.delete(key);
                        let count = this.userWeekCount.get(user.id) ?? 0;
                        count--;
                        this.userWeekCount.set(user.id, count);
                    }
                } else {
                    this.confirmDialogService.confirm('Meer keren ingepland',
                        `De persoon is op deze dag en dit project meerdere keren ingepland en kan daarom alleen via de transport of weekplanning aangepast worden.`, 'Sluiten', null).then(() => {
                    });
                    return;
                }
            } else {
                const fromDate = new Date(day.day);
                const toDate = new Date(day.day);
                const startTime = 60 * (this.fcNightPlanning.value ? Settings.DEFAULT_START_NIGHT : Settings.DEFAULT_START);
                fromDate.setMinutes(startTime);
                toDate.setMinutes(startTime);
                toDate.setMinutes(toDate.getMinutes() + (60 * Settings.DEFAULT_DURATION_OTHER));

                const userPlanning = new UserPlanning();
                userPlanning.afas_project_id = day.project.afas_project_id;
                userPlanning.user_id = user.id;
                userPlanning.begindate = fromDate;
                userPlanning.enddate = toDate;
                userPlanning.work_begin = day.project.location;
                userPlanning.work_end = day.project.location;
                userPlanning.origin = this.locationService.formatAddress(user as any);
                userPlanning.destination = this.locationService.formatAddress(user as any);

                this.userPlanningsToSave.push(userPlanning);

                const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
                if (!this.projectDayUserMap.has(key)) {
                    let count = this.userWeekCount.get(userPlanning.user_id) ?? 0;
                    count++;
                    this.userWeekCount.set(userPlanning.user_id, count);
                }
                this.projectDayUserMap.set(key, {
                    type: 'project',
                    userPlanning: [userPlanning]
                });
            }

        }
    }

    addressSelected(event: MatAutocompleteSelectedEvent, form: FormGroup<ControlsOf<{
        location: string,
        location_housenumber: string,
        location_place: string,
        location_postcode: string,
        location_straat: string,
        performer: number,
        projectmanager: number
    }>>) {
        const address = this.addresses.find(a => a.formattedAddress === event.option.value);
        if (address) {
            const numberIndex = address?.addressLine?.search(/\d/);
            const street = address.addressLine?.slice(0, numberIndex < 5 ? address.addressLine?.length : numberIndex).trim();
            const number = address.addressLine?.replace(street, '');
            form.controls.location_postcode.setValue(address?.postalCode);
            form.controls.location_housenumber.setValue(number);
            form.controls.location_straat.setValue(street);
            form.controls.location_place.setValue(address?.locality);
        }
    }

    undo() {
        setTimeout(() => {
            this.rollingBack = true;
        });
        setTimeout(() => {
            this.projectDayUserMap.clear();
            this.projectDayTeamMap.clear();
            this.userWeekCount.clear();
            this.dateHasUserMap.clear();
            this.projectDayMaterialMap.clear();
            this.userPlanningsToSave = [];
            this.planningHasToSave = [];
            this.planningsToSave = [];
            this.parseData(this.planningList, this.projects, this.usersMap, this.entitiesMap, this.entityTypeMap, true);
            this.rollingBack = false;
            this.projectDayTeamMap.forEach((planning, key) => {
                const mPlanning = Utils.mainPlanning(planning);
                planning.user_planning.filter(up => !up.id).forEach(up => {
                    this.projectDayUserMap.delete(this.makeKey(new Date(mPlanning.begindate), up.user_id, planning.afas_project_id));
                });
                planning.user_planning = planning.user_planning.filter(up => !!up.id);
            });
        }, 50);
    }

    save() {
        this.saving = true;
        const saveCalls = [];
        if (this.planningsToSave?.length) {
            this.planningsToSave.forEach((planning, index) => {
                const pl = {...planning};
                delete pl.user_planning;
                saveCalls.push(
                    from(this.planningService.save(pl)).pipe(tap(p => {
                        planning.id = p.id;
                        planning.user_planning.filter(up => !up.id).forEach(up => {
                            up.planning_id = planning.id;
                            up.planning = null;
                        });
                    }))
                );
            });
        }
        this.userPlanningsToSave.filter(up => !!up.planning_id).forEach(up => {
            up.planning = null;
        });
        saveCalls.push(this.userPlanningService.saveUserPlanningProject(this.userPlanningsToSave, this.planningHasToSave).pipe(tap(() => {
            this.userPlanningsToSave.forEach(ph => {
                const planning = this.planningList.find(planning => planning.id === ph.planning_id);
                const key = this.makeKey(new Date(ph.begindate), ph.user_id, (planning?.afas_project_id ?? ph?.afas_project_id));

                let userPlannings = this.projectDayUserMap.get(key);
                if (userPlannings) {
                    userPlannings.userPlanning.splice(userPlannings.userPlanning.indexOf(ph), 1);
                }
                this.projectDayUserMap.set(key, userPlannings);
                if (!userPlannings?.userPlanning?.length && !userPlannings?.planningHas?.length) {
                    this.projectDayUserMap.delete(key);
                }
            });
            this.userPlanningsToSave = [];
            this.planningHasToSave.forEach(ph => {
                const key = this.makeKey(new Date(ph.begindate), ph.entity_id, ph.planning?.afas_project_id);

                let planningHasList = this.projectDayMaterialMap.get(key);
                if (planningHasList) {
                    planningHasList.splice(planningHasList.indexOf(ph), 1);
                }
                this.projectDayMaterialMap.set(key, planningHasList);
                if (!planningHasList?.length) {
                    this.projectDayMaterialMap.delete(key);
                }
            });
            this.planningHasToSave = [];
        })));

        concat(...saveCalls).subscribe(result => {
            this.planningsToSave = [];
            this.saving = false;
        }, error => {
            console.error(error);
            this.saving = false;
        });

        this.projectDaysList.forEach(projectDays => {
            if (projectDays.form.dirty) {
                projectDays.project.performer_id = projectDays.form.value.performer_id;
                projectDays.project.location = projectDays.form.value.location;
                projectDays.project.location_postcode = projectDays.form.value.location_postcode;
                projectDays.project.location_housenumber = projectDays.form.value.location_housenumber;
                projectDays.project.location_straat = projectDays.form.value.location_straat;
                projectDays.project.location_place = projectDays.form.value.location_place;
                projectDays.project.projectmanager_id = projectDays.form.value.projectmanager_id;
                this.projectService.saveProject(projectDays.project).subscribe(p => {
                    projectDays.project.updated_at = p.data.updated_at;
                    projectDays.form.markAsPristine();
                    this.saving = false;
                }, () => {
                    this.saving = false;
                });
            }
        });
    }

    next() {
        this.openChangesBackActionCheck().then((result) => {
            if (result) {
                this.projectDaysList = null;
                this.projectDayUserMap.clear();
                this.projectDayTeamMap.clear();
                this.userWeekCount.clear();
                this.dateHasUserMap.clear();
                const fromDate = new Date(this.fromDate);
                fromDate.setDate(fromDate.getDate() + 7);
                const toDate = new Date(this.toDate);
                toDate.setDate(toDate.getDate() + 7);
                this.fromDate = fromDate;
                this.toDate = toDate;
                this.getData();
            }
        });
    }

    prev() {
        this.openChangesBackActionCheck().then((result) => {
            if (result) {
                this.projectDaysList = null;
                this.projectDayUserMap.clear();
                this.projectDayTeamMap.clear();
                this.userWeekCount.clear();
                this.dateHasUserMap.clear();
                const fromDate = new Date(this.fromDate);
                fromDate.setDate(fromDate.getDate() - 7);
                const toDate = new Date(this.toDate);
                toDate.setDate(toDate.getDate() - 7);
                this.fromDate = fromDate;
                this.toDate = toDate;
                this.getData();
            }
        });
    }

    getData() {
        const requestFromDate = new Date(this.fromDate);
        const requestToDate = new Date(this.toDate);
        const planning$ = this.planningService.getFilteredList(requestFromDate, requestToDate).pipe(debounceTime(100));
        const project$ = this.projectService.getFilteredList(requestFromDate, requestToDate).pipe(debounceTime(100));
        const leaves$ = this.afasService.getLeaves(requestFromDate, requestToDate);
        const illness$ = this.afasService.getIllness(requestFromDate, requestToDate);
        const users$ = this.userService.getList(false, false);
        const usersMap$ = this.userService.getMap();
        const entities$ = this.entitiesService.getList(this.fromDate, this.toDate);
        const entitiesMap$ = this.entitiesService.getMap();
        const entityTypes$ = this.entitiesService.getTypesMap();
        this.blockedMap = null;
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.subscriptions.add(combineLatest(planning$, project$, users$, usersMap$, entitiesMap$, entities$, entityTypes$)
            .subscribe(([planningList, projects, users, usersMap, entitiesMap, entities, entityTypeMap]) => {

                this.usersMap = usersMap;
                this.entitiesMap = entitiesMap;
                this.entityTypeMap = entityTypeMap;


                this.users = users.filter(u => u.function !== 'Chauffeur')
                    .sort((a, b) => a.lastname?.localeCompare(b.lastname))
                    .sort((a, b) => a.function?.localeCompare(b.function));

                if (this.entities?.length !== entities?.length && entities?.length) {
                    this.teams = entities.filter(e => !!e.entitytypes.find(t => t.is_team) && e.default_employees?.filter(df => usersMap.has(df))?.length).map(team => {
                        return {
                            entityType: team.entitytypes.find(e => e.is_team),
                            entity: team,
                            users: (team.default_employees?.map(e => usersMap.get(+e)).filter(u => !!u) ?? []).sort((a, b) => a.lastname?.localeCompare(b.lastname)),
                            showUsers: false
                        }
                    }).sort((a, b) => a.entityType.name.localeCompare(b.entityType.name));

                    this.smallMaterial = entities.filter(e => !!e.entitytypes.find(e => e.visible_small));
                }
                this.entities = entities;

                if (!this.blockedMap) {
                    this.blockedMap = new Map<string, { icon: string; description: string; }>();
                    this.subscriptions.add(combineLatest([leaves$, illness$]).subscribe(([leaves, illness]) => {
                        const leavesAndIlls = [...illness.data.map(ill => {
                            return {
                                startDate: ill.startDate,
                                endDate: ill.endDate,
                                description: ill.absenceTypeDesc,
                                employeeId: ill.employeeId,
                                icon: 'fa-face-thermometer'
                            };
                        }), ...leaves.data.map(leave => {
                            return {
                                startDate: leave.startDate,
                                endDate: leave.endDate,
                                description: leave.leaveDescr,
                                employeeId: leave.employeeId,
                                icon: 'fa-user-slash'
                            };
                        })];

                        const loopDate = new Date(this.fromDate);
                        while (loopDate.getTime() < this.toDate.getTime()) {
                            const startOfDay = Utils.setTime(new Date(loopDate), 0, 0);
                            const endOfDay = Utils.setTime(new Date(loopDate), 23, 59);
                            leavesAndIlls.forEach(leaveOrIll => {
                                const user = this.users.find(u => u.afas_employee_id === leaveOrIll.employeeId);
                                if (Utils.newDate(leaveOrIll.startDate) <= endOfDay && (leaveOrIll.endDate === null || Utils.newDate(leaveOrIll.endDate) >= startOfDay) && user) {
                                    this.blockedMap.set(formatDate(startOfDay, 'yyyy-MM-dd', 'nl') + '-' + user.id, {
                                        description: leaveOrIll.description,
                                        icon: leaveOrIll.icon
                                    });
                                }
                            });
                            loopDate.setDate(loopDate.getDate() + 1);
                        }
                    }));
                }
                this.parseData(planningList, projects, usersMap, entitiesMap, entityTypeMap);
                this.projects = projects;
                this.planningList = planningList;

            }));
        this.subscriptions.add(this.userService.getByType(UserType.PROJECTMANAGER).subscribe(users => this.projectManagers = users));
        this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));
    }

    private parseData(planningList: Planning[], projects: Project[], usersMap: Map<number, User>, entitiesMap: Map<number, Entity>, entityTypeMap: Map<number, EntityType>, clean = false) {
        if (!this.projectDaysList) {
            const newProjects = [
                ...new Set([...projects.map(p => p.afas_project_id), ...planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])
            ];
            this.projectDaysList = this.projectDaysList?.filter(p => p.project?.afas_project_id && !newProjects.includes(p.project?.afas_project_id)) || [];
        }

        const currentDateUserDayMap = new Map<string, boolean>();
        const currentDateEntityDayMap = new Map<string, boolean>();

        if (planningList !== this.planningList || clean) {
            this.planningUserDatesMap.clear();
            planningList.forEach(planning => {
                const date = new Date(Utils.mainPlanning(planning).begindate);
                this.projectDayTeamMap.set(this.makeKey(date, planning.entity_id, (planning.afas_project_id ?? planning.worknumber)), planning)


                planning.performer_user = usersMap.get(planning.performer);
                planning.projectmanager_user = usersMap.get(planning.projectmanager);
                planning.user_planning.forEach(userPlanning => {
                    const date = new Date(userPlanning.begindate);
                    const key = this.makeKey(date, userPlanning.user_id, (planning.afas_project_id ?? planning.worknumber));

                    let current = this.projectDayUserMap.get(key);
                    if (!current) {
                        current = {
                            type: 'userPlanning',
                            planningHas: [],
                            userPlanning: []
                        }
                    }
                    if (!current.userPlanning.some(up => up.id === userPlanning.id) && !this.userPlanningsToSave.some(ups => ups.id === userPlanning.id)) {
                        current.userPlanning.push(userPlanning);
                    } else {
                        const cup = current.userPlanning.find(up => up.id === userPlanning.id);
                        if (cup) {
                            delete userPlanning.deleted_at;
                            Object.assign(cup, userPlanning);
                        }
                        const ups = this.userPlanningsToSave.find(upts => upts.id === userPlanning.id);
                        if (ups) {
                            delete userPlanning.deleted_at;
                            Object.assign(ups, userPlanning);
                        }
                    }
                    if (current.planningHas?.length || current.userPlanning?.length) {
                        this.projectDayUserMap.set(key, current);
                    }
                    currentDateUserDayMap.set(key, true);
                    this.planningUserDatesMap.set(userPlanning.user_id, [...(this.planningUserDatesMap.get(userPlanning.user_id) || []), formatDate(date, 'yyyy-MM-dd', 'nl')]);
                });

                Utils.planningAllEntities(planning).forEach(plnEntity => {
                    plnEntity.entity = entitiesMap.get(plnEntity.entity_id);
                });
                planning.planning_has?.forEach(planningHas => {
                    const date = new Date(planningHas.begindate);
                    const key = this.makeKey(date, planningHas.driver_user_id, (planning.afas_project_id ?? planning.worknumber));
                    let current = this.projectDayUserMap.get(key);
                    if (!current) {
                        current = {
                            type: 'planning',
                            planningHas: [],
                            userPlanning: []
                        }
                    }
                    if (!current.planningHas.some(phe => phe.id === planningHas.id)) {
                        current.planningHas.push(planningHas);
                    }
                    this.projectDayUserMap.set(key, current);
                    currentDateUserDayMap.set(key, true);
                    if (entityTypeMap.get(planningHas.entitytype_id).visible_small) {
                        const materialKey = this.makeKey(date, planningHas.entity_id, (planning.afas_project_id ?? planning.worknumber));
                        let currentMaterial = this.projectDayMaterialMap.get(materialKey) ?? [];
                        if (!currentMaterial.some(phe => +phe.id == +planningHas.id)) {
                            currentMaterial.push(planningHas);
                            this.projectDayMaterialMap.set(materialKey, currentMaterial);
                        }
                        currentDateEntityDayMap.set(materialKey, true);

                    }
                    this.planningUserDatesMap.set(planningHas.driver_user_id, [...(this.planningUserDatesMap.get(planningHas.driver_user_id) || []), formatDate(date, 'yyyy-MM-dd', 'nl')]);
                });
            });
        }
        if (projects !== this.projects || clean) {
            this.projectUserDatesMap.clear();
            projects.forEach(project => {
                project.user_planning.forEach(userPlanning => {
                    const date = new Date(userPlanning.begindate);
                    const key = this.makeKey(date, userPlanning.user_id, userPlanning.afas_project_id);
                    let current = this.projectDayUserMap.get(key);
                    if (!current) {
                        current = {
                            type: 'project',
                            planningHas: [],
                            userPlanning: []
                        }
                    }
                    if (!current.userPlanning.some(up => +up.id == +userPlanning.id) && !this.userPlanningsToSave.some(ups => ups.id === userPlanning.id)) {
                        current.userPlanning.push(userPlanning);
                        this.projectDayUserMap.set(key, current);
                    } else {
                        const ups = this.userPlanningsToSave.find(upts => upts.id === userPlanning.id);
                        if (ups) {
                            delete userPlanning.deleted_at;
                            Object.assign(ups, userPlanning);
                        }
                    }

                    if (this.projectDayUserMap.has(key)) {
                        const projectDayUser = this.projectDayUserMap.get(key);
                        const currentUserPlanning = projectDayUser.userPlanning.find(up => up.user_id === userPlanning.user_id && up.afas_project_id === userPlanning.afas_project_id);
                        if (currentUserPlanning) {
                            currentUserPlanning.id = userPlanning.id;
                            currentUserPlanning.updated_at = userPlanning.updated_at;
                        }
                    }
                    currentDateUserDayMap.set(key, true);
                    this.projectUserDatesMap.set(userPlanning.user_id, [...(this.projectUserDatesMap.get(userPlanning.user_id) || []), formatDate(date, 'yyyy-MM-dd', 'nl')]);
                });
            });
        }

        if (planningList !== this.planningList || clean) {
            this.projectDayUserMap.forEach((projectDayUser, key) => {
                if (!currentDateUserDayMap.has(key) && projectDayUser?.type === 'userPlanning') {
                    if (this.projectDayUserMap.has(key) && !projectDayUser.userPlanning.some(up => this.userPlanningsToSave.indexOf(up) !== -1)) {
                        this.projectDayUserMap.delete(key);
                    }
                }
                if (projectDayUser.userPlanning.some(m => !!this.userPlanningsToSave.find(up => up.id === m.id)?.deleted_at)) {
                    this.projectDayUserMap.delete(key);
                }
            });
        }
        if (projects !== this.projects || clean) {
            this.projectDayUserMap.forEach((projectDayUser, key) => {
                if (!currentDateUserDayMap.has(key) && projectDayUser?.type === 'project') {
                    if (this.projectDayUserMap.has(key) && !projectDayUser.userPlanning.some(up => this.userPlanningsToSave.indexOf(up) !== -1)) {
                        this.projectDayUserMap.delete(key);
                    }
                }
                if (projectDayUser.userPlanning.some(m => !!this.userPlanningsToSave.find(up => up.id === m.id)?.deleted_at)) {
                    this.projectDayUserMap.delete(key);
                }
            });
        }


        if (planningList !== this.planningList || clean) {
            this.projectDayMaterialMap.forEach((projectDayMaterial, key) => {
                if (!currentDateEntityDayMap.has(key)) {
                    if (this.projectDayMaterialMap.has(key) &&
                        (!projectDayMaterial.some(m => this.planningHasToSave.indexOf(m) !== -1))) {
                        this.projectDayMaterialMap.delete(key);
                    }
                }
                if (projectDayMaterial.some(m => !!this.planningHasToSave.find(ph => ph.id === m.id)?.deleted_at)) {
                    this.projectDayMaterialMap.delete(key);
                }
            });
        }

        const currentDates = [];
        const loopDate = new Date(this.fromDate);
        while (loopDate.getTime() < this.toDate.getTime()) {
            currentDates.push(formatDate(loopDate, 'yyyy-MM-dd', 'nl'));
            loopDate.setDate(loopDate.getDate() + 1);
        }

        const userHasDatesMap = new Map<number, string[]>();
        this.projectUserDatesMap.forEach((date, userId) => {
            userHasDatesMap.set(userId, [...(userHasDatesMap.get(userId) ?? []), ...date]);
        });
        this.planningUserDatesMap.forEach((date, userId) => {
            userHasDatesMap.set(userId, [...(userHasDatesMap.get(userId) ?? []), ...date]);
        });

        userHasDatesMap.forEach((dates, userId) => {
            const datesUnique = ([...new Set([...dates])] || []).filter(d => currentDates.includes(d));
            if (datesUnique.length !== this.userWeekCount.get(userId)) {
                this.userWeekCount.set(userId, datesUnique.length);
            }
        });

        const projectsIds = [
            ...new Set([...projects.map(p => p.afas_project_id), ...planningList.map(p => p.afas_project_id)] || [])
        ]
            .filter(p => !!p)
            .sort((a, b) => a.padEnd(8, '0').localeCompare(b.padEnd(8, '0')));

        projectsIds.forEach(projectId => {
            if (!this.projectDaysList.find(p => p.project?.afas_project_id === projectId)) {
                this.projectDaysList.push(this.createProjectDays(projectId, projects, planningList));
            } else {
                const projectDay = this.projectDaysList.find(p => p.project?.afas_project_id === projectId);
                const project = projects.find(p => p.afas_project_id === projectId) ?? planningList.find(p => p.project?.afas_project_id === projectId)?.project;
                projectDay.form.patchValue(project);

            }
        });
    }

    private createProjectDays(afasProjectId: string, projects: Project[], planningList?: Planning[]) {
        const planning = planningList?.find(p => (p.afas_project_id ?? p.worknumber) === afasProjectId && !p.is_transport) ?? planningList?.find(p => (p.afas_project_id ?? p.worknumber) === afasProjectId);
        const project = projects.find(p => p.afas_project_id === afasProjectId) ?? planning?.project;
        const projectDays = {
            project,
            worknumber: planning?.worknumber,
            form: new FormGroup<ControlsOf<{
                location: string,
                location_housenumber: string,
                location_place: string,
                location_postcode: string,
                location_straat: string,
                performer_id: number,
                projectmanager_id: number
            }>>({
                location: new FormControl(project?.location),
                location_housenumber: new FormControl(project?.location_housenumber),
                location_place: new FormControl(project?.location_place),
                location_postcode: new FormControl(project?.location_postcode),
                location_straat: new FormControl(project?.location_straat),
                performer_id: new FormControl(project?.performer_id),
                projectmanager_id: new FormControl(project?.projectmanager_id)
            }),
            days: []
        };
        if (!project?.afas_project_id) {
            projectDays.form.disable();
        }
        this.subscriptions.add(projectDays.form.dirty$.subscribe(dirty => {
            this.formDirty = dirty;
        }));
        this.subscriptions.add(projectDays.form.controls.location.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            if (search !== project.location) {
                const addresses = [];
                this.subscriptions.add(this.locationService.getLocations(search + ' Nederland').subscribe(result => {
                    result.resourceSets.forEach(res => {
                        res.resources.forEach(address => {
                            addresses.push(address.address);
                        });
                    });
                    this.addresses = addresses;
                }));
            }
        }));
        const loopDate = new Date(this.fromDate);
        while (loopDate.getTime() < this.toDate.getTime()) {
            const day = Utils.setTime(new Date(loopDate), 0, 0);
            projectDays.days.push({
                day,
                project
            });
            loopDate.setDate(loopDate.getDate() + 1);
        }
        return projectDays;
    }
}
