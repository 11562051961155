import {Entity} from './entity.class';
import {Planning} from './planning.class';

export class PlanningHirePeople {
    public entitytype_id: number;

    public entity_id: number;

    public planning_id: number;

    public begindate: Date;

    public enddate: Date;

    public name: any;

    public created_at: Date;

    public updated_at: Date;

    public id: number;

    public entity: Entity;

    public planning: Planning;

    public count: number;

    origin: string;

    destination: string;

}
