<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Projectleider rapport - Inzet ploegen per week</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div class="dialog-content">
    <table class="table table-bordered table-striped text-right table-hover">
        <tr>
            <th>
                Projectleider
            </th>
            <th *ngFor="let week of weeks">
                Week {{week.startDate | date: 'w'}}<br>
                <small>
                    {{week.startDate | date: 'EEE dd MMM y'}}<br>
                    {{week.endDate | date: 'EEE dd MMM y'}}
                </small>
            </th>
        </tr>
        <ng-container *ngFor="let projectManager of projectManagers">
            <tr>
                <td class="text-left">
                    {{projectManager.name}}
                </td>
                <ng-container *ngFor="let week of weeks">
                    <td class="text-center">
                        <div class="stat">
                            <ng-container *ngFor="let date of week.dates">
                                <div *ngIf="reportMap.has('' + projectManager.id + '_' + date)"></div>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
            </tr>

        </ng-container>
    </table>
</div>
