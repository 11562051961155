<div class="login-background">
    <div class="login-block">
        <div id="errorMessage">
            <ng-container *ngIf="validToken == ValidationState.Validating">
                De wachtwoordvalidatie token wordt gevalideerd.
            </ng-container>
            <ng-container *ngIf="validToken == ValidationState.Invalid">
                <p>Deze link is niet meer geldig.</p>
                <a class="textlink next" routerLink="/wachtwoord-vergeten">Vraag hier een nieuwe link aan</a>
            </ng-container>
            <ng-container *ngIf="message">
                {{message}}
            </ng-container>
        </div>

        <div *ngIf="validToken == ValidationState.Valid">
            <form [formGroup]="form">
                <div class="title">
                    <h1 class="h4 condensed">Nieuw wachtwoord</h1>
                </div>
                <mat-form-field appearance="fill" id="password">
                    <mat-label>Wachtwoord</mat-label>
                    <input matInput
                           type="password"
                           formControlName="password"
                           name="password"
                           autocomplete="new-password"
                           placeholder="Je nieuwe wachtwoord">
                    <mat-error *ngIf="password.errors && password.errors.minlength">
                        Je wachtwoord is te kort
                    </mat-error>
                    <mat-error *ngIf="password.errors && password.errors.pattern && !password.errors.minlength">
                        Je wachtwoord is niet sterk genoeg
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" id="passwordconf">
                    <mat-label>Wachtwoord (herhaling)</mat-label>
                    <input matInput
                           type="password"
                           formControlName="passwordconf"
                           name="passwordconf"
                           autocomplete="new-password"
                           placeholder="Je wachtwoord ter bevestiging">
                    <mat-error>De wachtwoorden moeten overeenkomen</mat-error>
                </mat-form-field>
                <div class="login-buttons">
                    <button mat-raised-button id="btn-setpassword" color="primary" (click)="setNewPassword()"
                            [disabled]="form.invalid">
                        {{loading ? 'We stellen je nieuwe wachtwoord in...' : 'Wachtwoord instellen'}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
