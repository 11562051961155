import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RealisationService} from '../services/realisation.service';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../utils.class';
import {TimePickerService} from '../services/time-picker.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PlanningService} from '../services/planning/planning.service';
import {UserService, UserType} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {RealisationHourtype} from '../classes/realisation';
import {tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-day-check-realisations',
    templateUrl: './day-check-realisations.component.html',
    styleUrls: ['./day-check-realisations.component.scss']
})
export class DayCheckRealisationsComponent extends CodaltComponent implements OnInit {

    date: Date;
    userRealisationMap = new Map<number, UserRealisations>();
    users: User[];
    show = true;


    constructor(private activatedRoute: ActivatedRoute,
                private timePickerService: TimePickerService,
                private dialog: MatDialog,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private planningService: PlanningService,
                private userService: UserService,
                private realisationService: RealisationService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string }) => {
            const today = new Date();
            const paramDate = params.date;
            this.date = paramDate ? new Date(paramDate) : new Date(formatDate(today, 'yyyy-MM-dd', 'nl'));

            this.getData();
        }));
    }

    private getData() {
        const users$ = this.userService.getList(false, false).pipe(tap(users => {
            this.users = users;
        }));
        const scheduleDay$ = this.realisationService.getScheduleDay(this.date);
        this.subscriptions.add(combineLatest([users$, scheduleDay$]).subscribe(([users, realisations]) => {
            this.userRealisationMap = new Map<number, UserRealisations>();

            realisations.data.filter(r => !r.removed).forEach(rea => {
                const user = this.users.find(u => u.id === rea.user_id);
                if (user) {
                    let ur = this.userRealisationMap.get(rea.user_id);
                    if (!ur) {
                        ur = {
                            worktime: 0,
                            break: 0,
                            traveltime: 0,
                            drivingtime: 0,
                            projects: []
                        } as UserRealisations;
                        this.userRealisationMap.set(rea.user_id, ur);
                    }
                    if (rea.hourtype === RealisationHourtype.worktime) {
                        ur.worktime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if (rea.hourtype === RealisationHourtype.driving_to || rea.hourtype === RealisationHourtype.driving_back) {
                        ur.drivingtime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if (rea.hourtype === RealisationHourtype.travel_to || rea.hourtype === RealisationHourtype.travel_back) {
                        ur.traveltime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if (ur.projects.indexOf(rea.planning?.afas_project_id ?? rea.planning?.worknumber ?? rea.afas_project_id) === -1) {
                        ur.projects.push(rea.planning?.afas_project_id ?? rea.planning?.worknumber ?? rea.afas_project_id);
                    }

                    ur.break += rea.pause;
                    ur.pause_adjusted = ur.pause_adjusted || rea.pause_adjusted;
                    ur.allSubmitted = ur.allSubmitted || !!rea.submitted;
                    ur.allApproved = ur.allApproved || !!rea.approved;
                    const back24h = new Date();
                    back24h.setUTCDate(back24h.getUTCDate() - 1);
                    ur.adjustable = ur.adjustable || UserService.userHasRights(UserType.HOUR_REGISTER, user);
                    ur.adjustable_time = ur.adjustable_time || (back24h.getTime() < Utils.getTimeOrNull(rea.begindate));
                }
            });

        }));
    }

    next() {
        this.date.setDate(this.date.getDate() + 1);
        this.router.navigateByUrl(`dagcontrole/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    prev() {
        this.date.setDate(this.date.getDate() - 1);
        this.router.navigateByUrl(`dagcontrole/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

}

interface UserRealisations {
    worktime: number;
    break: number;
    traveltime: number;
    drivingtime: number;
    pause_adjusted: boolean;
    projects: string[];
    allSubmitted: boolean;
    allApproved: boolean;
    adjustable: boolean;
    adjustable_time: boolean;
}
