<div *ngIf="!print" class="control">
    <button (click)="prev.emit()" color="primary" mat-icon-button>
        <i class="fas fa-chevron-left"></i>
    </button>
</div>
<div *ngIf="fromDate && toDate" class="indicator" [class.print]="print">
    <div [style.right.px]="weekright" [style.width.px]="weekwidth" class="week">
        <span>Week</span> {{primaryWeekStart | date : 'w'}}
    </div>
</div>
<div *ngIf="singleDate" class="indicator" [class.print]="print">
    <div class="week" [style.width.%]="100">
        <span>{{singleDate | date : 'EEEE'}}</span> {{singleDate | date : 'd MMMM yyyy'}}
    </div>
</div>
<div *ngIf="!print" class="control">
    <button (click)="next.emit()" color="primary" mat-icon-button>
        <i class="fas fa-chevron-right"></i>
    </button>
</div>
