import {Pipe, PipeTransform} from '@angular/core';
import {Entity} from '../classes/entity.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Planning} from '../classes/planning.class';

@Pipe({
    name: 'entityUnavailable'
})
export class EntityUnavailablePipe implements PipeTransform {

    transform<T>(entity: Entity, planning: PlanningHasEntity, plannings: Planning[]): boolean {
        if (plannings) {
            const ownPlanning = plannings.find(p => p.id === planning.planning_id);
            const otherPlannings = plannings.filter(p => p.id !== planning.planning_id);
            const isInOwnPlanning = ownPlanning?.planning_has?.find(ph => ph.entity_id === entity.id);
            let unavailable = false;

            // No checks needed if already in own planning
            if (!isInOwnPlanning) {
                otherPlannings.forEach(otherPlanning => {
                    // Break when unavailable is true already
                    if (!unavailable) {
                        otherPlanning?.planning_has.filter(ph => ph.entity_id === entity.id).forEach(planningHas => {
                            // Break when unavailable is true already
                            if (!unavailable) {
                                unavailable = (new Date(planningHas.begindate).getTime() < new Date(planning.enddate).getTime() &&
                                    new Date(planningHas.enddate).getTime() > new Date(planning.begindate).getTime());
                            }
                        });
                    }
                });
            }

            return !!isInOwnPlanning || unavailable;
        }
        return false;
    }

}
