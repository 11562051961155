import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LocalStorage} from '../storage.class';
import {EntityType, EntityTypeCode} from '../services/entities/entity-type.class';
import {EntitiesService} from '../services/entities/entities.service';
import {CodaltComponent} from '../codalt.component';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-planning-filter',
    templateUrl: './planning-filter.component.html',
    styleUrls: ['./planning-filter.component.scss']
})
export class PlanningFilterComponent extends CodaltComponent implements OnInit {

    @Input() mobile = false;

    @Output()
    public selectedTeams = new EventEmitter<number[]>();

    entityTypes: EntityType[];

    fcTeams = new FormControl(LocalStorage.PlanningFilter ?? [EntityTypeCode.AsfaltTeam]);

    constructor(private entitiesService: EntitiesService) {
        super();
    }

    ngOnInit(): void {

        this.subscriptions.add(this.entitiesService.getTypes().subscribe(types => {
            this.entityTypes = types.data.filter(t => t.is_team);
        }));

        this.fcTeams.valueChanges.pipe(debounceTime(200)).subscribe(() => {
            LocalStorage.PlanningFilter = this.fcTeams.value;
            this.selectedTeams.emit(this.fcTeams.value);
        });
        this.selectedTeams.emit(this.fcTeams.value);
    }

}
