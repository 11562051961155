<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Ingepland personeel {{planning.worknumber ?? planning.afas_project_id}}</div>
    <div>
        <button mat-dialog-close mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div class="flex-fill">
    <div class="row">
        <div class="col-lg-8 col-md-9 col-xl-9">
            <h3>Details</h3>
            <i>
                Datum/tijd hoofdplanning:
                {{mainPlanning.begindate | date : 'EEEE d MMMM yyyy HH:mm'}} -  {{mainPlanning.enddate | date : 'EEEE d MMMM yyyy HH:mm'}}
            </i>
            <br><br>
            <table class="table table-last-col table-hover">
                <tr>
                    <th>Functie</th>
                    <th>Medewerker</th>
                    <th style="width: 115px; min-width: 115px;">Van</th>
                    <th style="width: 65px; min-width: 65px;">Tot</th>
                    <th>
                        Route
                    </th>
                    <th>Opmerking</th>
                    <th>

                    </th>
                </tr>
                <tr *ngFor="let formgroup of form.controls" [formGroup]="formgroup">
                    <td>
                        {{formgroup.controls.function.value}}
                    </td>
                    <td>
                        {{formgroup.controls.employee.value}}
                    </td>
                    <td class="datetime-input" matTooltip="{{formgroup.controls.conflictText.value}}">

                        <app-datetime-input [formControl]="formgroup.controls.begindate"
                                            labelDate="start"
                                            labelTime="tijd"></app-datetime-input>
                    </td>
                    <td class="datetime-input">
                        <app-datetime-input [formControl]="formgroup.controls.enddate"
                                            labelDate="eind"
                                            [hideDate]="true"
                                            labelTime="tijd"></app-datetime-input>
                    </td>
                    <td (click)="selectAddress(formgroup)"
                        class="p-2 curpoint text-center" matRipple [matTooltip]="formgroup.controls.origin.value">
                        <app-address-icon [address]="formgroup.controls.origin.value"
                                          [userId]="formgroup.value.user_id"
                                          [planning]="planning"
                                          class="mr-1 ml-1"
                                          [users]="employees"></app-address-icon>
                        -
                        <app-address-icon [address]="formgroup.controls.work_begin.value"
                                          [planning]="planning"
                                          [userId]="formgroup.value.user_id"
                                          class="mr-1 ml-1"
                                          [users]="employees"></app-address-icon>
                        <app-address-icon [address]="formgroup.controls.work_end.value"
                                          *ngIf="formgroup.controls.work_begin.value !== formgroup.controls.work_end.value"
                                          [userId]="formgroup.value.user_id"
                                          [planning]="planning"
                                          class="mr-1 ml-1"
                                          [users]="employees"></app-address-icon>
                        -
                        <app-address-icon [address]="formgroup.controls.destination.value"
                                          [userId]="formgroup.value.user_id"
                                          [planning]="planning"
                                          class="mr-1 ml-1"
                                          [users]="employees"></app-address-icon>
                    </td>
                    <td class="textarea-input">
                            <textarea [formControl]="formgroup.controls.comment"
                                      matInput
                                      cdkTextareaAutosize
                                      placeholder="Omschrijving"
                                      [cdkAutosizeMaxRows]="5"
                                      rows="1"></textarea>
                    </td>
                    <td>
                        <button (click)="delete(formgroup)" class="delete-button" color="warn" mat-icon-button>
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </table>
            <h3>Personeel inplannen</h3>
            <div class="row">
                <div class="col">
                    <div class="pr-5">
                        <ng-select [formControl]="fcEmployee"
                                   appearance="outline"
                                   (change)="addEmployee(+this.fcEmployee.value)"
                                   name="employee-filter"
                                   placeholder="Kies  medewerker"
                                   [clearable]="true"
                                   appendTo="body"
                                   tabIndex="-1">
                            <ng-option *ngFor="let employee of filteredEmployees" [value]="employee.id">
                                {{employee.name}} ({{employee.function}})
                            </ng-option>
                        </ng-select>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-xl-3">
            <h3>Overzicht</h3>
            <table class="table">
                <tr>
                    <th>Functie</th>
                    <th>Aantal</th>
                </tr>
                <tr *ngFor="let sum of sums">
                    <td>
                        {{sum.function}}
                    </td>
                    <td>
                        {{sum.amount}}
                    </td>
                </tr>
            </table>
            <b>Projectnummer:</b> {{planning.worknumber ?? planning.afas_project_id}}<br>
            <b>Locatie:</b> {{planning.location}}<br>
            <b>Omschrijving:</b> {{planning.description}}
        </div>
    </div>
</div>
<div class="d-flex flex-wrap pt-2 pb-2 justify-content-between">
    <button id="close-empl" (click)="close()" class="mr-2" color="secondary" mat-raised-button>Sluiten</button>
    <button (click)="save()" [disabled]="saving" color="primary"
            mat-raised-button>
        Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
    </button>
</div>
