import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';

@Pipe({
    name: 'entitiesRows',
    pure: false
})
export class EntitiesRowsPipe implements PipeTransform {

    // This pipe calculates the amount of empty-rows in transportplanning that groups same-driver plannings
    transform(plannings: { planning: PlanningHasEntity }[]): unknown {
        let count = 1;
        for (let i = 0; i < plannings.length; i++) {
            const thisDriver = plannings[i].planning.driver_user_id;
            const prevDriver = plannings[i - 1]?.planning.driver_user_id;
            const nextDriver = plannings[i + 1]?.planning.driver_user_id;
            const prev2Driver = plannings[i - 2]?.planning.driver_user_id;
            if (i > 0 && ((nextDriver === thisDriver && prevDriver !== thisDriver) || (prevDriver !== thisDriver && prev2Driver === prevDriver))) {
                count++;
            }
            count++;
        }
        return count;
    }

}
