<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">{{dialogTitle}}</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div mat-dialog-content>
    <div class="form" *ngIf="!planningList">
        Aan het laden...
    </div>
    <div class="form" *ngIf="planningList">
        <div [class.cutter]="viewModel.cutter" [class.wipetruck]="viewModel.wipetruck" class="top-form">
            <form *ngIf="form" [formGroup]="form">
                <div class="row">
                    <div [class.col-12]="!viewModel.sleepOrBlock"
                         [class.col-8]="viewModel.sleepOrBlock">
                        <div class="form-line">
                            <mat-form-field appearance="fill" *ngIf="initialStatus !== 3">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status_id" placeholder="Status">
                                    <mat-option *ngFor="let status of statusList" [value]="status.id"
                                                [disabled]="viewModel.fixedStatusDisabled && status.id < 3">
                                        {{status.name}}
                                    </mat-option>
                                </mat-select>

                                <mat-error [control]="form.get('status_id')"></mat-error>

                            </mat-form-field>
                            <div *ngIf="initialStatus === 3">
                                <i>Status</i><br>
                                <button (click)="backToConcept()" color="primary" mat-raised-button>
                                    Terug naar concept
                                </button>
                            </div>
                            <ng-select formControlName="worknumber"
                                       #projectSelect
                                       (focus)="focusProjectSelect()"
                                       (ngModelChange)="afasProjectChange($event)"
                                       (close)="afasProjectClose()"
                                       [typeahead]="searchAfasProjects$"
                                       [clearable]="true"
                                       [selectableGroup]="false"
                                       [items]="projects"
                                       class="ng-select-fill project-selection"
                                       placeholder="Project"
                                       groupBy="mainProject"
                                       bindLabel="name"
                                       bindValue="afas_project_id">
                                <ng-template ng-label-tmp let-item="item">
                                    <b>{{item.afas_project_id}}</b> {{item.name}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <b>{{item.afas_project_id}}</b> {{item.name}}
                                </ng-template>
                            </ng-select>
                            <mat-form-field appearance="fill" *ngIf="viewModel.contractor">
                                <mat-label>Opdrachtgever</mat-label>
                                <input [matAutocomplete]="autoContractors" formControlName="contractor" matInput placeholder="Opdrachtgever" type="text">
                                <mat-autocomplete #autoContractors="matAutocomplete" autoActiveFirstOption>
                                    <mat-option *ngFor="let val of autocompleteContractors" [value]="val">
                                        {{val}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error [control]="form.get('contractor')"></mat-error>

                            </mat-form-field>
                            <ng-select formControlName="location"
                                       [matTooltip]="planning?.location"
                                       [typeahead]="searchLocations$"
                                       [clearable]="true"
                                       (focus)="focusLocationSelect()"
                                       #locationSelect
                                       (ngModelChange)="addressSelected($event)"
                                       [selectableGroup]="false"
                                       [items]="addresses"
                                       class="ng-select-fill location-selection"
                                       placeholder="Locatie"
                                       bindLabel="formattedAddress"
                                       bindValue="formattedAddress">
                            </ng-select>
                            <ng-container *ngIf="viewModel.cutter" formGroupName="cutter">
                                <mat-form-field appearance="fill">
                                    <mat-label>Frees</mat-label>
                                    <mat-select formControlName="cutter" placeholder="Frees">
                                        <mat-option *ngFor="let cutter of cutterList" [disabled]="cutter.notAvailable"
                                                    [value]="cutter.id">{{cutter.name}}</mat-option>
                                    </mat-select>

                                    <mat-error [control]="form.get('cutter').get('cutter')"></mat-error>

                                </mat-form-field>
                            </ng-container>
                            <mat-form-field appearance="fill" *ngIf="viewModel.projectteam">
                                <mat-label>Projectteam</mat-label>
                                <mat-select formControlName="projectteam" placeholder="Ploeg">
                                    <mat-option *ngFor="let team of projectteamList" [disabled]="team.notAvailable" [value]="team.id">{{team.name}}</mat-option>
                                </mat-select>

                                <mat-error [control]="form.get('projectteam')"></mat-error>

                            </mat-form-field>
                            <ng-container *ngIf="viewModel.asfaltteam">
                                <mat-form-field appearance="fill">
                                    <mat-label>Ploeg</mat-label>
                                    <mat-select formControlName="asfaltteam" placeholder="Ploeg">
                                        <mat-option *ngFor="let team of asfaltTeamList" [disabled]="team.notAvailable"
                                                    [value]="team.id">{{team.name}}</mat-option>
                                    </mat-select>

                                    <mat-error [control]="form.get('asfaltteam')"></mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="viewModel.preparationteam">
                                <mat-form-field appearance="fill">
                                    <mat-label>Ploeg</mat-label>
                                    <mat-select formControlName="preparationteam" placeholder="Ploeg">
                                        <mat-option *ngFor="let team of preparationTeamList" [disabled]="team.notAvailable"
                                                    [value]="team.id">{{team.name}}</mat-option>
                                    </mat-select>

                                    <mat-error [control]="form.get('preparationteam')"></mat-error>
                                </mat-form-field>
                            </ng-container>

                            <div *ngIf="viewModel.dumpers && form.get('dumpers').disabled" class="hidden-details">
                                <div class="title">Dumpers</div>
                                <div *ngFor="let dumper of origPlanning.planning_dumpers">
                                    <div [matTooltip]="dumper.entity?.name">{{dumper.entity?.name ?? dumper.entity_id}}</div>
                                    <div class="time">
                                        <ng-container *ngIf="Utils.getDateDate(dumper.begindate) !== Utils.getDateDate(mainPlanning.begindate)">
                                            {{dumper.begindate | date : 'EEE'}}
                                        </ng-container>
                                        {{dumper.begindate | date : 'HH:mm'}}-{{dumper.enddate | date : 'HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <mat-form-field *ngIf="viewModel.dumpers && !form.get('dumpers').disabled" [matTooltip]="dumperListString" appearance="fill">
                                <mat-label>Dumpers</mat-label>
                                <mat-select formControlName="dumpers" multiple placeholder="Selecteer dumpers">
                                    <mat-option *ngFor="let dumper of dumperList" [disabled]="dumper.notAvailable && !dumper.usedHere" [value]="dumper.id">
                                        {{dumper.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error [control]="form.get('dumpers')"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-line">
                            <ng-container formGroupName="date">
                                <mat-form-field appearance="fill" class="date" matTooltip="{{form.get('date')?.get('date')?.value | date : 'EEEE d MMM'}}">
                                    <mat-label>Datum</mat-label>
                                    <input (focus)="pickerStart.open()" [matDatepicker]="pickerStart" formControlName="date" matInput placeholder="Datum"
                                           tabindex="-1">
                                    <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #pickerStart></mat-datepicker>
                                </mat-form-field>
                                <div class="timepicker">
                                    <mat-form-field appearance="fill"
                                                    matTooltip="van {{form.get('date')?.get('begintime')?.value | date : 'HH:mm'}} tot {{form.get('date')?.get('endtime')?.value | date : 'HH:mm'}}">
                                        <mat-label>Van</mat-label>
                                        <mat-select formControlName="begintime" placeholder="van">
                                            <ng-container *ngIf="startTimes">
                                                <mat-optgroup
                                                    *ngFor="let day of startTimes"
                                                    label="{{day.datetime | date : 'EEEE d MMM'}}">
                                                    <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                                        {{time.datetime | date : 'HH:mm'}} uur
                                                    </mat-option>
                                                </mat-optgroup>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill"
                                                    matTooltip="van {{form.get('date')?.get('begintime')?.value | date : 'HH:mm'}} tot {{form.get('date')?.get('endtime')?.value | date : 'HH:mm'}}">
                                        <mat-label>Tot</mat-label>
                                        <mat-select formControlName="endtime" placeholder="Tot tijd">
                                            <ng-container *ngIf="endTimes">
                                                <mat-optgroup
                                                    *ngFor="let day of endTimes"
                                                    label="{{day.datetime | date : 'EEEE d MMM'}}">
                                                    <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                                        {{time.datetime | date : 'HH:mm'}} uur
                                                    </mat-option>
                                                </mat-optgroup>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <mat-form-field appearance="fill" *ngIf="viewModel.performer">
                                <mat-label>Uitvoerder</mat-label>
                                <mat-select formControlName="performer" placeholder="Uitvoerder">
                                    <mat-option
                                        *ngIf="usersMap.has(planning.performer)  && usersMap.get(planning.performer).deleted_at"
                                        [value]="usersMap.get(planning.performer).id">
                                        {{usersMap.get(planning.performer).name}}
                                    </mat-option>
                                    <mat-option *ngFor="let executor of executors" [value]="executor.id">{{executor.name}}</mat-option>
                                </mat-select>

                                <mat-error [control]="form.get('performer')"></mat-error>

                            </mat-form-field>
                            <mat-form-field *ngIf="viewModel.cutter" appearance="fill" formGroupName="cutter">
                                <mat-label>Ontvangst</mat-label>
                                <input formControlName="location" matInput placeholder="Ontvangst" type="text">
                                <mat-error [control]="form.get('cutter').get('location')"></mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="viewModel.projectmanager">
                                <mat-label>Projectleider</mat-label>
                                <mat-select formControlName="projectmanager" placeholder="Projectleider">
                                    <mat-option
                                        *ngIf="usersMap.has(planning.projectmanager)  && usersMap.get(planning.projectmanager).deleted_at"
                                        [value]="usersMap.get(planning.projectmanager).id">
                                        {{usersMap.get(planning.projectmanager).name}}
                                    </mat-option>
                                    <mat-option *ngFor="let projectManager of projectManagers" [value]="projectManager.id">{{projectManager.name}}</mat-option>
                                </mat-select>

                                <mat-error [control]="form.get('projectmanager')"></mat-error>


                            </mat-form-field>
                            <mat-form-field *ngIf="viewModel.asphalt_performer" appearance="fill">
                                <mat-label>Asfaltuitvoerder</mat-label>
                                <mat-select formControlName="asphalt_performer" placeholder="Asfaltuitvoerder">
                                    <mat-option
                                        *ngIf="usersMap.has(planning.asphalt_performer)  && usersMap.get(planning.asphalt_performer).deleted_at"
                                        [value]="usersMap.get(planning.asphalt_performer).id">
                                        {{usersMap.get(planning.asphalt_performer).name}}
                                    </mat-option>
                                    <mat-option *ngFor="let asphaltExecutor of asphaltExecutors"
                                                [value]="asphaltExecutor.id">{{asphaltExecutor.name}}</mat-option>
                                </mat-select>

                                <mat-error [control]="form.get('asphalt_performer')"></mat-error>

                            </mat-form-field>
                            <div *ngIf="viewModel.set && form.get('sets').disabled" class="hidden-details">
                                <div class="title">Sets</div>
                                <div *ngFor="let set of origPlanning.planning_sets">
                                    <div [matTooltip]="set.entity?.name">{{set.entity?.name ?? set.entity_id}}</div>
                                    <div class="time">
                                        <ng-container *ngIf="Utils.getDateDate(set.begindate) !== Utils.getDateDate(mainPlanning.begindate)">
                                            {{set.begindate | date : 'EEE'}}
                                        </ng-container>
                                        {{set.begindate | date : 'HH:mm'}}-{{set.enddate | date : 'HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="viewModel.set && !form.get('sets').disabled">
                                <mat-form-field [matTooltip]="setListString" appearance="fill">
                                    <mat-label>Sets</mat-label>
                                    <mat-select formControlName="sets" multiple placeholder="Selecteer sets">
                                        <mat-option *ngFor="let set of setList" [disabled]="set.notAvailable && !set.usedHere" [value]="set.id">
                                            {{set.name}}
                                        </mat-option>
                                    </mat-select>

                                    <mat-error [control]="form.get('sets')"></mat-error>

                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col col-md-8 pr-md-0" *ngIf="!viewModel.sleepOrBlock">

                        <div class="form-line">
                            <ng-container *ngIf="viewModel.wipetruck" formGroupName="wipetruck">
                                <mat-form-field appearance="fill">
                                    <mat-label>Machinist</mat-label>
                                    <input [matAutocomplete]="autoWipetruckDriver" formControlName="driver" matInput placeholder="Machinist" type="text">
                                    <mat-autocomplete #autoWipetruckDriver="matAutocomplete" autoActiveFirstOption>
                                        <mat-option *ngFor="let val of autocompleteWipetruckdrivers" [value]="val">
                                            {{val}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error [control]="form.get('wipetruck').get('driver')"></mat-error>

                                </mat-form-field>
                            </ng-container>
                            <mat-form-field *ngIf="viewModel.traffic_controllers" appearance="fill">
                                <mat-label>Aantal verkeersregelaars</mat-label>
                                <input appDecimalInput autocomplete="off" formControlName="traffic_controllers" matInput type="text">
                                <mat-error [control]="form.get('traffic_controllers')"></mat-error>
                            </mat-form-field>
                            <ng-container *ngIf="viewModel.cutter" formGroupName="cutter">
                                <mat-form-field appearance="fill">
                                    <mat-label>Tonnen</mat-label>
                                    <input appDecimalInput formControlName="tons" matInput placeholder="Tonnen" type="text">
                                    <mat-error [control]="form.get('cutter').get('tons')"></mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Vrachtwagens</mat-label>
                                    <input appDecimalInput formControlName="trucks" matInput placeholder="Vrachtwagens" type="text">
                                    <mat-error [control]="form.get('cutter').get('trucks')"></mat-error>
                                </mat-form-field>
                            </ng-container>
                            <mat-form-field *ngIf="viewModel.pouring_off" appearance="fill">
                                <mat-label>Afgieten</mat-label>
                                <input [matAutocomplete]="pouring_off" formControlName="pouring_off" matInput placeholder="Afgieten" type="text">
                                <mat-autocomplete #pouring_off="matAutocomplete" autoActiveFirstOption>
                                    <mat-option *ngFor="let val of autocompletePouringOff" [value]="val">
                                        {{val}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error [control]="form.get('pouring_off')"></mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="viewModel.scatter" appearance="fill">
                                <mat-label>Afstrooien</mat-label>
                                <mat-select formControlName="scatter" placeholder="Afstrooien">
                                    <mat-option [value]="true">Ja</mat-option>
                                    <mat-option [value]="false">Nee</mat-option>
                                </mat-select>
                                <mat-error [control]="form.get('scatter')"></mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="viewModel.cranes" appearance="fill" [matTooltip]="craneListString">
                                <mat-label>Mobiele kraan</mat-label>
                                <mat-select formControlName="mobile_crane" placeholder="Mobiele kraan">
                                    <mat-option [value]="true">Ja</mat-option>
                                    <mat-option [value]="false">Nee</mat-option>
                                </mat-select>
                                <mat-error [control]="form.get('mobile_crane')"></mat-error>
                            </mat-form-field>
                            <div *ngIf="viewModel.rollers && form.get('rollers').disabled" class="hidden-details">
                                <div class="title">Walsen</div>
                                <div *ngFor="let roller of origPlanning.planning_rollers">
                                    <div [matTooltip]="roller.entity?.name">{{roller.entity?.name ?? roller.entity_id}}</div>
                                    <div class="time">
                                        <ng-container *ngIf="Utils.getDateDate(roller.begindate) !== Utils.getDateDate(mainPlanning.begindate)">
                                            {{roller.begindate | date : 'EEE'}}
                                        </ng-container>
                                        {{roller.begindate | date : 'HH:mm'}}-{{roller.enddate | date : 'HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <mat-form-field *ngIf="viewModel.rollers && !form.get('rollers').disabled" appearance="fill" [matTooltip]="rollerListString">
                                <mat-label>Walsen</mat-label>
                                <mat-select formControlName="rollers" multiple placeholder="Selecteer walsen">
                                    <mat-option *ngFor="let roller of rollerList" [disabled]="roller.notAvailable && !roller.usedHere" [value]="roller.id">
                                        {{roller.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error [control]="form.get('rollers')"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-line">
                            <ng-container *ngIf="viewModel.wipetruck" formGroupName="wipetruck">
                                <mat-form-field appearance="fill">
                                    <mat-label>Zuig/-veegwagen</mat-label>
                                    <mat-select formControlName="wipetruck" placeholder="Zuig/-veegwagen">
                                        <mat-option *ngFor="let wipetruck of wipetruckList" [disabled]="wipetruck.notAvailable"
                                                    [value]="wipetruck.id">{{wipetruck.name}}</mat-option>
                                    </mat-select>
                                    <mat-error [control]="form.get('wipetruck').get('wipetruck')"></mat-error>
                                </mat-form-field>
                            </ng-container>
                            <mat-form-field *ngIf="viewModel.traffic" appearance="fill">
                                <mat-label>Verkeersmaatregelen</mat-label>
                                <mat-select formControlName="traffic" placeholder="Verkeersmaatregelen">
                                    <mat-option value="Verkeersbus">Verkeersbus</mat-option>
                                    <mat-option value="Verkeersplan">Verkeersplan</mat-option>
                                    <mat-option value="Verkeersplan + Verkeersbus">Verkeersplan + Verkeersbus</mat-option>
                                    <mat-option value="Geen maatregelen">Geen maatregelen</mat-option>
                                </mat-select>
                                <mat-error [control]="form.get('traffic')"></mat-error>
                            </mat-form-field>
                            <ng-container *ngIf="viewModel.cutter" formGroupName="cutter">
                                <mat-form-field appearance="fill">
                                    <mat-label>Dikte</mat-label>
                                    <input appDecimalInput formControlName="thickness" matInput placeholder="Dikte" type="text">
                                    <mat-error [control]="form.get('cutter').get('thickness')"></mat-error>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="viewModel.cutter" formGroupName="cutter">
                                <mat-form-field appearance="fill">
                                    <mat-label>Machinist</mat-label>
                                    <input [matAutocomplete]="autoCutters" formControlName="driver" matInput placeholder="Machinist" type="text">
                                    <mat-autocomplete #autoCutters="matAutocomplete" autoActiveFirstOption>
                                        <mat-option *ngFor="let val of autocompleteCutterDrivers" [value]="val">
                                            {{val}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error [control]="form.get('cutter').get('driver')"></mat-error>

                                </mat-form-field>
                            </ng-container>
                            <mat-form-field *ngIf="viewModel.asphalt_location" [matTooltip]="planning?.asphalt_location" appearance="fill">
                                <mat-label>Asfaltlocatie</mat-label>
                                <input formControlName="asphalt_location" matInput placeholder="Asfaltlocatie" type="text">
                                <mat-error [control]="form.get('asphalt_location')"></mat-error>
                            </mat-form-field>
                            <div *ngIf="viewModel.asphaltOthers && form.get('asphalt_others').disabled" class="hidden-details">
                                <div class="title">GM Asfalt overig</div>
                                <div *ngFor="let asphaltOther of origPlanning.planning_asphalt_others">
                                    <div [matTooltip]="asphaltOther.entity?.name">{{asphaltOther.entity?.name ?? asphaltOther.entity_id}}</div>
                                    <div class="time">
                                        <ng-container *ngIf="Utils.getDateDate(asphaltOther.begindate) !== Utils.getDateDate(mainPlanning.begindate)">
                                            {{asphaltOther.begindate | date : 'EEE'}}
                                        </ng-container>
                                        {{asphaltOther.begindate | date : 'HH:mm'}}-{{asphaltOther.enddate | date : 'HH:mm'}}
                                    </div>
                                </div>
                            </div>
                            <mat-form-field *ngIf="viewModel.asphaltOthers && !form.get('asphalt_others').disabled" appearance="fill"
                                            [matTooltip]="otherString">
                                <mat-label>GM Asfalt overig</mat-label>
                                <mat-select formControlName="asphalt_others" multiple placeholder="Selecteer GM asfalt overig">
                                    <mat-option *ngFor="let equipment of asphaltOtherList"
                                                [disabled]="equipment.notAvailable && !equipment.usedHere"
                                                [value]="equipment.id">
                                        {{equipment.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="form.get('hotbox').value !== null">
                                    Oude waarde hotbox: {{form.get('hotbox').value ? 'Ja' : 'Nee'}}
                                </mat-hint>
                                <mat-error [control]="form.get('asphalt_others')"></mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="viewModel.sticky_wagons" appearance="fill" [matTooltip]="stickyWagonString">
                                <mat-label>Kleefwagen</mat-label>
                                <mat-select formControlName="sticky_wagon" placeholder="Kleefwagen">
                                    <mat-option value="geen">Geen</mat-option>
                                    <mat-option value="klein">Klein</mat-option>
                                    <mat-option value="groot">Groot</mat-option>
                                </mat-select>
                                <mat-error [control]="form.get('sticky_wagon')"></mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="viewModel.hire_people" appearance="fill" (click)="hirePeopleSelector()">
                                <mat-label>Inhuur mensen</mat-label>
                                <input (keydown)="$event.preventDefault()" class="blocked-input" matInput
                                       value="{{planning.planning_hire_people | PlanningEntitiesToString : entitiesMap}}" placeholder="Inhuur mensen">
                                <mat-error [control]="form.get('hire_people')"></mat-error>
                                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="pl-md-0 col col-md-4">
                        <mat-form-field appearance="fill" class="comment-field w-100">
                            <mat-label>Omschrijving</mat-label>
                            <textarea formControlName="description" matInput placeholder="Omschrijving" rows="6"></textarea>
                            <mat-error [control]="form.get('description')"></mat-error>
                        </mat-form-field>
                    </div>
                </div>


            </form>
        </div>
        <div class="bottom-form">
            <div *ngIf="viewModel.asfaltteam">
                <h5>Asfaltleverantie</h5>
                <app-asphalt-list [formsDisabled]="viewModel.asphaltFormDisabled"
                                  [mainPlanning]="mainPlanning"
                                  *ngIf="form"
                                  [planningAsphaltList]="planning.asphalt_list"
                                  [planning]="planning"></app-asphalt-list>
            </div>
            <div *ngIf="viewModel.cutterList">
                <h5>
                    Frezen
                </h5>
                <app-cutter-list [formsDisabled]="viewModel.formDisabled"
                                 [mainPlanning]="mainPlanning"
                                 [fixedDate]="fixedDate"
                                 *ngIf="form"
                                 [planningEntitiesList]="planning.planning_cutters"
                                 [planning]="planning"></app-cutter-list>
            </div>
            <div *ngIf="viewModel.wipertruckList">
                <h5>Veeg-/zuigwagens</h5>
                <app-wipetruck-list [formsDisabled]="viewModel.formDisabled"
                                    [mainPlanning]="mainPlanning"
                                    [fixedDate]="fixedDate"
                                    *ngIf="form"
                                    [planningEntitiesList]="planning.planning_wipetrucks"
                                    [planning]="planning"></app-wipetruck-list>
            </div>
            <div *ngIf="planning.status_id === 3 && !form?.get('reset_transport').value">
                <h5>Personeel</h5>
                <table class="table table-center" [class.table-add-row]="!viewModel.formDisabled && (UserType.EMPLOYEE_PLANNING | hasUserGroup)">
                    <tr>
                        <th>Functie</th>
                        <th>Medewerker</th>
                        <th style="width: 115px; min-width: 115px;">Van</th>
                        <th style="width: 65px; min-width: 65px;">Tot</th>
                        <th>Opmerking</th>
                    </tr>
                    <tr *ngFor="let userPlanning of planning.user_planning">
                        <td>
                            {{userPlanning.user.function}}
                        </td>
                        <td>
                            {{userPlanning.user.name}}
                        </td>
                        <td>
                            {{userPlanning.begindate | date : 'd MMM HH:mm'}}
                        </td>
                        <td>
                            {{userPlanning.enddate | date : 'HH:mm'}}
                        </td>
                        <td>
                            {{userPlanning.comment}}
                        </td>
                    </tr>
                    <tr *ngIf="!viewModel.formDisabled && (UserType.EMPLOYEE_PLANNING | hasUserGroup)">
                        <td colspan="5">
                            <button class="w-100"
                                    color="primary"
                                    mat-button
                                    [disabled]="!planning.id"
                                    (click)="openEmployeesPlanning()">
                                <ng-container *ngIf="planning.id">
                                    Personeelsplanning aanpassen <i class="fa-duotone fa-user-helmet-safety"></i>
                                </ng-container>
                                <ng-container *ngIf="!planning.id">
                                    Sla planning op om personeelsplanning te kunnen aanpassen <i class="fa-duotone fa-user-helmet-safety"></i>
                                </ng-container>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-wrap pt-2 pb-2">
    <button (click)="delete()" *ngIf="planning.id && !viewModel.formDisabled" color="warn" color="warn" mat-button>
        Verwijderen
    </button>
    <button (click)="copy()" *ngIf="planning.id" [disabled]="form?.dirty" color="warn" color="secondary" mat-button>
        Kopi&euml;ren
    </button>
    <button (click)="print()" *ngIf="planning.id" color="warn" color="secondary" mat-button>
        Printen
    </button>
    <div *ngIf="externalChanged" class="fixed-date-message">
        Deze planning is vanaf een ander apparaat aangepast. Open de planning opnieuw om wijzigingen aan te brengen.
    </div>
    <div *ngIf="fixedDate" class="fixed-date-message">
        Dit item kan alleen door een materieelplanner worden aangepast omdat de planning is vastgezet tot {{fixedDate | date : 'EEEE d MMMM yyyy'}}.
    </div>
    <div *ngIf="viewModel.formDisabled && !fixedDate" class="fixed-date-message">
        Dit item kan alleen door een materieelplanner en
        {{(usersMap.get(planning.projectmanager)?.name)}}
        worden aangepast.
    </div>
    <div class="spacer"></div>
    <button (click)="close()" class="mr-2" color="secondary" mat-raised-button>{{viewModel.formDisabled ? 'Sluiten' : 'Annuleren'}}</button>
    <button (click)="save()" *ngIf="!externalChanged && (!viewModel.formDisabled || !viewModel.asphaltFormDisabled)" [disabled]="saving" color="primary"
            mat-raised-button>
        {{saving ? 'Valideren & opslaan...' : 'Opslaan'}} <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
    </button>
</div>
