import {Planning} from './planning.class';
import {PlanningAsfaltteam} from './planningasfaltteam.class';
import {PlanningCutter} from './planningcutter.class';
import {PlanningHasEntity} from './planning-has-entity.class';
import {PlanningTruck} from './planningtruck.class';
import {PlanningDumper} from './planningdumper.class';

export class PlanningDayItem {
    public duration: number;
    public minutesOffset: number;
    public planning: Planning;
    public planningEntity: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity | PlanningCutter | PlanningTruck | PlanningDumper;
}
