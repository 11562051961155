import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'datetimeEquals'
})
export class DatetimeEqualsPipe implements PipeTransform {

    transform(value: Date, equals: Date): unknown {
        const val = new Date(value);
        const eq = new Date(equals);

        return val.getTime() === eq.getTime();
    }

}
