import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {RequestRestorePasswordState} from './request-restore-password-state.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-request-restore-password',
    templateUrl: './request-restore-password.component.html',
    styleUrls: ['./request-restore-password.component.scss']
})
export class RequestRestorePasswordComponent implements OnInit {

    public RequestRestorePasswordState = RequestRestorePasswordState;
    environment = environment;

    form = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });
    public requestState: RequestRestorePasswordState = RequestRestorePasswordState.FillInEmail;

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
    }

    requestPasswordEmail() {
        this.requestState = RequestRestorePasswordState.SendingEmail;
        this.authService.requestRestorePassword(this.form.value.email).then((response) => {
            this.requestState = RequestRestorePasswordState.SendedEmail;
        }, () => {
            this.requestState = RequestRestorePasswordState.SendEmailFail;
        });
    }

}
