import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {PlanningService} from '../../services/planning/planning.service';
import {EntitiesService} from '../../services/entities/entities.service';
import {Entity} from '../../classes/entity.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {UserService, UserType} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {EntityType, EntityTypeCode} from '../../services/entities/entity-type.class';
import {Utils} from '../../utils.class';
import {Subscription} from 'rxjs';
import {Planning} from '../../classes/planning.class';

@Component({
    selector: 'app-transport-in-use',
    templateUrl: './transport-in-use.component.html',
    styleUrls: ['./transport-in-use.component.scss']
})
export class TransportInUseComponent extends CodaltComponent implements OnChanges, OnInit {

    @Input() currentDate: Date;

    entities: Entity[];

    users: User[];
    drivers: User[];

    planningMap = new Map<number, PlanningHasEntity[]>();
    plannings: Planning[];

    types: EntityType[];
    typeMap = new Map<number, Entity[]>();
    usedDrivers: number[] = [];

    private dateSubscriptions: Subscription;

    constructor(private planningService: PlanningService,
                private entitiesService: EntitiesService,
                private userService: UserService) {
        super();
    }

    ngOnInit() {
        const showTypes = [
            EntityTypeCode.Truck,
            EntityTypeCode.Crane,
            EntityTypeCode.StickyWagon,
            EntityTypeCode.Roller,
            EntityTypeCode.Dumper,
            EntityTypeCode.Set
        ];

        this.subscriptions.add(this.entitiesService.getTypes().subscribe(types => {
            this.types = types.data
                .filter(type => showTypes.indexOf(type.id) !== -1)
                .sort((a, b) => {
                    return showTypes.indexOf(a.id) - showTypes.indexOf(b.id);
                });

            this.loadData();
        }));

        this.subscriptions.add(this.userService.getList(false, false).subscribe(drivers => this.users = drivers));
        this.subscriptions.add(this.userService.getByType(UserType.TRUCK_DRIVER).subscribe(drivers => {
            this.drivers = drivers.sort((a, b) => (a.function + a.name).localeCompare((b.function + b.name)));
        }));
    }

    loadData() {
        this.typeMap = new Map<number, Entity[]>();
        this.usedDrivers = [];
        this.planningMap = new Map<number, PlanningHasEntity[]>();

        const dayAfter = this.Utils.newDate(this.currentDate);
        dayAfter.setDate(dayAfter.getDate() + 1);

        this.dateSubscriptions?.unsubscribe();
        this.dateSubscriptions = new Subscription();

        this.dateSubscriptions.add(this.entitiesService.getList(this.currentDate, dayAfter).subscribe(entities => {
            this.types.forEach(type => {
                this.typeMap.set(type.id,
                    entities
                        .filter(e => !!e.entitytypes.find(et => et.id === type.id))
                        .sort((a, b) => {
                            return +a.order - +b.order;
                        })
                );
            });
        }));

        this.dateSubscriptions.add(this.planningService.getFilteredList(this.currentDate, dayAfter).subscribe(planning => {
            this.usedDrivers = [];
            this.planningMap = new Map<number, PlanningHasEntity[]>();
            this.plannings = planning;
            planning.forEach(pln => {
                pln.planning_has?.filter(ae => Utils.displayOnDate(ae, pln, this.currentDate))
                    .forEach(phe => {
                        [phe.entity_id, phe.truck_entity_id, phe.lowloader_entity_id].filter(e => !!e).forEach(anyEntityId => {
                            const planningList = this.planningMap.get(anyEntityId);
                            if (planningList) {
                                if (planningList.indexOf(phe) === -1) {
                                    planningList.push(phe);

                                    planningList.sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
                                }
                            } else {
                                this.planningMap.set(anyEntityId, [phe]);
                            }
                        });

                        if (phe.driver_user_id) {
                            this.usedDrivers.push(phe.driver_user_id);
                        }
                    });
            });
        }));

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && !!changes['currentDate'] && this.types) {
            this.loadData();
        }
    }
}
