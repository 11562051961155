<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Bak van Wessel</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div mat-dialog-content>
    <table class="table table-striped table-hover table-bordered">
        <thead>
        <tr>
            <th>Opdrachtgever</th>
            <th>Locatie</th>
            <th>Projectleider</th>
            <th>Ton asfalt</th>
        </tr>
        </thead>
        <tr (click)="openPlanning(pln)" *ngFor="let pln of planning" class="curpoint">
            <td>{{pln.contractor}}</td>
            <td>{{pln.location}}</td>
            <td>{{users.get(pln.projectmanager).name}}</td>
            <td class="txtright">{{tons.get(pln.id)}}</td>
        </tr>
    </table>

    <button mat-raised-button (click)="openPlanning()" color="primary">Planning toevoegen</button>
</div>
