import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from './utils.class';

@Pipe({
    name: 'isNight'
})
export class IsNightPipe implements PipeTransform {

    transform(begin: Date, end?: Date): boolean {
        return Utils.isNight(begin, end);
    }

}
