import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {PlanningStatus} from "../../../../planning-status.enum";
import {AsphaltListItemComponent} from "./asphalt-list-item.component";

export class AsphaltListItemComponentRules {

    static time = (component: AsphaltListItemComponent) => {
        return component.planning.status_id !== PlanningStatus.bakvanwessel;
    };

}

export function RequiredRule(component: AsphaltListItemComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const getControlName = (c: AbstractControl): string | null => {
            if (c && c.parent) {
                const formGroup = c.parent.controls;
                return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
            }
        }

        // controlName is empty on initializing because parent has not been set yet
        const controlName = getControlName(control);
        if (controlName) {
            const rule = AsphaltListItemComponentRules[controlName];
            if (!rule) {
                throw('No rule defined for ' + controlName + ', please define rule in entities-list-abstract-component.required-rules.ts');
            }

            if (
                (control.value == null || control.value === undefined
                    || (Array.isArray(control.value) && control.value.length < 1)) &&
                rule.call(null, component)
            ) {
                return {required: true};
            }
            return null;
        }
        return {required: true};
    };


}