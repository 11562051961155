<div class="modal-inner">
    <h1>Kies een project</h1>
    <input placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
    <div class="results">
        <div class="result text-center" *ngIf="searching">
            aan het zoeken...
        </div>
        <div class="result"
             (click)="selectProject(project)"
             *ngFor="let project of projects">
            <strong>{{project.afas_project_id}}</strong> {{project.name}}
        </div>
    </div>
    <div class="buttons">

        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
