import {UserService, UserType} from '../services/user/user.service';

export class PlanningOverviewViewModel {
    openEntity = false;
    addPlanning = false;
    addPlanningFixed = false;
    lockPlanning = false;

    updateViewSate() {
        this.openEntity = UserService.userHasRights(UserType.WORKPLANNER);
        this.addPlanning = UserService.userHasRights(UserType.PROJECTMANAGER);
        this.addPlanningFixed = UserService.userHasRights(UserType.WORKPLANNER);
        this.lockPlanning = UserService.userHasRights(UserType.WORKPLANNER);
    }
}
