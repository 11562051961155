import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {UserService, UserType} from '../services/user/user.service';

@Pipe({
    name: 'checkHoursFilter'
})
export class CheckHoursFilterPipe implements PipeTransform {

    transform(values: Realisation[], functions: string[], hourtypes?: string[], hideApproved?: boolean): Realisation[] {
        let resultValues = values;
        if (hideApproved) {
            resultValues = resultValues.filter(v => {
                if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK)) {
                    return !v.approved || (v.comment_user_approved && !v.comment_user_approved_handled);
                }
                return !v.approved;
            });
        }
        if (hourtypes?.length) {
            resultValues = resultValues.filter(v => hourtypes.map(ht => ht.split('|')).flat().includes(v.hourtype));
        }
        if (functions?.length) {
            resultValues = resultValues.filter(p => functions.indexOf(p?.user?.function) !== -1);
        }
        return resultValues;
    }

}
