import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Address, LocationService} from '../../services/location.service';
import {User} from '../../classes/user.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {debounceTime} from 'rxjs/operators';
import {CodaltComponent} from '../../codalt.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-transport-address',
    templateUrl: './transport-address.component.html',
    styleUrls: ['./transport-address.component.scss']
})
export class TransportAddressComponent extends CodaltComponent implements OnChanges, OnInit {

    @Input() users: User[];
    @Input() address: (string | Address);
    @Input() planning: PlanningHasEntity;

    @Input() main: PlanningHasEntity;

    @Input() placeholder: string;

    @Input() fc: FormControl;

    @Input() fg: FormGroup;

    @Input() hasNext: boolean;
    @Input() isOrigin: boolean;

    addresses: string[];
    fcSearch = new FormControl();
    bingLocationSubsciption: Subscription;
    addressVisible = true;

    driverAddress: string;

    dvdsAddress: string;

    isFocussed = false;

    icon: 'own' | 'dvds' | 'else' | 'work' | 'next' | 'none';

    constructor(private locationService: LocationService) {
        super();
        this.dvdsAddress = this.locationService.formatAddress(this.locationService.dvdsAddress);
    }

    ngOnInit() {
        if (this.fc.disabled) {
            this.fcSearch.disable();
        }
        this.subscriptions.add(this.fcSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            this.bingLocationSubsciption?.unsubscribe();
            const addresses = [];
            this.bingLocationSubsciption = this.locationService.getLocations(search + ' Nederland').subscribe(result => {
                result.resourceSets.forEach(res => {
                    res.resources.forEach(address => {
                        addresses.push(address.address.formattedAddress);
                    });
                });
                this.addresses = addresses;
            });
        }));
        this.subscriptions.add(this.fg.get('driver_user_id')?.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            this.setDriverAddress();
        }));
        this.subscriptions.add(this.fc.statusChanges.subscribe(() => {
            if (this.fc.disabled) {
                this.fcSearch.disable();
            } else {
                this.fcSearch.enable();
            }
        }))
    }

    private setDriverAddress() {
        this.driverAddress = this.locationService.formatAddress(this.users.find(u => u.id === this.fg.get('driver_user_id').value) as any);
        this.setIcon();
    }

    ngOnChanges(): void {
        this.setDriverAddress();
    }

    optionSelected(event: any) {
        this.fc.setValue(event.option.value);
        this.fc.markAsDirty();
        this.fcSearch.setValue('');
        this.setIcon();
        setTimeout(() => {
            window.focus();
            if (document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
        });
    }

    setIcon() {
        if (!this.users || !(this.fc.value?.length > 0)) {
            this.icon = 'else';
            this.addressVisible = true;
            return;
        }
        this.addressVisible = false;
        if (this.main.planning?.location === this.fc.value) {
            this.addressVisible = true;
            this.icon = 'work';
        } else if ('next' === this.fc.value) {
            this.icon = 'next';
        } else if ('none' === this.fc.value) {
            this.icon = 'none';
        } else if (this.dvdsAddress === this.fc.value) {
            this.icon = 'dvds';
        } else if (this.driverAddress === this.fc.value) {
            this.icon = 'own';
        } else {
            this.addressVisible = true;
            this.icon = 'else';
        }
    }

}
