import {Component, Input, OnChanges} from '@angular/core';
import {Address, LocationService} from '../../../services/location.service';
import {User} from '../../../classes/user.class';
import {Planning} from '../../../classes/planning.class';

@Component({
    selector: 'app-address-icon',
    templateUrl: './address-icon.component.html',
    styleUrls: ['./address-icon.component.scss']
})
export class AddressIconComponent implements OnChanges {

    @Input() users: User[];
    @Input() userId;
    @Input() address: (string | Address);
    @Input() planning: Planning;

    icon: 'own' | 'dvds' | 'else' | 'work';

    constructor(private locationService: LocationService) {
    }

    ngOnChanges(): void {
        if (this.planning?.location === this.address) {
            this.icon = 'work';
        } else if (this.locationService.formatAddress(this.locationService.dvdsAddress) === this.address) {
            this.icon = 'dvds';
        } else if (this.locationService.formatAddress(this.users.find(u => u.id === this.userId) as any) === this.address) {
            this.icon = 'own';
        } else if (this.address) {
            this.icon = 'else';
        }

    }

}
