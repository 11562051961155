import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PlanningDay} from '../../classes/planning-day.class';
import {combineLatest, Subscription} from 'rxjs';
import {PlanningFixedService} from '../../services/planning/planning-fixed.service';
import {PlanningService} from '../../services/planning/planning.service';
import {Utils} from '../../utils.class';
import {PlanningAsfaltteam} from '../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {EntitiesService} from '../../services/entities/entities.service';
import {Entity} from '../../classes/entity.class';

@Component({
    selector: 'app-set-planning-fixed-dialog',
    templateUrl: './set-planning-fixed-dialog.component.html',
    styleUrls: ['./set-planning-fixed-dialog.component.scss']
})
export class SetPlanningFixedDialogComponent implements OnInit, OnDestroy {

    fixedDate: Date;
    mayFixPlanning = false;
    private subscriptions = new Subscription();

    constructor(@Inject(MAT_DIALOG_DATA) public day: PlanningDay,
                public dialogRef: MatDialogRef<SetPlanningFixedDialogComponent>,
                private planningFixedService: PlanningFixedService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.planningFixedService.getLatestItem().subscribe(fixed => {
            this.fixedDate = new Date(fixed.fixeduntil);
            this.mayFixPlanning = this.fixedDate < this.day.date;
        }));
    }

    fixPlanning() {
        const fixedDate = Utils.setTime(new Date(this.day.date), 23, 59);
        this.planningFixedService.setFixed(fixedDate);
        this.dialogRef.close();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
