<div class="login-background">

    <form [formGroup]="loginForm">
        <div class="login-form">
            <ng-container
                *ngIf="loginFormState === LoginFormState.FillInPassword || loginFormState === LoginFormState.FillIn || loginFormState === LoginFormState.Error || loginFormState === LoginFormState.Login">
                <h1 class="h2 condensed">Inloggen met code</h1>

                <mat-form-field appearance="fill" id="email">
                    <input formControlName="email"
                           matInput
                           name="email"
                           placeholder="Vul hier je e-mailadres in"
                           autocomplete="email"
                           (keydown.enter)="checkLoginType($event)"
                           type="text">
                    <mat-label>E-mailadres</mat-label>
                    <mat-error *ngIf="loginForm.invalid">Vul een correct e-mailadres in</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" id="password" *ngIf="loginFormState === LoginFormState.FillInPassword">
                    <mat-label>Wachtwoord</mat-label>
                    <input formControlName="password"
                           autocomplete="current-password"
                           matInput
                           name="password"
                           placeholder="Wat is uw wachtwoord?"
                           type="password">
                    <mat-error *ngIf="loginForm.invalid">Vul uw wachtwoord in</mat-error>
                </mat-form-field>
                <div *ngIf="errorMessage" class="mb-3 pb-1 pt-1 error-background text-center">
                    {{errorMessage}}
                </div>
                <div class="login-buttons">

                    <button (click)="login()"
                            [disabled]="loginFormState === LoginFormState.Login || loginForm.invalid"
                            class="loading big dark" color="primary" id="btn-login"
                            mat-raised-button>
                        <ng-container *ngIf="loginFormState === LoginFormState.FillInPassword || loginForm.get('password')?.value">
                            Inloggen
                        </ng-container>
                        <ng-container *ngIf="loginFormState !== LoginFormState.FillInPassword && !loginForm.get('password')?.value">
                            <ng-container *ngIf="loginFormState !== LoginFormState.Login">
                                Opvragen
                            </ng-container>
                            <ng-container *ngIf="loginFormState === LoginFormState.Login">
                                We sturen je nu een code
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </ng-container>
                        </ng-container>
                    </button>
                    <a *ngIf="loginFormState !== LoginFormState.FillInPassword && !loginForm.get('password')?.value"
                       mat-raised-button color="accent" href="tel:+31646788962">
                        <i class="fa-regular fa-phone"></i> Ian
                    </a>
                    <button *ngIf="loginFormState === LoginFormState.FillInPassword || loginForm.get('password')?.value"
                            (click)="forgotPassword()" class="mr-2" id="btn-lost-password" mat-button>
                        Wachtwoord vergeten
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="loginFormState === LoginFormState.Send || loginFormState === LoginFormState.Validating">
                <h1>E-mail verstuurd</h1>
                <div class="send mb-2">
                    We hebben een code verstuurd naar <span class="mail">{{loginForm.value.email}}</span>.<br>
                </div>
                <mat-form-field appearance="fill" id="code">
                    <input (keyup.enter)="validateCode()"
                           (paste)="pasteCode($event)"
                           [decimalInput]="0"
                           appDecimalInput
                           autocomplete="one-time-code"
                           formControlName="code"
                           matInput
                           maxlength="6"
                           name="code"
                           placeholder="Voer de code hier in"
                           type="text">
                    <mat-label>Code uit e-mail</mat-label>
                </mat-form-field>
                <div *ngIf="errorMessage" class="mb-3 pb-1 pt-1 error-background text-center">
                    {{errorMessage}}
                </div>
                <div class="login-buttons">
                    <button (click)="validateCode()"
                            [disabled]="loginFormState === LoginFormState.Validating || loginForm.invalid"
                            class="loading big dark" color="primary" id="btn-code"
                            mat-raised-button>
                        <ng-container *ngIf="loginFormState === LoginFormState.Send">
                            Bevestigen
                        </ng-container>
                        <ng-container *ngIf="loginFormState === LoginFormState.Validating">
                            We controleren je code
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </ng-container>
                    </button>
                    <a mat-raised-button color="accent" href="tel:+31646788962">
                        <i class="fa-regular fa-phone"></i> Ian
                    </a>
                </div>
                <small *ngIf="loginFormState === LoginFormState.Send" class="mt-3 mb-3">
                    Geen e-mail ontvangen? Controleer je spam
                </small>
            </ng-container>
        </div>
    </form>
</div>
