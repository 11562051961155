import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

declare var tinymce: any;

@Component({
    selector: 'simple-tiny',
    template: `
        <div id="{{elementId}}{{propertyName}}"></div>`,
    styleUrls: [`simple-tiny.component.scss`]
})
export class SimpleTinyComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @HostBinding('class.ng-touched') ngTouched: boolean = false;
    @HostBinding('class.ng-invalid') ngInvalid: boolean = false;

    @Input() contentCSS;
    @Input() showMenuBar = true;
    @Input() elementId: String;
    @Input() content: any;
    @Input() height: number;
    @Input() propertyName = 'content';
    @Input() colorSetName = 'common';
    @Output() onEditorKeyup = new EventEmitter<any>();
    editor;
    private colorSet = {
        common: [
            '0f0064', 'blauw',
            '85D1E4', 'lichtblauw',
            '000000', 'zwart',
            '666666', 'grijs',
            'ffffff', 'wit']
    };

    subscriptions = new Subscription();

    private currentColorSet = this.colorSet.common;

    constructor(private matDialog: MatDialog) {
    }

    updateview(editor) {
        const editorcontent = editor.getContent({format: 'html'}).trim();
        if (this.content instanceof FormControl) {
            this.content.setValue(editorcontent);
            setTimeout(() => {
                this.ngTouched = true;
                this.ngInvalid = this.content.invalid;
            });
        } else {
            this.content[this.propertyName] = editorcontent;
        }
    }

    ngOnChanges(changes) {
        if (this.editor) {
            if (!this.content[this.propertyName]) {
                this.content[this.propertyName] = '';
            }
            let content = this.content[this.propertyName];
            if (this.content instanceof FormControl) {
                content = this.content.value || '';
                if (this.content.disabled) {
                    this.editor.mode.set('readonly');
                }

            }
            this.editor.setContent(content);
            this.editor.undoManager.clear();
        }
        if (this.content instanceof FormControl) {
            this.subscriptions.add(this.content.statusChanges.subscribe(() => {
                this.ngInvalid = this.content.invalid;
                this.ngTouched = this.content.touched;
            }));
        }
    }

    ngAfterViewInit() {
        this.setColorSet();
        setTimeout(() => {

            tinymce.init({
                promotion: false,
                selector: '#' + this.elementId + this.propertyName,
                base_url: '/tinymce',
                suffix: '.min',
                browser_spellcheck: true,
                contextmenu: false,
                language: 'nl',
                menubar: this.showMenuBar ? 'insert edit format table view' : '',
                menu: {
                    insert: {title: 'Invoegen', items: 'charmap | link media | tempalte hr charmap | quote | shy | clear | inbetweenad '},
                    edit: {title: 'Bewerken', items: 'undo redo | cut copy paste pastetext | selectall'},
                    format: {title: 'Stijl', items: ' bold italic underline | align | removeformat  | styleselect'},
                    table: {title: 'Tabel', items: 'inserttable tableprops | cell row column'},
                    view: {title: 'Weergeven', items: 'fullscreen code preview'}
                },
                plugins: ['link', 'media', 'table', 'lists', 'advlist', 'fullscreen', 'quickbars', 'charmap', 'code'],
                toolbar1: 'undo redo | bold italic | bullist numlist',
                toolbar2: '',
                toolbar3: '',
                paste_as_text: true,
                color_map: this.currentColorSet,
                formats: {
                    h2accent: {block: 'h2', classes: 'color-accent'}
                },
                block_formats: 'Paragraaf=p; Kop 2=h2; Kop 2 rood=h2accent; Header 3=h3',
                quickbars_selection_toolbar: 'bold italic underline quicklink h2 h3 charmap forecolor',
                skin: 'oxide',
                table_default_styles: {
                    width: '100%'
                },
                table_default_attributes: {
                    cellspacing: 0
                },
                extended_valid_elements: 'iframe[src|title|frameborder|allowfullscreen]',
                content_css: this.contentCSS ? `/assets/tinycss/${this.contentCSS}` : null,
                style_formats: [
                    {title: 'Kop 1', block: 'h1'},
                    {title: 'Kop 2', block: 'h2'},
                    {title: 'Kop 3', block: 'h3'},
                    {title: 'Kleiner', inline: 'span', classes: 'textsmall'},
                    {title: 'Groter', inline: 'span', classes: 'textlarge'}
                ],
                relative_urls: false,
                remove_script_host: false,
                min_height: this.height ?? 200,
                init_instance_callback: editor => {
                    if (!this.content[this.propertyName]) {
                        this.content[this.propertyName] = '';
                    }

                    let content = this.content[this.propertyName];
                    if (this.content instanceof FormControl) {
                        content = this.content.value || '';
                        if (this.content.disabled) {
                            this.editor.mode.set('readonly');
                        }
                    }

                    this.editor.setContent(content);
                },
                setup: editor => {
                    this.editor = editor;

                    editor.on('ExecCommand', () => {
                        editor.save();
                    });

                    editor.on('change', () => {
                        editor.save();
                        if (this.content instanceof FormControl) {
                            this.content.markAllAsTouched();
                        }
                        if (editor.getContent({format: 'html'}).trim().length !== this.content?.value?.length) {
                            this.content.markAsDirty();
                        }
                        this.updateview(editor);

                    });

                    editor.on('ObjectResized', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                }
            });

        });
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.elementId += '' + (Math.round(Math.random() * 9999));
    }

    private setColorSet(): void {
        this.currentColorSet = this.colorSet[this.colorSetName];
    }

}
