import {Component, HostListener, OnInit} from '@angular/core';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {AsphaltService} from '../../services/asphalt/asphalt.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Asphaltmill} from '../../classes/asphaltmill.class';
import {ApiService} from '../../services/api/api.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-import-asphalt-list-dialog',
    templateUrl: './import-asphalt-list-dialog.component.html',
    styleUrls: ['./import-asphalt-list-dialog.component.scss']
})
export class ImportAsphaltListDialogComponent implements OnInit {

    public uploading;
    public dragOver = false;
    public saving = false;
    public file: File;
    environment = environment;

    public asphaltMills: Asphaltmill[];

    public form = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.maxLength(255)])
    });

    constructor(private confirmDialogService: ConfirmDialogService,
                private asphaltService: AsphaltService,
                private apiService: ApiService,
                public dialogRef: MatDialogRef<ImportAsphaltListDialogComponent>) {
    }

    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.target.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        this.file = files[0];
    }

    ngOnInit() {
        this.asphaltService.getAsphaltMills().then(asphaltMill => {
            this.asphaltMills = asphaltMill;
        });
    }

    public selectFile(event) {
        event.stopPropagation();
        event.preventDefault();
        this.file = event.target.files[0];
    }

    public import() {
        this.uploadFiles([this.file]);
    }

    public uploadFiles(files: File[]) {
        const url = `${environment.apiEndpoint}asphalt/import`;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                this.uploading = true;
                if (files[i].size < 110664300) { // ~100MB
                    this.handleOneFile(url, files, i);
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    private handleOneFile(url: string, files: File[], i: number) {
        this.apiService.makeFileRequest(url, files[i], {asphaltmill: this.form.get('name').value}).subscribe(data => {
            if (data['success']) {
                this.uploading = false;
                const nonExisting = data['nonExisting'];
                const columnMap = data['columnMap'];

                if (nonExisting.length > 0) {
                    let typesToAddText = '';
                    nonExisting.forEach(row => {
                        typesToAddText += `<br> - ${row[columnMap['asphalttype']]}`;
                    });
                    this.confirmDialogService.confirm(
                        'Niet bestaande asfalt soorten',
                        `De volgende asfalt soorten zijn nieuw: ${typesToAddText}<br> wilt u deze toevoegen?`,
                        'Soorten Toevoegen',
                        'Annuleren').then(confirm => {
                        this.asphaltService.importCorrection(data).then(result => {
                            this.dialogRef.close();
                        });
                    }, cancel => {

                    });
                } else {
                    this.dialogRef.close();
                }
            } else {
                if (files.length === 1) {
                    this.uploading = false;
                }
                this.uploadErrorMessage(data['message']);
            }
        }, error => {
            this.uploading = false;
            this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage']}`);
        });
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Er is iets foutgegaan bij het uploaden',
            `${message}`,
            'Oké', null).then(() => {
            this.dialogRef.close();
        }, () => {
        });
    }
}
