<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Materieel {{planning.worknumber ?? planning.afas_project_id}}</div>
    <div>
        <button mat-dialog-close mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div class="flex-fill" *ngIf="entitiesMap && entities">
    <div class="row">
        <div class="col-lg-8 col-md-9 col-xl-9">
            <h3>Details</h3>
            <table class="table table-last-col table-hover">
                <tr>
                    <th>Type</th>
                    <th>Materieel</th>
                    <th style="width: 115px; min-width: 115px;">Van</th>
                    <th style="width: 65px; min-width: 65px;">Tot</th>
                    <th>Opmerking</th>
                    <th>

                    </th>
                </tr>
                <tr *ngFor="let formgroup of form.controls" [formGroup]="formgroup">
                    <td>
                        {{entityTypeMap.get(formgroup.controls.entitytype_id.value)?.name}}
                    </td>
                    <td>
                        {{entitiesMap.get(formgroup.controls.entity_id.value)?.name}}
                    </td>
                    <td class="datetime-input" matTooltip="{{formgroup.controls.conflictText.value}}">

                        <app-datetime-input [formControl]="formgroup.controls.begindate"
                                            labelDate="start"
                                            labelTime="tijd"></app-datetime-input>
                    </td>
                    <td class="datetime-input">
                        <app-datetime-input [formControl]="formgroup.controls.enddate"
                                            labelDate="eind"
                                            [hideDate]="true"
                                            labelTime="tijd"></app-datetime-input>
                    </td>
                    <td class="textarea-input">
                            <textarea [formControl]="formgroup.controls.comment"
                                      matInput
                                      cdkTextareaAutosize
                                      placeholder="Omschrijving"
                                      [cdkAutosizeMaxRows]="5"
                                      rows="1"></textarea>
                    </td>
                    <td>
                        <button (click)="delete(formgroup)" class="delete-button" color="warn" mat-icon-button>
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </table>
            <h3>Materieel inplannen</h3>
            <div class="row">
                <div class="col">
                    <div class="pr-5">
                        <ng-select [formControl]="fcEntity"
                                   appearance="outline"
                                   (change)="addEquipment(+this.fcEntity.value)"
                                   name="employee-filter"
                                   placeholder="Kies materieel"
                                   [clearable]="true"
                                   appendTo="body"
                                   tabIndex="-1">
                            <ng-option *ngFor="let entity of filteredEntities" [value]="entity.id">
                                {{entity.name}}
                                (
                                <ng-container *ngFor="let t of entity.entitytypes">{{t.name}} </ng-container>
                                )
                            </ng-option>
                        </ng-select>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-xl-3">
            <h3>Overzicht</h3>
            <table class="table">
                <tr>
                    <th>Functie</th>
                    <th>Aantal</th>
                </tr>
                <tr *ngFor="let sum of sums">
                    <td>
                        {{entityTypeMap.get(sum.entitytypeId)?.plural_name}}
                    </td>
                    <td>
                        {{sum.amount}}
                    </td>
                </tr>
            </table>
            <b>Projectnummer:</b> {{planning.worknumber ?? planning.afas_project_id}}<br>
            <b>Locatie:</b> {{planning.location}}<br>
            <b>Omschrijving:</b> {{planning.description}}
        </div>
    </div>
</div>
<div class="d-flex flex-wrap pt-2 pb-2 justify-content-between">
    <button id="close-empl" (click)="close()" class="mr-2" color="secondary" mat-raised-button>Sluiten</button>
    <button (click)="save()" [disabled]="saving" color="primary"
            mat-raised-button>
        Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
    </button>
</div>
