<div class="brand" (click)="logoclick()">
    <div class="logo curpoint" routerLink="/">

    </div>
    <div class="title">
        <div class="head" [class.print]="print">
            {{mainTitle}}
        </div>
        <div class="sub" *ngIf="!print">
            {{subTitle}}
        </div>
    </div>
</div>
