import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';

@Injectable({
    providedIn: 'root'
})
export class AfasAuthService {

    constructor(private apiService: ApiService) {
    }

    public requestOtp(user: string): Promise<Object> {
        return this.apiService.postCall('afas-auth/requestotp', {user: user});
    }

    public validateOtp(user: string, otp: string): Promise<Object> {
        return this.apiService.postCall('afas-auth/validateotp', {user: user, otp: otp});
    }

    public validateToken(barcode: string): Promise<Object> {
        return this.apiService.postCall('afas-auth/validatetoken', {barcode: barcode});
    }

    public deleteExisting(): Promise<Object> {
        return this.apiService.postCall('afas-auth/deleteexisting');
    }

    public isAuthenticated(): Promise<Object> {
        return this.apiService.postCall('afas-auth/authenticated');
    }

}
