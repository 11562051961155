import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PlanningService, TrucksForDate} from "../../../services/planning/planning.service";
import {Subscription} from "rxjs";
import {EntityUnavailableService} from "../../../services/entities/entity-unavailable.service";
import {EntitiesService} from "../../../services/entities/entities.service";
import {Utils} from "../../../utils.class";

@Component({
    selector: 'app-trucks',
    templateUrl: './trucks.component.html',
    styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit, OnDestroy {

    public trucks: TrucksForDate;
    @Input() print: boolean;
    public todaysTruckCount = 0;
    @Input() private fromDate: Date;
    @Input() private toDate: Date;
    @Input() private date: Date;
    private subscriptions = new Subscription();

    constructor(private planningService: PlanningService,
                private entitiesService: EntitiesService,
                private entityUnavailableService: EntityUnavailableService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.planningService.getCountOfUsedTrucks(this.fromDate, this.toDate, this.date)
            .subscribe(trucks => this.trucks = trucks)
        );

        const endOfDay = Utils.setTime(new Date(this.date), 23, 59);
        this.subscriptions.add(this.entityUnavailableService.getTrucksAvailableForRange(this.date, endOfDay)
            .subscribe(todayTrucks => this.todaysTruckCount = todayTrucks)
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
