import {Pipe, PipeTransform} from '@angular/core';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';

@Pipe({
    name: 'entityType',
    pure: false
})
export class EntityTypePipe implements PipeTransform {

    transform(plannings: PlanningHasEntity[], field, value): unknown {
        return plannings.filter(p => !!p.entitytype && p.entitytype[field] === value);
    }

}
