import {Pipe, PipeTransform} from '@angular/core';
import {UserService, UserType} from '../services/user/user.service';
import {User} from '../classes/user.class';

@Pipe({
    name: 'hasUserGroup'
})
export class HasUserGroupPipe implements PipeTransform {

    transform(group: UserType, user?: User): boolean {
        return UserService.userHasRights(group, user);
    }

}
