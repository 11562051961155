import {FormControl} from '@angular/forms';
import {PlanningService} from '../services/planning/planning.service';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Utils} from '../utils.class';
import {first, map} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
import {ProjectService} from '../services/project.service';

export const localUserAvailableValidator = (planning?: PlanningHasEntity,
                                            planningService?: PlanningService,
                                            projectService?: ProjectService,
                                            clearWhenInvalid?: boolean) => {
    return (input: FormControl) => {
        if (planning?.begindate && planning.enddate && input.value) {
            const begindate = Utils.getTimeOrNull(planning?.begindate);
            const enddate = Utils.getTimeOrNull(planning?.enddate);
            let res = false;
            return combineLatest(
                planningService.getFilteredList(Utils.newDate(planning.begindate), Utils.newDate(planning.enddate)),
                projectService.getFilteredList(Utils.newDate(planning.begindate), Utils.newDate(planning.enddate))
            ).pipe(first(), map(([planningList, projectList]) => {
                planningList?.forEach(pln => {
                    pln.planning_has.forEach(planningHas => {
                        if (planningHas.id !== planning.id) {
                            if (planningHas.driver_user_id === input.value) {
                                if (Utils.getTimeOrNull(planningHas.begindate) < enddate && Utils.getTimeOrNull(planningHas.enddate) > begindate) {
                                    res = true;
                                }
                            }
                        }
                    });

                    pln.user_planning.forEach(userplanning => {
                        if (userplanning.user_id === input.value) {
                            if (Utils.getTimeOrNull(userplanning.begindate) < enddate && Utils.getTimeOrNull(userplanning.enddate) > begindate) {
                                res = true;
                            }
                        }
                    });
                });
                projectList.forEach(project => {
                    project.user_planning.forEach(userplanning => {
                        if (userplanning.user_id === input.value) {
                            if (Utils.getTimeOrNull(userplanning.begindate) < enddate && Utils.getTimeOrNull(userplanning.enddate) > begindate) {
                                res = true;
                            }
                        }
                    });
                });
                if (clearWhenInvalid && res) {
                    input.reset();
                    res = false;
                }
                return res ? {notAvailable: res} : null;
            }));
        }
        return of(null);
    };
};
