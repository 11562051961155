import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Settings} from '../settings.class';
import {tap} from 'rxjs/operators';
import {ApiService} from './api/api.service';
import {TravelData} from '../classes/travel-data';

@Injectable()
export class LocationService {

    dvdsAddress: Address = {
        street: 'Floridadreef',
        housenumber: '19',
        zipcode: '3565 AM',
        town: 'Utrecht'
    };

    constructor(private http: HttpClient, private apiService: ApiService) {
    }

    formatAddress(address: Address) {
        if (!address) {
            return '';
        }
        return `${address.street} ${address.housenumber}, ${address.zipcode} ${address.town}`;
    }

    getLocations(term: string) {
        const url = `https://dev.virtualearth.net/REST/v1/Locations?q=${term}&key=${Settings.BING_MAPS_API_KEY}&c=nl&inclnb=1`;
        return this.http.jsonp<{
            resourceSets: {
                estimatedTotal: number,
                resources: {
                    address: {
                        formattedAddress: string,
                        addressLine: string,
                        adminDistrict2: string
                    }
                }[]
            }[]
        }>(url, 'jsonp').pipe(tap(addresses => {
            addresses.resourceSets[0].resources.forEach(res => {
                if (!res.address.addressLine) {
                    res.address.formattedAddress = res.address.formattedAddress.replace(/,/, ' ' + res.address.adminDistrict2 + ', ');
                }
            });
        }));
    }

    autoRoute(from: string, to: string) {
        return this.apiService.getCall$<TravelData>('location/auto', {from, to});
    }

}

export interface Address {
    street;
    housenumber;
    zipcode;
    town;
}
