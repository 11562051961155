<div class="modal-inner" *ngIf="step == 'project'">
    <h1>Kies een project</h1>
    <h2 *ngIf="settlement.afas_project_id">Huidig: <strong>{{settlement.afas_project_id}}</strong></h2>
    <input placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
    <div class="results">
        <div class="result text-center" *ngIf="searching">
            aan het zoeken...
        </div>
        <div class="result"
             (click)="form.controls.afas_project_id.setValue(project.projectId);saveAndClose()"
             *ngFor="let project of projects">
            <strong>{{project.projectId}}</strong> {{project.description}}
        </div>
        <div class="result" *ngIf="!searching && projects?.length == 0">Geen projecten gevonden</div>
    </div>
    <div class="buttons">
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>

<div class="modal-inner" *ngIf="step == 'hourtype'">
    <h1>Kies een uursoort</h1>
    <h2>
        Huidig: <strong>{{settlement.hourtype?.code}}</strong> {{settlement.hourtype?.description}} <small>{{settlement.hourtype?.type}}</small>
    </h2>
    <input placeholder="Zoeken..." [(ngModel)]="itemSearch">
    <div class="results">
        <div class="result"
             (click)="form.controls.hourtype_code.setValue(hourtype.code);saveAndClose()"
             *ngFor="let hourtype of hourtypes | search : 'description' : itemSearch">
            <strong>{{hourtype.code}}</strong> {{hourtype.description}} <small>{{hourtype.type}}</small>
        </div>
    </div>
    <div class="buttons">
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
