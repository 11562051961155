<div class="controls">
    <div class="header">
        <app-header [mainTitle]="environment.isCodalt ? 'Planning Codalt' : 'Planning DvdS'" subTitle="Profiel"></app-header>
    </div>
    <div class="indicator">
    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="overview">
    <div class="container my-3">
        <div *ngIf="form" class="row justify-content-center">
            <div class="col-12">
                <form [formGroup]="form">
                    <mat-form-field class="w-100" id="name">
                        <input formControlName="name" matInput name="name" placeholder="Naam" type="text">
                        <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" id="email">
                        <input formControlName="email" matInput name="email" placeholder="E-mailadres" type="email">
                        <mat-error>Vul een correct e-mailadres in</mat-error>
                    </mat-form-field>
                    <div class="row justify-content-center" *ngIf="form">
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="spacer"></div>
                                <button (click)="save()" [disabled]="form.invalid || saving" color="primary" id="btn-save" mat-raised-button>
                                    Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <ng-container *ngIf="UserType.WORKPLANNER | hasUserGroup">
                    <h3>Afas koppeling</h3>
                    <div class="row mb-4">
                        <div class="col-1 text-center align-items-center justify-content-center d-flex pr-0">
                            <i class="fa fa-2x" [class.fa-check]="employee" [class.fa-crosshairs]="!employee"></i>
                        </div>
                        <div class="col-auto pl-0">
                            <b>{{afasUser ? 'Gekoppeld aan Afas' : 'Niet gekoppeld aan Afas'}}</b>
                            <br>
                            <ng-container *ngIf="afasUser">
                                {{afasUser.userId}}
                                <ng-container *ngIf="employee">
                                    / {{employee?.employeeId}} / {{employee?.personId}}
                                    / {{employee?.firstName}} {{employee?.prefixBirthName}} {{employee?.birthName}}
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col" *ngIf="!otpRequesting && !otpRequested">
                            <button mat-raised-button color="warn" *ngIf="afasUser" (click)="deleteTokens()">Ontkoppelen</button>
                            <button mat-raised-button color="primary" *ngIf="!afasUser" (click)="otpRequest=true">Koppelen</button>
                        </div>
                    </div>
                </ng-container>

                <div class="row">
                    <div class="col-12">
                        <form class="example-form" name="a" *ngIf="otpRequest">
                            <h3>Activatiecode Afas aanvagen</h3>
                            <mat-form-field>
                                <span matPrefix>{{environmentCode}}</span>
                                <input matInput
                                       placeholder="Gebruikersnaam AFAS"
                                       minlength="2"
                                       required
                                       maxlength="20"
                                       #nameInput="ngModel"
                                       name="name"
                                       [(ngModel)]="user">
                                <mat-error *ngIf="nameInput.errors">
                                    Vul je gebruikersnaam in
                                </mat-error>
                            </mat-form-field>
                            <br>
                            <div class="mat-error" *ngIf="requestOtpError">
                                Er is iets fout gegaan ({{requestOtpError}})<br><br>
                            </div>
                            <button mat-raised-button
                                    color="primary"
                                    [disabled]="otpRequesting"
                                    (click)="requestOtp()">
                                {{otpRequesting ? 'Aan het versturen...' : 'Verstuur activatiecode'}}
                            </button>
                        </form>


                        <form *ngIf="otpRequested">
                            <h3>Activatiecode Afas invullen</h3>
                            <p>
                                Er is een activatiecode verstuurd naar het e-mailadres gekoppeld aan
                                <b>{{environmentCode}}{{user}}</b>. Vul dit wachtwoord hieronder in.
                                <br>
                                <b>Let op:</b> dit is niet het wachtwoord waarmee je inlogt bij Afas Profit.
                            </p>
                            <mat-form-field class="mb-3">
                                <input matInput
                                       id="passwordField"
                                       placeholder="activatiecode"
                                       [(ngModel)]="otp"
                                       name="otp"
                                       required
                                       numeric
                                       minlength="6"
                                       #otpInput="ngModel">
                                <mat-error *ngIf="otpInput.errors">
                                    Vul de activatiecode in dat je per e-mail ontvangen hebt
                                </mat-error>
                            </mat-form-field>
                            <div class="mat-error" *ngIf="validateOtpError">
                                Er is iets fout gegaan ({{validateOtpError}})<br><br>
                            </div>

                            <div class="button-row">
                                <button mat-raised-button
                                        color="primary"
                                        class="mb-2"
                                        [disabled]="otpValidating"
                                        (click)="validateOtp()">
                                    {{otpValidating ? 'Activatiecode valideren...' : 'Activatiecode bevestigen'}}
                                </button>
                                <button mat-raised-button (click)="reset()">Opnieuw proberen</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="UserType.ADMIN | hasUserGroup">
                <form [formGroup]="relForm" *ngIf="relForm">
                    <ng-select formControlName="EmId" placeholder="Boeken op" [clearable]="true">
                        <ng-option [value]="employee.employeeId" *ngIf="employee">{{employee.firstName}} {{employee.birthName}} ({{employee.functionDesc}})
                        </ng-option>
                        <ng-option *ngFor="let mat of materieel" [value]="mat.employeeId">{{mat.firstName}} {{mat.birthName}} ({{mat.functionDesc}})</ng-option>
                        <ng-option *ngFor="let mat of employees" [value]="mat.employeeId">{{mat.firstName}} {{mat.birthName}} ({{mat.functionDesc}})</ng-option>
                    </ng-select>
                    <ng-select formControlName="PrId"
                               placeholder="Project"
                               [clearable]="true"
                               groupBy="mainProject"
                               [selectableGroup]="false"
                               [items]="projects"
                               bindLabel="description"
                               bindValue="projectId">
                        <ng-template ng-label-tmp let-item="item">
                            <b>{{item.projectId}}</b> {{item.description}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <b>{{item.projectId}}</b> {{item.description}}
                        </ng-template>
                    </ng-select>
                    <ng-select formControlName="StId"
                               placeholder="Uursoort"
                               [items]="hourtypes"
                               bindLabel="description"
                               bindValue="code">
                    </ng-select>
                    <mat-form-field class="w-100" id="DaTi">
                        <input formControlName="DaTi" matInput name="datum" placeholder="Datum (yyyy-mm-dd)" type="text">
                        <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" id="Ds">
                        <input formControlName="Ds" matInput name="description" placeholder="Omschrijving" type="text">
                        <mat-error>Vul een correct e-mailadres in</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" id="Qu">
                        <input appDecimalInput [decimalInput]="4" formControlName="Qu" matInput name="aantal" placeholder="Aantal" type="text">
                        <mat-error>Vul een correct e-mailadres in</mat-error>
                    </mat-form-field>

                    <div class="row justify-content-center" *ngIf="form">
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="spacer"></div>
                                <button (click)="saveRealization()" [disabled]="form.invalid || savingRealization" color="primary" id="btn-save-rea"
                                        mat-raised-button>
                                    Opslaan <i *ngIf="savingRealization" class="fas fa-circle-notch fa-spin"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="container-fluid pb-5" *ngIf="UserType.ADMIN | hasUserGroup">
        <h3>Uren</h3>
        <ng-select [formControl]="fcEmployee" name="employee-filter" placeholder="Kies materieel of medewerker" [clearable]="true">
            <ng-option [value]="employee.employeeId" *ngIf="employee">{{employee.firstName}} {{employee.birthName}} ({{employee.functionDesc}})</ng-option>
            <ng-option *ngFor="let mat of materieel" [value]="mat.employeeId">{{mat.firstName}} {{mat.birthName}} ({{mat.functionDesc}})</ng-option>
            <ng-option *ngFor="let employee of employees" [value]="employee.employeeId">{{employee.firstName}} {{employee.birthName}} ({{employee.functionDesc}}
                )
            </ng-option>
        </ng-select>

        <table class="table table-striped">
            <thead>
            <tr>
                <th>identity</th>
                <th>year</th>
                <th>periodId</th>
                <th>Datum</th>
                <th>employeeId</th>
                <th>projectId</th>
                <th>description</th>
                <th>itemType</th>
                <th>itemCodeId</th>
                <th>Aantal</th>
                <th>chargeable</th>
                <th>approved</th>
                <th>prepared</th>
                <th *ngIf="false">dateModified</th>
                <th *ngIf="false">systemDate</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let rea of realization">
                <td>{{rea.identity}}</td>
                <td>{{rea.year}}</td>
                <td>{{rea.periodId}}</td>
                <td class="text-nowrap">{{rea.dateTime | date: 'EEE dd MMM y HH:mm'}}</td>
                <td>{{rea.employeeId}}</td>
                <td>{{rea.projectId}}</td>
                <td>{{rea.description}}</td>
                <td>{{rea.itemType}}</td>
                <td>{{rea.itemCodeId}}</td>
                <td>{{rea.quantityUnit}} {{rea.initId}}</td>
                <td>{{rea.chargeable}}</td>
                <td>{{rea.approved}}</td>
                <td>{{rea.prepared}}</td>
                <td *ngIf="false" class="text-nowrap">{{rea.dateModified | date: 'EEE dd MMM y HH:mm'}}</td>
                <td *ngIf="false" class="text-nowrap">{{rea.systemDate | date: 'EEE dd MMM y HH:mm'}}</td>
            </tr>
            </tbody>
        </table>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingRealization"></mat-progress-bar>
    </div>
</div>
